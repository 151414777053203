import { Employee, EmploymentType, TimeEntryType, getEmploymentTypes } from "@pentacode/core/src/model";
import { MonthlyStatement } from "@pentacode/core/src/statement";
import { getRange, toDateString, parseDateString, monthNames, formatNumber as fmtNum } from "@pentacode/core/src/util";
import { TimeResult } from "@pentacode/core/src/hours";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Chart, ChartConfig } from "./chart";
import "./spinner";
import { DateString } from "@pentacode/openapi/src/units";

const formatNumber = (val: number) => fmtNum(val, 0);

interface ChartSeries {
    name: string;
    data: number[];
    type?: string;
    color: string;
}

@customElement("ptc-reports-costs")
export class Reports extends Routing(StateMixin(LitElement)) {
    routePattern = /^reports\/costs/;

    get routeTitle() {
        return "Personalkostenbericht";
    }

    @state()
    private _loading = false;

    @state()
    private _statements: MonthlyStatement[] = [];

    @state()
    private _selectedEmployee: Employee | null = null;

    @state()
    private _segmentation: "status" | "employment" | "department" = "status";

    @state()
    private _filterString = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query(".chart-cost-monthly")
    private _chartCostMonthly: Chart;

    @query(".chart-cost-total")
    private _chartCostTotal: Chart;

    private _updateFilter() {
        this._filterString = this._filterInput.value;
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    handleRoute() {
        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }
        this.load();
    }

    updated(changes: Map<string, any>) {
        if (
            (changes.has("_statements") || changes.has("_segmentation") || changes.has("_selectedEmployee")) &&
            this._chartCostMonthly &&
            this._chartCostTotal
        ) {
            this._updateCharts();
        }
    }

    async load() {
        if (!this.year) {
            return;
        }

        let { from, to } = getRange(`${this.year}-01-01` as DateString, "year");

        const currMonth = getRange(toDateString(new Date()), "month");

        if (to > currMonth.to) {
            to = currMonth.to;
        }

        this._loading = true;

        try {
            this._statements = await app.getMonthlyStatements({
                // Also get dezember statement from previous year to show balance
                from,
                to,
            });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _getCosts({
        status = "total",
        month,
        employee,
        employment,
        department: depId,
    }: {
        status?: "work" | "breaks" | "commission" | "vacation" | "sick" | "bonus" | "total" | "benefits";
        month?: number;
        employee?: number;
        employment?: EmploymentType;
        department?: number;
    }) {
        let statements = this._statements;
        if (typeof month === "number") {
            statements = statements.filter((s) => s.month === month);
        }
        if (typeof employee === "number") {
            statements = statements.filter((s) => s.employeeId === employee);
        }
        if (typeof employment === "number") {
            statements = statements.filter((s) => {
                const employee = app.getEmployee(s.employeeId);
                const contract = employee && employee.getContractForMonth(s.year, s.month);
                return contract && employment === contract.employmentType;
            });
        }
        if (typeof depId === "number") {
            function reduceCost(hours: TimeResult[]) {
                return hours.reduce((total, h) => total + h.salaryCost + h.commissionCost + h.bonusCost, 0);
            }
            return statements.reduce((total, s) => {
                const emp = app.getEmployee(s.employeeId)!;
                const empDepartments = new Set(emp.positions.map((p) => p.departmentId));
                const totalWorkCost = reduceCost(s.time.distinct.filter((h) => h.type === TimeEntryType.Work));
                const totalDepCost = reduceCost(
                    s.time.distinct.filter((h) => {
                        const { department } = (h.position && app.getPosition(h.position)) || { department: null };
                        return department && department.id === depId;
                    })
                );

                const depRatio = totalWorkCost
                    ? totalDepCost / totalWorkCost
                    : empDepartments.has(depId)
                      ? 1 / empDepartments.size
                      : 0;

                return total + s.cost.total * depRatio;
            }, 0);
        } else {
            return statements.reduce((total, s) => total + s.cost[status], 0);
        }
    }

    private _getCostSeriesByStatus() {
        const employee = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        return [
            {
                name: "Arbeit",
                data: monthNames.map((_n, month) => this._getCosts({ status: "work", employee, month })),
                color: colors.green,
            },
            {
                name: "Pausen",
                data: monthNames.map((_n, month) => this._getCosts({ status: "breaks", employee, month })),
                color: colors.yellow,
            },
            {
                name: "Provision",
                data: monthNames.map((_n, month) => this._getCosts({ status: "commission", employee, month })),
                color: colors.red,
            },
            {
                name: "Urlaub",
                data: monthNames.map((_n, month) => this._getCosts({ status: "vacation", employee, month })),
                color: colors.blue,
            },
            {
                name: "Krank",
                data: monthNames.map((_n, month) => this._getCosts({ status: "sick", employee, month })),
                color: colors.orange,
            },
            {
                name: "Zuschläge",
                data: monthNames.map((_n, month) => this._getCosts({ status: "bonus", employee, month })),
                color: colors.purple,
            },
            {
                name: "Zusatzleistungen",
                data: monthNames.map((_n, month) => this._getCosts({ status: "benefits", employee, month })),
                color: colors.teal,
            },
        ];
    }

    private _getCostSeriesByEmploymentType() {
        const employee = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        return getEmploymentTypes().map(({ type, label, color }) => ({
            name: label,
            data: monthNames.map((_n, month) => this._getCosts({ employee, month, employment: type })),
            color,
        }));
    }

    private _getCostSeriesByDepartment() {
        const employee = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        const series = app.venues
            .flatMap((venue) =>
                venue.departments.map((dep) => ({
                    name: dep.name,
                    data: monthNames.map((_n, month) => this._getCosts({ employee, month, department: dep.id })),
                    color: colors[dep.color] || dep.color,
                }))
            )
            .filter((s) => s.data.some((val) => !!val));

        series.push({
            name: "Sonstige",
            data: monthNames.map((_, month) => {
                const covered = series.reduce((t, s) => t + s.data[month], 0);
                const total = this._getCosts({ month });
                return Math.max(0, total - covered);
            }),
            color: colors.grey,
        });

        return series;
    }

    private _getChartConfigCostMonthly(series: ChartSeries[]): ChartConfig {
        return {
            chart: {
                type: "area",
                stacked: true,
                height: 400,
                selection: { enabled: false },
            },
            colors: series.map((each: any) => each.color),
            legend: {
                show: false,
            },
            series: series,
            labels: monthNames.map((n) => n.slice(0, 3)),
            xaxis: {
                type: "category",
            },
            yaxis: {
                title: { text: "Personalkosten (Tausend Euro)" },
                labels: {
                    formatter: (val: number) => (val / 1000).toFixed(0),
                },
                opposite: true,
                forceNiceScale: true,
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val: number) => formatNumber(val) + " €",
                },
                x: {
                    formatter: (val: number) =>
                        `${monthNames[val - 1]}: ${formatNumber(
                            series.reduce((total, s) => total + s.data[val - 1], 0)
                        )} €`,
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            fill: {
                type: "solid",
                opacity: 1,
            },
            stroke: {
                show: false,
                curve: "smooth",
            },
        };
    }

    private _getChartConfigCostTotal(monthlySeries: ChartSeries[]): ChartConfig {
        const series = monthlySeries.map((s) => s.data.reduce((total: number, val: number) => total + val, 0));
        const labels = monthlySeries.map((s) => s.name);
        const colors = monthlySeries.map((s) => s.color);

        return {
            chart: {
                type: "donut",
                height: "300",
                selection: { enabled: false },
            },
            colors,
            legend: {
                show: true,
                position: "bottom",
                // formatter: function(val: string, opts: any) {
                //     return val + ": " + formatNumber(opts.w.globals.series[opts.seriesIndex]) + " €";
                // }
            },
            labels,
            series,
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: (val: number) => formatNumber(val) + " €",
                },
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                label: "Gesamt",
                                showAlways: true,
                                show: true,
                                formatter: (w: any) =>
                                    formatNumber(
                                        w.globals.seriesTotals.reduce((a: number, b: number) => {
                                            return a + b;
                                        }, 0)
                                    ) + " €",
                            },
                            value: {
                                formatter: (val: any) => formatNumber(val) + " €",
                            },
                        },
                    },
                },
            },
        };
    }

    private _updateCharts() {
        let series: ChartSeries[] =
            this._segmentation === "employment"
                ? this._getCostSeriesByEmploymentType()
                : this._segmentation === "department"
                  ? this._getCostSeriesByDepartment()
                  : this._getCostSeriesByStatus();

        series = series.filter((s) => s.data.some((val) => !!val));

        this._chartCostMonthly.config = this._getChartConfigCostMonthly(series);
        this._chartCostTotal.config = this._getChartConfigCostTotal(series);
    }

    private _yearSelected(e: Event) {
        const date = parseDateString(this.date) || new Date();
        date.setFullYear(parseInt((e.target as HTMLSelectElement).value));
        this.go(null, { date: toDateString(date) });
    }

    private _selectEmployee(employee: Employee | null) {
        this._selectedEmployee = employee;
        this.renderRoot.querySelector(".charts")!.scrollIntoView();
    }

    static styles = [
        shared,
        css`
            :host {
                display: grid;
                grid-template-columns: 200px 1fr;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .main {
                position: relative;
            }

            .scroller {
                overflow: auto;
            }

            .year-selector {
                font-size: var(--font-size-medium);
                padding-top: 0.3em;
                padding-bottom: 0.3em;
                margin-left: 0.5em;
            }

            .charts {
                position: sticky;
                left: 0;
                padding: 0 1em;
                display: flex;
                align-items: center;
                z-index: 10;
            }

            .charts-cost-total {
                flex: 1;
            }

            .charts-cost-monthly {
                flex: 3;
            }

            .charts-title {
                font-size: var(--font-size-large);
                padding: 0.5em;
                font-weight: 600;
                text-align: center;
            }

            .table {
                margin: 0 0.5em;
            }

            .row {
                display: grid;
                grid-template-columns: 12em repeat(13, 1fr);
                background: var(--color-bg);
                min-width: 75em;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .column {
                padding: 0.5em;
                text-align: right;
                min-width: 0;
            }

            .column:not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 1;
            }

            .header-column {
                text-align: left;
                position: sticky;
                left: 0.5em;
                background: inherit;
                border-radius: inherit;
            }

            .header-row > .column {
                font-weight: 600;
                border: none;
                text-align: center;
            }

            .header-row > .header-column {
                padding-left: 0;
                padding-bottom: 0.3em;
            }

            .filter-input input {
                font-weight: normal;
                padding-top: 0.4em;
                padding-bottom: 0.4em;
            }

            .row.total-row > .column {
                font-weight: bold;
            }

            .total-column {
                font-weight: 600;
            }

            .row:not(.header-row):hover:not([active]) {
                background: #f1f1f1;
                cursor: pointer;
            }

            .row[active] {
                border-radius: var(--border-radius);
                border-color: transparent;
                background: var(--color-primary);
                color: var(--color-bg);
                position: sticky;
                top: 3.5em;
                bottom: 0.5em;
                z-index: 2;
                font-weight: bold;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        const years: number[] = [];
        for (let year = new Date().getFullYear(); year > 2010; year--) {
            years.push(year);
        }

        const employees = app.accessibleEmployees.filter(({ name }) =>
            name.toLowerCase().includes(this._filterString.toLowerCase())
        );

        return html`
            <div class="fullbleed vertical layout">
                <div class="header horizontal center-aligning layout padded-medium noprint">
                    <div class="stretch"></div>

                    <div class="tabs">
                        <button
                            ?active=${this._segmentation === "status"}
                            @click=${() => (this._segmentation = "status")}
                        >
                            Status
                        </button>
                        <button
                            ?active=${this._segmentation === "employment"}
                            @click=${() => (this._segmentation = "employment")}
                        >
                            Anstellung
                        </button>
                        <button
                            ?active=${this._segmentation === "department"}
                            @click=${() => (this._segmentation = "department")}
                        >
                            Abteilung
                        </button>
                    </div>

                    <div class="stretch"></div>

                    <select class="year-selector" .value=${this.year.toString()} @change=${this._yearSelected}>
                        ${years.map((year) => html` <option .value=${year.toString()}>${year}</option> `)}
                    </select>
                </div>

                <div class="scroller stretch">
                    <div class="charts">
                        <div class="charts-cost-total">
                            <div class="charts-title">
                                ${(this._selectedEmployee && this._selectedEmployee.name) || "Alle Mitarbeiter"}
                            </div>
                            <ptc-chart class="chart-cost-total"></ptc-chart>
                        </div>
                        <div class="charts-cost-monthly">
                            <ptc-chart class="chart-cost-monthly"></ptc-chart>
                        </div>
                    </div>

                    <div class="table">
                        <div class="row header-row">
                            <div class="column header-column">
                                <div class="filter-input right icon input">
                                    <input
                                        id="filterInput"
                                        type="text"
                                        placeholder="Suchen..."
                                        @input=${this._updateFilter}
                                    />
                                    <i
                                        class="${this._filterString ? "times click" : "search"} icon"
                                        @click=${this._clearFilter}
                                    ></i>
                                </div>
                            </div>

                            ${monthNames.map(
                                (n) => html`
                                    <div class="column vertical end-justifying layout">
                                        <div>${n.slice(0, 3)}</div>
                                    </div>
                                `
                            )}

                            <div class="column vertical end-justifying layout total-column"><div>Gesamt</div></div>
                        </div>

                        <div
                            class="row total-row"
                            ?active=${!this._selectedEmployee}
                            @click=${() => this._selectEmployee(null)}
                        >
                            <div class="column header-column">Alle Mitarbeiter</div>

                            ${monthNames.map(
                                (_n, month) => html`
                                    <div class="column">
                                        ${formatNumber(this._getCosts({ status: "total", month }))}
                                    </div>
                                `
                            )}

                            <div class="column total-column">${formatNumber(this._getCosts({ status: "total" }))}</div>
                        </div>

                        ${employees.map((employee) => {
                            const data = monthNames.map((_n, month) =>
                                this._getCosts({
                                    status: "total",
                                    employee: employee.id,
                                    month,
                                })
                            );

                            if (data.every((d) => !d)) {
                                return null;
                            }

                            return html`
                                <div
                                    class="row"
                                    ?active=${this._selectedEmployee && this._selectedEmployee.id === employee.id}
                                    @click=${() => this._selectEmployee(employee)}
                                >
                                    <div class="column header-column ellipsis">
                                        ${employee.lastName}, ${employee.firstName}
                                    </div>

                                    ${data.map((val) => html` <div class="column">${formatNumber(val)}</div> `)}

                                    <div class="column total-column">
                                        ${formatNumber(data.reduce((total, m) => total + m))}
                                    </div>
                                </div>
                            `;
                        })}
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
