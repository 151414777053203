import {
    TimeSettings,
    BreakMode,
    AutoBreak,
    Feature,
    BreakTiming,
    timeLogActionLabel,
    TimeLogAction,
    timeLogActionIcon,
    MealsMode,
    breakModeLabel,
} from "@pentacode/core/src/model";
import { toDurationString, parseDurationString } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { shared } from "../styles";
import { app } from "../init";
import { contactSupport } from "../lib/help";
import { Checkbox } from "./checkbox";
import "./popover";
import "./help";
import { confirm } from "./alert-dialog";
import { TimeInput } from "./time-input";
import { Drawer } from "./drawer";
import "./drawer";
import { EntityMultiSelect } from "./entity-multi-select";
import { Hours, Meals, add } from "@pentacode/openapi/src/units";

@customElement("ptc-time-settings-form")
export class TimeSettingsForm extends LitElement {
    @property({ attribute: false })
    settings: TimeSettings;

    @property({ attribute: false })
    hideFields: string[] = [];

    @state()
    private _autoBreaks: AutoBreak[] = [];

    @state()
    private _expandedSections = new Set<string>();

    @query("form")
    private _form: HTMLFormElement;

    @query("ptc-checkbox-button[name='trackingEnabled']")
    private _trackingCheckbox: Checkbox;

    @query("ptc-checkbox-button[name='loggingRequiresPhoto']")
    private _requirePhotoCheckbox: Checkbox;

    @query("[name='loggingRequiresPhotoFor']")
    private _requirePhotoInput: EntityMultiSelect<TimeLogAction>;

    @query("[name='bonusNight1Start']")
    private _night1StartInput: TimeInput;

    @query("[name='bonusNight1End']")
    private _night1EndInput: TimeInput;

    @query("[name='bonusNight2Start']")
    private _night2StartInput: TimeInput;

    @query("[name='bonusNight2End']")
    private _night2EndInput: TimeInput;

    get data(): Partial<TimeSettings> {
        if (!this._form) {
            return {};
        }

        const data = new FormData(this._form);

        const autoBreaksDuration = data.getAll("autoBreakDuration") as string[];
        const autoBreaksAmount = data.getAll("autoBreakAmount") as string[];

        const autoBreaks = [];

        for (let i = 0; i < autoBreaksDuration.length; i++) {
            autoBreaks.push({
                duration: parseDurationString(autoBreaksDuration[i]),
                amount: (parseInt(autoBreaksAmount[i]) / 60) as Hours,
            });
        }

        return {
            trackingEnabled: data.has("trackingEnabled"),
            applyEarlyCheckin: JSON.parse(data.get("applyEarlyCheckin") as string),
            applyLateCheckout: JSON.parse(data.get("applyLateCheckout") as string),
            checkinWindow: parseFloat(data.get("checkinWindow") as string) as Hours,
            checkoutWindow: parseFloat(data.get("checkoutWindow") as string) as Hours,
            checkinRounding: parseFloat(data.get("checkinRounding") as string) as Hours,
            checkoutRounding: parseFloat(data.get("checkoutRounding") as string) as Hours,
            breakMode: parseInt(data.get("breakMode") as string),
            breakTiming: data.get("breakTiming") as BreakTiming,
            unplannedCheckins: data.has("unplannedCheckins"),
            trackerDisplayEmployeeInfo: data.has("trackerDisplayEmployeeInfo"),
            trackerDisplayShiftDetails: data.has("trackerDisplayShiftDetails"),
            trackerDisplayRoster: data.has("trackerDisplayRoster"),
            mealsMode: data.get("mealsMode") as MealsMode,
            autoMeals: parseInt(data.get("autoMeals") as string) as Meals,
            mealEnabledBreakfast: data.has("mealEnabledBreakfast"),
            mealStartBreakfast: (data.get("mealStartBreakfast") as string) || null,
            mealEndBreakfast: (data.get("mealEndBreakfast") as string) || null,
            mealEnabledLunch: data.has("mealEnabledLunch"),
            mealStartLunch: (data.get("mealStartLunch") as string) || null,
            mealEndLunch: (data.get("mealEndLunch") as string) || null,
            mealEnabledDinner: data.has("mealEnabledDinner"),
            mealStartDinner: (data.get("mealStartDinner") as string) || null,
            mealEndDinner: (data.get("mealEndDinner") as string) || null,
            mealsMinDuration: parseDurationString((data.get("mealsMinDuration") as string) || "00:00"),
            autoBreaks: autoBreaks.sort((a, b) => a.duration - b.duration),
            paidBreaksAuto: data.has("paidBreaksAuto"),
            paidBreaksManual: data.has("paidBreaksManual"),
            trackingViaAppEnabled: data.has("trackingViaAppEnabled"),
            trackingViaAppRequiresToken: data.has("trackingViaAppRequiresToken"),
            loggingRequiresPhotoFor: this._requirePhotoInput.selected,
            loggingRequiresLocation: data.has("loggingRequiresLocation"),
            bonusNight1Start: data.get("bonusNight1Start") as string,
            bonusNight1End: data.get("bonusNight1End") as string,
            bonusNight2Start: data.get("bonusNight2Start") as string,
            bonusNight2End: data.get("bonusNight2End") as string,
            bonusMinDuration: parseDurationString((data.get("bonusMinDuration") as string) || "00:00"),
            bonusSundayNextDayUntil: data.get("bonusSundayNextDayUntil") as string,
            bonusHolidayNextDayUntil: data.get("bonusHolidayNextDayUntil") as string,
            bonusChristmasEveFrom: data.get("bonusChristmasEveFrom") as string,
            bonusNewYearsEveFrom: data.get("bonusNewYearsEveFrom") as string,
        };
    }

    get hasChanged() {
        const { autoBreaks, loggingRequiresPhotoFor, ...rest } = this.data;
        const s = this.settings;
        if (!autoBreaks || !s) {
            return false;
        }

        const loggingRequiresPhotoChanged =
            loggingRequiresPhotoFor?.length !== this.settings.loggingRequiresPhotoFor.length ||
            loggingRequiresPhotoFor.some((action) => !this.settings.loggingRequiresPhotoFor.includes(action));

        const rulesChanged =
            Object.entries(rest).some(([key, value]) => s[key as keyof typeof s] !== value) ||
            autoBreaks.length !== s.autoBreaks.length ||
            autoBreaks.some((b, i) => b.duration !== s.autoBreaks[i].duration || b.amount !== s.autoBreaks[i].amount) ||
            loggingRequiresPhotoChanged;
        return rulesChanged;
    }

    checkValidity() {
        this._checkNight1Times();
        this._checkNight2Times();
        return this._form.checkValidity();
    }

    reportValidity() {
        this._checkNight1Times();
        this._checkNight2Times();
        return this._form.reportValidity();
    }

    init(settings: TimeSettings) {
        this.settings = settings;
        this._expandedSections.clear();
        this._resetForm();
        this.requestUpdate();
    }

    updated() {
        this.renderRoot.querySelectorAll("ptc-drawer").forEach((drawer: Drawer) => drawer.updateInnerSize());
    }

    private _change() {
        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("change"));
    }

    private _resetForm() {
        for (let [prop, value] of Object.entries(this.settings)) {
            if (prop === "bonusMinDuration" || prop === "mealsMinDuration") {
                value = toDurationString(value);
            }

            const inputs = this.renderRoot.querySelectorAll(`[name=${prop}]`) as NodeListOf<HTMLInputElement>;
            for (const inp of inputs) {
                switch (inp.type) {
                    case "date":
                        inp.value = (value || "") as string;
                        break;
                    case "radio":
                        inp.checked = inp.value === (value as any).toString();
                        break;
                    case "checkbox":
                        inp.checked = value as boolean;
                        break;
                    default:
                        inp.value = value as any;
                        break;
                }
            }
        }

        this._autoBreaks = this.settings.autoBreaks || [];
        this._requirePhotoCheckbox.checked = !!this.settings.loggingRequiresPhotoFor.length;
        this._requirePhotoInput.selected = this.settings.loggingRequiresPhotoFor;
    }

    private _addAutoBreak() {
        const { duration, amount } = this._autoBreaks[this._autoBreaks.length - 1] || { duration: 0, amount: 0 };
        this._autoBreaks.push({ duration: add(duration, 3 as Hours), amount: add(amount, 0.25 as Hours) });
        this._change();
    }

    private _removeAutoBreak(i: number) {
        this._autoBreaks.splice(i, 1);
        this._change();
    }

    private async _toggleTracking() {
        const { trackingEnabled } = this.data;
        if (trackingEnabled && !app.company!.features.includes(Feature.Time)) {
            const confirmed = await confirm(
                "Sie möchten die Arbeitszeiten automatisiert und bequem über die digitale Stempeluhr erfassen? " +
                    "Bitte wenden Sie sich an unser Supportteam um zu erfahren, wie Sie dieses " +
                    "Feature freischalten können!",
                "Support Kontaktieren",
                "Abbrechen",
                { title: "Digitale Stempeluhr Freischalten", icon: "stopwatch" }
            );

            if (confirmed) {
                contactSupport("Digitale Stempeluhr Freischalten");
            }

            this._trackingCheckbox.checked = false;
            this._change();
            return;
        }

        trackingEnabled ? this._expandedSections.add("tracking") : this._expandedSections.delete("tracking");
        this.requestUpdate();
    }

    private _toggleRequiresPhoto() {
        if (this._requirePhotoCheckbox.checked) {
            this._requirePhotoInput.selected = ["startShift", "endShift"];
        } else {
            this._requirePhotoInput.selected = [];
        }
    }

    private _checkNight1Times() {
        const start = this._night1StartInput.value?.slice(0, 5);
        const end = this._night1EndInput.value?.slice(0, 5);

        if (!start || !end) {
            this._night1StartInput.setCustomValidity("");
            this._night1EndInput.setCustomValidity("");
            return;
        }

        if (!(start >= "20:00" || start < "06:00")) {
            this._night1StartInput.setCustomValidity("Dieser Wert muss >= 20:00 oder < 06:00 sein.");
            this._night1EndInput.setCustomValidity("");
        } else if (start >= "20:00" && end > "06:00" && end < start) {
            this._night1StartInput.setCustomValidity("");
            this._night1EndInput.setCustomValidity(`Dieser Wert muss > ${start} oder < 06:00 sein.`);
        } else if (start < "06:00" && (end < start || end > "06:00")) {
            this._night1StartInput.setCustomValidity("");
            this._night1EndInput.setCustomValidity(`Dieser Wert muss zwischen ${start} und 06:00 liegen.`);
        } else {
            this._night1StartInput.setCustomValidity("");
            this._night1EndInput.setCustomValidity("");
        }
    }

    private _checkNight2Times() {
        const start = this._night2StartInput.value?.slice(0, 5);
        const end = this._night2EndInput.value?.slice(0, 5);

        if (!start || !end) {
            this._night2StartInput.setCustomValidity("");
            this._night2EndInput.setCustomValidity("");
            return;
        }

        if (start < "00:00" || start > "04:00") {
            this._night2StartInput.setCustomValidity("Dieser Wert muss zwischen 00:00 und 04:00 liegen.");
        } else {
            this._night2StartInput.setCustomValidity("");
        }

        if (end < start || end > "04:00") {
            this._night2EndInput.setCustomValidity(`Dieser Wert muss zwischen ${start} und 04:00 liegen.`);
        } else {
            this._night2EndInput.setCustomValidity("");
        }
    }

    firstUpdated() {
        setTimeout(() => this.requestUpdate(), 50);
    }

    private _toggleSection(section: string) {
        if (!this._expandedSections.has(section)) {
            this._expandedSections.add(section);
        } else {
            this._expandedSections.delete(section);
        }
        this.requestUpdate();
    }

    static styles = [
        shared,
        Checkbox.styles,
        TimeInput.styles,
        css`
            :host {
                display: block;
            }

            .wrapper {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                grid-gap: 0 0.5em;
            }

            .subheader {
                grid-column: 1/-1;
            }

            .auto-breaks > div > * {
                margin-left: 0.5em;
            }

            .auto-breaks > div {
                margin: 0.5em 0;
            }

            .auto-breaks .duration {
                width: 3.5em;
                text-align: right;
                padding: 0.4em 0.6em;
            }

            ptc-time-input {
                width: 5em;
            }

            .auto-breaks > div:not(:hover) button {
                display: none;
            }

            .auto-breaks > button {
                margin-left: 7em;
            }
        `,
    ];

    render() {
        const {
            breakMode,
            trackingEnabled,
            trackingViaAppEnabled,
            mealsMode,
            mealEnabledBreakfast,
            mealEnabledLunch,
            mealEnabledDinner,
            mealStartBreakfast,
            mealEndBreakfast,
            mealStartLunch,
            mealEndLunch,
            mealStartDinner,
        } = this.data;
        return html`
            <form @input=${() => this.requestUpdate()} @change=${this._change}>
                <div class="spacing vertical layout">
                    <div class="box">
                        <button
                            type="button"
                            class="larger small-caps fill-horizontally transparent text-left-aligning"
                            @click=${() => this._toggleSection("breaks")}
                        >
                            <div class="horizontal spacing center-aligning layout">
                                <div class="stretch">
                                    <i class="coffee"></i> Pausen
                                    <ptc-help-anchor page="handbuch/einstellungen/zeiterfassung/#pausen">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </div>
                                <div class="smaller pill">${breakModeLabel(breakMode!)}</div>
                                <i
                                    class="smaller ${this._expandedSections.has("breaks")
                                        ? "chevron-down"
                                        : "chevron-right"}"
                                ></i>
                            </div>
                        </button>
                        <ptc-drawer .collapsed=${!this._expandedSections.has("breaks")}>
                            <div class="padded">
                                <div class="field">
                                    <label>Pausenmodus</label>

                                    <select name="breakMode">
                                        <option value="${BreakMode.Manual}">${breakModeLabel(BreakMode.Manual)}</option>
                                        <option value="${BreakMode.Auto}">${breakModeLabel(BreakMode.Auto)}</option>
                                        <option value="${BreakMode.AutoPlusManual}">
                                            ${breakModeLabel(BreakMode.AutoPlusManual)}
                                        </option>
                                        <option value="${BreakMode.AutoOrManual}">
                                            ${breakModeLabel(BreakMode.AutoOrManual)}
                                        </option>
                                        <option value="${BreakMode.Planned}">
                                            ${breakModeLabel(BreakMode.Planned)}
                                        </option>
                                        <option value="${BreakMode.PlannedPlusManual}">
                                            ${breakModeLabel(BreakMode.PlannedPlusManual)}
                                        </option>
                                        <option value="${BreakMode.PlannedOrManual}">
                                            ${breakModeLabel(BreakMode.PlannedOrManual)}
                                        </option>
                                    </select>
                                </div>

                                <div
                                    class="field"
                                    ?hidden=${![
                                        BreakMode.Auto,
                                        BreakMode.AutoPlusManual,
                                        BreakMode.AutoOrManual,
                                    ].includes(breakMode!)}
                                >
                                    <label>Automatische Pausen</label>
                                    <div class="auto-breaks">
                                        ${this._autoBreaks.map(
                                            (b, i) => html`
                                                <div class="horizontal center-aligning layout">
                                                    <div>Ab</div>
                                                    <ptc-time-input
                                                        .value=${toDurationString(b.duration)}
                                                        required
                                                        name="autoBreakDuration"
                                                    >
                                                    </ptc-time-input>
                                                    <div><strong>Stunden</strong> gebe</div>
                                                    <input
                                                        type="number"
                                                        .value=${(b.amount * 60).toFixed(0)}
                                                        min="15"
                                                        required
                                                        name="autoBreakAmount"
                                                        class="duration"
                                                    />
                                                    <div><strong>Minuten</strong> Pause</div>
                                                    <button
                                                        type="button"
                                                        class="transparent icon"
                                                        @click=${() => this._removeAutoBreak(i)}
                                                    >
                                                        <i class="trash"></i>
                                                    </button>
                                                </div>
                                            `
                                        )}

                                        <button class="transparent" type="button" @click=${this._addAutoBreak}>
                                            <i class="plus"></i> Stufe Hinzufügen
                                        </button>
                                    </div>
                                </div>

                                <div class="field" ?hidden=${breakMode === BreakMode.Manual}>
                                    <ptc-checkbox-button
                                        buttonClass="ghost"
                                        name="paidBreaksAuto"
                                        label="Automatische/Geplante Pausen Werden Bezahlt"
                                    ></ptc-checkbox-button>
                                </div>

                                <div class="field">
                                    <ptc-checkbox-button
                                        ?hidden=${![
                                            BreakMode.Manual,
                                            BreakMode.AutoPlusManual,
                                            BreakMode.PlannedPlusManual,
                                        ].includes(breakMode!)}
                                        buttonClass="ghost"
                                        name="paidBreaksManual"
                                        label="Manuelle Pausen Werden Bezahlt"
                                    ></ptc-checkbox-button>
                                </div>

                                <div class="evenly stretching center-aligning horizontal layout">
                                    <label>Pausen abziehen von...</label>
                                    <select name="breakTiming">
                                        <option .value=${BreakTiming.Start}>Schichtbeginn</option>
                                        <option .value=${BreakTiming.End}>Schichtende</option>
                                    </select>
                                </div>
                            </div>
                        </ptc-drawer>
                    </div>

                    <div class="box">
                        <button
                            type="button"
                            class="larger small-caps fill-horizontally transparent text-left-aligning"
                            @click=${() => this._toggleSection("meals")}
                        >
                            <div class="horizontal spacing center-aligning layout">
                                <div class="stretch">
                                    <i class="utensils"></i> Mitarbeiter-Essen
                                    <ptc-help-anchor page="handbuch/einstellungen/zeiterfassung/#mitarbeiteressen">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </div>
                                <div class="smaller pill">
                                    ${mealsMode === MealsMode.Auto
                                        ? "Automatisch"
                                        : mealsMode === MealsMode.Employee
                                          ? "Durch Mitarbeiter"
                                          : "Manuell"}
                                </div>
                                <i
                                    class="smaller ${this._expandedSections.has("meals")
                                        ? "chevron-down"
                                        : "chevron-right"}"
                                ></i>
                            </div>
                        </button>

                        <ptc-drawer .collapsed=${!this._expandedSections.has("meals")}>
                            <div class="padded">
                                <div class="spacing horizontal layout">
                                    <div class="field stretch">
                                        <label>Erfassung</label>
                                        <select name="mealsMode">
                                            <option .value=${MealsMode.Manual}>Manuell</option>
                                            <option .value=${MealsMode.Employee}>Durch Mitarbeiter</option>
                                            <option .value=${MealsMode.Auto}>Automatisch</option>
                                        </select>
                                    </div>
                                    <div class="field" ?hidden=${mealsMode === MealsMode.Manual}>
                                        <label>Mindestdauer</label>
                                        <ptc-time-input
                                            name="mealsMinDuration"
                                            class="fill-horizontally"
                                        ></ptc-time-input>
                                    </div>
                                    <div class="field" hidden>
                                        <label>Maximale Anzahl (pro Mitarbeiter)</label>
                                        <select name="autoMeals">
                                            <option value="0">Kein Essen</option>
                                            <option value="1">1 Essen pro Tag</option>
                                            <option value="2">Bis zu 2 pro Tag</option>
                                            <option value="3">Bis zu 3 pro Tag</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="box" ?hidden=${mealsMode === MealsMode.Manual}>
                                    <div class="half-padded center-aligning spacing horizontal layout border-bottom">
                                        <ptc-checkbox-button
                                            class="stretch"
                                            buttonClass="slim transparent"
                                            labelPosition="after"
                                            name="mealEnabledBreakfast"
                                            .label=${html` <i class="pancakes"></i> Frühstück `}
                                        ></ptc-checkbox-button>
                                        <div class="subtle smaller">von</div>
                                        <ptc-time-input
                                            name="mealStartBreakfast"
                                            ?disabled=${!mealEnabledBreakfast}
                                            ?required=${mealEnabledBreakfast}
                                        ></ptc-time-input>
                                        <div class="subtle smaller">bis</div>
                                        <ptc-time-input
                                            name="mealEndBreakfast"
                                            min=${mealStartBreakfast || "00:00"}
                                            ?disabled=${!mealEnabledBreakfast}
                                            ?required=${mealEnabledBreakfast}
                                        ></ptc-time-input>
                                    </div>
                                    <div class="half-padded center-aligning spacing horizontal layout border-bottom">
                                        <ptc-checkbox-button
                                            class="stretch"
                                            buttonClass="slim transparent"
                                            labelPosition="after"
                                            name="mealEnabledLunch"
                                            .label=${html` <i class="burger-fries"></i> Mittagessen `}
                                        ></ptc-checkbox-button>
                                        <div class="subtle smaller">von</div>
                                        <ptc-time-input
                                            name="mealStartLunch"
                                            ?disabled=${!mealEnabledLunch}
                                            ?required=${mealEnabledLunch}
                                            min=${(mealEnabledBreakfast && mealEndBreakfast) || "00:00"}
                                        ></ptc-time-input>
                                        <div class="subtle smaller">bis</div>
                                        <ptc-time-input
                                            name="mealEndLunch"
                                            min=${mealStartLunch || "00:00"}
                                            ?disabled=${!mealEnabledLunch}
                                            ?required=${mealEnabledLunch}
                                        ></ptc-time-input>
                                    </div>
                                    <div class="half-padded center-aligning spacing horizontal layout">
                                        <ptc-checkbox-button
                                            class="stretch"
                                            buttonClass="slim transparent"
                                            labelPosition="after"
                                            name="mealEnabledDinner"
                                            .label=${html` <i class="plate-utensils"></i> Abendessen `}
                                        ></ptc-checkbox-button>
                                        <div class="subtle smaller">von</div>
                                        <ptc-time-input
                                            name="mealStartDinner"
                                            ?disabled=${!mealEnabledDinner}
                                            ?required=${mealEnabledDinner}
                                            min=${(mealEnabledLunch && mealEndLunch) ||
                                            (mealEnabledBreakfast && mealEndBreakfast) ||
                                            "00:00"}
                                        ></ptc-time-input>
                                        <div class="subtle smaller">bis</div>
                                        <ptc-time-input
                                            name="mealEndDinner"
                                            min=${mealStartDinner || "00:00"}
                                            ?disabled=${!mealEnabledDinner}
                                            ?required=${mealEnabledDinner}
                                        ></ptc-time-input>
                                    </div>
                                </div>
                            </div>
                        </ptc-drawer>
                    </div>

                    <div class="box">
                        <button
                            type="button"
                            class="larger small-caps fill-horizontally transparent text-left-aligning"
                            @click=${() => this._toggleSection("tracking")}
                        >
                            <div class="horizontal center-aligning spacing layout">
                                <div class="stretch">
                                    <i class="stopwatch"></i> Stempeluhr
                                    <ptc-help-anchor page="handbuch/einstellungen/zeiterfassung/#stempeluhr">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </div>
                                <ptc-checkbox-button
                                    name="trackingEnabled"
                                    @change=${this._toggleTracking}
                                    buttonClass="transparent slim"
                                    .label=${trackingEnabled ? "aktiviert" : "deaktiviert"}
                                    class="negatively-margined"
                                    @click=${(e: Event) => e.stopPropagation()}
                                ></ptc-checkbox-button>
                                <i
                                    class="smaller ${this._expandedSections.has("tracking")
                                        ? "chevron-down"
                                        : "chevron-right"}"
                                ></i>
                            </div>
                        </button>
                        <ptc-drawer .collapsed=${!this._expandedSections.has("tracking")}>
                            <div class="padded">
                                <div class="wrapper" ?disabled=${!trackingEnabled}>
                                    <div class="larger subtle small-caps margined subheader">
                                        <i class="play-circle"></i> Anmeldung
                                    </div>

                                    <div class="field">
                                        <label>Bei Verfrühter Anmeldung</label>

                                        <select name="applyEarlyCheckin">
                                            <option value="false">Übernehme Zeit Laut Dienstplan</option>
                                            <option value="true">Übernehme Aktuelle Zeit</option>
                                        </select>
                                    </div>

                                    <div class="field">
                                        <label>Anmeldung Erlauben</label>

                                        <select name="checkinWindow">
                                            <option value="0.25">15 Minuten Zuvor</option>
                                            <option value="0.5">30 Minuten Zuvor</option>
                                            <option value="1">1 Stunde Zuvor</option>
                                            <option value="2">2 Stunden Zuvor</option>
                                            <option value="3">3 Stunden Zuvor</option>
                                            <option value="4">4 Stunden Zuvor</option>
                                            <option value="5">5 Stunden Zuvor</option>
                                            <option value="6">6 Stunden Zuvor</option>
                                            <option value="7">7 Stunden Zuvor</option>
                                            <option value="8">8 Stunden Zuvor</option>
                                        </select>
                                    </div>

                                    <div class="field">
                                        <ptc-checkbox-button
                                            name="unplannedCheckins"
                                            label="Spontane Schichten Erlauben"
                                            buttonClass="ghost"
                                        ></ptc-checkbox-button>
                                    </div>

                                    <div class="larger subtle small-caps margined subheader">
                                        <i class="stop-circle"></i> Abmeldung
                                    </div>

                                    <div class="field">
                                        <label>Bei Verspäteter Abmeldung</label>

                                        <select name="applyLateCheckout">
                                            <option value="false">Übernehme Zeit Laut Dienstplan</option>
                                            <option value="true">Übernehme Aktuelle Zeit</option>
                                        </select>
                                    </div>

                                    <div class="field">
                                        <label>Automatische Abmeldung</label>

                                        <select name="checkoutWindow">
                                            <option value="8">8 Stunden Nach Anmeldung</option>
                                            <option value="9">9 Stunden Nach Anmeldung</option>
                                            <option value="10">10 Stunden Nach Anmeldung</option>
                                            <option value="11">11 Stunden Nach Anmeldung</option>
                                            <option value="12">12 Stunden Nach Anmeldung</option>
                                            <option value="13">13 Stunden Nach Anmeldung</option>
                                            <option value="14">14 Stunden Nach Anmeldung</option>
                                            <option value="15">15 Stunden Nach Anmeldung</option>
                                            <option value="16">16 Stunden Nach Anmeldung</option>
                                            <option value="17">17 Stunden Nach Anmeldung</option>
                                            <option value="18">18 Stunden Nach Anmeldung</option>
                                        </select>
                                    </div>

                                    <div class="larger subtle small-caps margined subheader">
                                        <i class="calculator-alt"></i> Rundung
                                    </div>

                                    <div class="field">
                                        <label>Bei Anmeldung</label>

                                        <select name="checkinRounding">
                                            <option value="-30">Auf 30 Minuten Abrunden</option>
                                            <option value="-15">Auf 15 Minuten Abrunden</option>
                                            <option value="-10">Auf 10 Minuten Abrunden</option>
                                            <option value="-5">Auf 5 Minuten Abrunden</option>
                                            <option value="0">Keine Rundung</option>
                                            <option value="5">Auf 5 Minuten Aufrunden</option>
                                            <option value="10">Auf 10 Minuten Aufrunden</option>
                                            <option value="15">Auf 15 Minuten Aufrunden</option>
                                            <option value="30">Auf 30 Minuten Aufrunden</option>
                                        </select>
                                    </div>

                                    <div class="field">
                                        <label>Bei Abmeldung</label>

                                        <select name="checkoutRounding">
                                            <option value="-30">Auf 30 Minuten Abrunden</option>
                                            <option value="-15">Auf 15 Minuten Abrunden</option>
                                            <option value="-10">Auf 10 Minuten Abrunden</option>
                                            <option value="-5">Auf 5 Minuten Abrunden</option>
                                            <option value="0">Keine Rundung</option>
                                            <option value="5">Auf 5 Minuten Aufrunden</option>
                                            <option value="10">Auf 10 Minuten Aufrunden</option>
                                            <option value="15">Auf 15 Minuten Aufrunden</option>
                                            <option value="30">Auf 30 Minuten Aufrunden</option>
                                        </select>
                                    </div>

                                    <div class="larger subtle small-caps margined subheader">
                                        <i class="location-dot"></i> Standort
                                    </div>

                                    <div class="field">
                                        <ptc-checkbox-button
                                            name="loggingRequiresLocation"
                                            label="Standort Festhalten"
                                            buttonClass="ghost"
                                        ></ptc-checkbox-button>
                                    </div>

                                    <div class="larger subtle small-caps margined subheader">
                                        <i class="image-polaroid-user"></i> Beweisfoto
                                    </div>

                                    <div class="field">
                                        <ptc-checkbox-button
                                            name="loggingRequiresPhoto"
                                            label="Beweisfoto Aktivieren"
                                            buttonClass="ghost"
                                            @change=${this._toggleRequiresPhoto}
                                        ></ptc-checkbox-button>
                                    </div>

                                    <div class="field" ?hidden=${!this._requirePhotoCheckbox?.checked}>
                                        <ptc-entity-multi-select
                                            .existing=${["startShift", "endShift", "startBreak", "endBreak"]}
                                            .getLabel=${(action: TimeLogAction) => timeLogActionLabel(action)}
                                            .getIcon=${(action: TimeLogAction) => timeLogActionIcon(action)}
                                            name="loggingRequiresPhotoFor"
                                        ></ptc-entity-multi-select>
                                    </div>

                                    <div class="larger subtle small-caps margined subheader">
                                        <i class="eye"></i> Anzeige
                                    </div>

                                    <div class="field">
                                        <ptc-checkbox-button
                                            name="trackerDisplayEmployeeInfo"
                                            label="Mitarbeiter Kurzinfo Anzeigen"
                                            buttonClass="ghost"
                                        ></ptc-checkbox-button>
                                    </div>

                                    <div class="field" ?hidden=${this.hideFields.includes("trackerDisplayRoster")}>
                                        <ptc-checkbox-button
                                            name="trackerDisplayRoster"
                                            label="Aktuellen Dienstplan Anzeigen"
                                            buttonClass="ghost"
                                        ></ptc-checkbox-button>
                                    </div>

                                    <div class="field">
                                        <ptc-checkbox-button
                                            name="trackerDisplayShiftDetails"
                                            label="Schicht Detailinfo Anzeigen"
                                            buttonClass="ghost"
                                        ></ptc-checkbox-button>
                                    </div>

                                    <div
                                        class="display-contents"
                                        ?hidden=${!app.company!.features.includes(Feature.StaffApp)}
                                    >
                                        <div class="larger subtle small-caps margined subheader">
                                            <i class="mobile-alt"></i> Mitarbeiter-App
                                        </div>

                                        <div class="field">
                                            <ptc-checkbox-button
                                                name="trackingViaAppEnabled"
                                                label="Stempeln Via MA-App Erlauben"
                                                buttonClass="ghost"
                                            ></ptc-checkbox-button>
                                        </div>

                                        <div class="field">
                                            <ptc-checkbox-button
                                                name="trackingViaAppRequiresToken"
                                                label="Stempeln Erfordert QR-Code"
                                                buttonClass="ghost"
                                                ?hidden=${!trackingViaAppEnabled}
                                            ></ptc-checkbox-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ptc-drawer>
                    </div>

                    <div class="box" hidden>
                        <button
                            type="button"
                            class="larger small-caps fill-horizontally transparent text-left-aligning"
                            @click=${() => this._toggleSection("bonus")}
                        >
                            <div class="horizontal center-aligning layout">
                                <div class="stretch">
                                    <i class="percent"></i> Zuschläge
                                    <ptc-help-anchor page="handbuch/einstellungen/zeiterfassung/#zuschläge">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </div>
                                <i
                                    class="smaller ${this._expandedSections.has("bonus")
                                        ? "chevron-down"
                                        : "chevron-right"}"
                                ></i>
                            </div>
                        </button>

                        <ptc-drawer .collapsed=${!this._expandedSections.has("bonus")}>
                            <div class="padded">
                                <div class="larger subtle small-caps margined subheader">
                                    <i class="moon"></i> Nachtzuschläge
                                </div>

                                <div class="evenly stretching horizontal spacing layout">
                                    <div class="field">
                                        <label>Zeitraum Nacht 1</label>
                                        <div class="input horizontal center-aligning layout">
                                            <ptc-time-input
                                                name="bonusNight1Start"
                                                required
                                                @change=${() => this._checkNight1Times()}
                                            >
                                            </ptc-time-input>
                                            <div>&nbsp;&nbsp;bis&nbsp;&nbsp;</div>
                                            <ptc-time-input
                                                name="bonusNight1End"
                                                @change=${() => this._checkNight1Times()}
                                                required
                                            >
                                            </ptc-time-input>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label>Zeitraum Nacht 2</label>
                                        <div class="input horizontal center-aligning layout">
                                            <ptc-time-input
                                                name="bonusNight2Start"
                                                @change=${() => this._checkNight2Times()}
                                                required
                                            >
                                            </ptc-time-input>
                                            <div>&nbsp;&nbsp;bis&nbsp;&nbsp;</div>
                                            <ptc-time-input
                                                name="bonusNight2End"
                                                @change=${() => this._checkNight2Times()}
                                                required
                                            >
                                            </ptc-time-input>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label>Mindestdauer</label>
                                        <div class="input horizontal center-aligning layout">
                                            <ptc-time-input name="bonusMinDuration"></ptc-time-input>
                                        </div>
                                    </div>
                                </div>

                                <div class="spacing grid" style="--grid-column-width: 20em">
                                    <div>
                                        <div class="larger subtle small-caps margined subheader">
                                            <i class="sun"></i> Sonntagzuschläge
                                        </div>

                                        <div class="spacing horizontal center-aligning layout">
                                            <label>Inklusive Folgetag bis</label>
                                            <ptc-time-input name="bonusSundayNextDayUntil" required></ptc-time-input>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="larger subtle small-caps margined subheader">
                                            <i class="wreath"></i> Feiertagszuschläge
                                        </div>

                                        <div class="spacing horizontal center-aligning layout">
                                            <label>Inklusive Folgetag bis</label>
                                            <ptc-time-input name="bonusHolidayNextDayUntil" required></ptc-time-input>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="larger subtle small-caps margined subheader">
                                            <i class="tree-christmas"></i> Heilig Abend (24. Dezember)
                                        </div>

                                        <div class="spacing horizontal center-aligning layout">
                                            <label>Beginn ab</label>
                                            <ptc-time-input name="bonusChristmasEveFrom" required></ptc-time-input>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="larger subtle small-caps margined subheader">
                                            <i class="glass-cheers"></i> Silvester (31. Dezember)
                                        </div>

                                        <div class="spacing horizontal center-aligning layout">
                                            <label>Beginn ab</label>
                                            <ptc-time-input name="bonusNewYearsEveFrom" required></ptc-time-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ptc-drawer>
                    </div>
                </div>
            </form>
        `;
    }
}
