import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { parseDateString, getRange, toDateString, formatDate, dateAdd, formatNumber } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { routeProperty, Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./month-picker";
import "./avatar";
import "./employees-filter";
import "./spinner";
import "./popover";
import { getContractChanges } from "@pentacode/core/src/contract";
import { cache } from "lit/directives/cache.js";
import { employmentTypeLabel } from "@pentacode/core/src/model";
import "./scroller";

@customElement("ptc-employees-contracts-all")
export class EmployeesContractsAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/contracts(\/(?<page>[^\/]+))?/;

    get routeTitle() {
        return "Verträge: Alle Mitarbeiter";
    }

    private _pages = ["list", "changes"];

    @routeProperty({ arg: "page" })
    private _page: string = "list";

    private get _employees() {
        return app.getFilteredEmployees();
    }

    async handleRoute() {
        if (!this._pages.includes(this._page)) {
            this.go(`employees/all/contracts/list`);
            return;
        }

        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }
    }

    static styles = [
        shared,
        css`
            .employee-header:hover {
                cursor: pointer;
                color: var(--color-primary);
            }

            .contract-row {
                display: grid;
                grid-template-columns: 18em 8em 8em 10em 8em;
                break-inside: avoid;
            }

            .contract-row:not(.header-row):hover {
                cursor: pointer;
                color: var(--color-primary);
            }

            .contract-row ptc-avatar {
                margin: -0.25em 0 -0.25em -0.25em;
            }

            .contract-row > * {
                border-bottom: solid 1px var(--shade-2);
                border-right: solid 1px var(--shade-2);
            }

            .header-row {
                position: sticky;
                z-index: 2;
                top: 0;
                font-weight: bold;
            }

            .header-row > * {
                background: var(--color-bg);
            }

            .row-header {
                position: sticky;
                z-index: 1;
                left: 0;
                background: var(--color-bg);
            }

            ptc-contract-change {
                padding-left: 0.1em;
            }

            @media print {
                * {
                    font-size: 0.7rem !important;
                }

                .padded {
                    padding: 0.3em !important;
                }

                :host,
                .fullbleed {
                    display: block;
                    position: static !important;
                }

                ptc-scroller {
                    height: auto;
                }

                .employee-wrapper {
                    break-inside: avoid;
                }

                ptc-avatar {
                    display: none;
                }

                .contract-row > :last-child {
                    border-right: none;
                }
            }
        `,
    ];

    private _renderList() {
        return html`
            <div class="fullbleed vertical layout">
                <div class="center-aligning padded spacing horizontal layout border-bottom noprint">
                    <button class="small subtle" @click=${() => this.go("employees/all/contracts/changes")}>
                        <i class="list-timeline"></i>
                        Vertragsänderungen
                    </button>

                    <div class="stretch"></div>

                    <button class="transparent slim" @click=${() => print()}><i class="print"></i></button>
                </div>

                <ptc-employees-filter class="border-bottom"></ptc-employees-filter>

                <div class="padded bottom-margined horizontal layout printonly small-caps">
                    <div class="stretch">Vertragsdaten (Stand ${formatDate(new Date())})</div>
                    <div class="subtle">${app.company?.name}</div>
                </div>
                <ptc-scroller class="stretch">
                    <div class="contract-row header-row">
                        <div class="row-header"></div>
                        <div class="padded">Besch.verh.</div>
                        <div class="padded">
                            <div>Sollstunden</div>
                            <div class="top-margined small subtle">(pro Woche)</div>
                        </div>
                        <div class="padded">Lohn / Gehalt</div>
                        <div class="padded">
                            Urlaubsanspr.
                            <div class="top-margined small subtle">(Tage / Jahr)</div>
                        </div>
                    </div>

                    ${this._employees.map((e) => {
                        const { currentContract } = e;
                        if (!currentContract) {
                            return;
                        }
                        const { defaultSalary } = currentContract;
                        return html`
                            <div class="contract-row" @click=${() => this.go(`employees/${e.id}/contracts`)}>
                                <div class="horizontal spacing center-aligning layout row-header padded">
                                    <ptc-avatar class="tinier" .employee=${e}></ptc-avatar>
                                    <div class="stretch collapse ellipsis">${e.lastName}, ${e.firstName}</div>
                                    ${e.staffNumber ? html`<div class="small subtle">#${e.staffNumber}</div>` : ""}
                                </div>
                                <div class="padded">${employmentTypeLabel(currentContract.employmentType)}</div>
                                <div class="padded">${formatNumber(currentContract.nominalWeeklyHours)}</div>
                                <div class="padded">
                                    ${!defaultSalary
                                        ? ""
                                        : defaultSalary.type === "monthly"
                                          ? html` ${formatNumber(defaultSalary.amount)} € / Mon `
                                          : html` ${formatNumber(defaultSalary.amount)} € / Std `}
                                </div>
                                <div class="padded">${formatNumber(currentContract.vacationDays)}</div>
                            </div>
                        `;
                    })}
                </ptc-scroller>
            </div>
        `;
    }

    private _renderChanges() {
        const date = parseDateString(this.date);

        if (!date) {
            return;
        }

        const range = getRange(this.date, "month");

        const changes = this._employees
            .map((employee) => ({ employee, changes: getContractChanges(app.company!, employee, range) }))
            .filter(({ changes }) => changes.length);

        return html`
            <div class="fullbleed vertical layout">
                <div class="center-aligning padded spacing horizontal layout border-bottom noprint">
                    <button class="transparent slim" @click=${() => this.go("employees/all/contracts/list")}>
                        <i class="chevron-left"></i> Verträge
                    </button>

                    <div class="stretch"></div>

                    <ptc-month-picker
                        @change=${({ detail: { date } }: CustomEvent<{ date: string }>) => this.go(null, { date })}
                        .date=${this.date}
                    ></ptc-month-picker>

                    <div class="stretch"></div>

                    <button class="transparent slim" @click=${() => print()}><i class="print"></i></button>
                </div>

                <ptc-employees-filter class="border-bottom"></ptc-employees-filter>

                <div class="padded horizontaly layout small-caps printonly bottom-margined">
                    <div class="stretch">
                        Vertragsänderungen
                        <strong>${formatDate(range.from)} - ${formatDate(dateAdd(range.to, { days: -1 }))}</strong>
                    </div>
                    <div class="subtle">${app.company?.name}</div>
                </div>

                ${!changes.length
                    ? html`
                          <div class="stretch faded double-padded centering vertical layout">
                              <i class="giant list-timeline"></i>
                              <div>Es liegen keine Vertragsänderungen für diesen Monat vor.</div>
                          </div>
                      `
                    : html`
                          <ptc-scroller class="stretch">
                              ${this._employees.map((e) => {
                                  const contractChanges = getContractChanges(app.company!, e, range);
                                  if (!contractChanges.length) {
                                      return;
                                  }
                                  return html`
                                      <div class="padded border-bottom employee-wrapper">
                                          <div
                                              class="padded spacing horizontal center-aligning layout employee-header"
                                              @click=${() => this.go(`employees/${e.id}/contracts/changes`)}
                                          >
                                              <ptc-avatar .employee=${e} class="tinier"></ptc-avatar>
                                              <div class="stretch semibold">
                                                  ${e.name}
                                                  ${e.staffNumber
                                                      ? html` <span class="small subtle">#${e.staffNumber}</span> `
                                                      : ""}
                                              </div>
                                          </div>
                                          ${contractChanges.map(
                                              (change) => html`
                                                  <ptc-contract-change .change=${change}></ptc-contract-change>
                                              `
                                          )}
                                      </div>
                                  `;
                              })}
                          </ptc-scroller>
                      `}
            </div>
        `;
    }

    render() {
        return cache(this._page === "changes" ? this._renderChanges() : this._renderList());
    }
}
