import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { GetDocumentsParams } from "@pentacode/core/src/api";
import {
    Document,
    DocumentTag,
    Employee,
    fileIcon,
    Form,
    formStatusColor,
    formStatusLabel,
    MimeType,
} from "@pentacode/core/src/model";
import { app } from "../init";
import { singleton } from "../lib/singleton";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import { EditDocumentDialog } from "./edit-document-dialog";
import { alert } from "./alert-dialog";
import "./employees-filter";
import "./popover";
import "./spinner";
import { DocumentDialog } from "./document-dialog";
import { formatDate, toDateString } from "@pentacode/core/src/util";
import "./avatar";
import { cache } from "lit/directives/cache.js";
import "./scroller";
import { getDefaultForms } from "@pentacode/core/src/forms";
import { selectFile } from "../lib/util";

@customElement("ptc-employees-documents-row")
export class EmployeesDocumentsRow extends LitElement {
    @property({ type: Boolean })
    isVisible = false;

    @property({ attribute: false })
    employee: Employee;

    @property({ attribute: false })
    documents: Document[] = [];

    shouldUpdate(changes: Map<string, any>) {
        return changes.has("isVisible") || this.isVisible;
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }

    private _render() {
        return html`
            <div
                class="margined center-aligning spacing horizontal layout hover-highlight"
                @click=${() => this.dispatchEvent(new CustomEvent("open-employee"))}
            >
                <ptc-avatar class="tinier" .employee=${this.employee}></ptc-avatar>
                <div>${this.employee.name}</div>
            </div>

            <div class="margined horizontal scroller">
                <div class="spacing horizontal layout">
                    ${this.documents.map(
                        (document) => html`
                            <div
                                class="rounded right-padded click horizontal center-aligning layout"
                                @click=${() =>
                                    this.dispatchEvent(new CustomEvent("open-document", { detail: { document } }))}
                            >
                                <i class="massive light ${fileIcon(document.type)}"></i>
                                <div>
                                    <div class="small semibold ellipsis bottom-margined" style="max-width: 10em;">
                                        ${document.name}
                                    </div>
                                    <div class="tiny horizontal spacing layout">
                                        <div class="pill">
                                            <i class="calendar"></i> ${formatDate(document.date, false)}
                                        </div>
                                        ${document.tags.length > 1
                                            ? html`
                                                  <div class="pill ellipsis" style="max-width: 100%">
                                                      <i class="tags"></i>
                                                      ${document.tags.length} Tags
                                                  </div>
                                              `
                                            : document.tags.map(
                                                  (tag) => html`
                                                      <div
                                                          class="pill ellipsis"
                                                          style="max-width: 100%; --color-highlight: ${tag.color}"
                                                      >
                                                          <i class="tag"></i> ${tag.name}
                                                      </div>
                                                  `
                                              )}
                                        ${document.form
                                            ? html`<div
                                                  class="pill"
                                                  style="--color-highlight: ${formStatusColor(document.form.status)}"
                                              >
                                                  <i class="pen-field"></i> ${formStatusLabel(document.form.status)}
                                              </div>`
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        `
                    )}
                    <button class="subtle" @click=${() => this.dispatchEvent(new CustomEvent("new-document"))}>
                        <div class="center-aligning horizontal layout" style="width: 11.5em">
                            <i class="huge thin file-circle-plus"></i>
                            <div class="tiny stretch left-margined text-left-aligning">
                                Datei wählen oder per <strong>Drag & Drop</strong> hier ablegen.
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        `;
    }
}

@customElement("ptc-employees-documents-all")
export class EmployeesDocumentsAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/documents/;

    get routeTitle() {
        return "Dokumente: Alle Mitarbeiter";
    }

    @state()
    private _documents: Document[] = [];

    @state()
    private _loading: boolean = false;

    @state()
    private _selectedTag: DocumentTag | null = null;

    @singleton("ptc-edit-document-dialog")
    private _editDocumentDialog: EditDocumentDialog;

    @singleton("ptc-document-dialog")
    private _documentDialog: DocumentDialog;

    handleRoute() {
        this._load();
    }

    private get _employees() {
        return app
            .getFilteredEmployees()
            .filter((e) =>
                this._documents.some(
                    (d) =>
                        d.employeeId === e.id &&
                        (!this._selectedTag || d.tags.some((tag) => tag.id === this._selectedTag?.id))
                )
            );
    }

    private _intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => this._intersectionHandler(entries),
        { root: this, rootMargin: "100%" }
    );

    private _intersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => ((e.target as EmployeesDocumentsRow).isVisible = e.isIntersecting));
    }

    updated() {
        const elements = this.renderRoot.querySelectorAll("ptc-employees-documents-row");
        for (const el of elements) {
            this._intersectionObserver.observe(el);
        }
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.addEventListener("dragenter", () => this.classList.add("dragging"));
        this.addEventListener("dragend", () => this.classList.remove("dragging"));
        this.addEventListener("dragover", (e: DragEvent) => e.preventDefault());
        this.addEventListener("drop", (e: DragEvent) => {
            e.preventDefault();
            const file = e.dataTransfer?.files[0];
            this._newDocument({ file });
            this.classList.remove("dragging");
        });
        this.addEventListener("dragleave", (e: DragEvent) => {
            if (e.target === this) {
                this.classList.remove("dragging");
            }
        });
    }

    private async _load() {
        this._loading = true;
        try {
            this._documents = await app.api.getDocuments(new GetDocumentsParams());
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _newDocument({ file, form, employee }: { file?: File; form?: Form; employee?: Employee } = {}) {
        const document = new Document({
            employeeId: employee?.id,
            date: toDateString(new Date()),
            tags: this._selectedTag ? [this._selectedTag] : [],
            form,
            name: form?.name || file?.name || "",
            type: form ? MimeType.PDF : undefined,
        });

        const created = await this._editDocumentDialog.show({ file, document });
        if (created) {
            this._load();
            app.fetchAccount();
            this._openDocument(created);
        }
    }

    private async _openDocument(doc: Document) {
        const edited = await this._documentDialog.show(doc);
        if (edited) {
            this._load();
            app.fetchAccount();
        }
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();

        if (!!e.dataTransfer && [...e.dataTransfer.types].includes("Files")) {
            e.dataTransfer.dropEffect = "copy";
        }
    }

    private _drop(e: DragEvent, employee: Employee) {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer?.files[0];

        this._newDocument({ employee, file });

        const el = e.target as EmployeesDocumentsRow;
        el.classList.remove("dragover");
        this.classList.remove("dragging");
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        const el = e.target as EmployeesDocumentsRow;
        el.classList.toggle("dragover", !!e.dataTransfer && [...e.dataTransfer.types].includes("Files"));
    }

    private _dragleave(e: DragEvent) {
        e.preventDefault();
        const el = e.target as EmployeesDocumentsRow;
        el.classList.remove("dragover");
    }

    private _dragend(e: DragEvent) {
        e.preventDefault();
        const el = e.target as EmployeesDocumentsRow;
        el.classList.remove("dragover");
        this.classList.remove("dragging");
    }

    static styles = [
        shared,
        css`
            :host(.dragging) ptc-employees-documents-row > * {
                pointer-events: none;
            }

            ptc-employees-documents-row {
                display: block;
                min-height: 5em;
            }

            ptc-employees-documents-row.dragover button,
            ptc-employees-documents-row.dragover .hover-highlight {
                color: var(--color-primary);
            }
        `,
    ];

    private _renderNewDocumentOptions() {
        return html`
            <ptc-popover class="popover-menu" hide-on-click>
                ${getDefaultForms(app.company!).map(
                    (form) => html`
                        <button type="button" class="small" @click=${() => this._newDocument({ form })}>
                            <i class="pen-field"></i> ${form.name}
                        </button>
                    `
                )}

                <button
                    type="button"
                    class="small"
                    @click=${async () => {
                        const file = await selectFile();
                        if (file) {
                            this._newDocument({ file });
                        }
                    }}
                >
                    <i class="folder-tree"></i> Datei Wählen...
                </button>
            </ptc-popover>
        `;
    }

    render() {
        return html`
            <div class="fullbleed vertical layout wrapper">
                <div class="padded spacing center-aligning horizontal layout border-bottom noprint">
                    <div class="stretch"></div>

                    <button class="large skinny transparent">
                        <i class="file-circle-plus"></i>
                    </button>

                    ${this._renderNewDocumentOptions()}
                </div>

                <div class="small padded spacing wrapping horizontal layout border-bottom">
                    <button
                        class="slim ${!this._selectedTag ? "primary" : "transparent"}"
                        @click=${() => (this._selectedTag = null)}
                    >
                        <i class="tags"></i> Alle
                        <span class="subtle" style="display: inline-block; margin-left: 0.25em;">
                            ${this._documents.length}
                        </span>
                    </button>
                    ${app.company?.documentTags?.map(
                        (tag) => html`
                            <button
                                class="slim ${this._selectedTag?.id === tag.id ? "primary" : "transparent"}"
                                @click=${() => (this._selectedTag = tag)}
                                style="--color-highlight: ${tag.color}"
                            >
                                <i class="tag"></i> ${tag.name}
                                <span class="subtle" style="display: inline-block; margin-left: 0.25em;">
                                    ${this._documents.filter((doc) => doc.tags.some((t) => t.name === tag.name)).length}
                                </span>
                            </button>
                        `
                    )}
                    <div class="stretch"></div>
                    <button
                        class="subtle"
                        @click=${() => this.go("settings/documents")}
                        ?disabled=${!app.hasPermission("manage.settings.documents")}
                    >
                        <i class="pencil-alt"></i> Kategorien Bearbeiten
                    </button>
                </div>

                <ptc-employees-filter class="border-bottom"></ptc-employees-filter>

                ${!this._employees.length
                    ? html`
                          <div class="stretch centering vertical layout">
                              <i class="giant light faded files"></i>
                              <div class="margined">Dieser Mitarbeiter hat noch keine Dokumente.</div>
                              <button class="subtle top-margined">
                                  <div class="center-aligning horizontal layout" style="width: 11.5em">
                                      <i class="huge thin file-circle-plus"></i>
                                      <div class="tiny stretch left-margined text-left-aligning">
                                          Datei wählen oder per <strong>Drag & Drop</strong> hier ablegen.
                                      </div>
                                  </div>
                              </button>

                              ${this._renderNewDocumentOptions()}
                          </div>
                      `
                    : html`
                          <ptc-scroller class="stretch">
                              ${this._employees.map(
                                  (employee) => html`
                                      <ptc-employees-documents-row
                                          class="border-bottom"
                                          .employee=${employee}
                                          .documents=${this._documents.filter(
                                              (doc) =>
                                                  doc.employeeId === employee.id &&
                                                  (!this._selectedTag ||
                                                      doc.tags.some((tag) => tag.id === this._selectedTag?.id))
                                          )}
                                          @open-employee=${() => this.go(`employees/${employee.id}/documents`)}
                                          @open-document=${(e: CustomEvent<{ document: Document }>) =>
                                              this._openDocument(e.detail.document)}
                                          @new-document=${(e: CustomEvent<{ file?: File }>) =>
                                              this._newDocument({ employee, file: e.detail?.file })}
                                          @dragover=${this._dragover}
                                          @drop=${(e: DragEvent) => this._drop(e, employee)}
                                          @dragenter=${this._dragenter}
                                          @dragleave=${this._dragleave}
                                          @dragend=${this._dragend}
                                      ></ptc-employees-documents-row>
                                  `
                              )}
                          </ptc-scroller>
                      `}

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
