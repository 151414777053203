import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { RevenueEntry, RevenueType, TaxKey, taxKeyLabel, RevenueGroup } from "@pentacode/core/src/model";
import { shared } from "../styles";
import { app } from "../init";
import "./revenues-autocomplete-input";
import "./popover";
import { Checkbox } from "./checkbox";
import { Euros } from "@pentacode/openapi/src/units";

@customElement("ptc-revenue-item")
export class RevenueItem extends LitElement {
    @property({ attribute: false })
    entry: RevenueEntry;

    @property()
    placeholder: string = "";

    @property()
    bookingPrefix: string = "";

    @property({ attribute: false })
    templates: RevenueGroup[] = [];

    @property({ type: Boolean })
    readonly: boolean = false;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    @query("input[name='amount']")
    private _amountInput: HTMLInputElement;

    @query("input[name='tip']")
    private _tipInput: HTMLInputElement;

    @query("input[name='receipt']")
    private _receiptInput: HTMLInputElement;

    @query("input[name='ledger']")
    private _ledgerInput: HTMLInputElement;

    @query("input[name='postingKey']")
    private _postingKeyInput: HTMLInputElement;

    @query("input[name='costCenter']")
    private _costCenterInput: HTMLInputElement;

    @query("input[name='invoice']")
    private _invoiceInput: HTMLInputElement;

    @query("select[name='taxKey']")
    private _taxKeySelect: HTMLSelectElement;

    @query("select[name='employee']")
    private _employeeSelect: HTMLSelectElement;

    @query("ptc-checkbox-button[name='cashbook']")
    private _cashbookButton: Checkbox;

    @query("ptc-checkbox-button[name='reporting']")
    private _reportingButton: Checkbox;

    @query("form")
    private _form: HTMLFormElement;

    focus() {
        const firstInput = this.entry!.type === RevenueType.PayAdvance ? this._employeeSelect : this._nameInput;
        if (firstInput.value) {
            this._amountInput.select();
        } else {
            firstInput.focus();
        }
    }

    reportValidity() {
        if (this.entry && this.entry.name && !this._form.checkValidity()) {
            this._amountInput.focus();
            return this._form.reportValidity();
        } else {
            return true;
        }
    }

    async updated(changes: Map<string, any>) {
        if (changes.has("entry") && this.entry) {
            if (this.entry.type === RevenueType.PayAdvance) {
                this._employeeSelect.value = (this.entry.employeeId && this.entry.employeeId.toString()) || "";
            } else {
                this._nameInput.value = this.entry.name;
            }
            this._amountInput.value = Math.abs(this.entry.amount).toFixed(2);
            this._receiptInput.value = this.entry.receipt;
            this._taxKeySelect.value = this.entry.taxKey.toString();
            this._ledgerInput.value = this.entry.ledger;
            this._postingKeyInput.value = this.entry.postingKey;
            this._costCenterInput.value = this.entry.costCenter;
            this._invoiceInput.value = this.entry.invoice;
            this._tipInput.value = Math.abs(this.entry.tip).toString();
            this._cashbookButton.checked = this.entry.cashbook;
            this._reportingButton.checked = this.entry.reporting;
        }
    }

    private _updateEntry() {
        const formData = new FormData(this._form);
        const entry = this.entry!;

        if (entry.type === RevenueType.PayAdvance) {
            const employeeId = parseInt(formData.get("employee") as string);
            const employee = app.getEmployee(employeeId);
            if (!employee) {
                return;
            }
            entry.name = employee.name;
            entry.employeeId = employeeId;
        } else {
            entry.name = formData.get("name") as string;
        }

        entry.receipt = formData.get("receipt") as string;
        const taxKey = parseInt(formData.get("taxKey") as string);
        entry.taxKey = taxKey in TaxKey ? taxKey : TaxKey.None;
        entry.ledger = formData.get("ledger") as string;
        entry.postingKey = formData.get("postingKey") as string;
        entry.costCenter = formData.get("costCenter") as string;
        entry.invoice = formData.get("invoice") as string;
        const tipString = formData.get("tip") as string;
        entry.tip = (tipString ? -1 * parseFloat(tipString) : 0) as Euros;
        const amountString = formData.get("amount") as string;
        const amount = amountString ? parseFloat(amountString) : 0;
        entry.amount = (entry.type === RevenueType.Sales ? amount : -amount) as Euros;

        entry.cashbook = formData.has("cashbook");
        entry.reporting = formData.has("reporting");

        this.dispatchEvent(new CustomEvent("change", { detail: { entry: this.entry } }));
    }

    private _submit(e: Event) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit", { detail: { entry: this.entry } }));
    }

    private _employeeChanged() {
        this._amountInput.select();
    }

    private async _templateSelected({ detail: group }: CustomEvent<RevenueGroup>) {
        const { name, type, ledger, costCenter, taxKey, reporting, cashbook, postingKey } = group;
        Object.assign(this.entry, {
            name,
            type,
            ledger,
            costCenter,
            taxKey,
            postingKey,
            reporting,
            cashbook,
            group: group.id ? group : null,
        });
        this.requestUpdate("entry");
        await this.updateComplete;
        this._amountInput.select();
    }

    private _formatNumber(e: Event) {
        const input = e.target as HTMLInputElement;

        const val = Number(input.value);
        input.value = isNaN(val) ? "0.00" : val.toFixed(2);

        this._updateEntry();
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: block;
            }

            input[type="number"] {
                text-align: right;
            }

            .amount {
                width: 8em;
            }

            .details {
                width: 250px;
                padding-top: 0.2em;
                font-size: var(--font-size-small);
            }

            .details .field {
                margin: 0;
                min-width: 0;
            }

            .details .field > label {
                padding: 0.5em;
            }

            .name-input {
                position: relative;
            }

            input[disabled],
            select[disabled] {
                opacity: 1 !important;
                border: none !important;
                background: none;
            }

            ptc-checkbox-button {
                margin-bottom: 0.5em;
            }
        `,
    ];

    render() {
        const entry = this.entry;
        const venue = app.getVenue(entry.venueId);

        if (!entry || !venue) {
            return html``;
        }

        return html`
            <form autocomplete="off" @submit=${this._submit} @change=${this._updateEntry}>
                <div class="input-group horizontal layout stretch">
                    ${this.entry.type === RevenueType.PayAdvance
                        ? html`
                              <select
                                  name="employee"
                                  class="stretch"
                                  required
                                  @focusin=${(e: Event) => e.stopPropagation()}
                                  @change=${this._employeeChanged}
                                  ?disabled=${this.readonly}
                              >
                                  <option selected disabled hidden value="">Mitarbeiter Wählen...</option>
                                  ${app.accessibleEmployees
                                      .filter((e) => !!e.getContractForDate(entry.date))
                                      .sort((a, b) => (a.lastName < b.lastName ? -1 : 1))
                                      .map(
                                          (e) => html`
                                              <option .value=${e.id.toString()}>${e.lastName}, ${e.firstName}</option>
                                          `
                                      )}
                              </select>
                          `
                        : html`
                              <ptc-revenues-autocomplete-input
                                  class="input stretch name-input"
                                  .templates=${this.templates}
                                  @template-selected=${this._templateSelected}
                                  @focusin=${(e: Event) => e.stopPropagation()}
                              >
                                  <input
                                      name="name"
                                      .placeholder=${this.placeholder}
                                      required
                                      ?disabled=${this.readonly}
                                  />
                              </ptc-revenues-autocomplete-input>
                          `}

                    <div class="right icon input amount">
                        <input
                            name="amount"
                            type="number"
                            required
                            step="0.01"
                            min="0"
                            ?disabled=${this.readonly}
                            @blur=${this._formatNumber}
                        />
                        <i class="euro-sign"></i>
                    </div>
                </div>

                <ptc-popover trigger="focus" .preferAlignment=${["right", "right-bottom", "right-top"]} class="details">
                    <div
                        class="field"
                        ?hidden=${![
                            RevenueType.Sales,
                            RevenueType.Cashless,
                            RevenueType.Expense,
                            RevenueType.Debt,
                        ].includes(this.entry.type)}
                    >
                        <label>Belegnr.</label>
                        <input type="text" name="receipt" ?disabled=${this.readonly} />
                    </div>

                    <div class="field" ?hidden=${this.entry.type !== RevenueType.Debt}>
                        <label>Rechnungsnr.</label>
                        <input type="text" name="invoice" ?disabled=${this.readonly} />
                    </div>

                    <div
                        class="horizontal spacing evenly stretching layout"
                        ?hidden=${![
                            RevenueType.Sales,
                            RevenueType.Expense,
                            RevenueType.Cashless,
                            RevenueType.Debt,
                        ].includes(this.entry.type)}
                    >
                        <div class="field">
                            <label>Steuersatz</label>
                            <select name="taxKey" ?disabled=${this.readonly}>
                                <option .value=${TaxKey.None.toString()}>
                                    ${taxKeyLabel(TaxKey.None, this.entry.date)}
                                </option>
                                <option .value=${TaxKey.Free.toString()}>
                                    ${taxKeyLabel(TaxKey.Free, this.entry.date)}
                                </option>
                                <option
                                    .value=${TaxKey.Sales7.toString()}
                                    ?disabled=${this.entry.type === RevenueType.Expense}
                                >
                                    ${taxKeyLabel(TaxKey.Sales7, this.entry.date)}
                                </option>
                                <option
                                    .value=${TaxKey.Sales19.toString()}
                                    ?disabled=${this.entry.type === RevenueType.Expense}
                                >
                                    ${taxKeyLabel(TaxKey.Sales19, this.entry.date)}
                                </option>
                                <option
                                    .value=${TaxKey.Input7.toString()}
                                    ?disabled=${this.entry.type !== RevenueType.Expense}
                                >
                                    ${taxKeyLabel(TaxKey.Input7, this.entry.date)}
                                </option>
                                <option
                                    .value=${TaxKey.Input9_5.toString()}
                                    ?disabled=${this.entry.type !== RevenueType.Expense}
                                >
                                    ${taxKeyLabel(TaxKey.Input9_5, this.entry.date)}
                                </option>
                                <option
                                    .value=${TaxKey.Input19.toString()}
                                    ?disabled=${this.entry.type !== RevenueType.Expense}
                                >
                                    ${taxKeyLabel(TaxKey.Input19, this.entry.date)}
                                </option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Buchungsschl.</label>
                            <input type="text" name="postingKey" ?disabled=${this.readonly} />
                        </div>
                    </div>

                    <div class="field">
                        <label>Konto</label>
                        <input type="text" name="ledger" ?disabled=${this.readonly} />
                    </div>

                    <div class="field" ?hidden=${this.entry.type !== RevenueType.Debt}>
                        <label>Trinkgeld</label>
                        <div class="right icon input">
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                name="tip"
                                ?disabled=${this.readonly}
                                @blur=${this._formatNumber}
                            />
                            <i class="euro-sign"></i>
                        </div>
                    </div>

                    <div
                        class="field"
                        ?hidden=${![
                            RevenueType.Sales,
                            RevenueType.Expense,
                            RevenueType.Cashless,
                            RevenueType.PayAdvance,
                        ].includes(this.entry.type)}
                    >
                        <label>Kostenst.</label>
                        <input type="text" name="costCenter" ?disabled=${this.readonly} />
                    </div>

                    <div>
                        <div class="divider"></div>

                        <div class="horizontal layout pad-children">
                            <ptc-checkbox-button
                                class="flex"
                                name="cashbook"
                                label="Kassenbuch"
                                buttonClass="small ghost"
                            ></ptc-checkbox-button>

                            <ptc-checkbox-button
                                class="flex"
                                name="reporting"
                                label="Berichte"
                                buttonClass="small ghost"
                            ></ptc-checkbox-button>
                        </div>
                    </div>

                    <button class="concealed"></button>
                </ptc-popover>
            </form>
        `;
    }
}
