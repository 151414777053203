import { Absence, Employee, PublishedRoster, TimeEntry, Venue } from "@pentacode/core/src/model";
import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { Checkbox, CheckboxButton } from "./checkbox";
import "./scroller";
import { alert } from "./alert-dialog";
import { PublishRosterParams } from "@pentacode/core/src/api";
import "./drawer";
import "./popover";
import { live } from "lit/directives/live.js";
import { DateString } from "@pentacode/openapi/src/units";
import { DateRange } from "@pentacode/core/src/time";

type Input = DateRange & {
    venue: Venue;
    entries: TimeEntry[];
    unpublishedEntries: TimeEntry[];
    absences: Absence[];
};

@customElement("ptc-publish-roster-dialog")
export class PublishRosterDialog extends Dialog<Input, PublishedRoster> {
    @query("form")
    private _form: HTMLFormElement;

    @state()
    private _venue: Venue;

    @state()
    private _entries: TimeEntry[];

    @state()
    private _unpublishedEntries: TimeEntry[];

    @state()
    private _absences: Absence[];

    @state()
    private _from: DateString;

    @state()
    private _to: DateString;

    @state()
    private _expandEmployees = false;

    private get _affectedEmployees() {
        const affected = new Map<
            number,
            {
                employee: Employee;
                count: number;
            }
        >();

        const addAffected = (employeeId: number) => {
            if (!affected.has(employeeId)) {
                const employee = app.getEmployee(employeeId);
                if (!employee) {
                    return;
                }
                affected.set(employeeId, {
                    employee,
                    count: 0,
                });
            }
            affected.get(employeeId)!.count++;
        };

        for (const entry of this._unpublishedEntries) {
            if (entry.employeeId) {
                addAffected(entry.employeeId);
            } else {
                for (const employee of app.accessibleEmployees) {
                    if (app.isAvailable(entry, employee, this._entries, this._absences, false)) {
                        addAffected(employee.id);
                    }
                }
            }

            if (entry.published?.employeeId && entry.published.employeeId !== entry.employeeId) {
                addAffected(entry.published.employeeId);
            }
        }

        return [...affected.values()];
    }

    private get _recipients(): number[] {
        if (!this._form) {
            return this._affectedEmployees.filter((e) => !!e.employee.email).map((e) => e.employee.id);
        }
        const formData = new FormData(this._form);
        return formData.getAll("employees").map((id: string) => parseInt(id));
    }

    async show({ venue, from, to, entries, unpublishedEntries, absences }: Input) {
        this._venue = venue;
        this._from = from;
        this._to = to;
        this._entries = entries;
        this._unpublishedEntries = unpublishedEntries;
        this._absences = absences;
        this._expandEmployees = false;
        return super.show();
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const employees = this._recipients;

        this.loading = true;
        try {
            const published = await app.api.publishRoster(
                new PublishRosterParams({
                    from: this._from,
                    to: this._to,
                    venue: this._venue.id,
                    entries: this._unpublishedEntries.map((e) => e.id),
                    notify: this._recipients,
                })
            );
            alert(`Ihre Änderungen wurden erfolgreich veröffentlicht und an ${employees.length} Mitarbeiter versand!`, {
                type: "success",
                title: "Änderungen Veröffentlicht",
            });
            this.done(published);
        } catch (e) {
            this.done();
            alert(e.message, { type: "warning" });
        }
        this.loading = false;
    }

    private _toggleAll(checked: boolean) {
        const elements = this.renderRoot.querySelectorAll("ptc-checkbox-button") as NodeListOf<CheckboxButton>;

        for (const el of elements) {
            if (!el.hasAttribute("disabled")) {
                el.checked = checked;
            }
        }

        this.requestUpdate();
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        css`
            .inner {
                overflow: hidden !important;
                max-width: 35em;
                padding: 0.5em;
            }

            .employees {
                display: grid;
                font-size: var(--font-size-small);
                grid-gap: 0.5em;
                grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
                padding: 0 0.5em 0.5em 0.5em;
            }

            .employee-button {
                padding: 0.3em 0.5em;
            }

            .employee-email {
                color: var(--color-primary);
            }

            form {
                max-height: 100%;
            }
        `,
    ];

    renderContent() {
        const affectedEmployees = this._affectedEmployees;
        const affectedEmployeesWithEmail = affectedEmployees.filter((e) => !!e.employee.email);
        const recipients = this._recipients;
        const allSelected = affectedEmployeesWithEmail.length === recipients.length;
        return html`
            <form class="vertical layout" @submit=${this._submit} @input=${() => this.requestUpdate()}>
                <div class="big margined text-centering"><i class="paper-plane"></i> Änderungen Veröffentlichen</div>

                <ptc-scroller class="stretch">
                    <div class="padded bottom-margined text-centering">
                        Möchten Sie diese
                        <strong>${this._unpublishedEntries.length} Änderungen</strong> veröffentlichen?
                    </div>

                    <div class="box">
                        <div
                            class="double-padded horizontal layout click"
                            @click=${() => (this._expandEmployees = !this._expandEmployees)}
                        >
                            <div class="stretch">
                                Es werden <strong>${recipients.length}</strong> von
                                <strong>${affectedEmployees.length} betroffenen Mitarbeitern</strong>
                                benachrichtigt.
                            </div>
                            <i class="${this._expandEmployees ? "chevron-down" : "chevron-right"}"></i>
                        </div>

                        <ptc-drawer .collapsed=${!this._expandEmployees}>
                            <div class="margined">
                                <ptc-checkbox-button
                                    name="allEmployees"
                                    .label=${html`<i class="people-group"></i> Alle betroffenen Mitarbeiter`}
                                    buttonClass="slim ghost"
                                    @input=${() => this._toggleAll(!allSelected)}
                                    .checked=${live(allSelected)}
                                ></ptc-checkbox-button>
                            </div>
                            <div class="employees">
                                ${affectedEmployees
                                    .sort(
                                        (a, b) =>
                                            Number(!!b.employee.email) - Number(!!a.employee.email) ||
                                            (a.employee.name < b.employee.name ? -1 : 1)
                                    )
                                    .map(({ employee, count }) => {
                                        return html`
                                            <div>
                                                <ptc-checkbox-button
                                                    name="employees"
                                                    .label=${html`
                                                        <div class="horizontal center-aligning spacing layout">
                                                            <ptc-avatar
                                                                class="small"
                                                                .employee=${employee}
                                                            ></ptc-avatar>
                                                            <div class="stretch collapse">
                                                                <div class="employee-name ellipsis">
                                                                    ${employee.name}
                                                                </div>
                                                                <div class="employee-email ellipsis">
                                                                    ${count} Änderungen
                                                                </div>
                                                            </div>
                                                        </div>
                                                    `}
                                                    .checked=${!!employee.email}
                                                    buttonClass="ghost employee-button"
                                                    ?disabled=${!employee.email}
                                                    .value=${employee.id.toString()}
                                                ></ptc-checkbox-button>
                                            </div>
                                            ${!employee.email
                                                ? html`
                                                      <ptc-popover class="tooltip" trigger="hover" non-interactive>
                                                          Es ist keine Email für diesen Mitarbeiter hinterlegt.
                                                      </ptc-popover>
                                                  `
                                                : ""}
                                        `;
                                    })}
                            </div>
                        </ptc-drawer>
                    </div>
                </ptc-scroller>

                <div class="top-margined horizontal spacing stretching layout">
                    <button class="primary" type="submit">
                        ${this._unpublishedEntries.length} Änderungen Veröffentlichen
                    </button>
                    <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
