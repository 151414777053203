import "./month-picker";
import "./calendar";
import {
    Availability,
    availabilityColor,
    availabilityIcon,
    availabilityLabel,
    AvailabilityStatus,
} from "@pentacode/core/src/model";
import { dateAdd, toDateString, parseDateString, getRange } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
// import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import { singleton } from "../lib/singleton";
import { AvailabilityDialog } from "./availability-dialog";
import { CalendarEntry } from "./calendar";
import { DateString } from "@pentacode/openapi/src/units";

@customElement("ptc-employees-availabilities-single")
export class EmployeesAvailabilitiesSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<id>\d+)\/availabilities/;

    @routeProperty({ arg: "id", type: Number })
    employeeId: number;

    @routeProperty({ param: "date" })
    date: DateString;

    get routeTitle() {
        return `Verfügbarkeiten: ${this._employee && this._employee.name}`;
    }

    async handleRoute() {
        if (!this.date) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }
        await this._load();
    }

    @state()
    private _loading = false;

    @state()
    private _availabilities: Availability[] = [];

    @singleton("ptc-availability-dialog")
    private _availabilityDialog: AvailabilityDialog;

    private get _employee() {
        return (this.employeeId && app.getEmployee(this.employeeId)) || null;
    }

    private async _load() {
        if (!this._employee) {
            return;
        }

        this._loading = true;

        const month = getRange(this.date, "month");
        const { from } = getRange(month.from, "week");
        const { to } = getRange(month.to, "week");

        try {
            this._availabilities = await app.api.getAvailabilities({ employees: [this.employeeId], from, to });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newAvailability(date: DateString) {
        const created = await this._availabilityDialog.show(
            new Availability({
                employeeId: this.employeeId,
                status: AvailabilityStatus.Available,
                date,
            })
        );
        if (created) {
            this._load();
        }
    }

    private async _editAvailability(av: Availability) {
        const edited = await this._availabilityDialog.show(av);
        if (edited) {
            this._load();
        }
    }

    static styles = [
        shared,
        css`
            .absence {
                border: solid 1px var(--shade-2);
                border-radius: 1em;
                padding-top: 0.5em;
            }

            .days-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: 0.5em 0;
            }

            .day {
                text-align: center;
                height: 2em;
                box-sizing: border-box;
                position: relative;
            }

            .day.start,
            .day.middle,
            .day.end {
                border-top: solid 1px var(--color-highlight);
                border-bottom: solid 1px var(--color-highlight);
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            .day.start {
                border-left: solid 1px var(--color-highlight);
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
            }

            .day.end {
                border-right: solid 1px var(--color-highlight);
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
            }

            .absence.denied .day,
            .absence.requested .day {
                color: var(--color-highlight);
                background: transparent;
            }

            .absence.denied .day:not(.before):not(.after)::after {
                content: "";
                display: block;
                width: 110%;
                height: 1px;
                background: currentColor;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -5%;
            }

            .today {
                background: var(--color-highlight);
                color: var(--color-bg);
                border-radius: 100%;
                border: solid 1px;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                font-weight: bold;
            }
        `,
    ];

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }

        const d = parseDateString(this.date) || new Date();

        return html`
            <div class="fullbleed vertical layout">
                <div class="padded center-aligning horizontal layout border-bottom">
                    <ptc-month-picker
                        @change=${({ detail: { date } }: CustomEvent<{ date: string }>) => this.go(null, { date })}
                        .date=${this.date}
                    ></ptc-month-picker>
                </div>

                <ptc-calendar
                    class="stretch"
                    .year=${d.getFullYear()}
                    .month=${d.getMonth()}
                    .entries=${this._availabilities.map((av) => ({
                        start: av.date,
                        end: dateAdd(av.date, { days: 1 }),
                        icon: availabilityIcon(av.status),
                        hoverLabel: av.comment || availabilityLabel(av.status),
                        label:
                            av.start && av.end
                                ? `${av.start.slice(0, 5)} - ${av.end.slice(0, 5)}`
                                : av.start
                                  ? `ab ${av.start.slice(0, 5)}`
                                  : av.end
                                    ? `bis ${av.end.slice(0, 5)}`
                                    : availabilityLabel(av.status),
                        color: availabilityColor(av.status),
                        data: av,
                    }))}
                    @entry-clicked=${(e: CustomEvent<{ entry: CalendarEntry<Availability> }>) =>
                        this._editAvailability(e.detail.entry.data!)}
                    @add-entry=${(e: CustomEvent<{ date: DateString }>) => this._newAvailability(e.detail.date)}
                    .renderDay=${(date: string) => {
                        const contract = this._employee?.getContractForDate(date);
                        const blocked = (!contract || contract.blocked) && (contract?.comment || "inaktiv");
                        return blocked
                            ? html`<div class="fullbleed faded centering layout" title="${blocked}">
                                  <i class="giant faded ban"></i>
                              </div>`
                            : undefined;
                    }}
                ></ptc-calendar>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
