import { Venue, Department } from "@pentacode/core/src/model";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared, colors } from "../styles";
import { alert } from "./alert-dialog";
import { DepartmentDialog } from "./department-dialog";
import "./spinner";

@customElement("ptc-venue-departments")
export class VenueDepartments extends LitElement {
    @property({ attribute: false })
    venue: Venue;

    @property({ type: Boolean })
    disableReordering = false;

    @property({ type: Boolean })
    hideTimeSettings = false;

    @state()
    private _loading: boolean = false;

    @singleton("ptc-department-dialog")
    private _departmentDialog: DepartmentDialog;

    private async _editDepartment(department: Department) {
        this._departmentDialog.show({ venue: this.venue, department, hideTimeSettings: this.hideTimeSettings });
    }

    private async _newDepartment(venue: Venue) {
        this._departmentDialog.show({ venue, hideTimeSettings: this.hideTimeSettings });
    }

    private async _moveDepartment(dep: Department, direction: "up" | "down") {
        const diff = direction === "up" ? -1 : 1;

        this._loading = true;

        try {
            await app.updateDepartment({
                id: dep.id,
                order: (dep.order += diff),
            });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        shared,
        css`
            .department-move-buttons {
                display: flex;
                flex-direction: column;
            }

            .department-wrapper:not(:hover) .department-move-buttons {
                visibility: hidden;
            }

            .department-move-buttons button {
                padding: 0.1em 0.4em;
                margin: 0;
            }
        `,
    ];

    render() {
        if (!this.venue) {
            return html``;
        }

        return html`
            <div class="departments">
                ${this.venue.departments
                    .sort((a, b) => a.order - b.order)
                    .map(
                        (dep, i) => html`
                            <div class="bottom-margined horizontal center-aligning layout department-wrapper">
                                <div
                                    class="padded box stretch click center-alinging horizontal layout"
                                    style="--color-highlight: ${colors[dep.color] || dep.color}"
                                    @click=${() => this._editDepartment(dep)}
                                >
                                    <div class="stretch semibold half-padded">${dep.name}</div>

                                    <div class="smaller pills">
                                        ${dep.positions
                                            .filter((r) => r.active)
                                            .sort((a, b) => a.order - b.order)
                                            .map((position) => {
                                                const positionCount = app.company!.employees.filter(
                                                    (emp) =>
                                                        emp.active && emp.positions.some((r) => r.id === position.id)
                                                ).length;
                                                return html`
                                                    <div
                                                        class="pill"
                                                        style="--color-highlight: ${position.color || "inherit"}"
                                                    >
                                                        ${position.name}

                                                        <div class="detail">${positionCount}</div>
                                                    </div>
                                                `;
                                            })}
                                    </div>
                                </div>

                                <div class="department-move-buttons" ?hidden=${this.disableReordering}>
                                    <button
                                        class="transparent"
                                        @click=${() => this._moveDepartment(dep, "up")}
                                        ?disabled=${i === 0}
                                    >
                                        <i class="caret-up"></i>
                                    </button>
                                    <button
                                        class="transparent"
                                        @click=${() => this._moveDepartment(dep, "down")}
                                        ?disabled=${i === this.venue.departments.length - 1}
                                    >
                                        <i class="caret-down"></i>
                                    </button>
                                </div>
                            </div>
                        `
                    )}
            </div>

            <button @click=${() => this._newDepartment(this.venue)} class="slim transparent fill-horizontally">
                <i class="plus"></i>
                Neue Abteilung
            </button>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
