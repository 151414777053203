import {
    AccountingSalaryConfig,
    AccountingSalaryConfigBenefit,
    AccountingSalaryConfigBonus,
    BenefitType,
    BonusType,
} from "@pentacode/core/src/model";
import { getPath } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { shared } from "../styles";
import { Checkbox } from "./checkbox";
import { app } from "../init";
import { EntityFiltersEl } from "./entity-filters";
import "./select-wage-type";

@customElement("ptc-accounting-salary-config-form")
export class AccountingSalaryConfigForm extends LitElement {
    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: block;
            }

            .name {
                font-weight: bold;
            }

            .field {
                margin: 0;
            }

            .grid {
                font-size: var(--font-size-small);
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
                grid-gap: 0 1em;
            }

            .table {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 0.5em;
                font-size: var(--font-size-small);
            }

            .table ptc-select-wage-type {
                display: block;
            }

            .table .header {
                font-weight: bold;
                text-align: center;
                padding: 0.3em;
            }

            .table .label {
                font-weight: 600;
                padding: 0.65em;
            }
        `,
    ];

    @property({ type: Boolean })
    isNew = true;

    @property({ attribute: false })
    set config(config: AccountingSalaryConfig) {
        this.updateComplete.then(() => {
            if (this._nameInput) {
                this._nameInput.value = config.name || "";
            }
            for (const prop of [
                "workHourly",
                "workMonthly",
                "workHourlyDays",
                "workMonthlyDays",
                "absenceHourly.vacation",
                "absenceHourly.sick",
                "absenceHourly.childSick",
                "absenceHourly.sickInKUG",
                "absenceHourly.compDay",
                "absenceHourlyDays.vacation",
                "absenceHourlyDays.sick",
                "absenceHourlyDays.childSick",
                "absenceHourlyDays.sickInKUG",
                "absenceHourlyDays.compDay",
                "absenceMonthly.vacation",
                "absenceMonthly.sick",
                "absenceMonthly.childSick",
                "absenceMonthly.sickInKUG",
                "absenceMonthly.compDay",
                "absenceMonthlyDays.vacation",
                "absenceMonthlyDays.sick",
                "absenceMonthlyDays.childSick",
                "absenceMonthlyDays.sickInKUG",
                "absenceMonthlyDays.compDay",
                "bonusTaxFree.night1",
                "bonusTaxFree.night2",
                "bonusTaxFree.sunday",
                "bonusTaxFree.holiday",
                "bonusTaxFree.special",
                "bonusTaxed.night1",
                "bonusTaxed.night2",
                "bonusTaxed.sunday",
                "bonusTaxed.holiday",
                "bonusTaxed.special",
                "mealsValue",
                "mealsBreakfast",
                "mealsLunch",
                "mealsDinner",
                "commission",
                "bonusAdvance",
                "payAdvance",
                "salary",
            ]) {
                const input = this.renderRoot!.querySelector(`ptc-select-wage-type[name="${prop}"`) as HTMLInputElement;
                if (!input) {
                    throw `cannot find input: ${prop}`;
                }
                input.value = getPath(config, prop) || "";
            }

            this._bonuses =
                app.company?.bonusTypes.map((type) => ({
                    type,
                    config:
                        config.bonuses?.find((b) => b.bonusTypeId === type.id) ||
                        new AccountingSalaryConfigBonus({ bonusTypeId: type.id }),
                })) || [];

            this._benefits =
                app.company?.benefitTypes.map((type) => ({
                    type,
                    config:
                        config.benefits?.find((b) => b.benefitTypeId === type.id) ||
                        new AccountingSalaryConfigBenefit({ benefitTypeId: type.id }),
                })) || [];

            this._entityFilters.filters =
                config.entities || config.employmentTypes.map((t) => ({ type: "employmentType", value: Number(t) }));
        });
    }

    get config(): AccountingSalaryConfig {
        const data = new FormData(this._form);
        const t = new AccountingSalaryConfig();
        const id = data.get("id") as string;
        if (id) {
            t.id = parseInt(id);
        }
        t.name = data.get("name") as string;
        t.workHourly = data.get("workHourly") as string;
        t.workHourlyDays = data.get("workHourlyDays") as string;
        t.workMonthly = data.get("workMonthly") as string;
        t.workHourlyDays = data.get("workHourlyDays") as string;
        t.workMonthlyDays = data.get("workMonthlyDays") as string;
        t.absenceHourly.vacation = data.get("absenceHourly.vacation") as string;
        t.absenceHourly.sick = data.get("absenceHourly.sick") as string;
        t.absenceHourly.childSick = data.get("absenceHourly.childSick") as string;
        t.absenceHourly.sickInKUG = data.get("absenceHourly.sickInKUG") as string;
        t.absenceHourly.compDay = data.get("absenceHourly.compDay") as string;
        t.absenceHourlyDays.vacation = data.get("absenceHourlyDays.vacation") as string;
        t.absenceHourlyDays.sick = data.get("absenceHourlyDays.sick") as string;
        t.absenceHourlyDays.childSick = data.get("absenceHourlyDays.childSick") as string;
        t.absenceHourlyDays.sickInKUG = data.get("absenceHourlyDays.sickInKUG") as string;
        t.absenceHourlyDays.compDay = data.get("absenceHourlyDays.compDay") as string;
        t.absenceMonthly.vacation = data.get("absenceMonthly.vacation") as string;
        t.absenceMonthly.sick = data.get("absenceMonthly.sick") as string;
        t.absenceMonthly.childSick = data.get("absenceMonthly.childSick") as string;
        t.absenceMonthly.sickInKUG = data.get("absenceMonthly.sickInKUG") as string;
        t.absenceMonthly.compDay = data.get("absenceMonthly.compDay") as string;
        t.absenceMonthlyDays.vacation = data.get("absenceMonthlyDays.vacation") as string;
        t.absenceMonthlyDays.sick = data.get("absenceMonthlyDays.sick") as string;
        t.absenceMonthlyDays.childSick = data.get("absenceMonthlyDays.childSick") as string;
        t.absenceMonthlyDays.sickInKUG = data.get("absenceMonthlyDays.sickInKUG") as string;
        t.absenceMonthlyDays.compDay = data.get("absenceMonthlyDays.compDay") as string;
        t.bonusTaxFree.night1 = data.get("bonusTaxFree.night1") as string;
        t.bonusTaxFree.night2 = data.get("bonusTaxFree.night2") as string;
        t.bonusTaxFree.sunday = data.get("bonusTaxFree.sunday") as string;
        t.bonusTaxFree.holiday = data.get("bonusTaxFree.holiday") as string;
        t.bonusTaxFree.special = data.get("bonusTaxFree.special") as string;
        t.bonusTaxed.night1 = data.get("bonusTaxed.night1") as string;
        t.bonusTaxed.night2 = data.get("bonusTaxed.night2") as string;
        t.bonusTaxed.sunday = data.get("bonusTaxed.sunday") as string;
        t.bonusTaxed.holiday = data.get("bonusTaxed.holiday") as string;
        t.bonusTaxed.special = data.get("bonusTaxed.special") as string;
        t.mealsValue = data.get("mealsValue") as string;
        t.mealsBreakfast = data.get("mealsBreakfast") as string;
        t.mealsLunch = data.get("mealsLunch") as string;
        t.mealsDinner = data.get("mealsDinner") as string;
        t.commission = data.get("commission") as string;
        t.bonusAdvance = data.get("bonusAdvance") as string;
        t.payAdvance = data.get("payAdvance") as string;
        t.salary = data.get("salary") as string;

        t.bonuses = [];

        const bonusConfigIds = data.getAll("bonusConfigId") as string[];
        const bonusTypeIds = data.getAll("bonusTypeId") as string[];
        const bonusTaxed = data.getAll("bonusTaxed") as string[];
        const bonusTaxFree = data.getAll("bonusTaxFree") as string[];

        for (const [i, id] of bonusConfigIds.entries()) {
            t.bonuses.push(
                new AccountingSalaryConfigBonus({
                    id: id ? Number(id) : undefined,
                    bonusTypeId: Number(bonusTypeIds[i]),
                    taxed: bonusTaxed[i],
                    taxFree: bonusTaxFree[i],
                })
            );
        }

        t.benefits = [];

        const benefitConfigIds = data.getAll("benefitConfigId") as string[];
        const benefitTypeIds = data.getAll("benefitTypeId") as string[];
        const benefitWageTypeNumbers = data.getAll("benefitWageTypeNumber") as string[];

        for (const [i, id] of benefitConfigIds.entries()) {
            t.benefits.push(
                new AccountingSalaryConfigBenefit({
                    id: id ? Number(id) : undefined,
                    benefitTypeId: Number(benefitTypeIds[i]),
                    wageTypeNumber: benefitWageTypeNumbers[i] || "",
                })
            );
        }

        t.entities = this._entityFilters.filters;

        return t;
    }

    @state()
    private _bonuses: {
        type: BonusType;
        config: AccountingSalaryConfigBonus;
    }[] = [];

    @state()
    private _benefits: {
        type: BenefitType;
        config: AccountingSalaryConfigBenefit;
    }[] = [];

    @query("form")
    private _form: HTMLFormElement;

    @query("ptc-entity-filters")
    private _entityFilters: EntityFiltersEl;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    reportValidity() {
        return this._form.reportValidity();
    }

    private _submit(e: Event) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit"));
    }

    private _change() {
        this.dispatchEvent(new CustomEvent("change"));
        this.requestUpdate();
    }

    render() {
        const company = app.company;
        if (!company) {
            return;
        }

        return html`
            <form @submit=${this._submit} @change=${this._change}>
                <input type="hidden" name="id" />

                <div class="padded smaller subtle blue colored-text">
                    ${this.isNew ? "Neuer Lohnartensatz" : "Lohnartensatz Bearbeiten"}
                </div>

                <div class="name field">
                    <input name="name" placeholder="Bezeichnung" required />
                </div>

                <div class="divider small-caps">Als Standard Verwenden Für</div>

                <ptc-entity-filters
                    .filterTypes=${["employmentType", "employeeId", "venue", "department", "position", "employeeTag"]}
                    class="focus-container"
                    hideFiltericon
                ></ptc-entity-filters>

                <div class="divider small-caps">Arbeit & Fehltage</div>

                <div class="table" style="grid-template-columns: 10em repeat(4, 1fr);">
                    <div></div>
                    <div class="header" style="grid-column: span 2;">Stundenlöhner</div>
                    <div class="header" style="grid-column: span 2;">Gehaltsempfänger</div>

                    <div></div>

                    <div class="smaller subtle header">Stunden</div>

                    <div class="smaller subtle header">Tage</div>

                    <div class="smaller subtle header">Stunden</div>

                    <div class="smaller subtle header">Tage</div>

                    <div class="label">Arbeit</div>

                    <div class="value">
                        <ptc-select-wage-type name="workHourly"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="workHourlyDays" hidden> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="workMonthly"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="workMonthlyDays" hidden> </ptc-select-wage-type>
                    </div>

                    <div class="label">Urlaub</div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourly.vacation"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourlyDays.vacation"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthly.vacation"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthlyDays.vacation"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Krank</div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourly.sick"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourlyDays.sick"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthly.sick"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthlyDays.sick"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Guttag</div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourly.compDay"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourlyDays.compDay"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthly.compDay"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthlyDays.compDay"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Kind Krank</div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourly.childSick"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourlyDays.childSick"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthly.childSick"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthlyDays.childSick"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Krank In KUG</div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourly.sickInKUG"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceHourlyDays.sickInKUG"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthly.sickInKUG"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="absenceMonthlyDays.sickInKUG"> </ptc-select-wage-type>
                    </div>
                </div>

                <div class="divider small-caps">Zuschläge</div>

                <div class="table">
                    <div></div>
                    <div class="header">Beitragsfrei</div>
                    <div class="header">Beitragspflichtig</div>

                    ${this._bonuses.length
                        ? this._bonuses.map(
                              ({ type, config }) => html`
                                  <input type="hidden" name="bonusConfigId" .value=${config.id?.toString() || ""} />
                                  <input type="hidden" name="bonusTypeId" .value=${type.id.toString()} />

                                  <div class="label">${type.name}</div>

                                  <div class="value">
                                      <ptc-select-wage-type
                                          name="bonusTaxFree"
                                          .value=${config.taxFree}
                                          ?invisible=${!type.taxFree}
                                      ></ptc-select-wage-type>
                                  </div>

                                  <div class="value">
                                      <ptc-select-wage-type name="bonusTaxed" .value=${config.taxed}>
                                      </ptc-select-wage-type>
                                  </div>
                              `
                          )
                        : html`<div style="grid-column: -1/1;" class="padded subtle">
                              Keine Zuschlagsarten definiert.
                          </div>`}
                </div>

                <div class="table" hidden>
                    <div></div>
                    <div class="header">Beitragsfrei</div>
                    <div class="header">Beitragspflichtig</div>

                    <div class="label">Nacht 1</div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxFree.night1"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxed.night1"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Nacht 2</div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxFree.night2"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxed.night2"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Sonntag</div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxFree.sunday"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxed.sunday"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Feiertag</div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxFree.holiday"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxed.holiday"> </ptc-select-wage-type>
                    </div>

                    <div class="label">Sondertag</div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxFree.special"> </ptc-select-wage-type>
                    </div>

                    <div class="value">
                        <ptc-select-wage-type name="bonusTaxed.special"> </ptc-select-wage-type>
                    </div>
                </div>

                <div class="divider small-caps">Sonderleistungen</div>

                <div class="table" style="grid-template-columns: repeat(2, 1fr);">
                    ${this._benefits.length
                        ? this._benefits.map(
                              ({ type, config }) => html`
                                  <input type="hidden" name="benefitConfigId" .value=${config.id?.toString() || ""} />
                                  <input type="hidden" name="benefitTypeId" .value=${type.id.toString()} />

                                  <div class="label">${type.name}</div>

                                  <div class="value">
                                      <ptc-select-wage-type
                                          name="benefitWageTypeNumber"
                                          .value=${config.wageTypeNumber || ""}
                                      >
                                      </ptc-select-wage-type>
                                  </div>
                              `
                          )
                        : html`<div style="grid-column: -1/1;" class="padded subtle">
                              Keine Sonderleisungen definiert.
                          </div>`}
                </div>

                <div class="divider small-caps">Mitarbeiteressen</div>

                <div class="grid">
                    <div class="field">
                        <label>Eurowert</label>
                        <ptc-select-wage-type name="mealsValue"> </ptc-select-wage-type>
                    </div>
                    <div class="field">
                        <label>Frühstück (Anzahl)</label>
                        <ptc-select-wage-type name="mealsBreakfast"> </ptc-select-wage-type>
                    </div>
                    <div class="field">
                        <label>Mittagessen (Anzahl)</label>
                        <ptc-select-wage-type name="mealsLunch"> </ptc-select-wage-type>
                    </div>
                    <div class="field">
                        <label>Abendessen (Anzahl)</label>
                        <ptc-select-wage-type name="mealsDinner"> </ptc-select-wage-type>
                    </div>
                </div>

                <div class="divider small-caps">Sonstiges</div>

                <div class="grid">
                    <div class="field">
                        <label>Gehalt</label>
                        <ptc-select-wage-type name="salary"> </ptc-select-wage-type>
                    </div>
                    <div class="field">
                        <label>Provision</label>
                        <ptc-select-wage-type name="commission"> </ptc-select-wage-type>
                    </div>
                    <div class="field">
                        <label>SFN-Pauschale</label>
                        <ptc-select-wage-type name="bonusAdvance"> </ptc-select-wage-type>
                    </div>
                    <div class="field">
                        <label>Vorschuss</label>
                        <ptc-select-wage-type name="payAdvance"> </ptc-select-wage-type>
                    </div>
                </div>
            </form>
        `;
    }
}
