import { Company } from "@pentacode/core/src/model";
import { CreateCompanyParams } from "@pentacode/core/src/api";
import { html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";

@customElement("ptc-company-dialog")
export class CompanyDialog extends Dialog<Partial<CreateCompanyParams> & { title: string }, Company> {
    readonly preventDismiss = true;

    @query("form")
    private _form: HTMLFormElement;

    @property()
    private _params: Partial<CreateCompanyParams> = {};

    @property()
    private _title: string;

    async show(params?: Partial<CreateCompanyParams> & { title: string }) {
        if (params) {
            const { title, ...rest } = params;
            this._title = title || "Neues Unternehmen";
            this._params = rest;
        }
        return super.show();
    }

    private async _submit(e: Event) {
        e.preventDefault();

        this.loading = true;

        const data = new FormData(this._form);

        try {
            const company = await app.api.createCompany(
                new CreateCompanyParams({
                    name: data.get("name") as string,
                    address: data.get("address") as string,
                    postalCode: data.get("postalCode") as string,
                    city: data.get("city") as string,
                    email: data.get("email") as string,
                    phone: data.get("phone") as string,
                })
            );
            this.done(company);
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this.show();
        }

        this.loading = false;
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                display: flex;
                flex-direction: column;
                max-width: 500px;
            }

            h1 {
                text-align: center;
            }

            .scroller-inner {
                padding: 0 1em;
            }

            form .field {
                margin: 0 0.7em;
            }
        `,
    ];

    renderContent() {
        return html`
            <form @submit=${this._submit}>
                <h1>${this._title}</h1>

                <div class="field">
                    <label>Name des Unternehmens</label>
                    <input type="text" name="name" placeholder="Name" required .value=${this._params.name} />
                </div>

                <div class="field">
                    <label>Adresse</label>
                    <input type="text" name="address" placeholder="Addresse" required .value=${this._params.address} />
                </div>

                <div class="horizontal layout fields">
                    <div class="field stretch">
                        <label>Postleitzahl</label>
                        <input
                            type="text"
                            name="postalCode"
                            placeholder="Addresse"
                            required
                            .value=${this._params.postalCode}
                        />
                    </div>

                    <div class="field stretch">
                        <label>Stadt</label>
                        <input type="text" name="city" placeholder="Addresse" required .value=${this._params.city} />
                    </div>
                </div>

                <div class="field">
                    <label>Emailadresse</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="E-mail Adresse"
                        required
                        .value=${this._params.email}
                    />
                </div>

                <div class="field">
                    <label>Telefon</label>
                    <input type="text" name="phone" placeholder="Telefon" required .value=${this._params.phone} />
                </div>

                <div class="horizontal layout padded-medium pad-children">
                    <button class="primary stretch" @click=${this._submit}>Speichern</button>

                    <button class="stretch transparent" @click=${() => this.done()} type="button">Abbrechen</button>
                </div>
            </form>
        `;
    }
}
