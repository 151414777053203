import { LitElement, html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { RevenueEntry, RevenueType } from "@pentacode/core/src/model";
import { GetRevenueEntriesParams, UpdateRevenueEntriesParams } from "@pentacode/core/src/api";
import { formatDate, getRange, formatNumber, dateAdd } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import { alert } from "./alert-dialog";
import { Checkbox } from "./checkbox";

interface Debtor {
    name: string;
    ledger: string;
}

@customElement("ptc-revenues-debtors")
export class Debtors extends Routing(StateMixin(LitElement)) {
    routePattern = /^revenues\/debtors/;

    get routeTitle() {
        return this.venue ? `Debitoren: ${this.venue.name}` : undefined;
    }

    @routeProperty({ param: "range", default: () => "month" })
    private _range: "month" | "year";

    @state()
    private _loading = false;

    @state()
    private _entries: RevenueEntry[] = [];

    @state()
    private _filterString = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query("#includePaid")
    private _includePaidCheckbox: Checkbox;

    private get _debtors(): Debtor[] {
        const debtors: Debtor[] = [];
        for (const { name, ledger } of this._entries) {
            if (!debtors.find((d) => d.name === name && d.ledger === ledger)) {
                debtors.push({ name, ledger });
            }
        }
        return debtors.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    private get _includePaid() {
        return this._includePaidCheckbox && this._includePaidCheckbox.checked;
    }

    handleRoute() {
        this._load();
    }

    private async _load() {
        if (this._loading || !this.venue || !this.date || !this._range) {
            return;
        }

        const range = getRange(this.date, this._range);

        this._loading = true;

        try {
            this._entries = (
                await app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        ...range,
                        venue: this.venue.id,
                        type: [RevenueType.Debt],
                    })
                )
            ).filter((e) => !e.draft);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    // private _scrollToDate(date: string) {
    //     const index = this._entries.findIndex(e => e.date >= date);
    //     const row = this._forms[index] || this._forms[this._forms.length - 1];
    //     row && row.scrollIntoView({ behavior: "auto", block: "start" });
    // }

    private _updateFilter() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _filterByString(e: RevenueEntry, filter: string) {
        return (
            e.text.toLowerCase().includes(filter) ||
            (e.sequence && e.sequence.toString() === filter) ||
            e.amount.toFixed(2).replace(/\./, ",").includes(filter) ||
            e.ledger.includes(filter) ||
            e.receipt.includes(filter) ||
            e.invoice.includes(filter) ||
            e.date.replace(/(\d\d\d\d)-(\d\d)-(\d\d)/, "$3.$2.$1").includes(filter)
        );
    }

    private async _updatePaid(e: Event, entry: RevenueEntry) {
        entry.paid = (e.target as HTMLInputElement).checked;
        await app.api.updateRevenueEntries(
            new UpdateRevenueEntriesParams({ updated: [entry], force: true, skipBalancing: true })
        );
        this.requestUpdate();
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
                max-width: 75em;
            }

            ptc-scroller {
                flex: 1;
                min-height: 0;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .debtor {
                border-bottom: solid 2px var(--shade-2);
                break-inside: avoid;
            }

            .debtor-row {
                display: grid;
                grid-template-columns: repeat(5, 8em) 3em;
            }

            .debtor-row > * {
                padding: 0.5em;
            }

            .debtor-total > * {
                padding: 0.8em 1em;
                min-width: 8em;
                text-align: right;
            }

            .name {
                font-weight: 600;
                padding: 0.8em;
            }

            .name .pill {
                margin-top: 0.5em;
            }

            .debtor-invoices > .debtor-row {
                border-bottom: solid 1px var(--shade-2);
            }

            .debtor-invoices .debtor-row > :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .debtors-header {
                font-weight: bold;
                border-bottom: solid 2px var(--shade-2);
            }

            .debtors-header > * {
                text-align: center;
            }

            .amount,
            .tip,
            .full {
                text-align: right;
            }

            @media print {
                :host {
                    display: block;
                    position: static !important;
                }

                ptc-scroller {
                    height: auto;
                }
            }
        `,
    ];

    private _renderDebtor({ name, ledger }: Debtor) {
        const entries = this._entries.filter((e) => e.name === name && e.ledger === ledger);
        const displayEntries = entries.filter(
            (e) => this._filterByString(e, this._filterString) && (this._includePaid || !e.paid)
        );

        if (!displayEntries.length) {
            return;
        }

        const total = entries.reduce((total, entry) => (total += Math.abs(entry.amount + entry.tip)), 0);
        const unpaid = entries
            .filter((e) => !e.paid)
            .reduce((total, entry) => (total += Math.abs(entry.amount + entry.tip)), 0);

        return html`
            <div class="debtor">
                <div class="horizontal layout">
                    <div class="name stretch collapse">
                        <div class="medium">${name}</div>
                        <div class="small pill">Konto: <strong>${ledger || "N/A"}</strong></div>
                    </div>
                    <div class="debtor-invoices">
                        ${repeat(
                            displayEntries,
                            (e) => e.id,
                            (entry) => html`
                                <div class="debtor-row">
                                    <div class="date">${formatDate(entry.date)}</div>
                                    <div class="number">${entry.invoice}</div>
                                    <div class="amount">${formatNumber(Math.abs(entry.amount))} €</div>
                                    <div class="tip">${formatNumber(Math.abs(entry.tip))} €</div>
                                    <div class="full">${formatNumber(Math.abs(entry.amount + entry.tip))} €</div>
                                    <div class="paid center-aligning center-justifying horizontal layout">
                                        <input
                                            type="checkbox"
                                            .checked=${entry.paid}
                                            @change=${(e: Event) => this._updatePaid(e, entry)}
                                        />
                                    </div>
                                </div>
                            `
                        )}

                        <div class="debtor-total horizontal layout">
                            <div class="stretch"></div>
                            <div>Gesamt: <strong>${formatNumber(total)} €</strong></div>
                            <div>Offen: <strong>${formatNumber(unpaid)} €</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        if (!this.venue || !this.date || !this._range) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        const { from, to } = getRange(this.date, this._range);

        return html`
            <div class="header horizontal center-aligning layout padded-medium noprint">
                <div class="horizontal tabs">
                    <button ?active=${this._range === "month"} @click=${() => this.go(null, { range: "month" })}>
                        Monat
                    </button>
                    <button ?active=${this._range === "year"} @click=${() => this.go(null, { range: "year" })}>
                        Jahr
                    </button>
                </div>

                <div class="stretch"></div>

                <button class="transparent icon" @click=${() => print()}>
                    <i class="print"></i>
                </button>

                <ptc-checkbox-button
                    id="includePaid"
                    buttonClass="transparent small padded-medium"
                    label="bezahlt"
                    @change=${() => this.requestUpdate()}
                ></ptc-checkbox-button>

                <div class="small right icon input noprint">
                    <input id="filterInput" placeholder="Suchen..." @input=${this._updateFilter} />
                    <i class="${this._filterString ? "times click" : "search"} icon" @click=${this._clearFilter}></i>
                </div>
            </div>

            <div class="large header printonly horizontal layout padded-medium">
                <div class="stretch">
                    Debitoren <strong>${formatDate(from)}</strong> -
                    <strong>${formatDate(dateAdd(to, { days: -1 }))}</strong>
                </div>
                <div>${this.venue.name}</div>
            </div>

            <div class="horizontal layout debtors-header">
                <div class="stretch collapse"></div>
                <div class="debtor-row">
                    <div>Datum</div>
                    <div>Rechnr.</div>
                    <div>Betrag</div>
                    <div>Trinkgeld</div>
                    <div>Gesamt</div>
                    <div>Bez.</div>
                </div>
            </div>

            <ptc-scroller> ${this._debtors.map((d) => this._renderDebtor(d))} </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
