import { Employee, Department, TimeEntry, TimeEntryType } from "@pentacode/core/src/model";
import { getRange, toDateString, parseDateString, monthNames, formatNumber as fmtNum } from "@pentacode/core/src/util";
import { calcAllHours, calcTotalHours } from "@pentacode/core/src/hours";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Chart, ChartConfig } from "./chart";
import "./scroller";
import "./spinner";
import { DateString } from "@pentacode/openapi/src/units";

const formatNumber = (val: number) => fmtNum(val, 0);

@customElement("ptc-reports-time")
export class ReportsTime extends Routing(StateMixin(LitElement)) {
    routePattern = /^reports\/time/;

    get routeTitle() {
        return "Arbeitszeit-Bericht";
    }

    @state()
    private _loading = false;

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _selectedEmployee: Employee | null = null;

    @state()
    private _selectedDepartment: Department | null = null;

    @state()
    private _filterString = "";

    @state()
    private _unit: "euro" | "hours" = "hours";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query(".chart-monthly")
    private _chartMonthly: Chart;

    @query(".venue-selector")
    private _venueSelector: HTMLSelectElement;

    private _cachedValues = new Map<string, { hours: number; euro: number }>();

    private get _plannedColor() {
        return this._unit === "euro" ? "violet" : "blue";
    }

    private get _actualColor() {
        return this._unit === "euro" ? "green" : "orange";
    }

    private _updateFilter() {
        this._filterString = this._filterInput.value;
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    handleRoute() {
        if (!this.venue) {
            if (app.venues.length) {
                this.go(null, { ...this.router.params, venue: app.venues[0].id.toString() }, true);
            }
            return;
        }

        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { ...this.router.params, date: toDateString(new Date()) }, true);
            return;
        }

        this.load();
    }

    updated(changes: Map<string, any>) {
        if (
            (changes.has("_timeEntries") ||
                changes.has("_unit") ||
                changes.has("_selectedEmployee") ||
                changes.has("_selectedDepartment")) &&
            this._chartMonthly
        ) {
            this._updateCharts();
        }
    }

    async load() {
        if (!this.year || !this.venue) {
            return;
        }

        let { from, to } = getRange(`${this.year}-01-01` as DateString, "year");

        const currMonth = getRange(toDateString(new Date()), "month");

        if (to > currMonth.to) {
            to = currMonth.to;
        }

        this._loading = true;

        try {
            this._timeEntries = await app.getTimeEntries({
                from,
                to,
                type: [TimeEntryType.Work],
                venue: this.venueId,
            });
            this._cachedValues.clear();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _selectVenue() {
        this.go(null, { venue: this._venueSelector.value, date: this.date });
    }

    private _getHours({
        department,
        month,
        employee: employeeId,
        mode,
        unit = this._unit,
    }: {
        department?: number;
        month?: number;
        employee?: number;
        mode: "planned" | "final";
        unit?: "euro" | "hours";
    }) {
        const key = `${department}_${employeeId}_${month}_${mode}`;

        const cached = this._cachedValues.get(key);

        if (cached) {
            return cached[unit];
        }

        const employees = employeeId ? [app.getEmployee(employeeId)!] : app.employees;

        let hours = 0;
        let euro = 0;

        for (const employee of employees) {
            let entries = this._timeEntries.filter((e) => e.employeeId === employee.id);

            if (typeof month === "number") {
                entries = entries.filter((e) => e.start.getMonth() === month);
            }

            if (typeof department === "number") {
                entries = entries.filter((e) => e.position && e.position!.departmentId === department);
            }

            const hrs = calcTotalHours(calcAllHours(employee, app.company!, entries, undefined, mode));

            hours += hrs.full;
            euro += hrs.salaryGross;
        }

        this._cachedValues.set(key, {
            euro,
            hours,
        });

        return unit === "euro" ? euro : hours;
    }

    private _getChartConfigMonthly(): ChartConfig {
        const employee = (this._selectedEmployee && this._selectedEmployee.id) || undefined;
        const department = (this._selectedDepartment && this._selectedDepartment.id) || undefined;

        const series = [
            {
                name: "Geplant",
                data: monthNames.map((_name, month) =>
                    this._getHours({ month, employee, department, mode: "planned" })
                ),
            },
            {
                name: "Erfasst",
                data: monthNames.map((_name, month) => this._getHours({ month, employee, department, mode: "final" })),
            },
        ];

        return {
            chart: {
                type: "bar",
                stacked: false,
                height: 350,
                parentHeightOffset: 0,
                selection: { enabled: false },
            },
            colors: [colors[this._plannedColor], colors[this._actualColor]],
            legend: {
                show: false,
            },
            series,
            labels: monthNames.map((n) => n.slice(0, 3)),
            xaxis: {
                type: "category",
            },
            yaxis: {
                title: { text: "Arbeitszeit" + (this._unit === "euro" ? " (€)" : " (Stunden)") },
                labels: {
                    formatter: (val: number) => val.toFixed(0),
                },
                opposite: true,
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val: number) => formatNumber(val) + (this._unit === "euro" ? " €" : " St."),
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            stroke: {
                show: true,
                width: 4,
                colors: ["transparent", "transparent"],
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                },
            },
        };
    }

    private _updateCharts() {
        this._chartMonthly.config = this._getChartConfigMonthly();
    }

    private _yearSelected(e: Event) {
        const date = parseDateString(this.date) || new Date();
        date.setFullYear(parseInt((e.target as HTMLSelectElement).value));
        this.go(null, { date: toDateString(date), venue: this._venueSelector.value });
    }

    private _selectEmployee(employee: Employee | null) {
        this._selectedEmployee = employee;
        this._selectedDepartment = null;
        this.renderRoot.querySelector(".charts")!.scrollIntoView();
    }

    static styles = [
        shared,
        css`
            :host {
                display: grid;
                grid-template-columns: 200px 1fr;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .main {
                position: relative;
            }

            .scroller {
                overflow: auto;
                margin: 0 0.5em;
            }

            .year-selector,
            .venue-selector {
                font-size: var(--font-size-medium);
                padding-top: 0.3em;
                padding-bottom: 0.3em;
            }

            .department-row {
                display: grid;
                grid-template-columns: 1fr 4em 4em;
                grid-gap: 0.5em;
            }

            .department-row-value {
                text-align: right;
            }

            button:not([active]) .department-row-value {
                color: var(--color-highlight);
            }

            .department-row-header {
                font-size: var(--font-size-tiny);
                color: var(--color-highlight);
                font-weight: bold;
                text-align: center;
                padding: 0.5em;
            }

            .charts {
                position: sticky;
                left: 0;
                display: flex;
                margin-top: 0.5em;
            }

            .charts-total {
                width: 19em;
            }

            .charts-monthly {
                flex: 1;
            }

            .charts-title {
                font-size: var(--font-size-large);
                padding: 0.5em;
                font-weight: 600;
                text-align: center;
            }

            .row {
                display: grid;
                grid-template-columns: 12em repeat(13, 1fr);
                background: var(--color-bg);
                min-width: 120em;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .column {
                display: flex;
                min-width: 0;
            }

            :not(.header-row) > .column > * {
                flex: 1;
                font-size: var(--font-size-small);
                padding: 0.5em;
                text-align: right;
                min-width: 0;
                color: var(--color-highlight);
            }

            .column > :not(:last-child) {
                border-right: dashed 1px var(--shade-1);
            }

            .column:not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 1;
            }

            .header-column {
                text-align: left;
                position: sticky;
                left: 0;
                background: inherit;
                border-radius: inherit;
            }

            .header-row > .column {
                padding-top: 0.5em;
                font-weight: 600;
                border: none;
                text-align: center;
            }

            .header-row > .header-column {
                padding-left: 0;
                padding-bottom: 0.3em;
            }

            .filter-input input {
                font-weight: normal;
                padding-top: 0.4em;
                padding-bottom: 0.4em;
            }

            .row.total-row > .column {
                font-weight: bold;
            }

            .total-column {
                font-weight: 600;
                position: sticky;
                right: 0;
                background: inherit;
                border-left: solid 1px var(--shade-1) !important;
            }

            .row:not(.header-row):hover:not([active]) {
                background: #f1f1f1;
                cursor: pointer;
            }

            .row[active] {
                border-radius: var(--border-radius);
                border-color: transparent;
                background: var(--color-primary);
                color: var(--color-bg);
                position: sticky;
                top: 3.5em;
                bottom: 0.5em;
                z-index: 2;
                font-weight: bold;
            }

            .row[active] * {
                color: var(--color-bg) !important;
            }
        `,
    ];

    render() {
        if (!app.company || !this.venue) {
            return html``;
        }

        const years: number[] = [];
        for (let year = new Date().getFullYear(); year > 2010; year--) {
            years.push(year);
        }

        const employees = app.employees.filter(({ name }) =>
            name.toLowerCase().includes(this._filterString.toLowerCase())
        );

        const employee = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        return html`
            <div class="fullbleed vertical layout">
                <div class="header horizontal center-aligning layout padded-medium noprint">
                    <select class="venue-selector" @change=${this._selectVenue} ?hidden=${app.venues.length < 2}>
                        ${app.venues.map(
                            (venue) => html`
                                <option
                                    .value=${venue.id.toString()}
                                    ?selected=${venue.id === this.venueId}
                                    ?disabled=${!app.hasAccess({ venue })}
                                >
                                    ${venue.name}
                                </option>
                            `
                        )}
                    </select>

                    <div class="stretch"></div>

                    <div class="horizontal tabs">
                        <button ?active=${this._unit === "hours"} @click=${() => (this._unit = "hours")}>
                            <i class="clock"></i>
                        </button>
                        <button ?active=${this._unit === "euro"} @click=${() => (this._unit = "euro")}>
                            <i class="euro-sign"></i>
                        </button>
                    </div>

                    <div class="stretch"></div>

                    <select class="year-selector" .value=${this.year.toString()} @change=${this._yearSelected}>
                        ${years.map((year) => html` <option .value=${year.toString()}>${year}</option> `)}
                    </select>
                </div>

                <div class="scroller stretch">
                    <div class="charts">
                        <div class="charts-total vertical layout">
                            <div class="charts-title">
                                ${(this._selectedEmployee && this._selectedEmployee.name) || "Alle Mitarbeiter"}
                            </div>

                            <div class="department-row">
                                <div></div>
                                <div class="department-row-header ${this._plannedColor}">Geplant</div>
                                <div class="department-row-header ${this._actualColor}">Erfasst</div>
                            </div>

                            <ptc-scroller class="stretch collapse">
                                <div class="departments vertical tabs">
                                    <button
                                        class="department-row"
                                        ?active=${!this._selectedDepartment}
                                        @click=${() => (this._selectedDepartment = null)}
                                    >
                                        <div class="ellipsis">Alle Abteilungen</div>
                                        <div class="department-row-value ${this._plannedColor}">
                                            ${formatNumber(this._getHours({ mode: "planned", employee }))}
                                        </div>
                                        <div class="department-row-value ${this._actualColor}">
                                            ${formatNumber(this._getHours({ mode: "final", employee }))}
                                        </div>
                                    </button>

                                    ${this.venue.departments.map((dep) => {
                                        const planned = this._getHours({
                                            department: dep.id,
                                            mode: "planned",
                                            employee,
                                        });
                                        const actual = this._getHours({ department: dep.id, mode: "final", employee });

                                        if (!planned && !actual) {
                                            return;
                                        }

                                        return html`
                                            <button
                                                class="department-row"
                                                ?active=${this._selectedDepartment &&
                                                this._selectedDepartment.id === dep.id}
                                                @click=${() => (this._selectedDepartment = dep)}
                                            >
                                                <div class="ellipsis">${dep.name}</div>
                                                <div class="department-row-value ${this._plannedColor}">
                                                    ${formatNumber(planned)}
                                                </div>
                                                <div class="department-row-value ${this._actualColor}">
                                                    ${formatNumber(actual)}
                                                </div>
                                            </button>
                                        `;
                                    })}
                                </div>
                            </ptc-scroller>
                        </div>
                        <div class="charts-monthly">
                            <ptc-chart class="chart-monthly"></ptc-chart>
                        </div>
                    </div>

                    <div class="table">
                        <div class="row header-row">
                            <div class="column header-column">
                                <div class="filter-input right icon input">
                                    <input
                                        id="filterInput"
                                        type="text"
                                        placeholder="Suchen..."
                                        @input=${this._updateFilter}
                                    />
                                    <i
                                        class="${this._filterString ? "times click" : "search"} icon"
                                        @click=${this._clearFilter}
                                    ></i>
                                </div>
                            </div>

                            ${monthNames.map(
                                (n) => html`
                                    <div class="column vertical end-justifying layout">
                                        <div>${n.slice(0, 3)}</div>
                                        <div class="horizontal layout">
                                            <div class="stretch tiny highlight-fg ${this._plannedColor} padded-light">
                                                Geplant
                                            </div>
                                            <div class="stretch tiny highlight-fg ${this._actualColor} padded-light">
                                                Erfasst
                                            </div>
                                        </div>
                                    </div>
                                `
                            )}

                            <div class="column vertical end-justifying layout total-column">
                                <div>Gesamt</div>
                                <div class="horizontal layout">
                                    <div class="stretch tiny highlight-fg ${this._plannedColor} padded-light">
                                        Geplant
                                    </div>
                                    <div class="stretch tiny highlight-fg ${this._actualColor} padded-light">
                                        Erfasst
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="row total-row"
                            ?active=${!this._selectedEmployee}
                            @click=${() => this._selectEmployee(null)}
                        >
                            <div class="column header-column">
                                <div class="ellipsis">Alle Mitarbeiter</div>
                            </div>

                            ${monthNames.map(
                                (_name, month) => html`
                                    <div class="column">
                                        <div class="${this._plannedColor}">
                                            ${formatNumber(this._getHours({ month, mode: "planned" }))}
                                        </div>
                                        <div class="${this._actualColor}">
                                            ${formatNumber(this._getHours({ month, mode: "final" }))}
                                        </div>
                                    </div>
                                `
                            )}

                            <div class="column total-column">
                                <div class="${this._plannedColor}">
                                    ${formatNumber(this._getHours({ mode: "planned" }))}
                                </div>
                                <div class="${this._actualColor}">
                                    ${formatNumber(this._getHours({ mode: "final" }))}
                                </div>
                            </div>
                        </div>

                        ${employees.map((employee) => {
                            const data = monthNames.map((_name, month) => ({
                                planned: this._getHours({ employee: employee.id, month, mode: "planned" }),
                                actual: this._getHours({ employee: employee.id, month, mode: "final" }),
                            }));

                            if (data.every((d) => !d.planned && !d.actual)) {
                                return null;
                            }

                            return html`
                                <div
                                    class="row"
                                    ?active=${this._selectedEmployee && this._selectedEmployee.id === employee.id}
                                    @click=${() => this._selectEmployee(employee)}
                                >
                                    <div class="column header-column">
                                        <div class="ellipsis">${employee.lastName}, ${employee.firstName}</div>
                                    </div>

                                    ${data.map(
                                        (val) => html`
                                            <div class="column">
                                                <div class="${this._plannedColor}">${formatNumber(val.planned)}</div>
                                                <div class="${this._actualColor}">${formatNumber(val.actual)}</div>
                                            </div>
                                        `
                                    )}

                                    <div class="column total-column">
                                        <div class="${this._plannedColor}">
                                            ${formatNumber(this._getHours({ employee: employee.id, mode: "planned" }))}
                                        </div>
                                        <div class="${this._actualColor}">
                                            ${formatNumber(this._getHours({ employee: employee.id, mode: "final" }))}
                                        </div>
                                    </div>
                                </div>
                            `;
                        })}
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
