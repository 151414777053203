import Logo from "../img/logo.svg";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { SetPasswordParams } from "@pentacode/core/src/api";
import { shared, mixins } from "../styles";
import { app, router } from "../init";
import { alert } from "./alert-dialog";

@customElement("ptc-set-password")
export class SetPassword extends LitElement {
    @query("form")
    _form: HTMLFormElement;

    @query("input[name='repeatPassword']")
    private _repeatPasswordInput: HTMLInputElement;

    @property()
    private _loading = false;

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        this._loading = true;

        const password = new FormData(this._form).get("password") as string;

        try {
            await app.api.setPassword(
                new SetPasswordParams({
                    id: parseInt(router.params.id),
                    token: router.params.token,
                    password,
                })
            );
            await alert("Passwort erfolgreich geändert!", { options: ["Einloggen"], type: "success" }),
                router.go("login", { email: router.params.email });
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _checkRepeatedPassword() {
        const formData = new FormData(this._form);
        this._repeatPasswordInput.setCustomValidity(
            formData.get("password") === formData.get("repeatPassword")
                ? ""
                : "Sie haben das Passwort nicht richtig wiederholt. " + "Bitte versuchen Sie es erneut!"
        );
    }

    static styles = [
        shared,
        css`
            :host {
                ${mixins.fullbleed()};
                ${mixins.scroll()};
                z-index: 100;
                background: #fafafa;
                padding: 2em;
            }

            .logo {
                width: 20em;
            }

            .wrapper {
                width: 100%;
                max-width: 25em;
            }
        `,
    ];

    render() {
        return html`
            <div class="fullbleed vertical centering layout">
                <img class="margined logo" src=${Logo} />

                <div class="wrapper text-centering">
                    <h1>Passwort Wählen</h1>

                    <div class="margined">
                        Bitte wählen Sie ein neues Passwort für den Account <strong>${router.params.email}</strong>!
                    </div>

                    <div class="card double-margined double-padded vertical layout">
                        <form class="vertical layout" @input=${this._checkRepeatedPassword} @submit=${this._submit}>
                            <div class="field">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Neues Passwort"
                                    required
                                    minlength="8"
                                />
                            </div>

                            <div class="field">
                                <input
                                    type="password"
                                    name="repeatPassword"
                                    placeholder="Passwort Wiederholen"
                                    required
                                />
                            </div>

                            <button type="submit" class="primary">Bestätigen</button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
