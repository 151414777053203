import "./scroller";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { app } from "../init";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { shared } from "../styles";
import { EmployeeTag } from "@pentacode/core/src/model";
import { ColorInput } from "./color-input";
import { clone } from "@pentacode/core/src/encoding";
import { alert } from "./alert-dialog";
import { randomColor } from "@pentacode/core/src/colors";
import "./sortable-list";

@customElement("ptc-settings-core-data")
export class SettingsCoreData extends Routing(StateMixin(LitElement)) {
    routePattern = /settings\/core_data/;

    get routeTitle() {
        return "Dokumente | Stammdaten";
    }

    get helpPage() {
        return "handbuch/einstellungen/stammdaten/";
    }

    @state()
    private _loading = false;

    @state()
    private _employeeTags: EmployeeTag[] = [];

    private get _hasChanges() {
        const existingEmployeeTags = app.company?.employeeTags || [];
        return (
            this._employeeTags.length !== existingEmployeeTags.length ||
            this._employeeTags.some((tag) => {
                const existing = existingEmployeeTags.find((t) => t.id === tag.id);
                return !existing || existing.name !== tag.name || existing.color !== tag.color;
            })
        );
    }

    handleRoute() {
        this._reset();
    }

    private _reset() {
        this._employeeTags =
            app.company?.employeeTags?.map((tag) => clone(tag)).sort((a, b) => a.order - b.order) || [];
    }

    private async _submit(e: Event) {
        e.preventDefault();

        this._loading = true;

        try {
            this._employeeTags.forEach((t, i) => (t.order = i));
            await app.updateCompany({ employeeTags: this._employeeTags });
            this._reset();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _removeEmployeeTag(index: number) {
        this._employeeTags.splice(index, 1);
        this.requestUpdate();
    }

    private async _newEmployeeTag() {
        this._employeeTags.push(
            new EmployeeTag({
                name: "",
                companyId: app.company!.id,
                color: randomColor(app.company?.employeeTags?.map((t) => t.color)),
            })
        );
        this.requestUpdate();
        await this.updateComplete;
        const tagWrappers = this.renderRoot.querySelectorAll(".employees .tag-item");
        const nameInput = tagWrappers[tagWrappers.length - 1]?.querySelector("input");
        nameInput?.focus();
    }

    static styles = [
        shared,
        ColorInput.styles,
        css`
            .tag-item > input {
                padding: 0.7em 2em 0.7em 3em;
                width: 100%;
            }

            .tag-item > input:focus-visible {
                box-shadow: inset var(--color-primary) 0 0 0 1px;
            }

            .tag-item > ptc-color-input {
                position: absolute;
                left: 0.5em;
                top: 0.5em;
            }

            .tag-item > button {
                position: absolute;
                right: 0.5em;
                top: 0.4em;
            }

            .tag-item:not(:hover) > button {
                opacity: 0;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return;
        }
        return html`
            <ptc-scroller class="fullbleed">
                <div class="double-padded">
                    <form
                        @submit=${this._submit}
                        @input=${() => this.requestUpdate()}
                        style="max-width: 50em; margin: 0 auto;"
                        autocomplete="off"
                    >
                        <h2>Schlagwörter</h2>
                        <div class="box employee">
                            <ptc-sortable-list
                                .items=${this._employeeTags}
                                .renderItem=${(tag: EmployeeTag, i: number) => html`
                                    <div class="tag-item relative border-bottom">
                                        <ptc-color-input
                                            name="color"
                                            .value=${tag.color}
                                            required
                                            @change=${(e: Event) => (tag.color = (e.target as HTMLInputElement).value)}
                                            class="right-margined"
                                        ></ptc-color-input>
                                        <input
                                            name="name"
                                            .value=${tag.name}
                                            required
                                            @input=${(e: Event) =>
                                                (tag.name = (e.target as HTMLInputElement).value.trim())}
                                            placeholder="Schlagwort eingeben..."
                                            class="plain"
                                        />
                                        <button
                                            class="skinny transparent remove-button"
                                            @click=${() => this._removeEmployeeTag(i)}
                                            type="button"
                                        >
                                            <i class="trash-alt"></i>
                                        </button>
                                    </div>
                                `}
                                @item-moved=${this._submit}
                            >
                            </ptc-sortable-list>

                            <div>
                                <button
                                    class="transparent fill-horizontally"
                                    type="button"
                                    @click=${this._newEmployeeTag}
                                >
                                    <i class="plus"></i> Neues Schlagwort
                                </button>
                            </div>
                        </div>

                        <div
                            class="padded top-margined horizontal spacing evenly stretching layout"
                            ?hidden=${!this._hasChanges}
                        >
                            <button class="primary">Speichern</button>
                            <button type="button" class="transparent" @click=${this._reset}>Abbrechen</button>
                        </div>
                    </form>
                </div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
