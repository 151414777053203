import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { TimeEntry } from "@pentacode/core/src/model";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert, confirm } from "./alert-dialog";
import "./popover";
import { Checkbox } from "./checkbox";
import { DateInput } from "./date-input";
import { DateString, Days } from "@pentacode/openapi/src/units";
import "./select-wage-type";
import { SelectWageType } from "./select-wage-type";

@customElement("ptc-vacation-adjustment-dialog")
export class VacationAdjustmentDialog extends Dialog<TimeEntry | undefined, boolean> {
    readonly preventDismiss = true;

    @query("ptc-date-input[name='date']")
    private _dateInput: HTMLInputElement;

    @query("input[name='days']")
    private _daysInput: HTMLInputElement;

    @query("textarea[name='comment']")
    private _commentInput: HTMLTextAreaElement;

    @query("select[name='sign']")
    private _signSelect: HTMLSelectElement;

    @query("ptc-checkbox-button[name='paid']")
    private _paidButton: Checkbox;

    @query("ptc-select-wage-type[name='wageType']")
    private _wageTypeSelect: SelectWageType;

    @query("form")
    private _form: HTMLFormElement;

    @state()
    private _timeEntry: TimeEntry;

    async show(timeEntry?: TimeEntry) {
        const promise = super.show();

        if (timeEntry) {
            this._timeEntry = timeEntry;
            await this.updateComplete;
            this._daysInput.value = (timeEntry.days && Math.abs(timeEntry.days).toString()) || "0";
            this._signSelect.value = timeEntry.days && timeEntry.days < 0 ? "-1" : "+1";
            this._dateInput.value = timeEntry.date;
            this._paidButton.checked = timeEntry.paid;
            this._commentInput.value = timeEntry.comment || "";
            this._wageTypeSelect.value = timeEntry.wageType || "";
            this._daysInput.select();
        }

        await this.updateComplete;
        this.requestUpdate();

        return promise;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const entry = this._timeEntry;
        const formData = new FormData(this._form);

        entry.date = formData.get("date") as DateString;
        entry.days = (parseInt(formData.get("sign") as string) * parseFloat(formData.get("days") as string)) as Days;
        entry.paid = formData.has("paid");
        entry.comment = formData.get("comment") as string;
        entry.wageType = formData.get("wageType") as string;

        this.loading = true;

        try {
            await app.createOrUpdateTimeEntries(entry, { wait: true, publish: true });
            this.done(true);
        } catch (err) {
            this.hide();
            await alert(err.message, { type: "warning" });
            this.show();
        }

        this.loading = false;
    }

    private async _signChanged() {
        this._paidButton.checked = this._signSelect.value === "+1" ? true : false;
        this.requestUpdate();
    }

    private _validateDate(e: Event) {
        const input = e.target as HTMLInputElement;
        const employee = app.getEmployee(this._timeEntry.employeeId!);
        const contract = employee && employee.getContractForDate(input.value);
        input.setCustomValidity(!contract ? "Es existiert kein Vertrag für diesen Zeitraum!" : "");
    }

    private async _delete() {
        this.hide();
        if (
            !(await confirm("Wollen Sie diese Urlaubsbuchung wirklich löschen?", "Löschen", "Abbrechen", {
                title: "Urlaubsbuchung Löschen",
                type: "destructive",
            }))
        ) {
            this._show();
            return;
        }
        this._show();

        this.loading = true;

        try {
            await app.removeTimeEntries(this._timeEntry);
            this.done(true);
        } catch (err) {
            this.hide();
            await alert(err.message, { type: "warning" });
            this._show();
        }

        this.loading = false;
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        DateInput.styles,
        css`
            :host {
                --dialog-max-width: 400px;
            }

            form {
                padding: 0 1em;
            }

            .actions {
                margin: 1.5em 0 1em 0;
            }

            .field > label {
                padding: 0.6em 0.4em;
            }

            .amount select {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;
            }

            .amount input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                text-align: right;
            }
        `,
    ];

    renderContent() {
        if (!this._timeEntry) {
            return html``;
        }

        return html`
            <form @submit=${this._submit}>
                <div class="center-aligning margined vertically-padded horizontal layout">
                    <div class="big stretch">
                        ${this._timeEntry.id ? "Urlaubsbuchung Bearbeiten" : "Neue Urlaubsbuchung"}
                    </div>
                    <button type="button" ?hidden=${!this._timeEntry.id} class="subtle icon" @click=${this._delete}>
                        <i class="trash-alt"></i>
                    </button>
                </div>

                <div class="horizontal spacing layout fields">
                    <div class="field stretch">
                        <label><i class="calendar-alt"></i>Datum</label>
                        <ptc-date-input
                            name="date"
                            required
                            @change=${(e: Event) => this._validateDate(e)}
                            datePicker="popover"
                        ></ptc-date-input>
                    </div>

                    <div class="field stretch">
                        <label><i class="stopwatch"></i>Betrag (Tage)</label>
                        <div class="horizontal layout amount">
                            <select name="sign" @change=${() => this._signChanged()}>
                                <option value="+1">Abbuchung</option>
                                <option value="-1">Gutschrift</option>
                            </select>
                            <input class="stretch collapse" type="number" min="0" step="0.01" name="days" required />
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label><i class="comment"></i>Kommentar</label>
                    <textarea name="comment" placeholder='z.B. "Auszahlung Urlaubstage"'></textarea>
                </div>

                <ptc-checkbox-button
                    .label=${html` <i class="euro-sign"></i> Im Lohnbericht Berücksichtigen `}
                    name="paid"
                    buttonClass="transparent"
                    ?hidden=${this._signSelect && this._signSelect.value === "-1"}
                    @change=${() => this.requestUpdate()}
                ></ptc-checkbox-button>

                <ptc-popover trigger="hover" class="tooltip" alignment="right">
                    Wählen Sie diese Option, falls die abgebuchten Urlaubstage ausbezahlt werden. Die Urlaubsbuchung
                    wird dann in der Lohnbuchhaltung und in der Personalkostenberechnung berücksichtigt.
                </ptc-popover>

                <div class="field" ?hidden=${!this._paidButton?.checked}>
                    <label><i class="calculator-simple"></i> Lohnart</label>
                    <ptc-select-wage-type name="wageType"></ptc-select-wage-type>
                </div>

                <div class="actions grid">
                    <button class="primary">Speichern</button>
                    <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
