import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, state } from "lit/decorators.js";
import {
    formatDate,
    formatNumber,
    formatRange,
    getCurrentMonthRange,
    getRange,
    toDateString,
} from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./date-range-picker";
import "./spinner";
import "./popover";
import { VacationBalance } from "@pentacode/core/src/model";
import { serializeFilters } from "@pentacode/core/src/filters";
import { GetBalancesParams } from "@pentacode/core/src/api";
import { Balance } from "./balance";
import { DateRange } from "@pentacode/core/src/time";
import { popover } from "../directives/popover";
import "./chart";
import { live } from "lit/directives/live.js";
import { singleton } from "../lib/singleton";
import { ResetBalancesDialog } from "./reset-balances-dialog";
import { RecalculateResultsDialog } from "./recalcuate-results-dialog";

@customElement("ptc-employees-vacation-balance-single")
export class EmployeesVacationBalanceSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<employeeId>\d+)\/vacation_balance$/;

    @routeProperty({ arg: "employeeId", type: Number })
    employeeId: number;

    defaultRange = () => {
        const firstContract = this._employee?.firstContract;
        if (!firstContract) {
            return getCurrentMonthRange();
        }
        const from = getRange(firstContract.start, this._resolution || "month").from;
        const today = toDateString(new Date());
        const contractEnd = this._employee?.latestContract?.end;
        let to = !contractEnd || today < contractEnd ? today : contractEnd;
        to = getRange(to, this._resolution || "month").to;
        return { from, to };
    };

    @routeProperty({ param: "resolution", default: () => "month" })
    private _resolution: "month" | "week" | "year";

    @singleton("ptc-reset-balances-dialog")
    private _resetBalancesDialog: ResetBalancesDialog;

    @singleton("ptc-recalculate-results-dialog")
    private _recalculateResultsDialog: RecalculateResultsDialog;

    get routeTitle() {
        return `Urlaubskonto: ${this._employee?.name}`;
    }

    @state()
    private _balances: VacationBalance[] = [];

    @state()
    private _loading = false;

    private get _employee() {
        return app.getEmployee(this.employeeId);
    }

    updated(changes: Map<string, unknown>) {
        if (
            changes.has("employeeId") ||
            changes.has("dateFrom") ||
            changes.has("dateTo") ||
            changes.has("_resolution")
        ) {
            this.load();
        }
    }

    async load() {
        if (this._loading || !this._resolution) {
            return;
        }

        this._loading = true;

        try {
            const resolution = this._resolution;
            this._balances = (
                await app.api.getVacationBalances(
                    new GetBalancesParams({
                        from: this.dateFrom,
                        to: this.dateTo,
                        resolution,
                        filters: [{ type: "employeeId", value: this.employeeId }],
                    })
                )
            ).sort((a, b) => (a.from > b.from ? -1 : 1));
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _editBalancesReset(balances: VacationBalance) {
        const edited = await this._resetBalancesDialog.show({
            date: balances.from,
            filters: [{ type: "employeeId", value: balances.employeeId }],
        });

        if (edited) {
            this.load();
        }
    }

    private async _resetBalances() {
        const edited = await this._resetBalancesDialog.show({
            date: getRange(new Date(), this._resolution || "month").from,
            filters: [{ type: "employeeId", value: this._employee!.id }],
        });

        if (edited) {
            this.load();
        }
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .scroller {
                flex: 1;
                overflow: auto;
            }

            table {
                border-collapse: separate;
                display: block;
            }

            thead {
                display: block;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            tbody {
                display: block;
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                display: grid;
                grid-template-columns: 1fr repeat(5, 8rem);
                align-items: center;
                width: 100%;
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th,
            td {
                padding: 0.5em;
                vertical-align: middle;
            }

            td {
                text-align: right;
            }

            thead {
                font-weight: bold;
                background: var(--color-bg);
                text-align: center;
            }

            tr.subbalance > th {
                padding-left: 2em;
            }
        `,
    ];

    private _renderBalances(balances: VacationBalance[], sub = false): TemplateResult {
        balances.sort((a, b) => (a.from > b.from ? -1 : 1));
        return html`
            ${balances.map(
                (balance, i) => html`
                    <tr
                        class="${sub ? "smaller subbalance" : ""} click"
                        @click=${() =>
                            this.go(`employees/${this.employeeId}/time`, {
                                from: balance.from,
                                to: balance.to,
                            })}
                    >
                        <th>${formatRange(balance)}</th>
                        <td>
                            <ptc-balance .value=${balance.reset ?? balance.carry}></ptc-balance>
                        </td>
                        <td>${formatNumber(balance.nominal)}</td>
                        <td>
                            ${balance.adjustments
                                ? html`<sup
                                      class="tinier"
                                      ${popover(
                                          html`
                                              <table class="simple">
                                                  <thead>
                                                      <tr>
                                                          <th style="text-align: left;">Ursache</th>
                                                          <th>Tage</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <th>
                                                              <i class="person-digging"></i>
                                                              Genommen
                                                          </th>
                                                          <td>${formatNumber(balance.taken)}</td>
                                                      </tr>
                                                      <tr>
                                                          <th>
                                                              <i class="receipt"></i>
                                                              Urlaubsbuchungen
                                                          </th>
                                                          <td>${formatNumber(balance.adjustments)}</td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          `,
                                          {
                                              trigger: "hover",
                                              class: "text-left-aligning tooltip non-interactive",
                                          }
                                      )}
                                  >
                                      <i class="receipt"></i>
                                  </sup> `
                                : ""}
                            ${formatNumber(balance.actual)}
                        </td>
                        <td>
                            <ptc-balance
                                .value=${balance.difference}
                                .icons=${"up-down"}
                                class="semibold"
                            ></ptc-balance>
                        </td>
                        <td>
                            <ptc-balance class="bold" .value=${balance.balance}></ptc-balance>
                        </td>
                    </tr>

                    ${typeof balance.subBalances !== "undefined" ? this._renderBalances(balance.subBalances, true) : ""}
                    ${typeof balance.reset === "number" && (!sub || i < balances.length - 1)
                        ? html`
                              <tr class="click smaller subbalance" @click=${() => this._editBalancesReset(balance)}>
                                  <th class="bold">
                                      <i class="undo"></i> Konto zurückgesetzt am ${formatDate(balance.from)}
                                  </th>
                                  <td style="grid-column: span 5;">
                                      <ptc-balance .value=${balance.carry} class="semibold"></ptc-balance>
                                      <i class="arrow-right"></i>
                                      <ptc-balance .value=${balance.reset} class="bold"></ptc-balance>
                                  </td>
                              </tr>
                          `
                        : ""}
                `
            )}
        `;
    }

    render() {
        return html`
            <div class="center-aligning horizontal layout padded border-bottom">
                <ptc-date-range-picker
                    @range-selected=${(e: CustomEvent<DateRange>) =>
                        this.go(null, { ...this.router.params, ...e.detail })}
                    .range=${this.dateRange}
                ></ptc-date-range-picker>

                <div class="left-margined">Auflösung:</div>

                <select
                    class="slim transparent bold"
                    .value=${live(this._resolution)}
                    @change=${(e: Event) =>
                        this.go(null, { ...this.router.params, resolution: (e.target as HTMLSelectElement).value })}
                >
                    <option .value=${"week"}>Woche</option>
                    <option .value=${"month"}>Monat</option>
                    <option .value=${"year"}>Jahr</option>
                </select>

                <div class="stretch"></div>

                <button
                    class="transparent slim right-margined"
                    title="Arbeitszeiten Exportieren"
                    @click=${() =>
                        this.go("exports/balances_excel", {
                            filters: serializeFilters([{ type: "employeeId", value: this.employeeId }]),
                        })}
                >
                    <i class="download"></i>
                </button>

                <button class="slim transparent">
                    <i class="ellipsis-h"></i>
                </button>

                <ptc-popover class="popover-menu">
                    <button @click=${this._resetBalances}><i class="undo"></i> Konten Zurücksetzen</button>
                    <button
                        @click=${async () => {
                            const recalculated = await this._recalculateResultsDialog.show({
                                filters: [{ type: "employeeId", value: this._employee!.id }],
                            });
                            if (recalculated) {
                                this.load();
                            }
                        }}
                    >
                        <i class="calculator-simple"></i> Konten Neu Berechnen
                    </button>
                </ptc-popover>
            </div>

            <div class="scroller">
                <div class="double-padded">
                    <div>
                        <table class="fill-horizontally">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Übertrag</th>
                                    <th scope="col">Zuwachs</th>
                                    <th scope="col">Verbraucht</th>
                                    <th scope="col">Differenz</th>
                                    <th scope="col">Saldo</th>
                                </tr>
                            </thead>

                            <tbody>
                                ${this._renderBalances(this._balances)}
                            </tbody>
                        </table>
                    </div>

                    <div
                        class="fullbleed center-aligning center-justifying vertical layout scrim"
                        ?hidden=${!this._loading}
                    >
                        <ptc-spinner ?active=${this._loading}></ptc-spinner>
                    </div>
                </div>
            </div>
        `;
    }
}
