import { LitElement, css, unsafeCSS, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { colors, shared } from "../styles";
import ApexCharts from "apexcharts";
// @ts-ignore
import apexcss from "!!raw-loader!apexcharts/dist/apexcharts.css";
import { Balances } from "packages/core/src/model";
import { parseDateString } from "@pentacode/core/src/util";

export type ChartConfig = ApexCharts.ApexOptions;

@customElement("ptc-chart")
export class Chart extends LitElement {
    private _chart: ApexCharts;

    static styles = [
        shared,
        css`
            ${unsafeCSS(apexcss)}

            :host {
                display: block;
            }
        `,
    ];

    @property({ attribute: false })
    config: ChartConfig;

    @property({ attribute: false })
    series: ChartConfig["series"];

    private _initChart() {
        const config = { ...this.config };
        config.chart!.fontFamily = "Nunito";
        config.chart!.toolbar = { show: false };
        const el = document.createElement("div");
        this.renderRoot.querySelector(".inner")!.appendChild(el);
        this._chart = new ApexCharts(el, config);
        this._chart.render();
    }

    updated(changes: Map<string, any>) {
        if (this.config && !this._chart) {
            this._initChart();
        }

        if (changes.has("config") && this.config) {
            const { series, ...rest } = this.config;
            this._chart.updateOptions(rest);

            if (series) {
                this.series = series;
            }
        }

        if (changes.has("series") && this.series) {
            this._chart.updateSeries(this.series);
        }
    }

    render() {
        return html` <div class="inner"></div> `;
    }
}

@customElement("ptc-chart-balances")
export class ChartBalances extends Chart {
    @property({ attribute: false })
    balances: Balances[] = [];

    @property()
    type: "time" | "vacation" | "bonuses" = "time";

    @property({ type: Number })
    width = 150;

    @property({ type: Number })
    height = 50;

    updated(changes: Map<string, unknown>) {
        super.updated(changes);
        if (changes.has("balances") || changes.has("type") || changes.has("width") || changes.has("height")) {
            // @ts-ignore
            this.config = this._getChartConfig();
        }
    }

    private _getChartConfig(type: "time" | "vacation" | "bonuses" = this.type) {
        if (!this.balances?.length) {
            return;
        }
        const data = this.balances
            .slice(0, 12)
            .map((balances) => ({
                x: parseDateString(balances.from),
                y: balances[type].balance,
            }))
            .reverse();

        let min = Math.min(...data.map((d) => d.y!));
        let max = Math.max(...data.map((d) => d.y!));

        const colorStop = (max / (max - min)) * 100;

        if (max < 0 || Math.abs(min) > max) {
            max = Math.abs(min);
        } else {
            min = -max;
        }

        const series = [
            {
                data,
            },
        ];

        return {
            chart: {
                type: "area",
                toolbar: {
                    show: false,
                },
                height: this.height,
                width: this.width,
                zoom: { enabled: false },
                selection: { enabled: false },
                sparkline: { enabled: true },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            series,
            xaxis: {
                type: "datetime",
                show: false,
            },
            yaxis: {
                forceNiceScale: true,
                opposite: true,
                show: false,
                min,
                max,
            },
            tooltip: { enabled: false },
            stroke: {
                width: 0,
                curve: "smooth",
            },
            fill: {
                type: "gradient",
                gradient: {
                    type: "vertical",
                    colorStops: [
                        {
                            offset: 0,
                            color: colors.green,
                            opacity: 1,
                        },
                        {
                            offset: colorStop,
                            color: colors.green,
                            opacity: 0.5,
                        },
                        {
                            offset: colorStop,
                            color: colors.red,
                            opacity: 0.5,
                        },
                        {
                            offset: 100,
                            color: colors.red,
                            opacity: 1,
                        },
                    ],
                },
            },
        };
    }
}
