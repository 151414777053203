import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Issue, IssueType } from "@pentacode/core/src/issues";
import { employmentTypeLabel, TimeEntryType } from "@pentacode/core/src/model";
import { formatNumber, toTimeString } from "@pentacode/core/src/util";
import { app } from "../init";
import { shared } from "../styles";
import "./avatar";
import "./spinner";

@customElement("ptc-issue")
export class IssueEl extends LitElement {
    @property({ attribute: false })
    issue: Issue;

    static styles = [
        shared,
        css`
            :host {
                display: block;
                padding: 0.5em;
            }

            .venue-icon {
                font-size: var(--font-size-large);
                margin: 0 0.3em;
            }

            .venue-name {
                font-weight: 600;
                margin: 0 2em 0 1em;
            }
        `,
    ];

    render() {
        const issue = this.issue;
        return issue.employee ? this._renderEmployeeIssue(issue) : this._renderRevenuesIssue(issue);
    }

    private _renderShifts({ timeEntries }: Issue) {
        return timeEntries.map((e) => {
            const start = e.startFinal || e.startPlanned;
            const end = e.startFinal ? e.endFinal : e.endPlanned;
            return html`
                <div
                    class="box"
                    style="padding: 0.3em 0.5em 0.10em 0.5em; --color-highlight: ${app.getTimeEntryColor(e)}"
                >
                    ${e.type === TimeEntryType.Work
                        ? html`
                              <div class="tinier spacing horizontal layout">
                                  <div class="stretch collapse ellipsis">${e.position && e.position.name}</div>
                                  <div>
                                      ${e.start.getDate().toString().padStart(2, "0")}.${(e.start.getMonth() + 1)
                                          .toString()
                                          .padStart(2, "0")}.
                                  </div>
                              </div>
                              <div>
                                  ${toTimeString(start) || html` &nbsp;&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp; `} -
                                  ${toTimeString(end) || html` &nbsp;&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp; `}
                              </div>
                          `
                        : html` <div class="padded">${app.localized.timeEntryTypeLabel(e.type)}</div> `}
                </div>
            `;
        });
    }

    private _renderDeficientSalary(issue: Issue) {
        const { salary, hours, effectiveHourlyRate } = issue.additionalInfo as {
            hours: number;
            salary: number;
            effectiveHourlyRate: number;
        };

        return html`
            <div class="padded box">
                <div class="smaller">Gehalt</div>
                <div>${formatNumber(salary)} €</div>
            </div>

            <div class="padded box">
                <div class="smaller">Arbeitszeit</div>
                <div>${formatNumber(hours)} St.</div>
            </div>

            <div class="padded box">
                <div class="smaller">Stundenlohn</div>
                <div>${formatNumber(effectiveHourlyRate)} € / St.</div>
            </div>
        `;
    }

    private _renderExcessiveSalary(issue: Issue) {
        const { maxSalary, actualSalary } = issue.additionalInfo as { maxSalary: number; actualSalary: number };

        return html`
            <div class="padded box">
                <div class="entry-position">Lohngrenze</div>
                <div>${formatNumber(maxSalary)} €</div>
            </div>

            <div class="padded box">
                <div class="entry-position">Gehalt</div>
                <div>${formatNumber(actualSalary)} €</div>
            </div>
        `;
    }

    private _renderRevenuesIssue(issue: Issue) {
        const venue = app.getVenue(issue.additionalInfo.venue);
        if (!venue) {
            return;
        }
        return html`
            <div class="issue center-aligning horizontal layout stretch">
                <i class="coins venue-icon"></i>

                <div class="venue-name">${venue.name}</div>

                <div class="smaller red padded box">${issue.message}</div>
            </div>
        `;
    }

    private _renderEmployeeIssue(issue: Issue) {
        const { employee: employeeId, message, date, type } = issue;
        const employee = app.getEmployee(employeeId!);

        if (!employee) {
            return null;
        }

        const contract = employee.getContractForDate(date);

        return html`
            <div class="center-aligning spacing horizontal layout stretch">
                <ptc-avatar .employee=${employee} class="mediumer right-margined"></ptc-avatar>

                <div style="width: 10em">
                    <div class="semibold ellipsis">${employee.name}</div>
                    <div class="tinier pill">${contract && employmentTypeLabel(contract.employmentType)}</div>
                </div>

                ${type === IssueType.DeficientSalary
                    ? this._renderDeficientSalary(issue)
                    : type === IssueType.ExcessiveSalary
                      ? this._renderExcessiveSalary(issue)
                      : this._renderShifts(issue)}

                <i class="arrow-right stretch"></i>

                <div class="smaller padded red box">${message}</div>
            </div>
        `;
    }
}
