import { RevenueEntry, TaxKey, Department, RevenueType, revenueTypeIcon } from "@pentacode/core/src/model";
import { getRange, toDateString, parseDateString, monthNames, formatNumber as fmtNum } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Chart, ChartConfig } from "./chart";
import "./scroller";
import { DateString } from "@pentacode/openapi/src/units";

const formatNumber = (val: number) => fmtNum(val, 0);

interface Segment {
    name: string;
    ledger?: string;
    taxKey?: TaxKey;
    costCenter?: string;
    department?: Department;
    months: number[];
    total: number;
    hideInCharts?: boolean;
}

type Data = Map<string, Segment>;

@customElement("ptc-reports-revenue")
export class ReportsRevenue extends Routing(StateMixin(LitElement)) {
    routePattern = /reports\/revenue(?:\/(?<type>\w+))?/;

    get routeTitle() {
        return this.venue ? `Umsatzbericht: ${this.venue.name}` : undefined;
    }

    @routeProperty({ arg: "type" })
    private _type: RevenueType;

    @state()
    private _loading = false;

    @state()
    private _entries: RevenueEntry[] = [];

    @state()
    private _filterString = "";

    @state()
    private _segmentation: "group" | "department" = "group";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query(".chart-monthly")
    private _chartMonthly: Chart;

    @query(".chart-total")
    private _chartTotal: Chart;

    @query(".venue-selector")
    private _venueSelector: HTMLSelectElement;

    private _updateFilter() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    handleRoute() {
        if (!this.venue) {
            if (app.venues.length) {
                this.go(null, { ...this.router.params, venue: app.venues[0].id.toString() }, true);
            }
            return;
        }

        if (![RevenueType.Sales, RevenueType.Expense, RevenueType.Cashless].includes(this._type)) {
            this.redirect("reports/revenue/sales");
            return;
        }

        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { ...this.router.params, date: toDateString(new Date()) }, true);
            return;
        }

        if (this._type !== RevenueType.Sales) {
            this._segmentation = "group";
        }

        this.load();
    }

    updated(changes: Map<string, any>) {
        if ((changes.has("_entries") || changes.has("_segmentation")) && this._chartMonthly) {
            this._updateCharts();
        }
    }

    async load() {
        if (!this.year || !this.venue) {
            return;
        }

        let { from, to } = getRange(`${this.year}-01-01` as DateString, "year");

        const currMonth = getRange(toDateString(new Date()), "month");

        if (to > currMonth.to) {
            to = currMonth.to;
        }

        this._loading = true;

        try {
            this._entries = (
                await app.api.getRevenueEntries({
                    from,
                    to,
                    venue: this.venueId!,
                    type: [this._type],
                })
            ).filter((e) => e.reporting);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _selectVenue() {
        this.go(null, { venue: this._venueSelector.value, date: this.date });
    }

    private _getData(): Data {
        const data: Data = new Map<string, Segment>();

        const all = {
            name: "Gesamt",
            months: monthNames.map(() => 0),
            total: 0,
        };

        const other = {
            name: "Sonstige",
            months: monthNames.map(() => 0),
            total: 0,
        };

        for (const e of this._entries) {
            const month = parseDateString(e.date)!.getMonth();

            if (this._segmentation === "group") {
                const key = e.group
                    ? `group_${e.group.id}`
                    : `${e.type}_${e.name}_${e.ledger}_${e.taxKey}_${e.costCenter}`;
                if (!data.has(key)) {
                    data.set(key, {
                        name: e.group ? e.group.name : e.name,
                        ledger: e.ledger,
                        taxKey: e.taxKey,
                        costCenter: e.costCenter,
                        months: monthNames.map(() => 0),
                        total: 0,
                    });
                }

                data.get(key)!.months[month] += Math.abs(e.amount);
                data.get(key)!.total += Math.abs(e.amount);
            } else {
                let attributed = 0;

                for (const att of (e.group && e.group.attributions) || []) {
                    const { department } = app.getDepartment(att.department);

                    if (!department) {
                        continue;
                    }

                    const key = `${department.id}`;

                    if (!data.has(key)) {
                        data.set(key, {
                            name: department.name,
                            months: monthNames.map(() => 0),
                            total: 0,
                            department,
                        });
                    }

                    const amount = (e.amount * att.ratio) / 100;

                    attributed += amount;
                    data.get(key)!.months[month] += amount;
                    data.get(key)!.total += amount;
                }

                other.months[month] += Math.max(0, e.amount - attributed);
                other.total += Math.max(0, e.amount - attributed);
            }

            all.months[month] += Math.abs(e.amount);
            all.total += Math.abs(e.amount);
        }

        for (const [, entry] of data) {
            if (entry.months.every((val, i) => Math.abs(val) <= Math.abs(all.months[i] * 0.05))) {
                for (const [month, value] of entry.months.entries()) {
                    other.months[month] += value;
                }

                other.total += entry.total;

                entry.hideInCharts = true;
            }
        }

        if (other.total) {
            data.set("other", other);
        }

        data.set("all", all);

        return data;
    }

    private _getChartConfigMonthly(data: Data): ChartConfig {
        const series = [...data.values()]
            .filter((s) => s.name !== "Gesamt" && !s.hideInCharts)
            .map(({ name, months, department: dep }, i) => ({
                name,
                data: months,
                color: dep ? colors[dep.color] || dep.color : Object.values(colors)[i],
            }));
        const all = data.get("all")!;

        return {
            chart: {
                type: "area",
                stacked: true,
                height: 350,
                parentHeightOffset: 0,
                selection: { enabled: false },
            },
            colors: series.map((s) => s.color),
            legend: {
                show: false,
            },
            series,
            labels: monthNames.map((n) => n.slice(0, 3)),
            xaxis: {
                type: "category",
            },
            yaxis: {
                title: { text: "Umsatz (Tausend €)" },
                labels: {
                    formatter: (val: number) => formatNumber(val / 1000),
                },
                opposite: true,
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val: number) => formatNumber(val) + " €",
                },
                x: {
                    formatter: (val: number) => `${monthNames[val - 1]}: ${formatNumber(all.months[val - 1])} €`,
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            fill: {
                type: "solid",
                opacity: 1,
            },
            stroke: {
                show: false,
                curve: "smooth",
            },
        };
    }

    private _getChartConfigTotal(data: Data): ChartConfig {
        const segments = [...data.values()].filter((s) => s.name !== "Gesamt" && !s.hideInCharts);
        const labels = segments.map((each) => each.name);
        const series = segments.map((each) => each.total);
        const clrs = segments.map(({ department: dep }, i) =>
            dep ? colors[dep.color] || dep.color : Object.values(colors)[i]
        );

        return {
            chart: {
                type: "donut",
                height: "300",
                selection: { enabled: false },
            },
            colors: clrs,
            legend: {
                show: true,
                position: "bottom",
                // formatter: function(val: string, opts: any) {
                //     return val + ": " + formatNumber(opts.w.globals.series[opts.seriesIndex]) + " €";
                // }
            },
            labels,
            series,
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: (val: number) => formatNumber(val) + " €",
                },
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                label: "Gesamt",
                                showAlways: true,
                                show: true,
                                formatter: (w: any) =>
                                    formatNumber(
                                        w.globals.seriesTotals.reduce((a: number, b: number) => {
                                            return a + b;
                                        }, 0)
                                    ) + " €",
                            },
                            value: {
                                formatter: (val: any) => formatNumber(val) + " €",
                            },
                        },
                    },
                },
            },
        };
    }

    private _updateCharts() {
        const data = this._getData();
        this._chartMonthly.config = this._getChartConfigMonthly(data);
        this._chartTotal.config = this._getChartConfigTotal(data);
    }

    private _yearSelected(e: Event) {
        const date = parseDateString(this.date) || new Date();
        date.setFullYear(parseInt((e.target as HTMLSelectElement).value));
        this.go(null, { date: toDateString(date), venue: this._venueSelector.value });
    }

    static styles = [
        shared,
        css`
            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .main {
                position: relative;
            }

            .scroller {
                overflow: auto;
                margin: 0 0.5em;
            }

            .year-selector,
            .venue-selector {
                font-size: var(--font-size-medium);
                padding-top: 0.3em;
                padding-bottom: 0.3em;
            }

            .department-row {
                display: grid;
                grid-template-columns: 1fr 4em 4em;
                grid-gap: 0.5em;
            }

            .department-row-value {
                text-align: right;
            }

            button:not([active]) .department-row-value {
                color: var(--color-highlight);
            }

            .department-row-header {
                font-size: var(--font-size-tiny);
                color: var(--color-highlight);
                font-weight: bold;
                text-align: center;
                padding: 0.5em;
            }

            .charts {
                position: sticky;
                left: 0;
                display: flex;
                margin-top: 0.5em;
                z-index: 10;
            }

            .charts-total {
                width: 19em;
            }

            .charts-monthly {
                flex: 1;
            }

            .charts-title {
                font-size: var(--font-size-large);
                padding: 0.5em;
                font-weight: 600;
                text-align: center;
            }

            .row {
                display: grid;
                grid-template-columns: 12em repeat(13, 1fr);
                background: var(--color-bg);
                min-width: 90em;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .column {
                text-align: right;
                padding: 0.5em;
            }

            .column.name {
                text-align: left;
            }

            :not(.header-row) > .column > * {
                flex: 1;
                font-size: var(--font-size-small);
                padding: 0.5em;
                text-align: right;
                min-width: 0;
                color: var(--color-highlight);
            }

            .column > :not(:last-child) {
                border-right: dashed 1px var(--shade-1);
            }

            .column:not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 1;
            }

            .header-column {
                text-align: left;
                position: sticky;
                left: 0;
                background: inherit;
                border-radius: inherit;
            }

            .header-row > .column {
                padding-top: 0.5em;
                font-weight: 600;
                border: none;
                text-align: center;
            }

            .header-row > .header-column {
                padding-left: 0;
                padding-bottom: 0.3em;
            }

            .filter-input input {
                font-weight: normal;
                padding-top: 0.4em;
                padding-bottom: 0.4em;
            }

            .row.total-row > .column {
                font-weight: bold;
            }

            .total-column {
                font-weight: 600;
                position: sticky;
                right: 0;
                background: inherit;
                border-left: solid 1px var(--shade-1) !important;
            }

            .row:not(.header-row):hover:not([active]) {
                background: #f1f1f1;
                cursor: pointer;
            }

            .row[active] {
                border-radius: var(--border-radius);
                border-color: transparent;
                background: var(--color-primary);
                color: var(--color-bg);
                position: sticky;
                top: 3.5em;
                bottom: 0.5em;
                z-index: 2;
                font-weight: bold;
            }

            .row[active] * {
                color: var(--color-bg) !important;
            }
        `,
    ];

    render() {
        if (!app.company || !this.venue) {
            return html``;
        }

        const years: number[] = [];
        for (let year = new Date().getFullYear(); year > 2010; year--) {
            years.push(year);
        }

        const data = [...this._getData().values()]
            .filter((entry) => entry.name !== "Sonstige")
            .sort((a, b) => b.total - a.total);

        return html`
            <div class="fullbleed vertical layout">
                <div class="header horizontal center-aligning layout padded-medium noprint">
                    <div class="horizontal tabs">
                        ${[RevenueType.Sales, RevenueType.Expense, RevenueType.Cashless].map(
                            (type) => html`
                                <button
                                    ?active=${this._type === type}
                                    @click=${() => this.go(`reports/revenue/${type}`)}
                                >
                                    <i class="${revenueTypeIcon(type)}"></i>
                                </button>
                            `
                        )}
                    </div>

                    <div class="stretch"></div>

                    <div class="horizontal tabs" ?hidden=${this._type !== RevenueType.Sales}>
                        <button
                            ?active=${this._segmentation === "group"}
                            @click=${() => (this._segmentation = "group")}
                        >
                            Umsatzgruppe
                        </button>
                        <button
                            ?active=${this._segmentation === "department"}
                            @click=${() => (this._segmentation = "department")}
                        >
                            Abteilung
                        </button>
                    </div>

                    <div class="stretch"></div>

                    <select class="venue-selector" @change=${this._selectVenue} ?hidden=${app.venues.length < 2}>
                        ${app.venues.map(
                            (venue) => html`
                                <option
                                    .value=${venue.id.toString()}
                                    ?selected=${venue.id === this.venueId}
                                    ?disabled=${!app.hasAccess({ venue })}
                                >
                                    ${venue.name}
                                </option>
                            `
                        )}
                    </select>

                    <select class="year-selector" .value=${this.year.toString()} @change=${this._yearSelected}>
                        ${years.map((year) => html` <option .value=${year.toString()}>${year}</option> `)}
                    </select>
                </div>

                <div class="scroller stretch">
                    <div class="charts">
                        <div class="charts-total vertical layout">
                            <div class="charts-title">Gesamt</div>
                            <ptc-chart class="chart-total"></ptc-chart>
                        </div>
                        <div class="charts-monthly">
                            <ptc-chart class="chart-monthly"></ptc-chart>
                        </div>
                    </div>

                    <div class="table">
                        <div class="row header-row">
                            <div class="column header-column">
                                <div class="filter-input right icon input">
                                    <input
                                        id="filterInput"
                                        type="text"
                                        placeholder="Suchen..."
                                        @input=${this._updateFilter}
                                    />
                                    <i
                                        class="${this._filterString ? "times click" : "search"} icon"
                                        @click=${this._clearFilter}
                                    ></i>
                                </div>
                            </div>

                            ${monthNames.map(
                                (n) => html`
                                    <div class="column vertical end-justifying layout">
                                        <div>${n.slice(0, 3)}</div>
                                    </div>
                                `
                            )}

                            <div class="column vertical end-justifying layout total-column">
                                <div>Gesamt</div>
                            </div>
                        </div>

                        ${[...data.values()].map(({ name, total, months }) =>
                            !name.toLowerCase().includes(this._filterString)
                                ? null
                                : html`
                                      <div class="row ${name === "Gesamt" ? "total-row" : ""}">
                                          <div class="column header-column">${name}</div>
                                          ${months.map((val) => html` <div class="column">${formatNumber(val)}</div> `)}
                                          <div class="column total-column">${formatNumber(total)}</div>
                                      </div>
                                  `
                        )}
                    </div>
                </div>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
