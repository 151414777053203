import { NotificationSettings, NotificationType, notificationTypeLabel } from "@pentacode/core/src/model";
import { UpdateEmployeesParams, UpdateCompanyParams } from "@pentacode/core/src/api";
import { debounce } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { shared, mixins } from "../styles";
import { app } from "../init";
import { alert } from "./alert-dialog";
import "./avatar";

@customElement("ptc-employees-notifications-all")
export class EmployeesNotificationsAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/notifications/;

    get routeTitle() {
        return "Benachrichtungen: Alle Mitarbeiter";
    }

    private get _employees() {
        return app.accessibleEmployees.filter((e) => e.active);
    }

    @query("form")
    private _form: HTMLFormElement;

    private _setAll(_channel: string, prop: string, val: boolean) {
        prop = prop.replace(".all", "");
        const inputs = this.renderRoot!.querySelectorAll(`input[value$="${prop}"]`) as NodeListOf<HTMLInputElement>;
        for (const inp of inputs) {
            inp.checked = val;
        }
        this._update();
    }

    private _update = debounce(async () => {
        const data = new FormData(this._form);

        const promises: Promise<any>[] = [];

        const update: { id: number; notifications: NotificationSettings }[] = [];

        for (const { id, notifications } of this._employees) {
            const email = data.getAll(`notifications.email.${id}`) as NotificationType[];
            if (
                email.length !== notifications.email.length ||
                email.some((val) => !notifications.email.includes(val))
            ) {
                notifications.email = email;
                update.push({ id, notifications });
            }
        }

        if (update.length) {
            promises.push(app.api.updateEmployees(new UpdateEmployeesParams({ update })));
        }

        const notifications = app.company!.settings.defaultNotifications;
        const email = data.getAll(`notifications.email.new`) as NotificationType[];
        if (email.length !== notifications.email.length || email.some((val) => !notifications.email.includes(val))) {
            notifications.email = email;
            promises.push(app.api.updateCompany(new UpdateCompanyParams({ settings: app.company!.settings })));
        }

        try {
            await Promise.all(promises);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this.requestUpdate();
    }, 2000);

    static styles = [
        shared,
        css`
            :host {
                ${mixins.scroll()};
            }

            .row {
                display: flex;
            }

            .row > * {
                border-bottom: solid 1px var(--shade-2);
                border-right: solid 1px var(--shade-2);
                background: var(--color-bg);
            }

            .sticky {
                z-index: 5;
            }

            .header.row .row-header {
                height: auto;
            }

            .row-header {
                width: 200px;
                flex: none;
                min-width: 200px;
                position: sticky;
                left: 0;
                border-right: solid 1px var(--shade-2);
                background: var(--color-bg);
                padding: 0 0.5em;
                height: 2.5em;
                z-index: 1;
            }

            .row-header ptc-avatar {
                font-size: var(--font-size-tiny);
                margin-right: 0.8em;
            }

            .employee-header {
                cursor: pointer;
            }

            .employee-header.selectable:hover {
                color: var(--color-primary);
            }

            .notification-label {
                width: 2.5em;
                line-height: 2.5em;
                writing-mode: vertical-rl;
                text-orientation: mixed;
                padding: 0.5em 0;
            }

            .checkbox-wrapper {
                width: 2.5em;
                text-align: center;
                position: relative;
                color: var(--green);
            }

            .checkbox-wrapper input {
                position: absolute;
                opacity: 0;
            }

            .checkbox-wrapper label {
                display: block;
                text-align: center;
                width: 100%;
                line-height: 2.5em;
                cursor: pointer;
                color: var(--color-highlight);
                padding: 0;
            }

            .checkbox-wrapper input:not(:checked) + label {
                opacity: 0;
            }

            .all-employees-icon {
                border-radius: 100%;
                background: var(--shade-1);
                border: solid 0.1em var(--shade-2);
                padding: 0.1em;
                margin-right: 0.6em;
            }
        `,
    ];

    render() {
        const company = app.company;
        if (!company) {
            return;
        }
        const employees = this._employees;
        return html`
            <form>
                <div class="sticky">
                    <div class="header row">
                        <div class="row-header"></div>
                        ${Object.values(NotificationType).map(
                            (type) => html` <div class="notification-label">${notificationTypeLabel(type)}</div> `
                        )}
                    </div>

                    <div class="row">
                        <div class="row-header employee-header horizontal center-aligning layout">
                            <i class="users all-employees-icon"></i>
                            <div class="employee-name stretch ellipsis bold">Alle Mitarbeiter</div>
                        </div>

                        ${Object.values(NotificationType).map(
                            (type) => html`
                                <div class="checkbox-wrapper click" title="${notificationTypeLabel(type)}">
                                    <input
                                        type="checkbox"
                                        name="notifications.email.all"
                                        value="${type}"
                                        id="checkbox_email.all.${type}"
                                        .checked=${employees.every((e) => e.notifications.email.includes(type))}
                                        @change=${(e: Event) =>
                                            this._setAll("email", type, (e.target as HTMLInputElement).checked)}
                                    />
                                    <label for="checkbox_email.all.${type}">
                                        <i class="check"></i>
                                    </label>
                                </div>
                            `
                        )}
                    </div>

                    <div class="row">
                        <div class="row-header employee-header horizontal center-aligning layout">
                            <i class="user-plus all-employees-icon"></i>
                            <div class="employee-name stretch ellipsis semibold">Neue Mitarbeiter</div>
                        </div>

                        ${Object.values(NotificationType).map(
                            (type) => html`
                                <div class="checkbox-wrapper click" title="${notificationTypeLabel(type)}">
                                    <input
                                        type="checkbox"
                                        name="notifications.email.new"
                                        value="${type}"
                                        id="checkbox_email.new.${type}"
                                        .checked=${company.settings.defaultNotifications.email.includes(type)}
                                        @change=${this._update}
                                    />
                                    <label for="checkbox_email.new.${type}">
                                        <i class="check"></i>
                                    </label>
                                </div>
                            `
                        )}
                    </div>
                </div>

                ${employees.map(
                    (emp) => html`
                        <div class="row">
                            <div
                                class="row-header employee-header selectable horizontal center-aligning layout"
                                @click=${() => this.go(`employees/${emp.id}/notifications`)}
                            >
                                <ptc-avatar .employee=${emp}></ptc-avatar>
                                <div class="employee-name stretch ellipsis">${emp.name}</div>
                            </div>

                            ${Object.values(NotificationType).map(
                                (type) => html`
                                    <div class="checkbox-wrapper click" title="${notificationTypeLabel(type)}">
                                        <input
                                            type="checkbox"
                                            name="notifications.email.${emp.id}"
                                            .value=${type}
                                            id="checkbox_email.${emp.id}.${type}"
                                            .checked=${emp.notifications.email.includes(type)}
                                            @change=${() => this._update()}
                                        />
                                        <label for="checkbox_email.${emp.id}.${type}">
                                            <i class="check"></i>
                                        </label>
                                    </div>
                                `
                            )}
                        </div>
                    `
                )}
            </form>
        `;
    }
}
