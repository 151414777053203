import { classMap } from "lit/directives/class-map.js";
import { repeat } from "lit/directives/repeat.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { LitElement, html, css } from "lit";
import { customElement, query, queryAll, state } from "lit/decorators.js";
import {
    RevenueEntry,
    TaxKey,
    RevenueType,
    RevenueGroup,
    taxKeyLabel,
    revenueTypeIcon,
    revenueTypeLabel,
} from "@pentacode/core/src/model";
import {
    GetRevenueEntriesParams,
    UpdateRevenueEntriesParams,
    CommitCashbookParams,
    GetRevenueGroupsParams,
} from "@pentacode/core/src/api";
import { formatDate, getRange, dateAdd, toDateString, formatNumber } from "@pentacode/core/src/util";
import { clone } from "@pentacode/core/src/encoding";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import { alert, confirm } from "./alert-dialog";
import { singleton } from "../lib/singleton";
import { print } from "../lib/print";
import "./popover";
import { CountingLogDialog } from "./counting-log-dialog";
import "./revenues-autocomplete-input";
import { Dialog } from "./dialog";
import { DateInput } from "./date-input";
import { RevenuesSplitDialog } from "./revenues-split-dialog";
import "./spinner";
import { DateString, Euros, subtract } from "@pentacode/openapi/src/units";

@customElement("ptc-revenues-cashbook")
export class Cashbook extends Routing(StateMixin(LitElement)) {
    routePattern = /^revenues\/cashbook/;

    get routeTitle() {
        if (!this.venue) {
            return;
        }
        return `Kassenbuch: ${this.venue.name}`;
    }

    @state()
    private _minDate?: DateString;

    @state()
    private _maxDate?: DateString;

    @state()
    private _loading = false;

    @state()
    private _previousEntry: RevenueEntry | null = null;

    @state()
    private _entries: RevenueEntry[] = [];

    @state()
    private _templates: RevenueGroup[] = [];

    @state()
    private _added = new Set<RevenueEntry>();

    @state()
    private _edited = new Set<RevenueEntry>();

    @state()
    private _deleted = new Set<RevenueEntry>();

    @state()
    private _filterString = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query(".commit-dialog")
    private _commitDialog: Dialog<void, void>;

    @query(".entries")
    private _entriesContainer: HTMLElement;

    @queryAll("form.row")
    private _forms: HTMLFormElement[];

    @singleton("ptc-counting-log-dialog")
    private _countingLogDialog: CountingLogDialog;

    @singleton("ptc-revenues-split-dialog")
    private _splitDialog: RevenuesSplitDialog;

    private get _hasChanges() {
        return !!this._added.size || !!this._edited.size || !!this._deleted.size;
    }

    private get _rowHasFocus() {
        return this._entriesContainer.contains(this.shadowRoot!.activeElement);
    }

    private get _venue() {
        return app.getVenue(this.venueId);
    }

    async handleRoute() {
        if (!this.date) {
            return;
        }

        const { from, to } = getRange(this.date, "month");
        this._minDate = from;
        this._maxDate = dateAdd(to, { days: -1 });

        await this._load();

        this._scrollToDate(this.date);
    }

    private async _load() {
        if (this._loading || !this.venue || !this.date) {
            return;
        }

        const range = getRange(this.date, "month");

        this._loading = true;
        try {
            const [prev, entries, templates] = await Promise.all([
                app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        to: range.from,
                        venue: this.venueId,
                        type: [
                            RevenueType.Sales,
                            RevenueType.Expense,
                            RevenueType.PayAdvance,
                            RevenueType.BankDeposit,
                            RevenueType.Debt,
                            RevenueType.Adjustment,
                            RevenueType.Cashless,
                            RevenueType.Other,
                        ],
                        cashbook: true,
                        limit: 1,
                        reverse: true,
                        draft: false,
                    })
                ),
                app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        ...range,
                        venue: this.venueId,
                        cashbook: true,
                    })
                ),
                app.api.getRevenueGroups(
                    new GetRevenueGroupsParams({
                        venue: this.venueId,
                        cashbook: true,
                    })
                ),
            ]);

            this._entries = entries.filter((e) => !e.draft && (!e.daily || e.type !== RevenueType.CashCount));
            this._templates = templates;
            this._previousEntry = prev[0] || null;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
        this._added.clear();
        this._edited.clear();
        this._deleted.clear();
        this._clearFilter();

        await this.updateComplete;
    }

    private async _updateEntry(index: number) {
        const entry = this._entries[index];
        const form = this.renderRoot!.querySelector(`[data-entry="${entry.id || entry.randomId}"]`) as HTMLFormElement;
        if (!form) {
            return;
        }
        const formData = new FormData(form);

        if (!entry || !form || !formData.get("date")) {
            return;
        }

        if (entry.type === RevenueType.PayAdvance) {
            const employeeId = parseInt(formData.get("employee") as string);
            const employee = app.getEmployee(employeeId);
            if (!employee) {
                return;
            }
            entry.name = employee.name;
            entry.employeeId = employeeId;
        } else {
            entry.name = formData.get("name") as string;
        }

        entry.receipt = formData.get("receipt") as string;
        const taxKey = parseInt(formData.get("taxKey") as string);
        entry.taxKey = taxKey in TaxKey ? taxKey : TaxKey.None;
        entry.ledger = formData.get("ledger") as string;
        entry.postingKey = formData.get("postingKey") as string;
        entry.costCenter = formData.get("costCenter") as string;
        const tip = parseFloat(formData.get("tip") as string);
        entry.tip = (isNaN(tip) ? 0 : -tip) as Euros;
        const amountIn = parseFloat(formData.get("amountIn") as string);
        const amountOut = parseFloat(formData.get("amountOut") as string);
        entry.amount = (
            !isNaN(amountIn) ? Math.max(0, amountIn) : !isNaN(amountOut) ? Math.min(0, -amountOut) : 0
        ) as Euros;

        entry.date = formData.get("date") as DateString;

        this._edited.add(entry);

        this.requestUpdate();
    }

    private _reorderRows() {
        this._entries.sort((a, b) => (b.date === a.date ? 0 : b.date > a.date ? -1 : 1));
        this.requestUpdate();
    }

    private _rowBlur() {
        setTimeout(() => !this._rowHasFocus && this._reorderRows(), 200);
    }

    private _focusRow(index: number, field = "date") {
        const form = this._forms[index];
        const input = form && (form.querySelector(`[name^="${field}"]`) as HTMLInputElement);
        input && input.focus();
    }

    private async _save() {
        if (this._loading) {
            return;
        }

        this._reorderRows();

        for (const form of this._forms) {
            if (!form.reportValidity()) {
                return;
            }
        }

        this._loading = true;
        try {
            const updated = this._entries.filter((entry) => !entry.readonly && !this._deleted.has(entry)).map(clone);
            updated.forEach((e) => (e.sequence = null));

            await app.api.updateRevenueEntries(
                new UpdateRevenueEntriesParams({
                    updated,
                    removed: this._entries.filter((entry) => this._deleted.has(entry)),
                })
            );

            this._loading = false;
            await this._load();
        } catch (e) {
            this._loading = false;
            alert(e.message, { type: "warning" });
        }
    }

    private _scrollToDate(date: string) {
        const index = this._entries.findIndex((e) => e.date >= date);
        const row = this._forms[index] || this._forms[this._forms.length - 1];
        row && row.scrollIntoView({ behavior: "auto", block: "center" });
    }

    private async _insertEntry(entry: RevenueEntry, index?: number) {
        entry.randomId = Math.floor(Math.random() * 1e7);
        if (typeof index !== "number") {
            index = this._entries.findIndex((e) => e.date > entry.date);
        }
        if (index === -1) {
            index = this._entries.length;
        }
        this._entries.splice(index, 0, entry);
        this._added.add(entry);
        this.requestUpdate();
        await this.updateComplete;
        setTimeout(() => this._focusRow(index!), 50);
    }

    private async _newEntry(type: RevenueType) {
        const today = toDateString(new Date());
        const entry = new RevenueEntry({
            venueId: this.venue!.id,
            type,
            date: this._maxDate && today > this._maxDate ? this._maxDate : today,
        });
        this._insertEntry(entry);
    }

    private async _deleteEntry(entry: RevenueEntry) {
        if (this._added.has(entry)) {
            this._entries.splice(this._entries.indexOf(entry), 1);
            this._added.delete(entry);
        } else {
            this._deleted.add(entry);
        }
        this.requestUpdate();
    }

    private async _restoreEntry(entry: RevenueEntry) {
        this._deleted.delete(entry);
        this.requestUpdate();
    }

    private _move(i: number, direction: "up" | "down", e?: Event) {
        e && e.stopPropagation();
        const newIndex = direction === "up" ? i - 1 : i + 1;
        const entry = this._entries[i];
        const other = this._entries[newIndex];
        this._entries.splice(i, 1);
        this._entries.splice(newIndex, 0, entry);
        this._edited.add(entry);
        this._edited.add(other);
        this.requestUpdate();
    }

    private _updateFilter() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _filterByString(e: RevenueEntry, filter: string) {
        return (
            e.name.toLowerCase().includes(filter) ||
            (e.sequence && e.sequence.toString() === filter) ||
            e.amount.toFixed(2).replace(/\./, ",").includes(filter) ||
            e.ledger.includes(filter) ||
            e.invoice.includes(filter) ||
            e.receipt.includes(filter) ||
            e.date.replace(/(\d\d\d\d)-(\d\d)-(\d\d)/, "$3.$2.$1").includes(filter)
        );
    }

    private async _commitCashbook() {
        this._commitDialog.show();
    }

    private async _submitCommitCashbook(e: Event) {
        e.preventDefault();
        this._commitDialog.dismiss();

        const formData = new FormData(e.target as HTMLFormElement);
        const until = formData.get("until") as string;

        if (
            this._loading ||
            !(await confirm(
                `Sind Sie sicher dass Sie das Kassenbuch bis zum ${formatDate(
                    until
                )} festschreiben wollen? Einträge in diesem Zeitraum können dann nicht mehr bearbeitet werden!`,
                "Bestätigen",
                "Abbrechen",
                { title: "Kassenbuch Festschreiben" }
            ))
        ) {
            return;
        }

        this._loading = true;
        try {
            await app.api.commitCashbook(new CommitCashbookParams({ venue: this.venueId, until }));

            this._loading = false;
            await this._load();
        } catch (e) {
            this._loading = false;
            alert(e.message, { type: "warning" });
        }
    }

    private async _templateSelected(index: number, { detail: group }: CustomEvent<RevenueGroup>) {
        const { name, type, ledger, costCenter, taxKey, postingKey, reporting, cashbook } = group;
        const entry = this._entries[index];
        Object.assign(entry, {
            name,
            type,
            ledger,
            costCenter,
            taxKey,
            postingKey,
            reporting,
            cashbook,
            group: group.id ? group : null,
        });
        this.requestUpdate();
        this._focusRow(index, "amount");
    }

    private async _createCountingLog() {
        const entry = await this._countingLogDialog.show({
            otherEntries: this._entries,
            entry: new RevenueEntry({
                venueId: this.venue!.id,
                date: toDateString(new Date()),
                type: RevenueType.CashCount,
                name: "Kassenzählung",
            }),
        });

        if (!entry) {
            return;
        }

        this._insertEntry(entry);

        const diff = subtract(entry.amount, entry.cashBalance);

        if (diff) {
            const confirmed = await confirm(
                `Die Zählung ergab einen ${
                    diff < 0
                        ? `Fehlbestand von ${formatNumber(Math.abs(diff))} €. `
                        : `Überschuss von ${formatNumber(Math.abs(diff))} €. `
                } ` + `Wollen Sie diesen in einer Ausgleichsbuchung erfassen?`,
                "Buchen",
                "Überspringen",
                {
                    title: "Fehlbestand Buchen",
                }
            );

            if (confirmed) {
                this._insertEntry(
                    new RevenueEntry({
                        venueId: this.venue!.id,
                        date: entry.date,
                        type: RevenueType.Adjustment,
                        name: "Ausgleich Fehlbestand nach Zählung",
                        amount: diff,
                        cashBalance: entry.amount,
                    })
                );
            }
        }
    }

    private async _printCountingLog(entry: RevenueEntry) {
        print(`/print/?view=counting-log&venue=${entry.venueId}&date=${entry.date}`);
    }

    private async _splitEntry(entry: RevenueEntry, templates: RevenueGroup[]) {
        const entries = await this._splitDialog.show({ entry, templates });
        if (entries) {
            let index = this._entries.indexOf(entry);
            this._deleteEntry(entry);
            for (const entry of entries) {
                index++;
                this._insertEntry(entry, index);
            }
        }
    }

    private _openDaily(entry: RevenueEntry) {
        this.go(`revenues/daily`, { venue: this.venue!.id, date: entry.date });
    }

    static styles = [
        shared,
        DateInput.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .header .title {
                margin: 0 0.5em;
            }

            .scroller {
                flex: 1;
                min-height: 0;
                overflow: auto;
            }

            .row {
                display: grid;
                grid-template-columns: 4em 3em 7.5em 1fr 7em 7em 5em 7em 7em 7em 7em 8em;
                min-width: 78em;
                font-size: var(--font-size-small);
                position: relative;
                page-break-inside: avoid;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }

            .row.added {
                background: var(--green-bg);
            }

            .row.changed {
                background: var(--orange-bg);
            }

            .row.deleted {
                background: var(--red-bg);
                color: var(--red);
            }

            .row.readonly {
                background: var(--shade-1);
            }

            .row.daily * {
                color: var(--blue);
            }

            .row.daily .date *,
            .row.daily .amount * {
                cursor: pointer !important;
            }

            .row.deleted::after {
                content: "";
                display: block;
                position: absolute;
                left: 3em;
                right: 3em;
                top: 50%;
                border-top: solid 2px;
            }

            .row > * {
                display: flex;
                align-items: center;
                padding: 0.3em;
            }

            .row > :not(:last-child) {
                border-right: dashed 1px var(--shade-2);
            }

            .row > .balance {
                justify-content: flex-end;
            }

            .header-row {
                font-weight: bold;
                position: sticky;
                top: 0;
                z-index: 9;
                background: var(--color-bg);
            }

            .header-row > * {
                padding: 0.5em;
                display: block;
                text-align: center !important;
            }

            .sequence,
            .amount,
            .balance {
                text-align: right;
            }

            .text {
                text-align: left;
            }

            .row :not(.date-input-inner) > input,
            .row select {
                width: 100%;
                padding: 0.5em;
                text-align: inherit;
                background: transparent;
                text-overflow: ellipsis;
                font-size: inherit;
            }

            .row ptc-date-input {
                --date-input-padding: 0.36em;
                background: transparent;
            }

            .row ptc-revenues-autocomplete-input {
                flex: 1;
            }

            .row input[readonly],
            .row ptc-date-input[readonly],
            .row input:not(:focus):not(:hover),
            .row ptc-date-input:not(.focus):not(:hover),
            .row select[readonly],
            .row select:not(:focus):not(:hover) {
                border-color: transparent;
            }

            .footer {
                padding: 0.5em;
                border-top: solid 1px var(--shade-2);
            }

            .footer > :not(:last-child) {
                margin-right: 0.5em;
            }

            .delete-button {
                color: var(--color-negative);
            }

            .row:not(:hover) .balance button,
            .row.readonly .balance button,
            .row:hover:not(.readonly) .balance input {
                display: none;
            }

            .row > .move-buttons {
                display: flex;
                flex-direction: column;
                position: absolute;
                left: 0;
                top: 0;
                padding: 0.1em;
                border: none;
                z-index: 1;
            }

            .header:not(:hover) .move-buttons {
                display: none;
            }

            .move-buttons button {
                padding: 0 0.1em;
                background: rgba(255, 255, 255, 0.9);
            }

            .row:not(:hover) .move-buttons {
                display: none;
            }

            .export-popover {
                width: 310px;
                padding: 0.5em;
            }

            .export-popover h3 {
                margin: 0.3em;
            }

            .export-popover form .field label {
                padding: 0.5em;
            }

            .row.counting-log {
                color: var(--purple);
                font-weight: 600;
            }

            .commit-dialog {
                --dialog-width: 25em;
            }

            .commit-dialog h1 {
                text-align: center;
                margin: 0;
            }

            .commit-dialog form {
                margin: 0.8em;
            }

            .balance-start,
            .balance-end {
                padding: 0.5em 0.8em;
            }

            @media print {
                :host {
                    display: block;
                    position: static !important;
                }

                .scroller {
                    height: auto;
                }

                .header {
                    font-size: var(--font-size-small);
                }

                .row {
                    font-size: var(--font-size-tiny);
                    background: transparent !important;
                }
            }
        `,
    ];

    private _renderCountingLog(entry: RevenueEntry, index: number) {
        if (entry.daily) {
            return;
        }
        const deleted = this._deleted.has(entry);
        const added = this._added.has(entry);
        const previous = this._entries[index - 1];
        const next = this._entries[index + 1];

        return html`
            <form
                class=${classMap({
                    "counting-log": true,
                    row: true,
                    daily: entry.daily,
                    added,
                    deleted,
                })}
                ?hidden=${!this._filterByString(entry, this._filterString)}
            >
                <div class="move-buttons" ?hidden=${!!this._filterString || deleted || entry.readonly}>
                    <button
                        type="button"
                        @click=${(e: Event) => this._move(index, "up", e)}
                        ?disabled=${!previous || previous.readonly || previous.date !== entry.date}
                    >
                        <i class="caret-up"></i>
                    </button>
                    <button
                        type="button"
                        @click=${(e: Event) => this._move(index, "down", e)}
                        ?disabled=${!next || next.readonly || next.date !== entry.date}
                    >
                        <i class="caret-down"></i>
                    </button>
                </div>

                <div class="sequence"></div>

                <div
                    class="type center-justifying center-aligning horizontal layout"
                    title="${ifDefined(revenueTypeLabel(entry.type))}"
                >
                    <i class="${ifDefined(revenueTypeIcon(entry.type))}"></i>
                </div>

                <div class="date input">
                    <ptc-date-input
                        name="date"
                        .value=${entry.date}
                        required
                        readonly
                        disableDatePicker
                    ></ptc-date-input>
                </div>

                <div class="name input">
                    <input name="name" .value=${entry.name} readonly />
                </div>

                <div class="receipt"></div>

                <div class="tax"></div>

                <div class="postingkey"></div>

                <div class="ledger"></div>

                <div class="costCenter"></div>

                <div class="amount"></div>

                <div class="amount"></div>

                <div class="balance input">
                    <button type="button" class="print-button transparent icon" ?hidden=${deleted}>
                        <i class="print" @click=${() => this._printCountingLog(entry)}></i>
                    </button>

                    <button type="button" class="delete-button transparent icon" ?hidden=${deleted}>
                        <i class="trash" @click=${() => this._deleteEntry(entry)}></i>
                    </button>

                    <button type="button" class="restore-button transparent icon" ?hidden=${!deleted}>
                        <i class="undo" @click=${() => this._restoreEntry(entry)}></i>
                    </button>

                    <input type="number" step="0.01" readonly .value=${entry.amount.toFixed(2)} />
                </div>
            </form>
        `;
    }

    private _renderRow(entry: RevenueEntry, index: number) {
        if (entry.type === RevenueType.CashCount) {
            return this._renderCountingLog(entry, index);
        }

        const deleted = this._deleted.has(entry);
        const added = this._added.has(entry);
        const changed = !added && this._edited.has(entry);
        const previous = this._entries[index - 1];
        const next = this._entries[index + 1];

        const templates = this._templates.filter(
            (e) =>
                e.type === entry.type &&
                (e.name !== entry.name ||
                    e.ledger !== entry.ledger ||
                    e.taxKey !== entry.taxKey ||
                    e.costCenter !== entry.costCenter)
        );

        return html`
            <form
                class=${classMap({
                    row: true,
                    daily: entry.daily,
                    readonly: entry.readonly,
                    added,
                    deleted,
                    changed,
                })}
                @change=${() => this._updateEntry(index)}
                @focusout=${() => this._rowBlur()}
                autocomplete="off"
                ?hidden=${!this._filterByString(entry, this._filterString)}
                data-entry=${ifDefined(entry.id || entry.randomId)}
            >
                <input name="id" type="hidden" .value=${entry.id?.toString()} />

                <div class="move-buttons" ?hidden=${!!this._filterString || deleted || entry.readonly}>
                    <button
                        type="button"
                        @click=${(e: Event) => this._move(index, "up", e)}
                        ?disabled=${!previous || previous.readonly || previous.date !== entry.date}
                    >
                        <i class="caret-up"></i>
                    </button>
                    <button
                        type="button"
                        @click=${(e: Event) => this._move(index, "down", e)}
                        ?disabled=${!next || next.readonly || next.date !== entry.date}
                    >
                        <i class="caret-down"></i>
                    </button>
                </div>

                <div class="sequence input">
                    <input name="sequence" type="number" .value=${String(entry.sequence)} readonly />
                </div>

                <div
                    class="type center-justifying center-aligning horizontal layout"
                    title="${revenueTypeLabel(entry.type) || ""}"
                >
                    <i class="${revenueTypeIcon(entry.type) || ""}"></i>
                </div>

                <div class="date input" @click=${entry.daily ? () => this._openDaily(entry) : null}>
                    <ptc-date-input
                        name="date"
                        .value=${entry.date}
                        required
                        .min=${this._minDate}
                        .max=${this._maxDate}
                        disable-year
                        disable-month
                        ?readonly=${deleted || entry.readonly || entry.daily}
                    >
                    </ptc-date-input>
                </div>

                <div class="name input">
                    ${entry.type === RevenueType.PayAdvance
                        ? html`
                              <select name="employee" class="stretch" ?disabled=${deleted || entry.readonly} required>
                                  <option disabled hidden value="">Mitarbeiter Wählen...</option>
                                  ${app.employees
                                      .filter((e) => !!e.getContractForDate(entry.date))
                                      .map(
                                          (e) => html`
                                              <option .value=${e.id.toString()} ?selected=${e.id === entry.employeeId}>
                                                  ${e.lastName}, ${e.firstName}
                                              </option>
                                          `
                                      )}
                              </select>
                          `
                        : html`
                              <ptc-revenues-autocomplete-input
                                  class="input stretch"
                                  .templates=${templates}
                                  @template-selected=${(e: CustomEvent<RevenueGroup>) =>
                                      this._templateSelected(index, e)}
                              >
                                  <input
                                      name="name"
                                      .value=${entry.name}
                                      required
                                      ?readonly=${deleted || entry.readonly}
                                  />
                              </ptc-revenues-autocomplete-input>
                          `}
                </div>

                <div class="receipt input">
                    <input name="receipt" .value=${entry.receipt} ?readonly=${deleted || entry.readonly} />
                </div>

                <div class="tax input">
                    <select name="taxKey" class="plain" ?disabled=${deleted || entry.readonly}>
                        <option .value=${TaxKey.None.toString()} ?selected=${entry.taxKey === TaxKey.None}>
                            ${taxKeyLabel(TaxKey.None, entry.date)}
                        </option>
                        <option .value=${TaxKey.Free.toString()} ?selected=${entry.taxKey === TaxKey.Free}>
                            ${taxKeyLabel(TaxKey.Free, entry.date)}
                        </option>
                        <option
                            .value=${TaxKey.Sales7.toString()}
                            ?selected=${entry.taxKey === TaxKey.Sales7}
                            ?disabled=${entry.type === RevenueType.Expense}
                        >
                            ${taxKeyLabel(TaxKey.Sales7, entry.date)}
                        </option>
                        <option
                            .value=${TaxKey.Sales19.toString()}
                            ?selected=${entry.taxKey === TaxKey.Sales19}
                            ?disabled=${entry.type === RevenueType.Expense}
                        >
                            ${taxKeyLabel(TaxKey.Sales19, entry.date)}
                        </option>
                        <option
                            .value=${TaxKey.Input7.toString()}
                            ?selected=${entry.taxKey === TaxKey.Input7}
                            ?disabled=${entry.type !== RevenueType.Expense}
                        >
                            ${taxKeyLabel(TaxKey.Input7, entry.date)}
                        </option>
                        <option
                            .value=${TaxKey.Input9_5.toString()}
                            ?selected=${entry.taxKey === TaxKey.Input9_5}
                            ?disabled=${entry.type !== RevenueType.Expense}
                        >
                            ${taxKeyLabel(TaxKey.Input9_5, entry.date)}
                        </option>
                        <option
                            .value=${TaxKey.Input19.toString()}
                            ?selected=${entry.taxKey === TaxKey.Input19}
                            ?disabled=${entry.type !== RevenueType.Expense}
                        >
                            ${taxKeyLabel(TaxKey.Input19, entry.date)}
                        </option>
                    </select>
                </div>

                <div class="postingkey input">
                    <input name="postingKey" .value=${entry.postingKey} ?readonly=${deleted || entry.readonly} />
                </div>

                <div class="ledger input">
                    <input name="ledger" .value=${entry.ledger} ?readonly=${deleted || entry.readonly} />
                </div>

                <div class="costcenter input">
                    <input name="costCenter" .value=${entry.costCenter} ?readonly=${deleted || entry.readonly} />
                </div>

                ${entry.type === RevenueType.Sales || entry.amount > 0
                    ? html`
                          <div class="amount input" @click=${entry.daily ? () => this._openDaily(entry) : null}>
                              <input
                                  name="amountIn"
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  .value=${entry.amount.toFixed(2)}
                                  required
                                  ?readonly=${deleted || entry.readonly || entry.daily}
                              />
                          </div>

                          <div class="amount" @click=${entry.daily ? () => this._openDaily(entry) : null}></div>
                      `
                    : html`
                          <div class="amount" @click=${entry.daily ? () => this._openDaily(entry) : null}></div>

                          <div class="amount input" @click=${entry.daily ? () => this._openDaily(entry) : null}>
                              <input
                                  name="amountOut"
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  .value=${Math.abs(entry.amount).toFixed(2)}
                                  required
                                  ?readonly=${deleted || entry.readonly || entry.daily}
                              />
                          </div>
                      `}

                <div class="balance input">
                    <button
                        type="button"
                        class="transparent icon"
                        ?hidden=${![RevenueType.Expense, RevenueType.Sales].includes(entry.type) || deleted}
                        title="Buchung Splitten"
                    >
                        <i class="page-break" @click=${() => this._splitEntry(entry, templates)}></i>
                    </button>

                    <button type="button" class="delete-button transparent icon" ?hidden=${entry.daily || deleted}>
                        <i class="trash" @click=${() => this._deleteEntry(entry)}></i>
                    </button>

                    <button type="button" class="restore-button transparent icon" ?hidden=${!deleted}>
                        <i class="undo" @click=${() => this._restoreEntry(entry)}></i>
                    </button>

                    <input type="number" step="0.01" readonly .value=${(entry.cashBalance - entry.tip).toFixed(2)} />
                </div>

                ${entry.tip
                    ? html`
                          <div></div>
                          <div></div>
                          <div></div>
                          <div class="name input"><input value="Trinkgeld" readonly /></div>
                          <div class="receipt"></div>
                          <div class="tax"></div>
                          <div class="postingkey"></div>
                          <div class="ledger"></div>
                          <div class="costCenter"></div>
                          <div class="amount"></div>
                          <div class="amount input">
                              <input
                                  name="tip"
                                  type="number"
                                  step="0.01"
                                  .value=${Math.abs(entry.tip).toFixed(2)}
                                  min="0"
                                  required
                              />
                          </div>
                          <div class="balance input">
                              <input type="number" step="0.01" readonly .value=${entry.cashBalance.toFixed(2)} />
                          </div>
                      `
                    : ""}
            </form>
        `;
    }

    render() {
        if (!this.venue || !this.date) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        const { from, to } = getRange(this.date, "month");
        const lastDay = dateAdd(to, { days: -1 });
        const today = toDateString(new Date());
        const yesterday = dateAdd(today, { days: -1 });
        const commitDate = lastDay < yesterday ? lastDay : yesterday;
        const lastEntry = this._entries.filter((e) => e.type !== RevenueType.CashCount).pop();
        const startBalance = this._previousEntry ? this._previousEntry.cashBalance : 0;
        const endBalance = lastEntry ? lastEntry.cashBalance : startBalance;

        return html`
            <div class="header horizontal center-aligning spacing layout padded noprint">
                <div class="small right icon input noprint">
                    <input id="filterInput" placeholder="Suchen..." @input=${this._updateFilter} />
                    <i class="${this._filterString ? "times click" : "search"} icon" @click=${this._clearFilter}></i>
                </div>

                <div class="stretch"></div>

                <button class="slim transparent"><i class="plus"></i></button>

                <ptc-popover class="popover-menu" hide-on-click>
                    <button @click=${() => this._newEntry(RevenueType.Sales)}>
                        <i class="money-bill-wave"></i>
                        Einnahme
                    </button>

                    <button @click=${() => this._newEntry(RevenueType.Expense)}>
                        <i class="shopping-cart"></i>
                        Ausgabe
                    </button>

                    <button @click=${() => this._newEntry(RevenueType.PayAdvance)}>
                        <i class="heart"></i>
                        Gehaltsvorschuss
                    </button>

                    <button @click=${() => this._newEntry(RevenueType.BankDeposit)}>
                        <i class="piggy-bank"></i>
                        Bankeinzahlung
                    </button>

                    <button @click=${() => this._createCountingLog()}>
                        <i class="coins"></i>
                        Zählprotokoll
                    </button>
                </ptc-popover>

                <button
                    class="slim transparent"
                    ?hidden=${this._hasChanges}
                    @click=${() => this.go("exports/cashbook_pdf", getRange(this.date, "month"))}
                >
                    <i class="download"></i>
                </button>

                <button class="slim transparent" @click=${() => window.print()} ?hidden=${this._hasChanges}>
                    <i class="print"></i>
                </button>

                <button class="slim transparent" @click=${() => this._commitCashbook()} ?hidden=${this._hasChanges}>
                    <i class="file-lock"></i>
                </button>

                <ptc-dialog class="commit-dialog">
                    <form @submit=${this._submitCommitCashbook}>
                        <h1>Kassenbuch Festschreiben</h1>

                        <div class="field">
                            <label>Bis Einschließlich</label>
                            <ptc-date-input name="until" .value=${commitDate} max="${today}"></ptc-date-input>
                        </div>

                        <div class="horizontal layout pad-children">
                            <button class="stretch primary">Festschreiben</button>
                            <button class="stretch" type="button" @click=${() => this._commitDialog.dismiss()}>
                                Abbrechen
                            </button>
                        </div>
                    </form>
                </ptc-dialog>
            </div>

            <div class="balance-start horizonal layout border-bottom">
                <div class="printonly">
                    Kassenbuch ${this._venue?.name} ${formatDate(from)} - ${formatDate(dateAdd(to, { days: -1 }))}
                </div>
                <div class="stretch"></div>
                <div>Anfangsbestand: <strong>${formatNumber(startBalance)}</strong></div>
            </div>

            <div class="scroller">
                <div class="header-row row">
                    <div class="sequence">Laufn.</div>
                    <div class="type">Art</div>
                    <div class="date">Datum</div>
                    <div class="name">Text</div>
                    <div class="receipt">Belegnr.</div>
                    <div class="tax">Steuersatz</div>
                    <div class="postingkey">BU</div>
                    <div class="ledger">Konto</div>
                    <div class="costcenter">KoSt</div>
                    <div class="amount">Ein</div>
                    <div class="amount">Aus</div>
                    <div class="balance">Saldo</div>
                </div>

                <div class="entries">
                    ${repeat(
                        this._entries,
                        (e) => e.id || e.randomId,
                        (entry, i) => this._renderRow(entry, i)
                    )}
                </div>
            </div>

            <div class="balance-start horizonal end-justifying layout border-top">
                <div>Endbestand: <strong>${formatNumber(endBalance)}</strong></div>
            </div>

            <div
                class="padded evenly stretching center-justifying spacing horizontal layout border-top"
                ?hidden=${!this._hasChanges}
            >
                <button class="primary" @click=${() => this._save()} style="max-width: 20em">Speichern</button>

                <button @click=${() => this._load()} class="transparent" style="max-width: 20em">Abbrechen</button>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
