import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
// import { request } from "../lib/ajax";
import { Dialog } from "./dialog";
// import Fuse from "fuse.js";
// import "./scroller";
import { contactSupport } from "../lib/help";
import { Popover } from "./popover";
import { getSingleton, singleton } from "../lib/singleton";

// interface Article {
//     title: string;
//     url: string;
//     keywords: string;
//     summary: string;
//     section: string;
//     icon: string;
//     anchors: { title: string };
// }

@customElement("ptc-help")
export class HelpWidget extends Dialog<{ displaySupportButton: boolean } | undefined, void> {
    static helpSite = process.env.PTC_HELP_SITE;

    static rootPage = "#search-input";
    static suggestedHelpPage?: string;

    readonly showScrim = false;

    @property({ type: Boolean })
    displaySupportButton = true;

    @state()
    private _page: string;

    // @state()
    // private _filterString = "";

    // @query("input[type='search']")
    // private _filterInput: HTMLInputElement;

    @query("iframe")
    private _iframe: HTMLIFrameElement;

    // private _fuse: Fuse<Article> = new Fuse([], {
    //     keys: [
    //         {
    //             name: "title",
    //             weight: 5,
    //         },
    //         {
    //             name: "keywords",
    //             weight: 4,
    //         },
    //         {
    //             name: "anchors.title",
    //             weight: 3,
    //         },
    //         {
    //             name: "summary",
    //             weight: 2,
    //         },
    //         {
    //             name: "section",
    //             weight: 1,
    //         },
    //     ],
    //     includeScore: true,
    // });

    // get _matchedArticles() {
    //     const results = this._fuse.search(this._filterString || document.title);
    //     return results.map((r) => r.item);
    // }

    // constructor() {
    //     super();
    //     this._loadArticles();
    // }

    async show({
        displaySupportButton = true,
        page = undefined,
    }: { displaySupportButton?: boolean; page?: string } = {}) {
        const promise = super.show();
        this.displaySupportButton = displaySupportButton;
        await this.updateComplete;
        this._openPage(page || HelpWidget.suggestedHelpPage);
        // this._filterString = this._filterInput.value = "";
        // this._filterInput.focus();
        return promise;
    }

    private _openPage(page = HelpWidget.rootPage) {
        this._page = page;
        this._iframe.src = `${HelpWidget.helpSite}${page}`;
    }

    private _openPageInNewTab(page = this._page) {
        window.open(`${HelpWidget.helpSite}${page}`, "Pentacode Hilfe");
    }

    // private _updateFilter() {
    //     this._filterString = this._filterInput.value;
    // }

    // private async _loadArticles() {
    //     this.loading = true;
    //     try {
    //         const res = await request(
    //             "GET",
    //             `${HelpWidget.helpSite}index.json`,
    //             undefined,
    //             new Map<string, string>([["Accept", "application/json"]])
    //         );
    //         const response = JSON.parse(res.responseText);
    //         this._fuse.setCollection(response.pages.filter((p: any) => p.isDescendant));
    //         this.requestUpdate();
    //     } catch (e) {}
    //     this.loading = false;
    // }

    private _contactSupport() {
        this.done();
        contactSupport();
    }

    static styles = [
        ...Dialog.styles,
        css`
            :host {
                z-index: 1000;
            }

            .outer {
                align-items: flex-start;
                justify-content: flex-end;
                padding: 2.5em;
                transform-origin: bottom left;
            }

            .inner {
                height: 40em;
                width: 25em;
                max-height: 100%;
                max-width: 100%;
                display: flex;
                flex-direction: column;
            }

            .article {
                padding: 0.5em;
                text-decoration: none;
                margin: 0 0.5em;
            }

            .article:not(:first-child) {
                border-top: solid 1px var(--shade-2);
            }

            .article:hover,
            .article:hover + .article {
                border-radius: var(--border-radius);
                border-color: transparent;
            }

            @media (max-width: 420px) {
                .outer {
                    padding: 0;
                }

                .inner {
                    box-shadow: none;
                    border-radius: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        `,
    ];

    renderContent() {
        return html`
            <div class="padded horizontal center-aligning layout border-bottom">
                <div class="stretch"><i class="question"></i> Hilfe</div>
                <button class="transparent icon" @click=${() => this._openPage(HelpWidget.rootPage)} tabindex="-1">
                    <i class="search"></i>
                </button>
                <button class="transparent icon" @click=${() => this.done()}>
                    <i class="times"></i>
                </button>
            </div>
            <div class="padded" hidden>
                <div class="left icon input">
                    <i class="search"></i>
                </div>
            </div>
            <iframe class="stretch"></iframe>
            <div
                class="smaller padded horizontal evenly stretching spacing layout"
                ?hidden=${!this.displaySupportButton}
            >
                <button class="subtle" @click=${() => this._openPageInNewTab()}>
                    <i class="browser"></i>
                    In Neuem Tab Öffnen
                </button>
                <button class="subtle" @click=${this._contactSupport}>
                    <i class="user-headset"></i>
                    Support Kontaktieren
                </button>
            </div>
        `;
    }
}

@customElement("ptc-help-popover")
export class HelpPopover extends Popover {
    @property()
    page: string = "";

    private get _url() {
        return `${process.env.PTC_HELP_SITE}${this.page}`;
    }

    static styles = [
        ...Popover.styles,
        css`
            :host {
                z-index: 1000;
                width: 300px;
                height: 400px;
            }
        `,
    ];

    private _openInHelpWidget() {
        openHelp({ page: this.page });
        this.hide();
    }

    private _openInBrowser() {
        window.open(this._url, "Pentacode Hilfe");
        this.hide();
    }

    renderContent() {
        return html`
            <div class="fullbleed vertical layout">
                <iframe class="stretch" .src=${this._url}> </iframe>
                <div class="tiny padded horizontal layout">
                    <button class="subtle" @click=${this._openInHelpWidget}>
                        <i class="smaller arrow-left"></i> <i class="window-restore"></i> Im Hilfefenster Öffnen
                    </button>
                    <div class="stretch"></div>
                    <button class="subtle" @click=${this._openInBrowser}>
                        <i class="browser"></i> In Neuem Tab Öffnen <i class="smaller arrow-right"></i>
                    </button>
                </div>
            </div>
        `;
    }
}

@customElement("ptc-help-anchor")
export class HelpAnchor extends LitElement {
    @property()
    page: string;

    @singleton("ptc-help-popover")
    private _popover: HelpPopover;

    private _hidePopoverTimeout?: number;

    private _leaveAnchorhandler = () => (this._hidePopoverTimeout = window.setTimeout(() => this._hidePopover(), 100));
    private _enterPopoverHandler = () => window.clearTimeout(this._hidePopoverTimeout);

    private _setup() {
        this.addEventListener("mouseleave", this._leaveAnchorhandler);
        this._popover.addEventListener("mouseenter", this._enterPopoverHandler);
        this._popover.addEventListener("popover-hide", () => this._tearDown(), { once: true });
    }

    private _tearDown() {
        this.removeEventListener("mouseleave", this._leaveAnchorhandler);
        this._popover.removeEventListener("mouseenter", this._enterPopoverHandler);
        this._popover.page = "";
    }

    private _showPopover() {
        this._setup();
        this._popover.page = this.page;
        this._popover.hideOnLeave = true;
        this._popover.showAt(this);
    }

    private _hidePopover() {
        this._popover.hide();
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("mouseenter", () => this._showPopover());
    }

    render() {
        return html`<slot></slot>`;
    }
}

export async function openHelp(params?: { displaySupportButton?: boolean; page?: string }) {
    const widget = (await getSingleton("ptc-help")) as HelpWidget;
    return widget.show(params);
}
