import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { Employee } from "@pentacode/core/src/model";
import { StateMixin } from "../mixins/state";
import { RouteArgs, Routing } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { shared, mixins } from "../styles";
import { app } from "../init";
import { NewEmployeeDialog } from "./new-employee-dialog";
import "./employees-all";
import "./employees-single";

@customElement("ptc-employees")
export class Employees extends Routing(StateMixin(LitElement)) {
    readonly routePattern = /^employees(?:\/(?<id>[^\/]+))?/;

    @state()
    private _loading = false;

    @state()
    private _page: string;

    @singleton("ptc-new-employee-dialog")
    private _newEmployeeDialog: NewEmployeeDialog;

    handleRoute([id]: RouteArgs) {
        id = id as string;
        if (id === "all") {
            this._page = "all";
            return;
        }

        if (!app.getEmployee(parseInt(id))) {
            this.go(`employees/all`, undefined, true);
            return;
        }

        this._page = "employee";
    }

    private async _newEmployee() {
        const employee = await this._newEmployeeDialog.show(new Employee());
        if (employee) {
            // const performRegistration = await confirm(
            //     "Mitarbeiter erfolgreich angelegt. Möchten Sie nun eine Sofortmeldung durchführen?",
            //     "Ja",
            //     "Nein",
            //     {
            //         title: "Mitarbeiter Angelegt",
            //         type: "success",
            //         optionsLayout: "horizontal",
            //     }
            // );

            this.go(`employees/${employee.id}`);
        }
    }

    static styles = [
        shared,
        css`
            ptc-employees-single,
            ptc-employees-all,
            ptc-employees-new {
                ${mixins.fullbleed()};
            }

            @media print {
                ptc-employees-single,
                ptc-employees-all,
                ptc-employees-new {
                    position: static;
                }
            }
        `,
    ];

    render() {
        return html`
            <ptc-employees-all ?hidden=${this._page !== "all"} @newemployee=${this._newEmployee}></ptc-employees-all>

            <ptc-employees-single
                ?hidden=${this._page !== "employee"}
                @newemployee=${this._newEmployee}
            ></ptc-employees-single>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
