import { LitElement, html, css } from "lit";
import { customElement, property, state, query, queryAll } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import {
    Employee,
    Contract,
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
    Department,
    RosterTemplate,
    RosterTargets,
    Absence,
    AbsenceStatus,
    RosterNote,
    RosterTab,
    Venue,
    Availability,
    AvailabilityStatus,
    EmployeeStatus,
    employeeStatusLabel,
    employeeStatusColor,
    Position,
    TimeFilter,
} from "@pentacode/core/src/model";
import { calcAllHours, calcTotalHours, calcNominalHours } from "@pentacode/core/src/hours";
import { MonthlyStatement } from "@pentacode/core/src/statement";
import { Issue } from "@pentacode/core/src/issues";
import {
    UpdateVenueParams,
    GetRosterTargetsParams,
    GetAbsencesParams,
    GetRosterNotesParams,
    GetPublicRosterUrlParams,
    GetAvailabilitesParams,
    UpdateAccountParams,
} from "@pentacode/core/src/api";
import {
    parseDateString,
    toDateString,
    getRange,
    dateAdd,
    formatDate,
    debounce,
    toDurationString,
    wait,
    parseTimes,
    toTimeString,
} from "@pentacode/core/src/util";
import { getHolidayForDate } from "@pentacode/core/src/holidays";
import { getIssues } from "@pentacode/core/src/issues";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { print } from "../lib/print";
import { isCursorInInput, isSafari, setClipboard } from "../lib/util";
import { app, router } from "../init";
import { shared, mixins, colors } from "../styles";
import "./scroller";
import { entryPositions, RosterEntry } from "./roster-entry";
import "./roster-entry";
import "./avatar";
import { Dialog } from "./dialog";
import { alert, confirm } from "./alert-dialog";
import "./spinner";
import { Checkbox } from "./checkbox";
import "./date-picker";
import "./balance";
import { RosterCosts } from "./roster-costs";
import { RosterTargetsElement } from "./roster-targets";
import { EmployeeDay } from "./employee-day";
import "./progress";
import { PublishRosterDialog } from "./publish-roster-dialog";
import { singleton } from "../lib/singleton";
import { AbsenceDialog } from "./absence-dialog";
import { RosterNotePopover } from "./roster-note-popover";
import { cache } from "lit/directives/cache.js";
import { until } from "lit/directives/until.js";
import { SendMessageDialog } from "./send-message-dialog";
import { AvailabilityDialog } from "./availability-dialog";
import "./popover";
import "./drawer";
import { AutoAssignMenu } from "./auto-assign-menu";
import { DateString, Hours } from "@pentacode/openapi/src/units";
import { EntityMultiSelect } from "./entity-multi-select";
import { Popover } from "./popover";
import { SortableList } from "./sortable-list";
import { TimeInput } from "./time-input";

interface DayData {
    employee: number;
    date: DateString;
    entries: TimeEntry[];
    blocked: boolean;
    blockedReason: string;
    today: boolean;
    isPast: boolean;
    readonly: boolean;
    absence?: Absence;
    availabilities: Availability[];
}

interface RosterData {
    dates: DateString[];
    departments: DepartmentData[];
}

export interface EmployeeData {
    employee: Employee;
    contract: Contract;
    nominal: number;
    actual: number;
    statement: MonthlyStatement;
    timeBalance: any;
}

interface DepartmentData {
    department: Department;
    employees: {
        employeeData: EmployeeData;
        days: DayData[];
    }[];
    unassigned: {
        date: DateString;
        entries: TimeEntry[];
    }[];
}

interface ShiftTemplate {
    type: TimeEntryType;
    venue?: number;
    uses?: number;
    start?: string;
    end?: string;
    position?: Position;
    breakPlanned?: number | null;
}

export interface DragData {
    entry?: Partial<TimeEntry>;
    rosterTemplate?: RosterTemplate;
    shiftTemplate?: ShiftTemplate;
    imageOffset?: { x: number; y: number };
}

@customElement("ptc-roster-row-legacy")
export class RosterRow extends LitElement {
    @property({ attribute: false })
    employee!: EmployeeData;

    @property({ attribute: false })
    days: DayData[] = [];

    @property({ attribute: false })
    department!: DepartmentData;

    @property({ attribute: false })
    venue!: Venue;

    @property({ attribute: false })
    issues!: Issue[];

    @property({ type: Boolean })
    isVisible = false;

    @property()
    activeDate!: string | null;

    @property({ attribute: false })
    activeEmployee!: number | null;

    @property({ attribute: false })
    activeDepartment!: number | null;

    @property()
    activeEntry!: string | null;

    @property({ attribute: false })
    activeTab!: RosterTab;

    @property({ type: Boolean })
    canMoveUp: boolean;

    @property({ type: Boolean })
    canMoveDown: boolean;

    createRenderRoot() {
        return this;
    }

    shouldUpdate(changes: Map<string, any>) {
        return changes.has("isVisible") || this.isVisible;
    }

    private _dragstart(e: DragEvent, data: DragData) {
        const el = e.target as HTMLElement;
        const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        const dt = e.dataTransfer!;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "all";
        dt.dropEffect = "move";
        data.imageOffset = { x: imgEl.offsetWidth / 2, y: imgEl.offsetHeight / 2 };
        dt.setDragImage(
            imgEl,
            data.imageOffset.x * window.devicePixelRatio,
            data.imageOffset.y * window.devicePixelRatio
        );
        this.classList.add("dragging");
        el.classList.add("dragging");
        this.dispatchEvent(new CustomEvent("begindrag", { detail: { data } }));
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = !e.altKey ? "link" : "copy";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _renderEmployeeHeader({ employee, nominal, actual }: EmployeeData) {
        return html`
            <div
                class="employee-header horizontal start-aligning layout"
                @click=${() => this.dispatchEvent(new CustomEvent("header-clicked"))}
            >
                <div>
                    <ptc-avatar .employee=${employee}></ptc-avatar>
                </div>

                <div class="stretch">
                    <div class="employee-name ellipsis">${employee.name}</div>

                    ${employee.status === EmployeeStatus.Active
                        ? html`
                              <ptc-progress
                                  .actual=${actual}
                                  .nominal=${nominal}
                                  class="tiny noprint"
                                  .format=${(n: number) => toDurationString(n, true)}
                              ></ptc-progress>
                          `
                        : html`
                              <div
                                  class="tiny inverted pill fill-horizontally text-centering ${employeeStatusColor(
                                      employee.status
                                  )}"
                              >
                                  ${employeeStatusLabel(employee.status)}
                              </div>
                          `}
                </div>

                <div class="employee-move-buttons">
                    <button
                        @click=${(e: Event) => {
                            e.stopPropagation();
                            this.dispatchEvent(new CustomEvent("move-up"));
                        }}
                        ?disabled=${!this.canMoveUp}
                    >
                        <i class="caret-up"></i>
                    </button>
                    <button
                        @click=${(e: Event) => {
                            e.stopPropagation();
                            this.dispatchEvent(new CustomEvent("move-down"));
                        }}
                        ?disabled=${!this.canMoveDown}
                    >
                        <i class="caret-down"></i>
                    </button>
                </div>
            </div>
        `;
    }

    private _renderTimeEntry(entry: TimeEntry, dep: Department, date: string, blocked?: boolean, stackSize?: number) {
        const department = (entry.position && entry.position.departmentId) || dep.id;
        const otherDep = !!entry.position && entry.position.departmentId !== dep.id;
        const allowDrag = !otherDep && !blocked;
        const issues = this.issues.filter((issue) => issue.timeEntries.some((e) => e.id === entry.id));
        return html`
            <ptc-roster-entry
                id="entry-${entry.id}-${dep.id}"
                draggable="${allowDrag ? "true" : "false"}"
                .entry=${entry}
                .error=${!!issues.length}
                .department=${dep}
                .venue=${this.venue}
                .stackSize=${stackSize}
                .condensed=${app.settings.rosterCondensedView}
                class="tiny ${this.activeEntry === entry.id && this.activeDepartment === dep.id ? "selected" : ""}"
                @remove=${() => this.dispatchEvent(new CustomEvent("remove-entry", { detail: entry }))}
                @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
                @select=${({ detail: { field } }: CustomEvent<{ field: string }>) =>
                    this.dispatchEvent(
                        new CustomEvent("select", {
                            detail: { date, employee: entry.employeeId, department, entry: entry.id, field },
                        })
                    )}
                style="margin-top: ${Math.min(stackSize || 1, 3) + 2}px; opacity: 0;"
                .animated=${true}
            ></ptc-roster-entry>
        `;
    }

    private _renderDay(dep: DepartmentData, emp: EmployeeData, day: DayData) {
        const { types } = this.activeTab;
        return day.absence && (!types || types.includes(day.absence.type))
            ? html`
                  <div class="employee-day" ?disabled=${!app.hasPermission("manage.employees.absences")}>
                      <div
                          class="absence fullbleed centering layout click ${day.absence.start === day.date
                              ? "absence-start"
                              : ""} ${day.absence.end === dateAdd(day.date, { days: 1 }) ? "absence-end" : ""}"
                          style="--color-highlight: ${timeEntryTypeColor(day.absence.type)}"
                          @click=${() =>
                              this.dispatchEvent(
                                  new CustomEvent("edit-absence", { detail: { absence: day.absence! } })
                              )}
                      >
                          ${day.entries.some((e) => e.type === day.absence!.type)
                              ? html` <i class="big ${app.localized.timeEntryTypeIcon(day.absence.type)}"></i> `
                              : ""}
                      </div>
                  </div>
              `
            : html`
                  <div
                      class=${classMap({
                          "employee-day": true,
                          blocked: day.blocked,
                          today: day.today,
                          active:
                              day.date === this.activeDate &&
                              day.employee === this.activeEmployee &&
                              dep.department.id === this.activeDepartment,
                      })}
                      @click=${() =>
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      employee: day.employee,
                                      department: dep.department.id,
                                      date: day.date,
                                      entry: null,
                                  },
                              })
                          )}
                      @dragenter=${this._dragenter}
                      @dragleave=${this._dragleave}
                      @dragover=${this._dragover}
                      @drop=${(e: DragEvent) => {
                          if (!day.blocked) {
                              e.preventDefault();
                              this.dispatchEvent(
                                  new CustomEvent("drop-into-day", {
                                      detail: {
                                          dragEvent: e,
                                          employee: emp.employee,
                                          department: dep.department,
                                          date: day.date,
                                      },
                                  })
                              );
                          }
                      }}
                      ?disabled=${day.readonly}
                  >
                      ${day.blocked
                          ? html`
                                <div class="fullbleed centering layout blocked-reason">
                                    <div class="ellipsis stretch padded-light" title="${day.blockedReason}">
                                        ${day.blockedReason}
                                    </div>
                                </div>
                            `
                          : day.availabilities.length && app.settings.rosterDisplayAvailabilities
                            ? html`
                                  <div class="fullbleed evenly stretching vertical layout availabilities faded">
                                      ${day.availabilities.map(
                                          (av) => html`
                                              <div
                                                  class="smaller colored-text relative centering layout availability"
                                                  style="--color-highlight: ${av.color}"
                                              >
                                                  <i class="${av.icon}"></i> ${av.label}
                                              </div>
                                          `
                                      )}
                                  </div>
                              `
                            : emp.employee.isBirthDay(day.date)
                              ? html`
                                    <div class="fullbleed centering layout faded">
                                        <i class="birthday-cake big faded"></i>
                                    </div>
                                `
                              : ""}
                      ${day.entries.map((a) => this._renderTimeEntry(a, dep.department, day.date, day.blocked))}

                      <button
                          ?hidden=${day.blocked || !!day.entries.length}
                          class="transparent add-button ${this.activeDepartment === dep.department.id &&
                          this.activeDate === day.date &&
                          this.activeEmployee === day.employee &&
                          this.activeEntry === "new"
                              ? "selected"
                              : ""}"
                          @click=${(e: Event) => {
                              e.stopPropagation();
                              this.dispatchEvent(
                                  new CustomEvent("select", {
                                      detail: {
                                          employee: day.employee,
                                          department: dep.department.id,
                                          date: day.date,
                                          entry: "new",
                                      },
                                  })
                              );
                          }}
                      >
                          <i class="plus"></i>
                      </button>
                  </div>
              `;
    }

    private _render() {
        return html`
            ${this._renderEmployeeHeader(this.employee)}
            ${this.days.map((day) => this._renderDay(this.department, this.employee, day))}
        `;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }
}

@customElement("ptc-roster-legacy")
export class Roster extends Routing(StateMixin(LitElement)) {
    routePattern = /^roster/;

    get routeTitle() {
        return this._venue ? `Dienstplan - ${this._venue.name}` : "Dienstplan";
    }

    get helpPage() {
        return "/handbuch/dienstplan";
    }

    @routeProperty({ param: "date" })
    private _date: DateString;

    private get _venue() {
        return this._activeTab && app.getVenue(this._activeTab.venue);
    }

    @routeProperty({ type: Number, param: "tab" })
    private _activeTabIndex: number;

    private get _activeTab() {
        return app.rosterTabs[this._activeTabIndex];
    }

    @state()
    private _employees: EmployeeData[] = [];

    @state()
    private _data: RosterData;

    @state()
    private _filterString = "";

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _issues: Issue[] = [];

    @state()
    private _frequentTimesLimit = 10;

    @state()
    private _loading: boolean = false;

    @state()
    private _selectedRosterTemplate: RosterTemplate | null = null;

    @state()
    private _statements: MonthlyStatement[] = [];

    @state()
    private _targets: RosterTargets[] = [];

    @state()
    private _absences: Absence[] = [];

    @state()
    private _availabilities: Availability[] = [];

    @state()
    private _activeDate: DateString | null = null;

    @state()
    private _activeEmployee: number | null = null;

    @state()
    private _activeDepartment: number | null = null;

    @state()
    private _activeEntry: string | null = null;

    @state()
    private _activeField: string | null = null;

    @state()
    private _displayRosterTemplates = false;

    @state()
    private _displayAutoAssignMenu = false;

    @state()
    private _displayIssues = false;

    @state()
    private _dragData: DragData | null = null;

    @state()
    private _rosterNotes: RosterNote[] = [];

    @state()
    private _rosterNoteLanes: RosterNote[][] = [];

    @state()
    private _publicUrlPromise: Promise<string> = new Promise<string>(() => {});

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query("#createRosterTemplateDialog")
    private _createRosterTemplateDialog: Dialog<void, void>;

    @query("#applyRosterTemplateDialog")
    private _applyRosterTemplateDialog: Dialog<void, void>;

    @query("#createRosterTemplateForm")
    private _createRosterTemplateForm: HTMLFormElement;

    // @query("#applyRosterTemplateForm")
    // private _applyRosterTemplateForm: HTMLFormElement;

    @query("ptc-employee-day")
    private _employeeDayForm: EmployeeDay;

    @queryAll("ptc-roster-targets")
    private _rosterTargetsElements: RosterTargetsElement[];

    @query("ptc-roster-costs")
    private _rosterCosts: RosterCosts;

    @query("ptc-auto-assign-menu")
    private _autoAssignMenu: AutoAssignMenu;

    @singleton("ptc-publish-roster-dialog")
    private _publishRosterDialog: PublishRosterDialog;

    @singleton("ptc-absence-dialog")
    private _absenceDialog: AbsenceDialog;

    @singleton("ptc-roster-note-popover")
    private _rosterNotePopover: RosterNotePopover;

    @singleton("ptc-send-message-dialog")
    private _sendMessageDialog: SendMessageDialog;

    @singleton("ptc-availability-dialog")
    private _availabilityDialog: AvailabilityDialog;

    private _frequentTimes: ShiftTemplate[] = [];

    private _collapsed = new Set<number>();

    private get _range() {
        return getRange(this._date || toDateString(new Date()), "week");
    }

    private get _departments(): Department[] {
        return this._venue?.departments.filter((department) => app.hasAccess({ department })) || [];
    }

    private get _filteredDepartments(): Department[] {
        return this._departments.filter(
            (d) => !this._activeTab.departments || this._activeTab.departments.includes(d.id)
        );
    }

    private get _publicUrlWithFilters(): Promise<string> {
        return this._publicUrlPromise.then((url) => {
            if (app.settings.rosterMirrorDepartments) {
                url += "&md=1";
            }

            const deps = this._activeTab.departments;
            return !deps || !deps.length || deps.length === this._venue!.departments.length
                ? url
                : `${url}&d=${deps.join(",")}`;
        });
    }

    private get _unpublishedEntries() {
        const { from, to } = this._range;
        const positions = this._filteredDepartments.flatMap((d) => d.positions.map((p) => p.id));
        return this._timeEntries.filter(
            (e) =>
                (e.position
                    ? positions.includes(e.position.id)
                    : e.employeeId
                      ? app.getEmployee(e.employeeId)?.positions.some((p) => positions.includes(p.id))
                      : true) &&
                e.date >= from &&
                e.date < to &&
                !e.isPast &&
                (!this._activeTab.time || e.isWithin(this._activeTab.time)) &&
                !e.isPublished
        );
    }

    private get _unpublishedCount() {
        return this._unpublishedEntries.length;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("dragover", (e: DragEvent) => this._dragover(e));
        this.addEventListener("drop", (e: DragEvent) => e.preventDefault());
        this.addEventListener("dragend", (e: DragEvent) => this._dragend(e));
        document.addEventListener("keydown", (e: KeyboardEvent) => {
            if (
                !this.active ||
                e.ctrlKey ||
                e.metaKey ||
                (!this._employeeDayForm?.matches(":focus-within") && isCursorInInput()) ||
                (this._displayAutoAssignMenu && e.key !== "Escape")
            ) {
                return;
            }
            const direction = { w: "up", s: "down", a: "left", d: "right" }[e.key] as "up" | "down" | "left" | "right";
            if (e.shiftKey && e.key.toLowerCase() === "a") {
                this.go(null, { ...this.router.params, date: dateAdd(this.date, { days: -7 }) });
                if (this._activeDate) {
                    this._setActive({ date: dateAdd(this._activeDate, { days: -7 }) });
                }
            } else if (e.shiftKey && e.key.toLowerCase() === "d") {
                this.go(null, { ...this.router.params, date: dateAdd(this.date, { days: 7 }) });
                if (this._activeDate) {
                    this._setActive({ date: dateAdd(this._activeDate, { days: 7 }) });
                }
            } else if (direction) {
                this._moveCursor(direction);
                e.preventDefault();
            } else if (e.key === "Escape") {
                if (this._displayAutoAssignMenu) {
                    this._autoAssignMenu?.close();
                }
                this._setActive({ date: null, employee: null, department: null, entry: null }, true);
            } else if (e.shiftKey && e.key === "Backspace") {
                const activeEntry = this._timeEntries.find((e) => e.id === this._activeEntry);
                if (activeEntry) {
                    this._removeTimeEntry(activeEntry);
                }
            } else if (e.key === "n") {
                this._setActive({ entry: "new" });
            }
        });
    }

    private _moveCursor(direction: "up" | "down" | "left" | "right") {
        let date: DateString | undefined = undefined;
        let department: number | undefined = undefined;
        let employee: number | undefined = undefined;
        let entry: string | null | undefined = undefined;
        let nextMove: "up" | "down" | "right" | "left" | undefined = undefined;

        if (direction === "up" || direction === "down") {
            const diff = direction === "up" ? -1 : direction === "down" ? 1 : 0;
            date = this._activeDate || this._data.dates[0];

            const depIndex = this._data.departments.findIndex((d) => d.department.id === this._activeDepartment);
            let dep = this._data.departments[depIndex] || this._data.departments[0];
            const empIndex = dep.employees.findIndex((e) => e.employeeData.employee.id === this._activeEmployee);
            let emp = dep.employees[empIndex] || dep.employees[0];
            let day = emp.days.find((d) => d.date === date)! || emp.days[0];
            const entryIndex = day.entries.findIndex((e) => e.id === this._activeEntry);

            let newDepIndex = depIndex;
            let newEntryIndex = entryIndex + diff;

            if (newEntryIndex < 0) {
                emp = dep.employees[empIndex - 1];
                if (!emp) {
                    do {
                        newDepIndex = newDepIndex ? newDepIndex - 1 : this._data.departments.length - 1;
                        dep = this._data.departments[newDepIndex];
                        emp = dep && dep.employees[dep.employees.length - 1];
                    } while ((!emp || this._collapsed.has(dep.department.id)) && newDepIndex !== depIndex);
                }
                if (!emp) {
                    return;
                }
                day = emp.days.find((d) => d.date === date)!;
                newEntryIndex = day.entries.length - 1;
            } else if (newEntryIndex > day.entries.length - 1) {
                emp = dep.employees[empIndex + 1];
                if (!emp) {
                    do {
                        newDepIndex = newDepIndex >= this._data.departments.length - 1 ? 0 : newDepIndex + 1;
                        dep = this._data.departments[newDepIndex];
                        emp = dep && dep.employees[0];
                    } while ((!emp || this._collapsed.has(dep.department.id)) && newDepIndex !== depIndex);
                }
                if (!emp) {
                    return;
                }
                day = emp.days.find((d) => d.date === date)!;
                newEntryIndex = 0;
            }

            department = dep.department.id;
            employee = emp.employeeData.employee.id;

            const e = day.entries[newEntryIndex];
            entry = (e && e.id) || null;
        } else {
            const diff = direction === "left" ? -1 : direction === "right" ? 1 : 0;
            const firstDate = this._data.dates[0];
            const lastDate = this._data.dates[this._data.dates.length - 1];
            const activeDate = this._activeDate || dateAdd(this._data.dates[0], { days: -1 });
            date = dateAdd(activeDate, { days: diff });
            if (date > lastDate) {
                date = firstDate;
                nextMove = "down";
            } else if (date < firstDate) {
                date = lastDate;
                nextMove = "up";
            }
            entry = null;
            if (!this._activeDepartment || !this._activeEmployee) {
                department = this._data.departments[0].department.id;
                employee = this._data.departments[0].employees[0].employeeData.employee.id;
            }
        }

        this._setActive(
            {
                date,
                department,
                employee,
                entry,
                field: "start",
            },
            true
        );

        if (nextMove) {
            this._moveCursor(nextMove);
        }
    }

    handleRoute() {
        //Invalid date
        if (!this._date || !parseDateString(this._date)) {
            this.go(null, { ...this.router.params, date: toDateString(new Date()) }, true);
            return;
        }

        //Invalid date
        if (!this._activeTab && app.rosterTabs.length) {
            this.go(null, { ...this.router.params, tab: 0 }, true);
            return;
        }
    }

    private _intersectionObserver?: IntersectionObserver;

    private _intersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => ((e.target as RosterRow).isVisible = e.isIntersecting));
    }

    private _observeRows() {
        if (!this._intersectionObserver) {
            const main = this.renderRoot.querySelector(".main");
            if (!main) {
                return;
            }
            this._intersectionObserver = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => this._intersectionHandler(entries),
                { root: main, rootMargin: "50%" }
            );
        }
        const elements = this.renderRoot.querySelectorAll("ptc-roster-row-legacy");
        for (const el of elements) {
            this._intersectionObserver.observe(el);
        }
    }

    private _updatePublicUrl() {
        this._publicUrlPromise = app.api
            .getPublicRosterUrl(new GetPublicRosterUrlParams({ venue: this._venue!.id, ...this._range }))
            .then((res) => res.url);
    }

    async updated(changes: Map<string, any>) {
        if (!this.active) {
            return;
        }
        if ((changes.has("_date") || changes.has("active")) && this._date && this.active) {
            this.synchronize(true);
        } else if (changes.has("_activeTabIndex")) {
            this._updatePublicUrl();
            this._setActive({ employee: null, department: null, date: null, entry: null });
            this.refresh();
        }
        this._updateRosterNoteStyles();
        this._updateDepartmentHeaderStyles();
        this._observeRows();
        await wait(20);
        for (const row of this.renderRoot.querySelectorAll<HTMLDivElement>(".unassigned-row")) {
            row.style.height = row.querySelector<HTMLDivElement>(".employee-row")!.offsetHeight + "px";
        }
    }

    pageFocused() {
        if (this.active) {
            if (this._displayAutoAssignMenu) {
                this._autoAssignMenu.close();
            }
            this.synchronize(true);
        }
    }

    async synchronize(showSpinner = false) {
        if (!this._venue) {
            return;
        }

        this._setActive({ employee: null, department: null, date: null, entry: null });

        if (showSpinner) {
            this._loading = true;
        }

        try {
            await app.syncTimeEntries();

            const { from, to } = this._range;
            const currMonth = getRange(from, "month");
            const nextMonth = getRange(to, "month");
            const twoWeeksPrior = dateAdd(this._range.from, { days: -14 });
            const entriesFrom = twoWeeksPrior < currMonth.from ? twoWeeksPrior : currMonth.from;

            const [entries, statements, targets, absences, notes, availabilites] = await Promise.all([
                app.getTimeEntries({
                    from: entriesFrom,
                    to: nextMonth.to,
                    type: [
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.Free,
                        TimeEntryType.CompDay,
                        TimeEntryType.ChildSick,
                        TimeEntryType.SickInKUG,
                    ],
                    includeDeleted: true,
                    includeUnassigned: true,
                }),
                app.getMonthlyStatements({ from: currMonth.from, to: nextMonth.to }),
                app.api.getRosterTargets(
                    new GetRosterTargetsParams({
                        date: this._date,
                        venue: this._venue.id,
                    })
                ),
                app.api.getAbsences(
                    new GetAbsencesParams({
                        from,
                        to,
                    })
                ),
                app.api.getRosterNotes(
                    new GetRosterNotesParams({
                        venue: this._venue.id,
                        from,
                        to,
                    })
                ),
                app.api.getAvailabilities(
                    new GetAvailabilitesParams({
                        from,
                        to,
                        employees: app.employees.map((e) => e.id),
                    })
                ),
            ]);

            this._updatePublicUrl();

            this._timeEntries = entries;
            this._statements = statements;
            this._targets = targets;
            this._absences = absences;
            this._rosterNotes = notes;
            this._availabilities = availabilites;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this.refresh();

        if (showSpinner) {
            this._loading = false;
        }
    }

    refresh() {
        if (!this._date || !this._venue) {
            return;
        }
        const { from, to } = this._range;
        const today = toDateString(new Date());

        const data: RosterData = {
            dates: [],
            departments: [],
        };

        const d = parseDateString(from)!;
        const year = d.getFullYear();
        const month = d.getMonth();
        for (let i = 0; i < 7; i++) {
            const date = dateAdd(from, { days: i });
            data.dates.push(date);
        }

        const { time, types } = this._activeTab;

        this._employees = app.employees
            .map((employee) => {
                const contract = employee.contracts.find((c) => c.start <= to && (!c.end || c.end > from));
                if (!contract) {
                    return null;
                }

                const entries = this._timeEntries.filter(
                    (a) => a.employeeId === employee.id && a.date >= from && a.date < to
                );
                const statement =
                    this._statements.find((s) => s.employeeId === employee.id && year === year && s.month === month) ||
                    new MonthlyStatement({
                        employeeId: employee.id,
                        year,
                        month,
                    });

                const average = statement.previousAverages?.hoursPerWorkDay;
                const nominal = calcNominalHours(app.company!, employee, { from, to });
                const actual = calcTotalHours(calcAllHours(employee, app.company!, entries, average, "mixed")).full;

                const empData: EmployeeData = {
                    employee,
                    contract,
                    statement,
                    nominal,
                    actual,
                    timeBalance: undefined,
                };

                return empData;
            })
            .filter((e) => !!e) as EmployeeData[];

        for (const department of this._departments) {
            const depData: DepartmentData = {
                department,
                employees: [],
                unassigned: data.dates.map((date) => ({
                    date,
                    entries: this._timeEntries
                        .filter(
                            (e) =>
                                e.date === date &&
                                e.position?.departmentId === department.id &&
                                !e.employeeId &&
                                !e.deleted &&
                                (!time || e.isWithin(time)) &&
                                (e.type === TimeEntryType.Work || !types || types.includes(e.type))
                        )
                        .sort(
                            (a, b) =>
                                (a.startPlanned ? Number(a.start) : Infinity) -
                                (b.startPlanned ? Number(b.start) : Infinity)
                        ),
                })),
            };

            for (const employee of app.getEmployeesForDepartment(department)) {
                const commitBefore = app.company?.settings.commitTimeEntriesBefore;
                const empData = this._employees.find((e) => e.employee.id === employee.id);
                if (!empData) {
                    continue;
                }
                const entries = this._timeEntries.filter(
                    (a) => a.employeeId === employee.id && a.date >= from && a.date < to
                );
                const days: DayData[] = [];

                for (const date of data.dates) {
                    const contract = employee.getContractForDate(date);
                    const todaysEntries = entries
                        .filter(
                            (a) =>
                                a.date === date &&
                                !a.deleted &&
                                (app.settings.rosterMirrorDepartments ||
                                    !a.position ||
                                    a.position.departmentId === department.id) &&
                                (!time || a.isWithin(time)) &&
                                (a.type === TimeEntryType.Work || !types || types.includes(a.type))
                        )
                        .sort(
                            (a, b) =>
                                (a.startPlanned ? Number(a.start) : Infinity) -
                                (b.startPlanned ? Number(b.start) : Infinity)
                        );
                    const absence = this._absences.find(
                        (a) =>
                            a.employeeId === employee.id &&
                            a.start <= date &&
                            a.end > date &&
                            (a.status === AbsenceStatus.Approved || a.status === AbsenceStatus.Inferred)
                    );
                    const isPast = date < today;
                    days.push({
                        employee: employee.id,
                        date,
                        blocked: !contract || contract.blocked,
                        blockedReason: (contract && contract.comment) || "Inaktiv",
                        today: date === today,
                        entries: todaysEntries,
                        isPast,
                        readonly:
                            (!!commitBefore && date < commitBefore) ||
                            (isPast && !app.hasPermission("manage.employees.time")),
                        absence,
                        availabilities: this._availabilities
                            .filter((a) => a.employeeId === employee.id && a.date === date)
                            .sort((a, b) => ((a.start || "") < (b.start || "") ? -1 : 1)),
                    });
                }

                depData.employees.push({
                    employeeData: empData,
                    days,
                });
            }

            data.departments.push(depData);
        }

        this._data = data;
        this._issues = app.employees
            .flatMap((e) => getIssues(e, app.company!, this._timeEntries, { from, to: dateAdd(to, { days: 1 }) }))
            .filter((issue) => !issue.ignored);
        this._updateFrequentTimes();
        this._employeeDayForm && this._employeeDayForm.updateForms();

        this._rosterCosts && this._rosterCosts.updateConfig();
        for (const t of this._rosterTargetsElements) {
            t.requestUpdate();
        }

        this._updateRosterNoteLanes();
    }

    private _updateRosterNoteLanes() {
        const rosterNoteLanes: RosterNote[][] = [];
        for (const note of this._rosterNotes) {
            if (
                note.departments &&
                this._activeTab.departments &&
                !note.departments.some((d) => this._activeTab.departments!.includes(Number(d)))
            ) {
                continue;
            }
            let lane = rosterNoteLanes.find((notes) => !notes.some((n) => n.start < note.end && n.end > note.start));
            if (!lane) {
                lane = [];
                rosterNoteLanes.push(lane);
            }
            lane.push(note);
        }
        this._rosterNoteLanes = rosterNoteLanes;
    }

    stateChanged() {
        if (this.active) {
            this.debouncedRefresh();
        }
    }

    debouncedRefresh = debounce(() => this.refresh(), 100);

    private _updateFilter() {
        this._filterString = this._filterInput.value;
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _availablePositions(employee: Employee, dep: Department) {
        return employee.positions
            .filter((position) => position.active && position.departmentId === dep.id)
            .sort((a, b) => a.order - b.order);
    }

    private async _addTimeEntry(vals: Partial<TimeEntry>, setActive = false) {
        if (!vals.employeeId && vals.type !== TimeEntryType.Work) {
            return;
        }

        if (![TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(vals.type!)) {
            if (!app.hasPermission("manage.employees.absences")) {
                alert("Sie haben keine ausreichenden Berechtigungen für diese Aktion!", {
                    type: "warning",
                    title: "Fehlende Berechtigung",
                });
                return;
            }

            const absence = new Absence({
                type: vals.type,
                employeeId: vals.employeeId!,
                start: vals.date,
                end: dateAdd(vals.date!, { days: 1 }),
            });

            return this._editAbsence(absence);
        }

        const entry = new TimeEntry(vals);
        await app.createOrUpdateTimeEntries(entry, { applyAutoMeals: true, otherEntries: this._timeEntries });
        this._timeEntries.push(entry);
        this.refresh();

        if (setActive) {
            this._setActive({
                employee: entry.employeeId,
                date: entry.date,
                department: entry.position && entry.position.departmentId,
                entry: entry.id,
                field: "start",
            });
        }
        return;
    }

    private async _removeTimeEntry(entry: TimeEntry) {
        if (entry.isPast && (entry.startFinal || entry.endFinal)) {
            const employee = entry.employeeId && app.getEmployee(entry.employeeId);
            if (!app.hasPermission("manage.employees.time") || (employee && !app.hasPermissionForEmployee(employee))) {
                alert("Sie haben keine Berechtigung für diese Aktion!", { type: "warning" });
                return;
            }
            if (
                !(await confirm("Sind Sie sicher dass Sie diesen Eintrag löschen möchten?", "Löschen", "Abbrechen", {
                    title: "Eintrag Löschen",
                    type: "destructive",
                    icon: "trash",
                }))
            ) {
                return;
            }
        }

        if (entry.id === this._activeEntry) {
            const activeDep = this._data.departments.find((d) => d.department.id === this._activeDepartment);
            const activeEmp =
                activeDep && activeDep.employees.find((e) => e.employeeData.employee.id === this._activeEmployee);
            const days = activeEmp?.days || activeDep?.unassigned;
            const activeDay = days?.find((d) => d.date === this._activeDate);
            const index = activeDay && activeDay.entries.findIndex((e) => e.id === this._activeEntry);
            const entry =
                (index && activeDay!.entries[index - 1]) || activeDay!.entries.find((e) => e.id !== this._activeEntry);
            this._setActive({ entry: (entry && entry.id) || "new" });
        }
        app.removeTimeEntries(entry);
        if (!entry.published) {
            this._timeEntries = this._timeEntries.filter((e) => e.id !== entry.id);
        }
        this.refresh();
    }

    private _updateFrequentTimes() {
        const templates = new Map<string, ShiftTemplate>(
            app.shiftTemplates.map(({ venue, start, end }) => [
                `${venue}-${start?.slice(0, 5)}-${end?.slice(0, 5)}`,
                {
                    type: TimeEntryType.Work,
                    venue,
                    start,
                    end,
                    uses: Infinity,
                },
            ])
        );

        const { time, departments } = this._activeTab;
        for (const a of this._timeEntries.filter(
            (a) =>
                a.type === TimeEntryType.Work &&
                !a.deleted &&
                !app.isRemoved(a) &&
                a.planned &&
                a.position &&
                (!departments || !departments.includes(a.position.departmentId)) &&
                (!time || a.isWithin(time))
        )) {
            const { venue, department } = app.getDepartment(a.position!.departmentId);
            if (!venue || !department || !app.hasAccess({ department })) {
                continue;
            }
            const start = (a.startPlanned || a.startFinal)?.toTimeString().slice(0, 5);
            const end = (a.endPlanned || a.endFinal)?.toTimeString().slice(0, 5);

            const key = `${venue.id}-${start}-${end}`;

            if (!templates.has(key)) {
                templates.set(key, { type: TimeEntryType.Work, venue: venue.id, start, end, uses: 0 });
            }

            templates.get(key)!.uses!++;
        }

        this._frequentTimes = [...templates.values()].sort(({ uses: n1 }, { uses: n2 }) => n2! - n1!);
    }

    private _updateTimeEntry(a: TimeEntry, data: Partial<TimeEntry> = {}) {
        if (app.isRemoved(a)) {
            return;
        }

        Object.assign(a, data);

        app.createOrUpdateTimeEntries(a);
        this.refresh();
    }

    private _toggleDepartment({ department: { id } }: DepartmentData) {
        if (this._collapsed.has(id)) {
            this._collapsed.delete(id);
        } else {
            this._collapsed.add(id);
        }
        this.requestUpdate();
    }

    private dropIntoDay(e: DragEvent, employee: Employee | null, department: Department, date: DateString) {
        e.preventDefault();

        const data = this._dragData;

        if (!data) {
            return;
        }

        if (data.rosterTemplate) {
            this._applyRosterTemplate(data.rosterTemplate);
        } else {
            let id: string | undefined = undefined;
            let type: TimeEntryType = TimeEntryType.Work;
            let position: Position | null = null;
            let startPlanned: Date | null = null;
            let endPlanned: Date | null = null;
            let startFinal: Date | null = null;
            let endFinal: Date | null = null;
            let breakPlanned: Hours | null = null;

            if (data.entry) {
                id = data.entry.id;
                type = data.entry.type || TimeEntryType.Work;
                position = data.entry.position || null;
                id = data.entry.id;
                [startPlanned, endPlanned] = parseTimes(
                    date,
                    toTimeString(data.entry.startPlanned),
                    toTimeString(data.entry.endPlanned)
                );
                [startFinal, endFinal] = parseTimes(
                    date,
                    toTimeString(data.entry.startFinal),
                    toTimeString(data.entry.endFinal)
                );
                breakPlanned = data.entry.breakPlanned || null;
            } else {
                [startPlanned, endPlanned] = parseTimes(date, data.shiftTemplate?.start, data.shiftTemplate?.end);
                position = data.shiftTemplate?.position || null;
                type = data.shiftTemplate?.type || TimeEntryType.Work;
            }

            const availablePositions = employee ? this._availablePositions(employee, department) : department.positions;

            if (type === TimeEntryType.Work) {
                if (!position || !availablePositions.some((p) => p.id === position!.id)) {
                    position = availablePositions[0];
                }
            } else {
                position = null;
            }

            if (!id || e.altKey) {
                const isPast = date < toDateString(new Date());
                // if the day is in the past and we're adding a new entry,
                // set final times instead of planned
                if (isPast && !startFinal && !endFinal) {
                    startFinal = startPlanned;
                    endFinal = endPlanned;
                    startPlanned = null;
                    endPlanned = null;
                }
                this._addTimeEntry(
                    {
                        type,
                        employeeId: employee?.id || null,
                        startPlanned,
                        endPlanned,
                        startFinal,
                        endFinal,
                        position,
                        date,
                        breakPlanned,
                    },
                    this._activeEntry === id || (type === TimeEntryType.Work && !startPlanned)
                );
            } else {
                const entry = this._timeEntries.find((e) => e.id === id);
                if (!entry) {
                    return;
                }
                entryPositions.set(`${department.id}_${entry.id}`, {
                    x: e.pageX - (data.imageOffset?.x || 0),
                    y: e.pageY - (data.imageOffset?.y || 0),
                });

                this._updateTimeEntry(entry, {
                    position,
                    date,
                    employeeId: employee?.id || null,
                    startPlanned,
                    endPlanned,
                    startFinal,
                    endFinal,
                });
                if (this._activeEntry === entry.id) {
                    this._setActive({
                        date,
                        department: position && position.departmentId,
                        employee: employee?.id || null,
                    });
                }
            }
        }
        (e.target as HTMLElement).classList.remove("dragover");

        setTimeout(() => this._dragend(), 50);
    }

    private _dragstart(e: DragEvent, data: DragData) {
        this._dragData = data;
        const el = e.target as HTMLElement;
        const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        const dt = e.dataTransfer!;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "all";
        dt.dropEffect = "move";
        data.imageOffset = { x: imgEl.offsetWidth / 2, y: imgEl.offsetHeight / 2 };
        dt.setDragImage(imgEl, data.imageOffset.x, data.imageOffset.y);
        this.classList.add("dragging");
        el.classList.add("dragging");
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = !e.altKey ? "link" : "copy";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _dragend(e?: DragEvent) {
        this._dragData = null;
        this.classList.remove("dragging");
        for (const el of [this, ...this.renderRoot!.querySelectorAll(".dragging")] as HTMLElement[]) {
            el.classList.remove("dragging");
        }
        e && e.preventDefault();
    }

    private _isFavorite({ venue, start, end }: ShiftTemplate) {
        return app.shiftTemplates.find((t) => t.venue === venue && t.start === start && t.end === end);
    }

    private _toggleFavorite({ venue, start, end }: ShiftTemplate) {
        const shiftTemplates = [...app.shiftTemplates];
        const existing = shiftTemplates.findIndex((t) => t.venue === venue && t.start === start && t.end === end);
        if (existing === -1) {
            shiftTemplates.push({ venue: venue!, start: start!, end: end! });
        } else {
            shiftTemplates.splice(existing, 1);
        }
        app.shiftTemplates = shiftTemplates;
        this.requestUpdate();
    }

    private async _publish() {
        const entries = this._unpublishedEntries;

        if (entries.length) {
            await this._publishRosterDialog.show({
                venue: this._venue!,
                entries: this._timeEntries,
                unpublishedEntries: entries,
                absences: this._absences,
                ...this._range,
            });
            await this.synchronize(true);
        }
    }

    private async _print() {
        print(await this._publicUrlWithFilters);
    }

    private async _shareViaMessage() {
        const venue = this._venue!;
        const departments = this._filteredDepartments;
        const url = await this._publicUrlWithFilters;
        const from = formatDate(this._range.from);
        const to = formatDate(dateAdd(this._range.to, { days: -1 }));
        const entireVenue = !departments || departments.length === venue.departments.length;

        await this._sendMessageDialog.show({
            venues: entireVenue ? [venue] : undefined,
            departments: entireVenue ? undefined : departments,
            message: `Hallo zusammen,

Es ist ein neuer Dienstplan für die Woche vom ${from} bis ${to} verfügbar!
Diesen kannst du über folgenden Link einsehen:

${url}

Liebe Grüße,
${app.profile!.name}`,
        });
    }

    private async _createRosterTemplate() {
        (this._createRosterTemplateDialog.querySelector('input[name="name"') as HTMLInputElement).value = "";
        this._createRosterTemplateDialog.show();
    }

    private async _submitRosterTemplate(e: FocusEvent) {
        e.preventDefault();

        this._createRosterTemplateDialog.loading = true;

        const formData = new FormData(this._createRosterTemplateForm);
        let { time, departments } = this._activeTab;
        if (!departments) {
            departments = this._filteredDepartments.map((d) => d.id);
        }
        const template = new RosterTemplate();
        template.name = formData.get("name") as string;
        template.shifts = this._timeEntries
            .filter(
                (timeEntry) =>
                    timeEntry.date >= this._range.from &&
                    timeEntry.date < this._range.to &&
                    timeEntry.type === TimeEntryType.Work &&
                    !timeEntry.deleted &&
                    !app.isRemoved(timeEntry) &&
                    timeEntry.planned &&
                    timeEntry.position &&
                    timeEntry.position.active &&
                    (!departments || departments.includes(timeEntry.position.departmentId)) &&
                    (!time || timeEntry.isWithin(time)) &&
                    app.hasAccess({ timeEntry })
            )
            .map((a) => ({
                day: a.start.getDay(),
                employee: a.employeeId,
                position: a.position!.id,
                start: a.startPlanned!.toTimeString().slice(0, 5),
                end: a.endPlanned!.toTimeString().slice(0, 5),
                break: a.breakPlanned,
            }));

        if (!template.shifts.length) {
            alert("Es wurden keine Schichten gewählt! (Dienstplanvorlagen umfassen nur Schichten mit Planzeiten)");
            this._createRosterTemplateDialog.loading = false;
            return;
        }

        try {
            await app.updateVenue(
                new UpdateVenueParams({
                    id: this._venue!.id,
                    rosterTemplates: [...this._venue!.rosterTemplates, template],
                })
            );
            this.requestUpdate();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._createRosterTemplateDialog.loading = false;
        this._createRosterTemplateDialog.dismiss();
    }

    private async _deleteRosterTemplate(template: RosterTemplate) {
        const confirmed = await confirm(
            `Wollen Sie die Vorlage "${template.name}" entfernen?`,
            "Entfernen",
            "Abbrechen",
            {
                type: "destructive",
                title: "Vorlage Entfernen",
            }
        );
        if (!confirmed) {
            return;
        }

        this._loading = true;
        try {
            await app.updateVenue(
                new UpdateVenueParams({
                    id: this._venue!.id,
                    rosterTemplates: this._venue!.rosterTemplates.filter((t) => t.id !== template.id),
                })
            );
            this.requestUpdate();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _applyRosterTemplate(template: RosterTemplate) {
        this._selectedRosterTemplate = template;
        this._applyRosterTemplateDialog.show();
    }

    private async _dismissApplyRosterTemplate() {
        this._applyRosterTemplateDialog.dismiss();
    }

    private async _submitApplyRosterTemplate(e: FocusEvent) {
        e.preventDefault();
        const template = this._selectedRosterTemplate;
        if (!template) {
            return;
        }

        const entries: TimeEntry[] = [];

        for (let {
            day,
            employee: employeeId,
            position: positionId,
            start,
            end,
            break: breakPlanned,
        } of template.shifts) {
            const { position } = app.getPosition(positionId) || { position: null };
            const employee = employeeId ? app.getEmployee(employeeId) : null;
            const date = dateAdd(this._range.from, { days: (day + 6) % 7 });
            const contract = employee?.getContractForDate(date);
            const absence = this._absences.find(
                (a) =>
                    a.employeeId === employeeId &&
                    a.start <= date &&
                    a.end > date &&
                    (a.status === AbsenceStatus.Approved || a.status === AbsenceStatus.Inferred)
            );

            if (!position || !position.active || !app.hasAccess({ position })) {
                continue;
            }

            if (
                !employee ||
                !!absence ||
                !contract ||
                contract.blocked ||
                !employee.positions.some((p) => p.id === position.id)
            ) {
                employeeId = null;
            }

            const entry = new TimeEntry({
                employeeId,
                position,
                date,
            });

            const isPast = entry.date < toDateString(new Date());
            const [startTS, endTS] = parseTimes(date, start, end);

            if (isPast) {
                entry.startFinal = startTS;
                entry.endFinal = endTS;
            } else {
                entry.startPlanned = startTS;
                entry.endPlanned = endTS;
                entry.breakPlanned = breakPlanned ?? null;
            }

            const { time, departments } = this._activeTab;

            if (
                (!departments || departments.includes(entry.position!.departmentId)) &&
                (!time || entry.isWithin(time)) &&
                // Deduplicate entries in case template is applied twice
                !this._timeEntries.some(
                    (e) =>
                        !e.deleted &&
                        e.employeeId === entry.employeeId &&
                        e.start.getTime() === entry.start.getTime() &&
                        e.end.getTime() === entry.end.getTime()
                )
            ) {
                entries.push(entry);
            }
        }

        this._applyRosterTemplateDialog.loading = true;
        try {
            await app.createOrUpdateTimeEntries(entries, { applyAutoMeals: true, otherEntries: this._timeEntries });
            alert(`Es wurden ${entries.length} Schichten erfolgreich eingefügt.`, {
                type: "success",
                title: "Vorlage Eingefügt",
            });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._applyRosterTemplateDialog.loading = false;

        this._dismissApplyRosterTemplate();
        this.synchronize(true);
    }

    private async _clearTimeEntries(e: Event) {
        e.preventDefault();

        const selected = new FormData(e.target as HTMLFormElement);

        let { time, departments, types } = this._activeTab;

        if (!departments) {
            departments = this._filteredDepartments.map((d) => d.id);
        }

        const entries = this._timeEntries.filter(
            (timeEntry) =>
                !timeEntry.deleted &&
                timeEntry.date >= this._range.from &&
                timeEntry.date < this._range.to &&
                app.hasAccess({ timeEntry }) &&
                [TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(timeEntry.type) &&
                (!types || [TimeEntryType.Work, ...types].includes(timeEntry.type)) &&
                (!timeEntry.position || !departments || departments.includes(timeEntry.position.departmentId)) &&
                (!time || timeEntry.isWithin(time)) &&
                (selected.has("planned") || timeEntry.type !== TimeEntryType.Work || timeEntry.startFinal) &&
                (selected.has("finished") || timeEntry.type !== TimeEntryType.Work || !timeEntry.startFinal)
        );

        const confirmed = await confirm(
            `Sind sie sicher dass Sie diese ${entries.length} Einträge löschen ` +
                `möchten? Diese Aktion kann nicht rückgängig gemacht werden!`,
            `${entries.length} Einträge Löschen`,
            "Abbrechen",
            { title: "Dienstplan Leeren", type: "destructive" }
        );
        if (!confirmed) {
            return;
        }

        app.removeTimeEntries(entries);
        this.synchronize(true);
    }

    private _getTemplateDepartments(t: RosterTemplate) {
        const departments = new Map<number, { name: string; color: string; count: number; id: number }>();
        for (const shift of t.shifts) {
            const r = app.getPosition(shift.position);
            if (!r) {
                continue;
            }

            if (!departments.has(r.department.id)) {
                departments.set(r.department.id, {
                    id: r.department.id,
                    name: r.department.name,
                    color: r.department.color,
                    count: 0,
                });
            }

            departments.get(r.department.id)!.count++;
        }
        return [...departments.values()];
    }

    private async _goToIssue(issue: Issue) {
        for (const entry of issue.timeEntries) {
            if (!entry.position) {
                continue;
            }

            const { department } = app.getDepartment(entry.position.departmentId);

            if (!department) {
                continue;
            }

            this._setActive({
                date: entry.date,
                employee: entry.employeeId,
                department: department.id,
                entry: entry.id,
            });
        }
    }

    private _updateRosterOrder = debounce((id: number, rosterOrder: string[]) => {
        app.updateDepartment({ id, rosterOrder });
    }, 3000);

    private _moveEmployee(dep: DepartmentData, i: number, direction: "up" | "down") {
        const employees = dep.employees;
        const employee = employees[i];
        employees.splice(i, 1);
        employees.splice(direction === "up" ? i - 1 : i + 1, 0, employee);
        const rosterOrder = employees.map((e) => e.employeeData.employee.id.toString());
        this.requestUpdate();
        dep.department.rosterOrder = rosterOrder;
        this._updateRosterOrder(dep.department.id, rosterOrder);
    }

    private _setActiveTimeout: any;
    private _setActive(
        {
            date = this._activeDate,
            employee = this._activeEmployee,
            department = this._activeDepartment,
            entry = this._activeEntry,
            field = this._activeField,
        }: {
            date?: DateString | null;
            employee?: number | null;
            department?: number | null;
            entry?: string | null;
            field?: string | null;
        } = {},
        instant = false
    ) {
        if (date && employee && department && !entry) {
            const first = this._timeEntries.find(
                (e) =>
                    e.employeeId === employee &&
                    e.date === date &&
                    (!e.position || e.position.departmentId === department) &&
                    !e.deleted &&
                    (!this._activeTab.time || e.isWithin(this._activeTab.time))
            );
            entry = (first && first.id) || "new";
        }

        const doIt = async () => {
            this._activeDate = date;
            this._activeEmployee = employee;
            this._activeDepartment = department || this._activeDepartment;
            this._activeEntry = entry;
            this._activeField = field;
            await this.updateComplete;
            const day = this.renderRoot!.querySelector(".employee-day.active");
            if (day) {
                try {
                    // @ts-ignore
                    day.scrollIntoViewIfNeeded();
                } catch (e) {
                    day.scrollIntoView({ block: "center" });
                }
            }
        };

        clearTimeout(this._setActiveTimeout);

        if (entry || instant) {
            doIt();
        } else {
            this._setActiveTimeout = setTimeout(doIt, 500);
        }
    }

    private _dayInput() {
        const entryEls =
            (this._activeEntry &&
                (this.renderRoot!.querySelectorAll(
                    `ptc-roster-entry[id^="entry-${this._activeEntry}"]`
                ) as any as RosterEntry[])) ||
            [];
        for (const el of entryEls) {
            el.requestUpdate();
        }
    }

    private async _editAbsence(absence: Absence) {
        const edited = await this._absenceDialog.show(absence);
        if (edited) {
            return this.synchronize(true);
        }
    }

    private async _newAvailability(employeeId: number, date: DateString) {
        const created = await this._availabilityDialog.show(
            new Availability({
                employeeId,
                status: AvailabilityStatus.Available,
                date,
            })
        );
        if (created) {
            this._availabilities = await app.api.getAvailabilities(
                new GetAvailabilitesParams({
                    ...this._range,
                    employees: app.employees.map((e) => e.id),
                })
            );
            this.refresh();
        }
    }

    private async _editAvailability(av: Availability) {
        const edited = await this._availabilityDialog.show(av);
        if (edited) {
            this._availabilities = await app.api.getAvailabilities(
                new GetAvailabilitesParams({
                    ...this._range,
                    employees: app.employees.map((e) => e.id),
                })
            );
            this.refresh();
        }
    }

    private async _addRosterNote(date: DateString) {
        const note = new RosterNote({
            venueId: this._venue!.id,
            start: date,
            end: dateAdd(date, { days: 1 }),
            text: "",
            departments: this._activeTab.departments?.map((d) => d.toString()),
        });
        this._rosterNotes.push(note);
        this.refresh();
        await this.updateComplete;
        setTimeout(() => this._editRosterNote(note), 100);
    }

    private _editRosterNote(note: RosterNote) {
        this._rosterNotePopover.hide();
        if (!app.hasPermission(`manage.roster.notes`)) {
            return;
        }
        const noteEl = this.renderRoot.querySelector(`[data-roster-note="${note.id || "new"}"]`) as HTMLElement;
        const change = () => this._updateRosterNoteLanes();
        const done = (e: CustomEvent<{ deleted?: boolean }>) => {
            this._rosterNotePopover.removeEventListener("change", change);
            this._rosterNotePopover.removeEventListener("done", done);
            if (e.detail?.deleted) {
                const index = this._rosterNotes.indexOf(note);
                this._rosterNotes.splice(index, 1);
                this._updateRosterNoteLanes();
            }
        };
        this._rosterNotePopover.addEventListener("change", change);
        this._rosterNotePopover.addEventListener("done", done);
        this._rosterNotePopover.rosterNote = note;
        this._rosterNotePopover.requestUpdate("rosterNote");
        this._rosterNotePopover.showAt(noteEl, true);
    }

    private _getRosterNoteStyle(note: RosterNote) {
        const rosterHeader = this.renderRoot.querySelector(".roster-header") as HTMLElement;
        if (!rosterHeader) {
            return "";
        }
        const dayHeaders = [...this.renderRoot.querySelectorAll(".day-header")] as HTMLElement[];
        const startDay = dayHeaders.find((h) => h.dataset.date === note.start) || dayHeaders[0];
        const endDay = dayHeaders.find((h) => h.dataset.date === note.end);
        const right = endDay ? rosterHeader.offsetWidth - endDay.offsetLeft : 0;
        let styles = `left: ${startDay.offsetLeft}px; right: ${right}px; --color-highlight: ${note.color};`;
        if (note.start < this._range.from) {
            styles += "border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;";
        }
        if (note.end > this._range.to) {
            styles += "border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: none;";
        }
        return styles;
    }

    private _updateRosterNoteStyles() {
        const els = this.renderRoot.querySelectorAll(".roster-note") as NodeListOf<HTMLElement>;
        for (const el of els) {
            const note = this._rosterNotes.find((note) =>
                el.dataset.rosterNote === "new" ? !note.id : el.dataset.rosterNote === note.id?.toString()
            );
            if (note) {
                el.setAttribute("style", this._getRosterNoteStyle(note));
            }
        }
    }

    private _updateDepartmentHeaderStyles() {
        const main = this.renderRoot.querySelector(".main") as HTMLDivElement;
        const headers = this.renderRoot.querySelectorAll(".department-header");
        const rosterNotes = this.renderRoot.querySelector(".roster-notes") as HTMLDivElement;

        if (!main || !rosterNotes) {
            return;
        }

        for (const header of headers) {
            header.setAttribute(
                "style",
                `width: ${main.offsetWidth - 32}px; top: ${rosterNotes.offsetTop + rosterNotes.offsetHeight + 5}px;`
            );
        }
    }

    private async _showAutoAssign() {
        this._collapsed.clear();
        this.classList.add("auto-assigning");
        this._displayAutoAssignMenu = true;
        await this.updateComplete;
        this._autoAssignMenu.init({
            entries: this._timeEntries,
            absences: this._absences,
            availabilities: this._availabilities,
            employees: this._employees,
            weights: {
                assignAll: 5,
                fillQuota: 5,
                avoidOvertime: 5,
                reduceAccumulatedOvertime: 5,
                minimizeCosts: 5,
                considerAvailabilities: 5,
                avoidProblems: 5,
            },
            reassignEntries: false,
            company: app.company!,
            range: this._range,
            departments: this._activeTab.departments || this._venue!.departments.map((d) => d.id),
        });
    }

    private async _closeAutoAssign() {
        this.classList.remove("auto-assigning");
        this._displayAutoAssignMenu = false;
        this.refresh();
    }

    static styles = [
        shared,
        Checkbox.styles,
        RosterCosts.styles,
        RosterTargetsElement.styles,
        css`
            :host {
                display: block;
                max-width: 1270px;
                position: relative;
            }

            :host(.auto-assigning) .main {
                cursor: not-allowed;
            }

            :host(:not(.auto-assigning)) ptc-roster-entry {
                transition: none !important;
                transform: none !important;
                opacity: 1 !important;
            }

            :host(.auto-assigning) .main > * {
                pointer-events: none;
            }

            .menu {
                padding: 0.5em 0;
                grid-area: menu;
                width: 240px;
                ${mixins.scroll()};
                border-left: solid 1px var(--shade-1);
                position: relative;
            }

            .menu .separator {
                margin: 0.5em;
                border-bottom: solid 1px var(--shade-2);
            }

            .venue-selector {
                width: calc(100% - 1em);
                margin: 0.5em;
            }

            .shift-template {
                padding: 0.7em;
                display: block;
                text-align: center;
                position: relative;
                background: var(--color-bg);
                color: var(--color-highlight);
                border: solid 1px;
                border-radius: 0.5em;
            }

            .shift-template-wrapper {
                margin: 0.5em;
                border-radius: 0.5em;
            }

            .shift-template.favorite .star {
                color: var(--yellow);
                font-weight: bold;
            }

            .shift-template .star {
                position: absolute;
                right: 0.7em;
                top: 0;
                bottom: 0;
                margin: auto;
                cursor: pointer;
            }

            .shift-template:not(.favorite) .star:not(:hover),
            .shift-template.favorite .star:hover {
                opacity: 0.5;
            }

            .roster-template {
                padding: 0.5em;
                display: block;
                position: relative;
                background: var(--color-bg);
                --color-highlight: var(--shade-3);
            }

            .roster-template:not(:first-child) {
                border-top: dashed 1px var(--shade-2);
            }

            .roster-template:hover {
                background: var(--shade-1);
            }

            .roster-template:hover,
            .roster-template:hover + .roster-template {
                border-radius: var(--border-radius);
                border-color: transparent;
            }

            .roster-template-delete {
                position: absolute;
                right: 0.3em;
                top: 0.3em;
            }

            .roster-template:not(:hover) .roster-template-delete {
                display: none;
            }

            .main {
                overflow: auto;
            }

            .main-inner {
                padding: 0 8px;
                position: relative;
                min-width: 70em;
            }

            .row {
                display: grid;
                grid-template-columns: 1fr repeat(7, 8.1em);
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .row > :not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .row > .today {
                border-left: solid 1px var(--blue-bg);
            }

            .roster-header {
                position: sticky;
                top: 0;
                background: var(--color-bg);
                z-index: 9;
                margin-bottom: 0.5em;
                border-bottom: solid 1px var(--shade-1);
            }

            .filter-wrapper {
                position: sticky;
                left: 0;
                background: var(--color-bg);
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 5;
                padding: 0 0.5em;
                margin-left: -0.5em;
            }

            .day-header {
                font-weight: bold;
                text-align: center;
                padding: 0.5em;
                position: relative;
            }

            .day-subheader {
                font-size: 80%;
                opacity: 0.7;
                margin-top: 2px;
            }

            .day-header.holiday {
                color: var(--violet);
            }

            .day-header.today {
                color: var(--blue);
            }

            .department {
                margin-bottom: 0.5em;
            }

            .department-header {
                letter-spacing: 5px;
                background: transparent;
                border-bottom: solid 2px;
                margin-bottom: 0.5em;
                color: var(--color-highlight);
                grid-column: span 8;
                text-align: center;
                font-weight: bold;
                font-size: 80%;
                cursor: pointer;
                position: relative;
                padding: 4px 0 2px 0;
                box-sizing: border-box;
                border-radius: 5px;
                border: solid 2px var(--color-highlight);
                background: var(--color-bg);
                position: sticky;
                left: 16px;
                top: 5em;
                z-index: 8;
            }

            .department-body {
                display: contents;
            }

            .department-header:hover::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(255, 255, 255, 0.3);
            }

            .department.collapsed > .department-body {
                display: none;
            }

            .department.collapsed > .department-header {
                background: var(--color-highlight);
                color: #fff;
            }

            .employee-row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .employee-day {
                display: flex;
                flex-direction: column;
                position: relative;
                cursor: pointer;
                max-height: 20em;
                overflow: auto;
            }

            .employee-day > * {
                margin: 3px;
            }

            .employee-day > ptc-roster-entry:not(:last-of-type) {
                margin-bottom: 0;
            }

            .employee-day.dragover {
                background: var(--color-primary-bg);
            }

            .employee-day.blocked {
                background: var(--shade-1);
            }

            .employee-day.active {
                background: var(--shade-1);
            }

            .employee-day .blocked-reason {
                text-align: center;
                opacity: 0.5;
            }

            .employee-header {
                font-size: var(--font-size-small);
                padding: 0.38em 0.6em 0.38em 0.6em;
                margin-left: -0.6em;
                cursor: pointer;
                position: relative;
                position: sticky;
                left: 0;
                z-index: 5;
                background: var(--color-bg);
            }

            .employee-header:hover .employee-name {
                color: var(--color-primary);
            }

            .employee-header .stretch {
                width: 0;
            }

            .employee-header ptc-avatar {
                margin-right: 0.5em;
                font-size: 1.05em;
            }

            .employee-move-buttons {
                display: flex;
                flex-direction: column;
                position: absolute;
                right: 0.3em;
                top: 0.4em;
            }

            .employee-header:not(:hover) .employee-move-buttons {
                display: none;
            }

            .employee-move-buttons button {
                padding: 0 0.1em;
                background: rgba(255, 255, 255, 0.9);
            }

            .employee-move-buttons button:first-child {
                margin-bottom: 0.1em;
            }

            .employee-info {
                display: grid;
                grid-template-columns: 11em 11em;
                grid-gap: 0.5em;
            }

            .employee-info-tile {
                text-align: center;
            }

            .employee-info-tile-label {
                font-size: var(--font-size-small);
                color: var(--color-primary);
                margin-left: 0.2em;
                margin-bottom: 0.2em;
                font-weight: 600;
            }

            .employee-info-tile-value {
                font-size: var(--font-size-large);
            }

            .employee-info-footnote {
                font-size: var(--font-size-tiny);
                grid-column: span 2;
                opacity: 0.8;
                text-align: center;
                margin: -0.5em;
            }

            .employee-name {
                font-weight: 600;
                line-height: 1.2em;
                margin-bottom: 0.3em;
            }

            .add-button {
                font-size: var(--font-size-tiny);
                height: 100%;
                padding: 0.3em;
                background: rgba(255, 255, 255, 0.7) !important;
            }

            .add-button:not(:first-child) {
                padding: 0.3em;
                margin-top: 3px;
            }

            .employee-day:not(:hover) .add-button:not(:focus):not(.selected) {
                display: none;
            }

            /*
            @keyframes drop {
                from {
                    transform: scale(1.05);
                }
            }
            animation: drop 0.2s cubic-bezier(0.05, 0.7, 0.03, 3) 0s;
            */

            ptc-roster-entry {
                cursor: pointer !important;
            }

            .selected {
                box-shadow: var(--color-primary) 0 0 0 0.2em !important;
            }

            .issues-scroller {
                max-height: 20em;
            }

            [draggable="true"] {
                cursor: grab;
                opacity: 0.999;
            }

            [draggable="true"]:active {
                cursor: grabbing;
            }

            ptc-roster-entry.dragging {
                opacity: 0.5;
            }

            ptc-roster-entry,
            .shift-template-wrapper {
                transition: all 0.1s;
            }

            ptc-roster-entry[draggable="true"]:hover,
            .shift-template-wrapper:hover {
                box-shadow: var(--color-highlight, var(--shade-4)) 0 0 0 0.2em;
            }

            :host(.dragging) {
                cursor: grabbing;
            }

            :host(.dragging) .employee-day *:not(.dragging) {
                pointer-events: none;
            }

            .template-dialog {
                --dialog-max-width: 350px;
            }

            .template-dialog-departments {
                grid-gap: 0.5em;
            }

            .issue-popover {
                font-size: var(--font-size-tiny);
                max-width: 120px;
            }

            .clear-roster-dialog form > button {
                width: 100%;
                margin-top: 0.7em;
            }

            .clear-roster-title {
                font-size: var(--font-size-big);
                margin: 0.5em;
                text-align: center;
            }

            .row.costs {
                position: sticky;
                bottom: 0;
                z-index: 9;
                background: var(--color-bg);
                border-top: solid 1px var(--shade-2);
            }

            .absence {
                background: var(--color-highlight);
                color: var(--color-bg);
                margin: 3px 0;
            }

            .absence-start {
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
                margin-left: 3px;
            }

            .absence-end {
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
                margin-right: 3px;
            }

            .availabilities {
                margin: 0;
            }

            .availability::before {
                content: "";
                display: block;
                ${mixins.fullbleed()};
                background: var(--color-highlight);
                opacity: 0.2;
            }

            ptc-roster-tabs-legacy {
                width: 100%;
                position: sticky;
                left: 0;
                z-index: 10;
                border-bottom: solid 1px var(--shade-1);
            }

            .unassigned-row {
                position: sticky;
                bottom: 0;
                z-index: 7;
                background: var(--color-bg);
                border-top: solid 1px var(--shade-1);
                color: var(--color-highlight);
                transition: height 0.3s;
            }

            /* .unassigned-row .employee-day {
                overflow: auto;
                --scrollbar-width: 0;
            } */

            .show-charts:not(.show-targets) .unassigned-row {
                bottom: 7.2em;
            }

            .show-charts.show-targets .unassigned-row {
                bottom: 10em;
            }

            .show-targets:not(.show-charts) .unassigned-row {
                bottom: 2.8em;
            }

            .entry-stack {
                position: relative;
                top: 1px;
            }

            .entry-stack > :first-child {
                position: relative;
                z-index: 1;
            }

            .entry-stack > :not(:first-child) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -5;
                pointer-events: none;
                width: 100%;
            }

            .entry-stack > :nth-child(2) {
                transform: scale(0.98) translateY(-2px);
                z-index: -1;
            }

            .entry-stack > :nth-child(3) {
                transform: scale(0.96) translateY(-4px);
                z-index: -2;
            }

            .condensed .employee-header ptc-avatar {
                font-size: 0.6em;
            }

            .condensed .employee-header ptc-progress,
            .condensed .employee-header .employee-move-buttons {
                display: none;
            }

            .condensed .employee-header .employee-name {
                margin-top: 0.3em;
            }

            .condensed .absence i {
                font-size: var(--font-size-medium);
            }

            .add-roster-note {
                position: absolute;
                margin: auto;
                background: var(--color-bg) !important;
                bottom: 0.2em;
                left: 0.2em;
                width: calc(100% - 0.4em);
                display: block;
                opacity: 1 !important;
                padding-top: 0.2em !important;
                padding-bottom: 0.2em !important;
            }

            .day-header:not(:hover) .add-roster-note {
                display: none;
            }

            .roster-note-lane {
                height: 2em;
                position: relative;
            }

            .roster-note {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }

            .roster-note-inner {
                color: var(--color-highlight, var(--color-primary));
                position: absolute;
                left: 0.1em;
                right: 0.1em;
                bottom: 0.1em;
                top: 0.1em;
                border-radius: 0.5em;
                border: solid 1px;
                text-align: center;
                padding: 0 0.3em;
                font-size: var(--font-size-small);
                line-height: 1.9em;
            }

            @media print {
                .main-outer,
                .main,
                .main-inner {
                    position: static !important;
                    display: block !important;
                    width: auto !important;
                    height: auto !important;
                    max-width: none;
                }

                .department-header {
                    width: 100% !important;
                }

                .employee-row {
                    page-break-inside: avoid;
                }
            }
        `,
    ];

    private _renderTimeEntry(
        entry: TimeEntry,
        dep: Department,
        date: DateString,
        blocked?: boolean,
        stackSize?: number
    ) {
        const department = (entry.position && entry.position.departmentId) || dep.id;
        const otherDep = !!entry.position && entry.position.departmentId !== dep.id;
        const allowDrag = !otherDep && !blocked;
        const issues = this._issues.filter((issue) => issue.timeEntries.some((e) => e.id === entry.id));
        return html`
            <ptc-roster-entry
                id="entry-${entry.id}-${dep.id}"
                draggable="${allowDrag ? "true" : "false"}"
                .entry=${entry}
                .error=${!!issues.length}
                .department=${dep}
                .venue=${this._venue!}
                .stackSize=${stackSize}
                .condensed=${app.settings.rosterCondensedView}
                class="tiny ${this._activeEntry === entry.id && this._activeDepartment === dep.id ? "selected" : ""}"
                @remove=${() => this._removeTimeEntry(entry)}
                @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
                @select=${({ detail: { field } }: CustomEvent<{ field: string }>) =>
                    this._setActive({ date, employee: entry.employeeId, department, entry: entry.id, field })}
                style="margin-top: ${Math.min(stackSize || 1, 3) + 2}px;"
            ></ptc-roster-entry>
        `;
    }

    private _renderUnassigned(dep: DepartmentData) {
        if (!app.settings.rosterDisplayUnassigned) {
            return;
        }
        return html`
            <div class="unassigned-row">
                <div class="row employee-row">
                    <div class="employee-header horizontal start-aligning layout">
                        <i class="huge user-slash"></i>
                        <div class="stretch horizontally-margined">
                            <div class="bold">${dep.department.name}</div>
                            <div>Nicht Zugewiesen</div>
                        </div>
                    </div>
                    ${dep.unassigned.map(({ date, entries }) => {
                        const stacks = new Map<string, TimeEntry[]>();
                        for (const entry of entries) {
                            const key = `${entry.position?.id}_${entry.startPlanned}_${entry.endPlanned}_${entry.isPublished}`;
                            if (!stacks.has(key)) {
                                stacks.set(key, []);
                            }
                            stacks.get(key)?.push(entry);
                        }

                        return html`
                            <div
                                class=${classMap({
                                    "employee-day": true,
                                    today: date === toDateString(new Date()),
                                    active:
                                        date === this._activeDate &&
                                        null === this._activeEmployee &&
                                        dep.department.id === this._activeDepartment,
                                })}
                                @click=${() =>
                                    this._setActive({
                                        employee: null,
                                        department: dep.department.id,
                                        date,
                                        entry: null,
                                    })}
                                @dragenter=${this._dragenter}
                                @dragleave=${this._dragleave}
                                @dragover=${this._dragover}
                                @drop=${(e: DragEvent) => this.dropIntoDay(e, null, dep.department, date)}
                            >
                                ${[...stacks.values()].map((entries) =>
                                    this._renderTimeEntry(entries[0], dep.department, date, false, entries.length)
                                )}
                                <button
                                    ?hidden=${!!entries.length}
                                    class="transparent add-button ${this._activeDepartment === dep.department.id &&
                                    this._activeDate === date &&
                                    this._activeEmployee === null &&
                                    this._activeEntry === "new"
                                        ? "selected"
                                        : ""}"
                                    @click=${(e: Event) => {
                                        e.stopPropagation();
                                        this._setActive({
                                            employee: null,
                                            department: dep.department.id,
                                            date,
                                            entry: "new",
                                        });
                                    }}
                                >
                                    <i class="plus"></i>
                                </button>
                            </div>
                        `;
                    })}
                </div>
            </div>
        `;
    }

    private _renderDepartment(dep: DepartmentData) {
        if (
            !this._activeTab ||
            (this._activeTab.departments && !this._activeTab.departments.includes(dep.department.id))
        ) {
            return;
        }
        const employees = dep.employees.filter(
            ({
                employeeData: {
                    employee: { name, staffNumber },
                },
            }) => `${name} ${staffNumber}`.toLowerCase().includes(this._filterString.toLowerCase())
        );
        const collapsed = !employees.length || (!this._filterString && this._collapsed.has(dep.department.id));
        const dragPosition = this._dragData && this._dragData.entry && this._dragData.entry.position;
        return html`
            <div
                class="department ${collapsed ? "collapsed" : ""}"
                style="--color-highlight: ${colors[dep.department.color] || dep.department.color}"
                ?disabled=${dragPosition && dragPosition.departmentId !== dep.department.id}
            >
                <div class="department-header" @click=${() => this._toggleDepartment(dep)}>
                    ${dep.department.name.toUpperCase()}
                    <i class="angle-${collapsed ? "right" : "down"}"></i>
                </div>

                <div class="department-body">
                    ${employees.map((emp, i) => {
                        const maxEntries = Math.max(...emp.days.map((d) => d.entries.length), 1);
                        const entryHeight = app.settings.rosterCondensedView ? 2.1 : 3.2;
                        const rowHeight = entryHeight * maxEntries + 0.2;
                        return html`
                            <ptc-roster-row-legacy
                                .employee=${emp.employeeData}
                                .days=${emp.days}
                                .department=${dep}
                                .venue=${this._venue!}
                                .activeDate=${this._activeDate}
                                .activeDepartment=${this._activeDepartment}
                                .activeEmployee=${this._activeEmployee}
                                .activeEntry=${this._activeEntry}
                                .activeTab=${this._activeTab}
                                .issues=${this._issues}
                                .canMoveUp=${i > 0}
                                .canMoveDown=${i < dep.employees.length - 1}
                                @move-up=${() => this._moveEmployee(dep, i, "up")}
                                @move-down=${() => this._moveEmployee(dep, i, "down")}
                                @header-clicked=${() =>
                                    this.go(`employees/${emp.employeeData.employee.id}/time`, {
                                        date: this._date,
                                    })}
                                @select=${({
                                    detail: { date, entry, department, field, instant },
                                }: CustomEvent<{
                                    date?: DateString;
                                    entry?: string | null;
                                    department: number | null;
                                    field?: string | null;
                                    instant?: boolean;
                                }>) =>
                                    this._setActive(
                                        {
                                            date,
                                            department,
                                            entry,
                                            field,
                                            employee: emp.employeeData.employee.id,
                                        },
                                        instant
                                    )}
                                @begindrag=${(e: CustomEvent<{ data: DragData }>) => (this._dragData = e.detail.data)}
                                @remove=${({ detail: { entry } }: CustomEvent<{ entry: TimeEntry }>) =>
                                    this._removeTimeEntry(entry)}
                                @drop-into-day=${({
                                    detail: { dragEvent, department, employee, date },
                                }: CustomEvent<{
                                    dragEvent: DragEvent;
                                    department: Department;
                                    employee: Employee;
                                    date: DateString;
                                }>) => this.dropIntoDay(dragEvent, employee, department, date)}
                                @edit-absence=${(e: CustomEvent<{ absence: Absence }>) =>
                                    this._editAbsence(e.detail.absence)}
                                class="row employee-row"
                                ?disabled=${dragPosition &&
                                !emp.employeeData.employee.positions.some((p) => p.id === dragPosition!.id)}
                                style="height: ${rowHeight}em"
                            ></ptc-roster-row-legacy>
                        `;
                    })}
                    ${this._renderUnassigned(dep)}
                    ${(app.settings.rosterDisplayTargets && this._renderTargets(dep.department)) || null}
                </div>
            </div>
        `;
    }

    private _renderTargets(department: Department) {
        const { from, to } = this._range;

        return html`
            <ptc-roster-targets
                .targets=${this._targets}
                .entries=${this._timeEntries}
                .department=${department}
                .editable=${app.hasPermission(`manage.planning.roster`)}
                .from=${from}
                .to=${to}
                class="noprint"
            ></ptc-roster-targets>
        `;
    }

    private _renderCosts() {
        const range = this._range;
        return this._venue && app.hasPermission(`manage.roster.costs`) && app.settings.rosterDisplayCosts
            ? html`
                  <ptc-roster-costs
                      .venue=${this._venue}
                      .entries=${this._timeEntries}
                      .statements=${this._statements}
                      .from=${range.from}
                      .to=${range.to}
                      class="noprint"
                  ></ptc-roster-costs>
              `
            : "";
    }

    private _renderSideMenu() {
        if (!this._venue) {
            return;
        }

        const templateCount =
            app.shiftTemplates.filter((t) => t.venue === this._venue!.id).length + this._frequentTimesLimit;
        const templates: ShiftTemplate[] = [
            { type: TimeEntryType.CompDay },
            { type: TimeEntryType.Free },
            ...this._frequentTimes.filter(({ venue }) => venue === this._venue!.id).slice(0, templateCount),
        ];

        if (app.hasPermission("manage.employees.absences")) {
            templates.unshift({ type: TimeEntryType.Sick }, { type: TimeEntryType.Vacation });
        }

        const unpublishedCount = this._unpublishedCount;

        const positions = this._filteredDepartments.flatMap((d) => d.positions.map((p) => p.id));
        const issues = this._issues.filter((issue) =>
            issue.timeEntries?.some(
                (e) =>
                    e.position &&
                    positions.includes(e.position.id) &&
                    (!this._activeTab.time || e.isWithin(this._activeTab.time))
            )
        );

        const filteredRosterTemplates = this._venue.rosterTemplates.filter((template) =>
            template.shifts.every((shift) => {
                const position = app.getPosition(shift.position)?.position;
                return app.hasAccess({ position });
            })
        );

        return html`
            <button
                class="small margined padded orange box"
                @click=${() => app.updateSettings({ rosterUseNewVersion: true })}
            >
                <i class="sparkles"></i> Neuer Dienstplan Verfügbar - Jetzt Ausprobieren! <i class="arrow-right"></i>
            </button>

            <ptc-date-picker
                class="small week-selector"
                mode="week"
                .value=${this._date}
                @change=${(e: CustomEvent) => this.go(null, { ...this.router.params, date: e.detail.date })}
            ></ptc-date-picker>

            <div class="separator"></div>

            <div class="half-margined grid" style="--grid-column-width: 3em; --grid-gap: 0.1em;">
                <button
                    class="slim ${app.settings.rosterDisplayCosts ? "primary" : "transparent"}"
                    @click=${() => app.updateSettings({ rosterDisplayCosts: !app.settings.rosterDisplayCosts })}
                    title="Kostenanalyse Anzeigen"
                    ?hidden=${!app.hasPermission(`manage.roster.costs`)}
                >
                    <i class="chart-line"></i>
                </button>

                <button
                    class="slim ${app.settings.rosterDisplayTargets ? "primary" : "transparent"}"
                    @click=${() => app.updateSettings({ rosterDisplayTargets: !app.settings.rosterDisplayTargets })}
                    title="Stundenvorgaben Anzeigen"
                >
                    <i class="tasks-alt"></i>
                </button>

                <button
                    title="Abteilungen Spiegeln"
                    class="slim ${app.settings.rosterMirrorDepartments ? "primary" : "transparent"}"
                    @click=${() =>
                        app.updateSettings({ rosterMirrorDepartments: !app.settings.rosterMirrorDepartments })}
                >
                    <i class="clone"></i>
                </button>

                <button
                    title="Kompakte Darstellung"
                    class="slim ${app.settings.rosterCondensedView ? "primary" : "transparent"}"
                    @click=${() => app.updateSettings({ rosterCondensedView: !app.settings.rosterCondensedView })}
                >
                    <i class="compress-arrows-alt"></i>
                </button>

                <button
                    title="Nicht Zugewiesene Schichten Anzeigen"
                    class="slim ${app.settings.rosterDisplayUnassigned ? "primary" : "transparent"}"
                    @click=${() =>
                        app.updateSettings({ rosterDisplayUnassigned: !app.settings.rosterDisplayUnassigned })}
                >
                    <i class="user-slash"></i>
                </button>

                <button
                    title="Verfügbarkeiten Anzeigen"
                    class="slim ${app.settings.rosterDisplayAvailabilities ? "primary" : "transparent"}"
                    @click=${() =>
                        app.updateSettings({ rosterDisplayAvailabilities: !app.settings.rosterDisplayAvailabilities })}
                >
                    <i class="comment-check"></i>
                </button>

                <button
                    title="Schichten Automatisch Zuweisen"
                    class="slim transparent"
                    @click=${this._showAutoAssign}
                    hidden
                >
                    <i class="robot"></i>
                </button>

                <button title="Dienstplan Teilen" class="slim transparent">
                    <i class="share"></i>
                </button>

                <ptc-popover style="width: 23em;">
                    ${until(
                        this._publicUrlWithFilters.then(
                            (url) => html`
                                <div class="large text-centering margined"><i class="share"></i> Dienstplan Teilen</div>

                                ${unpublishedCount
                                    ? html`
                                          <div class="small padded margined orange box">
                                              <i class="exclamation-triangle"></i><strong>Achtung:</strong> Es liegen
                                              <strong>${unpublishedCount}</strong> nicht veröffentlichte Änderungen vor!
                                              Unveröffentlichte Änderungen erscheinen nicht im geteilten Dienstplan!
                                          </div>
                                      `
                                    : ""}

                                <div class="horizontally-margined padded box">
                                    <div class="tiny semibold faded text-centering">
                                        <i class="link"></i> Teilbarer Link:
                                    </div>
                                    <div class="small blue colored-text" style="word-break: break-all;">
                                        <a href="${url}" target="_blank">${url}</a>
                                    </div>
                                </div>

                                <div
                                    class="small top-margined horizontally-margined horizontal evenly stretching layout"
                                >
                                    <button class="slim transparent" type="button" @click=${this._print}>
                                        <i class="print"></i>
                                        Drucken
                                    </button>

                                    <button class="slim transparent" type="button" @click=${() => setClipboard(url)}>
                                        <i class="clipboard"></i>
                                        Kopieren
                                    </button>

                                    <div>
                                        <button
                                            class="slim transparent nowrap"
                                            type="button"
                                            @click=${this._shareViaMessage}
                                            ?disabled=${!app.hasPermission("manage.employees.messages")}
                                        >
                                            <i class="envelope"></i>
                                            Versenden
                                        </button>
                                    </div>

                                    ${!app.hasPermission("manage.employees.messages")
                                        ? html`
                                              <ptc-popover
                                                  class="tooltip"
                                                  trigger="hover"
                                                  non-interactive
                                                  style="width: 15em"
                                              >
                                                  Das Versenden von Nachrichten erfordert die Berechtigung
                                                  <strong>Mitarbeiter / Nachrichten Versenden</strong>.
                                              </ptc-popover>
                                          `
                                        : ""}
                                </div>
                            `
                        ),
                        html`
                            <div class="padded center-aligning center-justifying vertical layout">
                                <ptc-spinner active></ptc-spinner>
                            </div>
                        `
                    )}
                </ptc-popover>

                <button title="Dienstplan Leeren" class="slim transparent">
                    <i class="trash-alt"></i>
                </button>

                <ptc-popover class="clear-roster-dialog" hide-on-leave>
                    <form @submit=${this._clearTimeEntries} @keydown=${(e: Event) => e.stopPropagation()}>
                        <div class="clear-roster-title">Dienstplan Leeren</div>

                        <ptc-checkbox-button
                            label="Geplante Schichten"
                            name="planned"
                            buttonClass="transparent"
                            checked
                        >
                        </ptc-checkbox-button>

                        <ptc-checkbox-button label="Abgeschl. Schichten" name="finished" buttonClass="transparent">
                        </ptc-checkbox-button>

                        <div class="subtle padded box">
                            <i class="info-circle"></i> <strong>Hinweis</strong>: Es werden nur jene Schichten gelöscht,
                            die den <strong>Filterkriterien</strong> des aktuellen Tabs entsprechen.
                            <a
                                href="https://pentacode.app/hilfe/handbuch/dienstplan/schreiben/#dienstplan-leeren"
                                target="_blank"
                            >
                                Mehr Infos ➜
                            </a>
                        </div>

                        <button class="negative">Dienstplan Leeren</button>
                    </form>
                </ptc-popover>
            </div>

            <div class="separator"></div>

            <div class="horizontally-margined vertical layout">
                ${unpublishedCount
                    ? html`
                          <button
                              class="orange slim text-left-aligning box"
                              @click=${() => this._publish()}
                              ?disabled=${!app.hasPermission(`manage.roster.publish`)}
                          >
                              <div class="horizontal layout">
                                  <div class="stretch">
                                      <i class="paper-plane"></i>
                                      <strong>${unpublishedCount}</strong>
                                      ${unpublishedCount > 1 ? "Änderungen" : "Änderung"}
                                  </div>
                              </div>
                          </button>
                      `
                    : html`
                          <button class="green slim text-left-aligning transparent" disabled>
                              <div class="horizontal layout">
                                  <div class="stretch">
                                      <i class="paper-plane"></i>
                                      Veröffentlicht
                                  </div>
                                  <i class="check"></i>
                              </div>
                          </button>
                      `}
            </div>

            <div class="separator"></div>

            <div>
                <div class="horizontally-margined vertical layout">
                    <button
                        class="text-left-aligning transparent slim"
                        @click=${() => (this._displayRosterTemplates = !this._displayRosterTemplates)}
                    >
                        <div class="horizontal layout">
                            <div class="stretch">
                                <i class="save"></i>
                                <strong>${filteredRosterTemplates.length}</strong>
                                ${filteredRosterTemplates.length === 1 ? "Vorlage" : "Vorlagen"}
                            </div>
                            <i class="${this._displayRosterTemplates ? "caret-down" : "caret-right"}"></i>
                        </div>
                    </button>
                </div>

                <ptc-drawer .collapsed=${!this._displayRosterTemplates}>
                    <ptc-scroller style="max-height: 25em">
                        <div class="horizontally-padded">
                            ${filteredRosterTemplates.map((t) => {
                                const departments = this._getTemplateDepartments(t);
                                return html`
                                    <div
                                        class="roster-template"
                                        draggable="true"
                                        @dragstart=${(e: DragEvent) => this._dragstart(e, { rosterTemplate: t })}
                                    >
                                        <div class="bottom-margined ellipsis">${t.name}</div>
                                        <div class="tiny pills">
                                            ${departments.map(
                                                ({ name, color, count }) => html`
                                                    <div
                                                        class="pill"
                                                        style="--color-highlight: ${colors[color] || color}"
                                                    >
                                                        ${name}
                                                        <div class="detail">${count}</div>
                                                    </div>
                                                `
                                            )}
                                        </div>
                                        <button
                                            class="small transparent icon roster-template-delete"
                                            @click=${() => this._deleteRosterTemplate(t)}
                                        >
                                            <i class="trash"></i>
                                        </button>
                                    </div>
                                `;
                            })}
                            <div class="vertical layout">
                                <button class="small subtle" @click=${this._createRosterTemplate}>
                                    <i class="plus"></i>
                                    Neue Vorlage
                                </button>
                            </div>
                        </div>
                    </ptc-scroller>
                </ptc-drawer>
            </div>

            ${issues.length
                ? html`
                      <div class="separator"></div>

                      <div class="horizontally-margined vertical layout">
                          <button
                              class="red text-left-aligning transparent slim"
                              @click=${() => (this._displayIssues = !this._displayIssues)}
                          >
                              <div class="horizontal layout">
                                  <div class="stretch">
                                      <i class="exclamation-triangle"></i>
                                      <strong>${issues.length}</strong> Probleme
                                  </div>
                                  <i class="${this._displayIssues ? "caret-down" : "caret-right"}"></i>
                              </div>
                          </button>
                      </div>

                      <ptc-drawer .collapsed=${!this._displayIssues}>
                          <ptc-scroller class="issues-scroller">
                              <div class="horizontally-padded spacing vertical layout">
                                  ${issues.map(
                                      (issue) => html`
                                          <button
                                              class="small red skinny transparent text-left-aligning box"
                                              @click=${() => this._goToIssue(issue)}
                                          >
                                              <div class="tiny bold">${app.getEmployee(issue.employee!)!.name}</div>
                                              <div>${issue.message}</div>
                                          </button>
                                      `
                                  )}
                              </div>
                          </ptc-scroller>
                      </ptc-drawer>
                  `
                : ""}

            <div class="separator"></div>

            <div class="templates">
                ${templates.map(
                    (t) => html`
                        <div class="shift-template-wrapper" style="--color-highlight: ${timeEntryTypeColor(t.type)}">
                            <div
                                class="shift-template ${this._isFavorite(t) ? "favorite" : ""}"
                                draggable="true"
                                @dragstart=${(e: DragEvent) =>
                                    this._dragstart(e, {
                                        shiftTemplate: t,
                                    })}
                            >
                                ${t.start && t.end
                                    ? `${t.start.substring(0, 5)} - ${t.end.substring(0, 5)}`
                                    : app.localized.timeEntryTypeLabel(t.type)}
                                <i class="star" ?hidden=${!t.start} @click=${() => this._toggleFavorite(t)}></i>
                            </div>
                        </div>
                    `
                )}
                <div class="vertical center-aligning layout">
                    <button
                        class="subtle tiny"
                        ?hidden=${this._frequentTimesLimit >= this._frequentTimes.length}
                        @click=${() => (this._frequentTimesLimit *= 2)}
                    >
                        Mehr Anzeigen
                    </button>
                </div>
            </div>
        `;
    }

    private _renderDayMenu() {
        return html`
            <ptc-employee-day
                class="fullbleed"
                .entries=${this._timeEntries}
                .statements=${this._statements}
                .absences=${this._absences}
                .availabilities=${this._availabilities}
                .activeDate=${this._activeDate!}
                .activeEmployee=${this._activeEmployee!}
                .activeDepartment=${this._activeDepartment!}
                .activeEntry=${this._activeEntry}
                .activeField=${this._activeField}
                .issues=${this._issues}
                .timeFilter=${this._activeTab.time}
                .active=${!!this.active && !!this._activeDate && !!this._activeDepartment}
                @select=${({
                    detail: { date, entry, department, field, instant },
                }: CustomEvent<{
                    date?: DateString;
                    entry?: string | null;
                    department: number | null;
                    field?: string | null;
                    instant?: boolean;
                }>) =>
                    this._setActive(
                        {
                            date,
                            department,
                            entry,
                            field,
                        },
                        instant
                    )}
                @addentry=${(e: CustomEvent<Partial<TimeEntry>>) => this._addTimeEntry(e.detail, true)}
                @updateentry=${(e: CustomEvent<TimeEntry>) => this._updateTimeEntry(e.detail)}
                @input=${() => this._dayInput()}
                @close=${() => this._setActive({ date: null, department: null, entry: null, field: null }, true)}
                @remove=${(e: CustomEvent<{ entry: TimeEntry }>) => this._removeTimeEntry(e.detail.entry)}
                @begindrag=${(e: CustomEvent<{ data: DragData }>) => (this._dragData = e.detail.data)}
                @edit-availability=${(e: CustomEvent<{ availability: Availability }>) =>
                    this._editAvailability(e.detail.availability)}
                @new-availability=${() => this._newAvailability(this._activeEmployee!, this._activeDate!)}
            ></ptc-employee-day>
        `;
    }

    private _renderAutoAssignMenu() {
        return html`<ptc-auto-assign-menu
            class="fullbleed"
            @close=${this._closeAutoAssign}
            @updated=${() => this.refresh()}
        ></ptc-auto-assign-menu>`;
    }

    private _renderHeader() {
        const today = toDateString(new Date());
        return html`
            <div class="roster-header">
                <div class="row">
                    <div class="filter-wrapper">
                        <div class="filter-input right icon input noprint">
                            <input
                                id="filterInput"
                                type="text"
                                placeholder="Suchen..."
                                @input=${this._updateFilter}
                                @keydown=${(e: Event) => e.stopPropagation()}
                            />
                            <i
                                class="${this._filterString ? "times click" : "search"} icon"
                                @click=${this._clearFilter}
                            ></i>
                        </div>
                    </div>

                    ${this._data.dates.map((date) => {
                        const holiday = getHolidayForDate(date, {
                            country: app.company!.country,
                            holidays: app.venues[0]?.enabledHolidays,
                        });
                        return html`
                            <div
                                class=${classMap({
                                    "day-header": true,
                                    holiday: !!holiday,
                                    today: date === today,
                                })}
                                data-date=${date}
                            >
                                ${["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"][
                                    new Date(date).getDay()
                                ]}
                                <div class="day-subheader ellipsis">${holiday ? holiday.name : formatDate(date)}</div>
                                <button
                                    class="skinny top-margined smaller subtle add-roster-note"
                                    ?hidden=${!app.hasPermission(`manage.roster.notes`)}
                                    @click=${() => this._addRosterNote(date)}
                                >
                                    <i class="sticky-note"></i>
                                    Notiz
                                </button>
                            </div>
                        `;
                    })}
                </div>

                <div class="roster-notes">
                    ${this._rosterNoteLanes.map(
                        (notes) =>
                            html` <div class="roster-note-lane">
                                ${notes.map(
                                    (note) => html`
                                        <div class="roster-note" data-roster-note="${note.id || "new"}">
                                            <div
                                                class="roster-note-inner ellipsis click"
                                                @click=${() => this._editRosterNote(note)}
                                                title=${note.text}
                                            >
                                                ${note.text.split("\n")[0]}
                                            </div>
                                        </div>
                                    `
                                )}
                            </div>`
                    )}
                </div>
            </div>
        `;
    }

    render() {
        if (!app.accessibleVenues.length) {
            return html`
                <div class="fullbleed spacing centering vertical layout">
                    <div class="large bold">Nicht so schnell!</div>
                    <div class="padded" style="max-width: 30em;">
                        Bevor Sie mit der Dienstplanung beginnen können müssen Sie zunächst Ihre Standorte und
                        Abteilungen einrichten!
                    </div>
                    <button @click=${() => this.go("settings/venues")}>
                        Arbeitsbereiche Definieren <i class="arrow-right"></i>
                    </button>
                </div>
            `;
        }

        if (!this._venue || !this._data) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner active></ptc-spinner>
                </div>
            `;
        }

        return html`
            <div
                class="fullbleed horizontal layout stretch collapse main-outer ${app.settings.rosterCondensedView
                    ? "condensed"
                    : ""}"
            >
                <div
                    class="main stretch collapse ${app.settings.rosterDisplayCosts ? "show-charts" : ""} ${app.settings
                        .rosterDisplayTargets
                        ? "show-targets"
                        : ""}"
                >
                    <ptc-roster-tabs-legacy
                        .activeIndex=${this._activeTabIndex}
                        class="noprint"
                    ></ptc-roster-tabs-legacy>
                    <div class="main-inner">
                        ${this._renderHeader()} ${this._data.departments.map((dep) => this._renderDepartment(dep))}
                        ${this._renderCosts()}
                    </div>
                </div>

                <div class="menu noprint">
                    ${this._displayAutoAssignMenu
                        ? this._renderAutoAssignMenu()
                        : this._activeDate
                          ? this._renderDayMenu()
                          : this._renderSideMenu()}
                </div>
            </div>

            <ptc-dialog id="createRosterTemplateDialog" class="template-dialog">
                <form
                    id="createRosterTemplateForm"
                    class="double-padded spacing vertical layout"
                    @submit=${this._submitRosterTemplate}
                    @keydown=${(e: Event) => e.stopPropagation()}
                >
                    <div class="big text-centering">Als Vorlage Speichern</div>

                    <input type="text" class="medium" name="name" placeholder="Vorlagenname" required />

                    <div class="subtle padded box">
                        <i class="info-circle"></i> <strong>Hinweis</strong>: Es werden nur jene Schichten gespeichert,
                        für die <strong>Planzeiten</strong> vorliegen und die den <strong>Filterkriterien</strong> des
                        aktuellen Tabs entsprechen.
                        <a
                            href="https://pentacode.app/hilfe/handbuch/dienstplan/schreiben/#dienstplan-vorlagen"
                            target="_blank"
                        >
                            Mehr Infos ➜
                        </a>
                    </div>

                    <div class="spacing evenly stretching horizontal layout">
                        <button class="primary">Speichern</button>
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this._createRosterTemplateDialog.dismiss()}
                        >
                            Abbrechen
                        </button>
                    </div>
                </form>
            </ptc-dialog>

            <ptc-dialog id="applyRosterTemplateDialog" class="template-dialog">
                <form
                    id="applyRosterTemplateForm"
                    @submit=${this._submitApplyRosterTemplate}
                    @keydown=${(e: Event) => e.stopPropagation()}
                    class="double-padded spacing vertical layout"
                >
                    ${this._selectedRosterTemplate
                        ? html`
                              <div class="big text-centering">
                                  Vorlage Anwenden: ${this._selectedRosterTemplate.name}
                              </div>

                              <div class="larger horizontally-padded text-centering">
                                  Möchten Sie diese Dienstplanvorlage anwenden?
                              </div>

                              <div class="subtle text-centering padded box">
                                  <i class="info-circle"></i> <strong>Hinweis</strong>: Es werden nur jene Schichten
                                  übernommen, die den <strong>Filterkriterien</strong> des aktuellen Tabs entsprechen.
                                  <a
                                      href="https://pentacode.app/hilfe/handbuch/dienstplan/schreiben/#dienstplan-vorlagen"
                                      target="_blank"
                                  >
                                      Mehr Infos ➜
                                  </a>
                              </div>

                              <div class="horizontal spacing evenly stretching layout">
                                  <button class="primary">Vorlage Anwenden</button>
                                  <button class="transparent" type="button" @click=${this._dismissApplyRosterTemplate}>
                                      Abbrechen
                                  </button>
                              </div>
                          `
                        : ""}
                </form>
            </ptc-dialog>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}

@customElement("ptc-roster-tabs-legacy")
export class RosterTabs extends StateMixin(LitElement) {
    @property({ type: Number })
    activeIndex: number;

    private get _activeTab() {
        return app.rosterTabs[this.activeIndex];
    }

    @queryAll(".edit-tab-form")
    private _forms: HTMLFormElement[];

    @query("ptc-sortable-list")
    private _list: SortableList<RosterTab>;

    private _updateTab(i: number) {
        if (!app.profile) {
            return;
        }
        const tab = app.rosterTabs[i];
        const form = [...this._forms].find((tab) => Number(tab.dataset.tabIndex) === i);
        const departmentsInput = form?.querySelector(
            "ptc-entity-multi-select[name='departments']"
        ) as EntityMultiSelect<Department>;
        const typesInput = form?.querySelector(
            "ptc-entity-multi-select[name='types']"
        ) as EntityMultiSelect<TimeEntryType>;
        if (!form || !departmentsInput || !typesInput) {
            return;
        }
        const data = new FormData(form);
        const name = data.get("name") as string;
        const from = data.get("from") as string;
        const to = data.get("to") as string;
        const departments = departmentsInput.selected.map((d) => d.id);
        const types = typesInput.selected;

        if (types.includes(TimeEntryType.Sick)) {
            types.push(TimeEntryType.SickInKUG, TimeEntryType.ChildSick);
        }
        tab.departments = departments.length ? departments : undefined;
        tab.name = name;
        if (!tab.time) {
            tab.time = { from: undefined, to: undefined } as { from?: string; to?: string };
        }
        tab.time.from = from || undefined;
        tab.time.to = to || undefined;
        tab.types = types;
        this._update();
    }

    private async _newTab() {
        if (!app.profile) {
            return;
        }

        app.profile.rosterTabs.push({
            name: "Neuer Reiter",
            venue: this._activeTab.venue,
        });
        this._update();
        await this.updateComplete;
        this._selectTab(app.profile.rosterTabs.length - 1);
        const popovers = this.renderRoot!.querySelectorAll("ptc-popover");
        const newPopover = popovers[popovers.length - 1] as Popover;
        if (newPopover) {
            newPopover.show(true);
            const nameInput = newPopover.querySelector("input[name='name']") as HTMLInputElement;
            nameInput && nameInput.select();
        }
    }

    private _removeTab(i: number) {
        if (!app.profile || app.rosterTabs.length < 2) {
            return;
        }
        app.profile.rosterTabs.splice(i, 1);
        this._update();
        if (this.activeIndex === i) {
            this._selectTab(Math.max(i - 1, 0));
        }
    }

    private _update() {
        app.publish();
        this.requestUpdate();
        this._debouncedUpdateProfile();
    }

    private _tabMoved(_tab: RosterTab, i: number) {
        app.profile!.rosterTabs = this._list.items;
        this._update();
        this._selectTab(i);
    }

    private _debouncedUpdateProfile = debounce(
        () =>
            app.api.updateAccount(
                new UpdateAccountParams({
                    profile: {
                        rosterTabs: app.rosterTabs,
                    },
                })
            ),
        3000
    );

    private _selectTab(i: number) {
        if (this.activeIndex !== i) {
            router.go(undefined, { ...router.params, tab: i.toString() });
        }
    }

    // private _clearTime(i: number) {
    //     app.profile!.rosterTabs[i].time = undefined;
    //     this._update();
    // }

    private _selectVenue(e: Event, tab: RosterTab) {
        const venue = Number((e.target as HTMLSelectElement).value);
        tab.venue = venue;
        tab.departments = undefined;
        this._update();
    }

    private _cycleFromRule(i: number) {
        const tab = app.profile!.rosterTabs[i];
        if (!tab.time) {
            tab.time = {} as TimeFilter;
        }
        const rules = ["closed", "open", "equal"];
        const currRule = tab.time.fromRule || "closed";
        tab.time.fromRule = rules[(rules.indexOf(currRule) + 1) % rules.length] as any;
        this._update();
    }

    private _cycleToRule(i: number) {
        const tab = app.profile!.rosterTabs[i];
        if (!tab.time) {
            tab.time = {} as TimeFilter;
        }
        const rules = ["closed", "open", "equal"];
        const currRule = tab.time.toRule || "closed";
        tab.time.toRule = rules[(rules.indexOf(currRule) + 1) % rules.length] as any;
        this._update();
    }

    private _renderTab(tab: RosterTab, i: number) {
        const venue = app.getVenue(tab.venue);

        if (!venue) {
            return;
        }

        const departments = venue.departments.filter((department) => app.hasAccess({ department }));
        const types = [TimeEntryType.Vacation, TimeEntryType.Sick, TimeEntryType.CompDay, TimeEntryType.Free];

        const fromRule = (tab.time && tab.time.fromRule) || "closed";
        const toRule = (tab.time && tab.time.toRule) || "closed";

        return html`
            <div
                class="stretch collapse horizontal start-aligning layout tab ${i === this.activeIndex ? "active" : ""}"
                @click=${() => this._selectTab(i)}
            >
                <div class="stretch collapse">
                    <div class="tab-name ellipsis">${tab.name}</div>
                    <div class="horizontal spacing layout">
                        ${app.venues.length > 1
                            ? html` <div class="pill ellipsis"><i class="inline building"></i> ${venue.name}</div> `
                            : ""}
                        ${tab.time && (tab.time.from || tab.time.to)
                            ? html`
                                  <div class="black pill">
                                      <i class="inline clock"></i> ${tab.time.from
                                          ? tab.time.from.slice(0, 5)
                                          : "offen"}
                                      - ${tab.time.to ? tab.time.to.slice(0, 5) : "offen"}
                                  </div>
                              `
                            : ""}
                        ${!tab.departments || tab.departments.length === departments.length
                            ? html` <div class="pill">Alle Abteilungen</div> `
                            : tab.departments.length <= 5
                              ? tab.departments.map((id) => {
                                    const { department } = app.getDepartment(id);
                                    return (
                                        department &&
                                        html`
                                            <div
                                                class="pill ellipsis"
                                                style="--color-highlight: ${colors[department.color] ||
                                                department.color}"
                                            >
                                                ${department.name}
                                            </div>
                                        `
                                    );
                                })
                              : html`<div class="pill ellipsis">
                                    <i class="inline filter"></i> ${tab.departments.length} Abteilungen
                                </div>`}
                        ${types.map((t) =>
                            tab.types && !tab.types.includes(t)
                                ? html`
                                      <div
                                          class="pill strike-through ellipsis"
                                          style="--color-highlight: ${timeEntryTypeColor(t)}"
                                      >
                                          <i class="inline ${app.localized.timeEntryTypeIcon(t)}"></i>
                                      </div>
                                  `
                                : ""
                        )}
                    </div>
                </div>
                <button class="small skinny transparent edit-button">
                    <i class="pencil-alt"></i>
                </button>
                <ptc-popover .preferAlignment=${["bottom-left"]}>
                    <ptc-scroller style="max-height: 80vh;">
                        <form
                            class="edit-tab-form"
                            data-tab-index=${i}
                            @change=${() => this._updateTab(i)}
                            autocomplete="off"
                            @keydown=${(e: Event) => e.stopPropagation()}
                        >
                            <div class="horizontal center-aligning spacing layout">
                                <input
                                    name="name"
                                    placeholder="Reitername"
                                    .value=${tab.name}
                                    autocomplete="nope"
                                    class="stretch"
                                />
                                <button
                                    class="transparent icon"
                                    type="button"
                                    @click=${() => this._removeTab(i)}
                                    ?disabled=${app.rosterTabs.length < 2}
                                >
                                    <i class="trash"></i>
                                </button>
                            </div>

                            ${app.accessibleVenues.length > 1
                                ? html`
                                      <div class="margined semibold subtle">
                                          <i class="smaller building"></i> Standort
                                      </div>

                                      <select @change=${(e: Event) => this._selectVenue(e, tab)} class="venue-selector">
                                          ${app.accessibleVenues.map(
                                              (venue) => html`
                                                  <option .value=${venue.id} ?selected=${tab.venue === venue.id}>
                                                      ${venue.name}
                                                  </option>
                                              `
                                          )}
                                      </select>
                                  `
                                : ""}

                            <div class="margined semibold subtle"><i class="smaller filter"></i> Abteilungen</div>

                            <ptc-entity-multi-select
                                name="departments"
                                .existing=${departments}
                                .getId=${(dep: Department) => dep.id}
                                .getLabel=${(dep: Department) => dep.name}
                                .getColor=${(dep: Department) => colors[dep.color] || dep.color}
                                .getIcon=${() => "people-line"}
                                .selected=${tab.departments
                                    ? tab.departments.map((id) => app.getDepartment(id)!.department)
                                    : []}
                                emptyLabel="Alle Abteilungen"
                                emptyIcon="people-group"
                                addLabel="Filter Hinzufügen..."
                                noOptionsLabel="Keine Abteilung gefunden."
                                @change=${() => this._updateTab(i)}
                            ></ptc-entity-multi-select>

                            <div class="margined semibold subtle"><i class="smaller umbrella-beach"></i> Fehltage</div>

                            <ptc-entity-multi-select
                                name="types"
                                .existing=${types}
                                .getLabel=${(type: TimeEntryType) => app.localized.timeEntryTypeLabel(type)}
                                .getColor=${(type: TimeEntryType) => timeEntryTypeColor(type)}
                                .getIcon=${(type: TimeEntryType) => app.localized.timeEntryTypeIcon(type)}
                                .selected=${tab.types?.filter((t) => types.includes(t)) || types}
                                emptyLabel="Keine Fehltage Anzeigen"
                                emptyIcon="eye-slash"
                                addLabel="Hinzufügen..."
                                noOptionsLabel="Nichts gefunden."
                                @change=${() => this._updateTab(i)}
                            ></ptc-entity-multi-select>

                            <div class="margined semibold subtle"><i class="smaller clock"></i> Zeitraum</div>

                            <div class="end-aligning horizontal layout" style="overflow: hidden;">
                                <button
                                    type="button"
                                    class="skinny transparent interval-button ${fromRule === "equal"
                                        ? "equal"
                                        : fromRule === "open"
                                          ? "open-left"
                                          : "open-right"}"
                                    @click=${() => this._cycleFromRule(i)}
                                ></button>
                                <div class="stretch">
                                    <div class="tiny bottom-margined bold text-centering">
                                        ${fromRule === "equal"
                                            ? `Beginn genau um`
                                            : fromRule === "open"
                                              ? `Ende nach`
                                              : `Beginn nach einschl.`}
                                    </div>
                                    <ptc-time-input .value=${tab.time && tab.time.from} name="from"></ptc-time-input>
                                </div>
                                <div class="time-dash">-</div>
                                <div class="stretch">
                                    <div class="tiny bottom-margined bold text-centering">
                                        ${toRule === "equal"
                                            ? `Ende genau um`
                                            : toRule === "open"
                                              ? `Beginn vor`
                                              : `Ende vor einschl.`}
                                    </div>
                                    <ptc-time-input .value=${tab.time && tab.time.to} name="to"></ptc-time-input>
                                </div>
                                <button
                                    type="button"
                                    class="skinny transparent interval-button ${toRule === "equal"
                                        ? "equal"
                                        : toRule === "open"
                                          ? "open-right"
                                          : "open-left"}"
                                    @click=${() => this._cycleToRule(i)}
                                ></button>
                            </div>
                        </form>
                    </ptc-scroller>
                </ptc-popover>
            </div>
        `;
    }

    static styles = [
        shared,
        Checkbox.styles,
        TimeInput.styles,
        css`
            :host {
                display: block;
                overflow-x: auto;
            }

            .inner {
                background: var(--shade-1);
            }

            .tab {
                border-right: solid 1px var(--shade-1);
                padding: 0.3em 0.3em 0.5em 0.5em;
                min-width: 15em;
                position: relative;
                overflow: hidden;
            }

            .tab .pill {
                font-size: 0.65em;
                max-width: 10em;
                font-weight: 600;
            }

            .tab:not(.active):hover {
                cursor: pointer;
                background: var(--color-bg);
            }

            .tab:not(:hover) .edit-button {
                opacity: 0;
                position: absolute;
                right: 0;
            }

            .tab.active {
                background: var(--color-bg);
                font-weight: 600;
                border-right: solid 1px var(--shade-2);
            }

            .tab-name {
                margin-bottom: 0.3em;
            }

            .checkbox-grid {
                display: grid;
                grid-gap: 0.5em;
                grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
            }

            .divider {
                margin: 0.5em 0;
                font-size: 0.9em;
            }

            ptc-popover {
                z-index: 100;
                width: 30em;
            }

            ptc-time-input input {
                padding: 0.4em;
            }

            select {
                width: 100%;
                font-size: 0.9em;
            }

            .interval-button {
                padding: 0 !important;
            }

            .interval-button::after {
                display: block;
                font-size: 1.5em;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                text-align: center;
            }

            .interval-button.open-right::after {
                content: "﹝";
                margin: 0 0.1em 0 -0.1em;
            }

            .interval-button.open-left::after {
                content: "﹞";
                margin: 0 -0.1em 0 0.1em;
            }

            .interval-button.equal::after {
                content: "=";
            }

            .time-dash {
                font-size: var(--font-size-large);
                margin: 0.3em 0.6em;
            }
        `,
    ];

    render() {
        return html`
            <div class="horizontal scroller inner">
                <div class="horizontal layout">
                    <ptc-sortable-list
                        layout="horizontal"
                        .items=${app.rosterTabs}
                        .renderItem=${(tab: RosterTab, i: number) => this._renderTab(tab, i)}
                        @item-moved=${(e: CustomEvent<{ item: RosterTab; toIndex: number }>) =>
                            this._tabMoved(e.detail.item, e.detail.toIndex)}
                    >
                    </ptc-sortable-list>

                    <div class="padded horizontal center-aligning layout">
                        <button class="small transparent" @click=${this._newTab}><i class="plus"></i></button>
                    </div>
                </div>
            </div>
        `;
    }
}
