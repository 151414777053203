import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { app } from "../init";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import "./avatar";
import "./popover";
import "./scroller";
import { EmployeeSortProperty } from "@pentacode/core/src/filters";
import { live } from "lit/directives/live.js";
import { EntityFiltersEl } from "./entity-filters";

@customElement("ptc-employees-filter")
export class EmployeesFilter extends StateMixin(LitElement) {
    @property({ type: Boolean })
    disableSorting = false;

    @query("ptc-entity-filters")
    private _entityFilters: EntityFiltersEl;

    @query("#sortPropertySelect")
    private _sortPropertySelect: HTMLInputElement;

    private _filtersChanged() {
        app.updateEmployeeFilters({ filters: this._entityFilters.filters });
        this._fireChange();
    }

    private _toggleSortDirection() {
        if (app.employeeFilters.sortDirection === "ascending") {
            app.updateEmployeeFilters({ sortDirection: "descending" });
        } else {
            app.updateEmployeeFilters({ sortDirection: "ascending" });
        }
        this._fireChange();
    }

    private _sortPropertySelected() {
        app.updateEmployeeFilters({
            sortProperty: this._sortPropertySelect.value as EmployeeSortProperty,
        });
        this._fireChange();
    }

    private _fireChange() {
        this.dispatchEvent(new CustomEvent("change", { detail: { filters: app.state.employeeFilters } }));
    }

    static styles = [shared, css``];

    render() {
        return html`
            <div class="center-aligning spacing horizontal layout">
                <ptc-entity-filters
                    .filters=${app.employeeFilters.filters}
                    @change=${this._filtersChanged}
                    class="stretch"
                ></ptc-entity-filters>

                ${app.employeeFilters.sortDirection === "ascending"
                    ? html`
                          <button
                              class="skinny transparent"
                              @click=${this._toggleSortDirection}
                              ?hidden=${this.disableSorting}
                              title="Aufsteigend Sortieren (Klicken zum Ändern)"
                          >
                              <i class="arrow-down-short-wide"></i>
                          </button>
                      `
                    : html`
                          <button
                              class="skinny transparent"
                              @click=${this._toggleSortDirection}
                              ?hidden=${this.disableSorting}
                              title="Absteigend sortieren (Klicken zum Ändern)"
                          >
                              <i class="arrow-down-wide-short"></i>
                          </button>
                      `}

                <div class="rounded select-wrapper click" ?hidden=${this.disableSorting}>
                    <select
                        class="semibold small skinny plain transparent"
                        id="sortPropertySelect"
                        .value=${live(app.employeeFilters.sortProperty)}
                        @change=${this._sortPropertySelected}
                    >
                        <option value="firstName">Vorname</option>
                        <option value="lastName">Nachname</option>
                        <option value="staffNumber">Personalnummer</option>
                        <option value="birthday">Geburtstag</option>
                        <option value="birthday_date">Geburtstag (Datum)</option>
                        <option value="email">Email</option>
                    </select>
                    <i class="caret-down"></i>
                </div>
            </div>
        `;
    }
}
