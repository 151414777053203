import "./employees-profile-form";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import {
    InviteStatus,
    EmployeeStatus,
    employeeStatusLabel,
    employeeStatusColor,
    Role,
} from "@pentacode/core/src/model";
import { UpdateEmployeeParams, DeleteEmployeeParams } from "@pentacode/core/src/api";
import { formatDate } from "@pentacode/core/src/util";
import { ErrorCode } from "@pentacode/core/src/error";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared, mixins } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import "./avatar";
import "./spinner";
import { EmployeeList } from "./employee-list";
import { Checkbox } from "./checkbox";
import { confirm } from "./alert-dialog";
import { PositionsForm } from "./positions-form";
import { DateInput } from "./date-input";
import "./employees-time-single";
import "./employees-ledgers-single";
import "./employees-time-balance-single";
import "./employees-vacation-balance-single";
import "./employees-bonuses-balance-single";
import "./employees-absences-single";
import "./employees-permissions-single";
import "./employees-availabilities-single";
import "./employees-documents-single";
import "./employees-advances-single";
import { singleton } from "../lib/singleton";
import { SendMessageDialog } from "./send-message-dialog";
import { RetireEmployeeDialog } from "./retire-employee-dialog";
import "./employees-contracts-single";
import "./popover";
import { serializeFilters } from "@pentacode/core/src/filters";
import { Permission } from "@pentacode/core/src/permissions";

@customElement("ptc-employees-single")
export class EmployeesSingle extends Routing(StateMixin(LitElement)) {
    static pages = [
        "core_data",
        "time",
        "time_balance",
        "vacation_balance",
        "bonuses_balance",
        "absences",
        "availabilities",
        "documents",
        "advances",
        "vacation",
        "positions",
        "permissions",
        "contracts",
        "tracking",
        "ledgers",
        "unauthorized",
    ];

    routePattern = /^employees\/(?<id>\d+)(?:\/(?<page>[^\/]+))?/;

    get routeTitle() {
        if (!this._employee) {
            return undefined;
        }

        switch (this._page) {
            case "core_data":
                return `Stammdaten: ${this._employee.name}`;
            case "positions":
                return `Positionen: ${this._employee.name}`;
            case "permissions":
                return `Zugänge & Rechte: ${this._employee.name}`;
            case "tracking":
                return `Zeiterfassung: ${this._employee.name}`;
            case "contracts":
                return `Vertrag: ${this._employee.name}`;
        }
    }

    @routeProperty({ type: Number, arg: "id" })
    private _employeeId: number;

    @routeProperty({ arg: "page" })
    private _page: string;

    @query("ptc-employee-list")
    private _employeeList: EmployeeList;

    @query("ptc-profile-form")
    private _profileForm: HTMLFormElement;

    @query("ptc-positions-form")
    private _positions: PositionsForm;

    @state()
    private _loading: boolean = false;

    @state()
    private _selectingEmployee: boolean = false;

    @singleton("ptc-send-message-dialog")
    private _sendMessageDialog: SendMessageDialog;

    @singleton("ptc-retire-employee-dialog")
    private _retireEmployeeDialog: RetireEmployeeDialog;

    private get _employee() {
        return app.getEmployee(this._employeeId)!;
    }

    private get _profileData() {
        return this._profileForm?.data || {};
    }

    private get _positionsData(): { positions: number[] } {
        return this._positions ? { positions: this._positions.positions.map((pos) => pos.id) } : { positions: [] };
    }

    private get _hasProfileChanged() {
        return this._profileForm?.hasChanged;
    }

    private get _hasPositionsChanged() {
        const updatedPositions = this._positions?.positions;
        return (
            updatedPositions &&
            (updatedPositions.length !== this._employee.positions.length ||
                this._employee.positions.some((position, i) => updatedPositions[i]?.id !== position.id))
        );
    }

    private get _hasPermission() {
        const employee = this._employee;
        return app.hasPermissionForEmployee(employee);
    }

    private _hasPagePermission(page: string) {
        // The page name mostly maps directly to the permission name, with a few execptions
        const perm = page
            .replace(/time_balance|vacation_balance|bonuses_balance/i, "ledgers")
            .replace("contracts", "contract");
        return (
            this._hasPermission &&
            (this._employee.status !== EmployeeStatus.Probation ||
                ["core_data", "positions", "documents", "create"].includes(perm)) &&
            app.hasPermission(`manage.employees.${perm}` as Permission)
        );
    }

    async handleRoute() {
        const page = this._page;

        if (!this._hasPermission) {
            if (this._page !== "unauthorized") {
                this.go(`employees/${this._employeeId}/unauthorized`);
            }
            return;
        } else if (!EmployeesSingle.pages.includes(page) || !this._hasPagePermission(page)) {
            const page = EmployeesSingle.pages.find((page) => this._hasPagePermission(page));
            if (page) {
                this.go(page ? `employees/${this._employeeId}/${page}` : "", undefined, true);
            } else {
                this._page = "";
            }
            return;
        }
    }

    async updated(changes: Map<string, unknown>) {
        if (!changes.has("_employeeId") && !changes.has("_page")) {
            return;
        }

        this._resetProfile();
        this._resetPositions();
    }

    private _resetProfile() {
        this._profileForm.init(this._employee);
        this.requestUpdate();
    }

    private _resetPositions() {
        this._positions.positions = [...this._employee.positions];
        this.requestUpdate();
    }

    private async _startSelectEmployee(e: MouseEvent) {
        e.stopPropagation();
        this._selectingEmployee = true;
        await this.updateComplete;
        this._employeeList.clearFilter();
        this._employeeList.focus();
    }

    private _employeeSelected(e: CustomEvent) {
        this.go(`employees/${e.detail.employee.id}/${this._page}`, this.router.params);
        this._selectingEmployee = false;
    }

    private async _submitProfile() {
        if (!this._profileForm.reportValidity()) {
            return;
        }

        const data = this._profileData;

        if (data.email !== this._employee.email) {
            if (
                !!this._employee.accountId &&
                !(await confirm(
                    html`Dieser Mitarbeiter besitzt einen Mitarbeiterzugang mit der Email
                        <strong>${this._employee.email}</strong> Wollen Sie die Emailadresse trotzdem auf
                        <strong>${data.email}</strong> abändern? Der Mitarbeiterzugang wird dann zurückgesetzt und muss
                        erneut eingerichtet werden.`,
                    "Bestätigen",
                    "Abbrechen",
                    { title: "Emailadresse Bearbeiten" }
                ))
            ) {
                return;
            } else if (
                this._employee.invite?.status === InviteStatus.Sent &&
                !(await confirm(
                    html`Es wurde bereits eine Einladung zur Einrichtung eines Mitarbeiterzugangs an die Emailadresse
                        <strong>${this._employee.email}</strong> versandt. Wollen Sie die Emailadresse trotzdem auf
                        <strong>${data.email}</strong> abändern? Die Einladung muss dann erneut versendet werden.`,
                    "Bestätigen",
                    "Abbrechen",
                    { title: "Emailadresse Bearbeiten" }
                ))
            ) {
                return;
            }
        }

        this._update(this._profileData);
    }

    private async _submitPositions(e: FocusEvent) {
        e.preventDefault();
        this._update(this._positionsData);
    }

    private async _updateAvatar({ detail: { image } }: CustomEvent) {
        await this._update({ avatar: image });
    }

    private async _update(data: Partial<UpdateEmployeeParams>, rethrowErrors = false, refresh = true) {
        this._loading = true;
        try {
            await app.updateEmployee(this._employee!, data, refresh);
            this._loading = false;
            this.updated(new Map<string, any>([["_employeeId", this._employeeId]]));
        } catch (e) {
            this._loading = false;
            if (rethrowErrors) {
                throw e;
            } else {
                alert(e.message, { type: "warning" });
            }
        }
    }

    private async _deleteEmployee() {
        if (
            !(await confirm(
                "Sind Sie sicher dass Sie diesen Mitarbeiter löschen möchten? Diese Änderung kann nicht rückgängig gemacht werden!",
                "Löschen",
                "Abbrechen",
                {
                    title: "Mitarbeiter Löschen",
                    type: "destructive",
                }
            ))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.api.deleteEmployee(new DeleteEmployeeParams({ id: this._employee.id }));
        } catch (e) {
            if (e.code === ErrorCode.CONFLICT) {
                const confirmed = await confirm(e.message, "Fortfahren", "Abbrechen", {
                    type: "destructive",
                });

                if (!confirmed) {
                    this._loading = false;
                    return;
                }

                await app.api.deleteEmployee(
                    new DeleteEmployeeParams({ id: this._employee.id, deleteDanglingEntries: true })
                );
            } else {
                alert(e.message, { type: "warning" });
                this._loading = false;
                return;
            }
        }

        this._loading = false;

        app.fetchAccount();

        this.go("employees/all");
    }

    private _goToPage(page: string, params?: { [param: string]: string | number }) {
        this.go(`employees/${this._employeeId}/${page}`, params);
    }

    private async _hireAsFullEmployee() {
        if (
            !(await confirm(
                "Möchten Sie diesen Probemitarbeiter als vollen Mitarbeiter einstellen?",
                "Einstellen",
                "Abbrechen",
                {
                    icon: "handshake-angle",
                    title: "Mitarbeiter Einstellen",
                }
            ))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.updateEmployee(this._employee, { status: EmployeeStatus.Active });
            this._goToPage(`contracts/${this._employee.contracts[0]?.id || "new"}`);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _retireEmployee() {
        this._retireEmployeeDialog.show(this._employee);
    }

    private async _rehireEmployee() {
        if (
            !(await confirm("Möchten Sie diesen Mitarbeiter wiedereinstellen?", "Wiedereinstellen", "Abbrechen", {
                icon: "person-walking-arrow-loop-left",
                title: "Mitarbeiter Wiedereinstellen",
            }))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.updateEmployee(this._employee, { status: EmployeeStatus.Active, scheduledStatusChange: null });
            this._goToPage(`contracts/new`);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("click", () => (this._selectingEmployee = false));
    }

    static styles = [
        shared,
        Checkbox.styles,
        DateInput.styles,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 15em;
                padding: 0;
                position: relative;
            }

            .employee-list {
                ${mixins.fullbleed()};
                background: var(--color-bg);
                z-index: 1;
            }

            .employee-avatar {
                font-size: var(--font-size-enormous);
                align-self: center;
            }

            .employee-name {
                font-size: var(--font-size-medium);
                align-self: center;
                margin: 0.5em 0;
                padding: 0.3em;
                padding-left: 0.5em;
            }

            .employee-positions {
                margin-bottom: 0;
            }

            .sub-view {
                flex: 1;
                min-width: 0;
                position: relative;
                border-right: solid 1px var(--shade-1);
                margin-right: -1px;
            }

            @media print {
                .sub-view {
                    max-width: none;
                    border: none;
                }
            }
        `,
    ];

    render() {
        const emp = this._employee;

        if (!emp) {
            return html``;
        }

        const hasPermission = this._hasPermission;

        return html`
            <div class="sub-menu noprint vertical layout">
                <ptc-employee-list
                    .employee=${this._employee}
                    @change=${this._employeeSelected}
                    class="employee-list"
                    ?hidden=${!this._selectingEmployee}
                >
                </ptc-employee-list>

                <div class="horizontally-margined vertically-padded vertical text-centering layout border-bottom">
                    <div class="horizontal layout">
                        <button
                            class="transparent skinny"
                            @click=${() => this.go(`employees/all/${this._page}`)}
                            title="Alle Mitarbeiter"
                        >
                            <i class="angle-left negatively-margined"></i>
                            <i class="users"></i>
                        </button>

                        <div class="stretch"></div>

                        <button
                            class="transparent skinny"
                            @click=${() => this._employeeList.selectPrevious()}
                            ?disabled=${!this._employeeList?.hasPrevious}
                            title="Vorheriger Mitarbeiter"
                        >
                            <i class="chevron-up"></i>
                        </button>

                        <button
                            class="transparent skinny"
                            @click=${() => this._employeeList.selectNext()}
                            ?disabled=${!this._employeeList?.hasNext}
                            title="Nächster Mitarbeiter"
                        >
                            <i class="chevron-down"></i>
                        </button>

                        <div class="stretch"></div>

                        <button class="transparent skinny">
                            <i class="ellipsis-h"></i>
                        </button>

                        <ptc-popover class="popover-menu" hide-on-click>
                            ${emp.status !== EmployeeStatus.Probation
                                ? html`
                                      <button
                                          class="transparent"
                                          @click=${() =>
                                              this.go("exports/employee_data_pdf", {
                                                  filters: serializeFilters([
                                                      { type: "employeeId", value: this._employeeId },
                                                  ]),
                                              })}
                                          ?disabled=${!hasPermission || !app.hasPermission("manage.employees.contract")}
                                      >
                                          <i class="address-card"></i> Datenblatt Drucken
                                      </button>

                                      <button
                                          class="transparent"
                                          @click=${() => this._sendMessageDialog.show({ employees: [this._employee] })}
                                          ?disabled=${!emp.email || !app.hasPermission("manage.employees.messages")}
                                      >
                                          <i class="envelope"></i> Nachricht Senden
                                      </button>
                                  `
                                : ""}
                            ${emp.status === EmployeeStatus.Retired ||
                            emp.scheduledStatusChange?.status === EmployeeStatus.Retired
                                ? html`
                                      <button
                                          class="transparent"
                                          @click=${() => this._rehireEmployee()}
                                          ?disabled=${!hasPermission || !app.hasPermission("manage.employees.contract")}
                                      >
                                          <i class="person-walking-arrow-loop-left"></i> Wiedereinstellen
                                      </button>
                                  `
                                : emp.status === EmployeeStatus.Probation
                                  ? html`
                                        <button
                                            class="transparent"
                                            @click=${() => this._hireAsFullEmployee()}
                                            ?disabled=${!hasPermission ||
                                            !app.hasPermission("manage.employees.contract")}
                                        >
                                            <i class="handshake-angle"></i> Einstellen
                                        </button>
                                    `
                                  : emp.status === EmployeeStatus.Active
                                    ? html`
                                          <button
                                              class="transparent"
                                              @click=${() => this._retireEmployee()}
                                              ?disabled=${emp.role === Role.Owner ||
                                              !hasPermission ||
                                              !app.hasPermission("manage.employees.contract")}
                                          >
                                              <i class="person-to-door"></i> Entlassen
                                          </button>
                                      `
                                    : ""}

                            <button
                                class="transparent"
                                @click=${() => this._deleteEmployee()}
                                ?disabled=${emp.role === Role.Owner ||
                                !hasPermission ||
                                !app.hasPermission("manage.employees.delete")}
                            >
                                <i class="trash-alt"></i> Mitarbeiter Löschen
                            </button>
                        </ptc-popover>
                    </div>

                    <ptc-avatar
                        .employee=${emp}
                        class="employee-avatar"
                        @edit=${this._updateAvatar}
                        .editable=${hasPermission && app.hasPermission("manage.employees.core_data")}
                    ></ptc-avatar>

                    <button
                        class="center-aligning center-justifying horizontal layout employee-name transparent"
                        @click=${this._startSelectEmployee}
                    >
                        <div class="stretch">${emp.name}</div>
                        <i class="caret-down"></i>
                    </button>

                    <div class="smaller employee-positions pills">
                        ${emp.status !== EmployeeStatus.Active
                            ? html`
                                  <div class="${employeeStatusColor(emp.status)} inverted pill">
                                      ${employeeStatusLabel(emp.status)}
                                  </div>
                              `
                            : ""}
                        ${emp.scheduledStatusChange
                            ? html`
                                  <div class="${employeeStatusColor(emp.scheduledStatusChange.status)} inverted pill">
                                      ${employeeStatusLabel(emp.scheduledStatusChange.status)} ab
                                      ${formatDate(emp.scheduledStatusChange.date)}
                                  </div>
                              `
                            : ""}
                        ${emp.positions
                            .slice(0, 2)
                            .map(
                                (position) => html`
                                    <div class="pill" style="--color-highlight: ${app.getPositionColor(position)}">
                                        ${position.name}
                                    </div>
                                `
                            )}
                        ${emp.positions.length > 2
                            ? html`
                                  <div class="pill">+ ${emp.positions.length - 2}</div>
                                  <ptc-popover class="tooltip" trigger="hover">
                                      <div class="smaller vertical spacing layout text-left-aligning">
                                          ${emp.positions
                                              .slice(2)
                                              .map(
                                                  (position) => html`
                                                      <div
                                                          class="pill"
                                                          style="--color-highlight: ${app.getPositionColor(position)}"
                                                      >
                                                          ${position.name}
                                                      </div>
                                                  `
                                              )}
                                      </div>
                                  </ptc-popover>
                              `
                            : ""}
                    </div>

                    ${emp.status === EmployeeStatus.Probation
                        ? html`
                              <button
                                  class="margined subtle"
                                  @click=${() => this._hireAsFullEmployee()}
                                  ?disabled=${!hasPermission || !app.hasPermission("manage.employees.contract")}
                              >
                                  <i class="handshake-angle"></i> Einstellen
                              </button>
                          `
                        : emp.status === EmployeeStatus.Retired
                          ? html`
                                <button
                                    class="margined subtle"
                                    @click=${() => this._rehireEmployee()}
                                    ?disabled=${!hasPermission || !app.hasPermission("manage.employees.contract")}
                                >
                                    <i class="person-walking-arrow-loop-left"></i> Wiedereinstellen
                                </button>
                            `
                          : ""}
                </div>

                <ptc-scroller class="stretch">
                    <div class="horizontally-margined vertically-padded vertical tabs border-bottom">
                        <button
                            ?active=${this._page === "time"}
                            @click=${() => this._goToPage("time")}
                            ?disabled=${!this._hasPagePermission("time")}
                        >
                            <i class="hourglass-clock"></i>
                            Arbeitszeiten
                        </button>

                        <button
                            ?active=${this._page === "time_balance"}
                            @click=${() => this._goToPage("time_balance")}
                            ?disabled="${!app.hasPermission("manage.employees.ledgers")}"
                        >
                            <i class="scale-unbalanced"></i>
                            Arbeitszeitkonto
                        </button>

                        <button
                            ?active=${this._page === "vacation_balance"}
                            @click=${() => this._goToPage("vacation_balance")}
                            ?disabled=${!app.hasPermission("manage.employees.ledgers")}
                        >
                            <i class="island-tropical"></i>
                            Urlaubskonto
                        </button>

                        <button
                            ?active=${this._page === "bonuses_balance"}
                            @click=${() => this._goToPage("bonuses_balance")}
                            ?disabled=${!app.hasPermission("manage.employees.ledgers")}
                        >
                            <i class="badge-percent"></i>
                            Zuschlagskonto
                        </button>

                        <button
                            ?active=${this._page === "absences"}
                            @click=${() => this._goToPage("absences")}
                            ?disabled=${!this._hasPagePermission("absences")}
                        >
                            <i class="person-to-portal"></i>
                            Abwesenheiten
                        </button>

                        <button
                            ?active=${this._page === "availabilities"}
                            @click=${() => this._goToPage("availabilities")}
                            ?disabled=${!this._hasPagePermission("availabilities")}
                        >
                            <i class="comment-check"></i>
                            Verfügbarkeiten
                        </button>

                        <button
                            ?active=${this._page === "documents"}
                            @click=${() => this._goToPage("documents")}
                            ?disabled=${!this._hasPagePermission("documents")}
                        >
                            <i class="files"></i>
                            Dokumente & Formulare
                        </button>

                        <button
                            ?active=${this._page === "advances"}
                            @click=${() => this._goToPage("advances")}
                            ?disabled=${!this._hasPagePermission("advances")}
                        >
                            <i class="hand-holding-dollar"></i>
                            Vorschüsse
                        </button>

                        <button
                            ?active=${this._page === "ledgers"}
                            @click=${() => this._goToPage("ledgers")}
                            ?disabled=${!this._hasPagePermission("ledgers")}
                        >
                            <i class="scale-unbalanced"></i>
                            Konten (Übersicht)
                        </button>
                    </div>

                    <div class="horizontally-margined vertically-padded vertical tabs border-bottom">
                        <button
                            ?active=${this._page === "core_data"}
                            @click=${() => this._goToPage("core_data")}
                            ?disabled=${!this._hasPagePermission("core_data")}
                        >
                            <i class="address-card"></i>
                            Stammdaten
                        </button>

                        <button
                            ?active=${this._page === "positions"}
                            @click=${() => this._goToPage("positions")}
                            ?disabled=${!this._hasPagePermission("positions")}
                        >
                            <i class="arrows-down-to-people"></i>
                            Positionen
                        </button>

                        <button
                            ?active=${this._page === "permissions"}
                            @click=${() => this._goToPage("permissions")}
                            ?disabled=${!this._hasPagePermission("permissions")}
                        >
                            <i class="user-unlock"></i>
                            Zugänge & Rechte
                        </button>

                        <button
                            ?active=${this._page === "contracts"}
                            @click=${() => this._goToPage("contracts")}
                            ?disabled=${!this._hasPagePermission("contracts")}
                        >
                            <i class="file-contract"></i>
                            Verträge
                        </button>
                    </div>

                    <div class="horizontally-margined vertically-padded vertical tabs">
                        <button
                            @click=${() => this.dispatchEvent(new CustomEvent("newemployee"))}
                            ?disabled=${!this._hasPagePermission("create")}
                        >
                            <i class="plus"></i>
                            Neuer Mitarbeiter
                        </button>
                    </div>
                </ptc-scroller>
            </div>

            <div class="sub-view">
                <ptc-employees-time-single
                    class="fullbleed"
                    ?hidden=${this._page !== "time"}
                ></ptc-employees-time-single>

                <ptc-employees-ledgers-single
                    class="fullbleed"
                    ?hidden=${this._page !== "ledgers"}
                ></ptc-employees-ledgers-single>

                <ptc-employees-time-balance-single
                    class="fullbleed"
                    ?hidden=${this._page !== "time_balance"}
                ></ptc-employees-time-balance-single>

                <ptc-employees-vacation-balance-single
                    class="fullbleed"
                    ?hidden=${this._page !== "vacation_balance"}
                ></ptc-employees-vacation-balance-single>

                <ptc-employees-bonuses-balance-single
                    class="fullbleed"
                    ?hidden=${this._page !== "bonuses_balance"}
                ></ptc-employees-bonuses-balance-single>

                <ptc-employees-absences-single
                    class="fullbleed"
                    ?hidden=${this._page !== "absences"}
                ></ptc-employees-absences-single>

                <ptc-employees-availabilities-single
                    class="fullbleed"
                    ?hidden=${this._page !== "availabilities"}
                ></ptc-employees-availabilities-single>

                <ptc-employees-documents-single
                    class="fullbleed"
                    ?hidden=${this._page !== "documents"}
                ></ptc-employees-documents-single>

                <ptc-employees-advances-single
                    class="fullbleed"
                    ?hidden=${this._page !== "advances"}
                ></ptc-employees-advances-single>

                <div class="core_data fullbleed" ?hidden=${this._page !== "core_data"}>
                    <div class="vertical layout fit-vertically">
                        <ptc-scroller class="stretch">
                            <ptc-profile-form
                                @submit=${this._submitProfile}
                                @input=${() => this.requestUpdate()}
                                @change=${() => this.requestUpdate()}
                                class="double-padded"
                                style="max-width: 50em; margin: 0 auto;"
                            ></ptc-profile-form>
                        </ptc-scroller>

                        <div>
                            <div
                                class="horizontal spacing evenly stretching padded layout"
                                ?hidden=${!this._hasProfileChanged}
                                style="max-width: 50em; margin: 0 auto;"
                            >
                                <button class="primary" ?disabled=${this._loading} @click=${this._submitProfile}>
                                    Speichern
                                </button>
                                <button class="transparent" ?disabled=${this._loading} @click=${this._resetProfile}>
                                    Abbrechen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <ptc-employees-contracts-single class="fullbleed contracts" ?hidden=${this._page !== "contracts"}>
                </ptc-employees-contracts-single>

                <div class="fullbleed positions" ?hidden=${this._page !== "positions"}>
                    <div class="vertical layout fit-vertically">
                        <ptc-scroller class="stretch">
                            <div style="max-width: 50em; margin: 0 auto" class="double-padded">
                                <h2>Positionen / Einsatzgebiete</h2>
                                <ptc-positions-form @change=${() => this.requestUpdate()}></ptc-positions-form>
                            </div>
                        </ptc-scroller>

                        <div
                            class="horizontal centering padded layout"
                            ?hidden=${!this._hasPositionsChanged}
                            ?disabled=${this._loading}
                        >
                            <div class="horizontal spacing evenly stretching layout stretch" style="max-width: 50em;">
                                <button class="primary" @click=${this._submitPositions}>Speichern</button>
                                <button class="transparent" type="button" @click=${this._resetPositions}>
                                    Abbrechen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <ptc-employees-permissions-single class="permissions" ?hidden=${this._page !== "permissions"}>
                </ptc-employees-permissions-single>

                <div class="fullbleed centering vertical layout" ?hidden=${this._page !== "unauthorized"}>
                    <i class="giant subtle hand-paper"></i>
                    <div class="subtle">Dieser Mitarbeiter liegt außerhalb Ihrer Berechtigungen.</div>
                </div>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
