import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { Contract, Employee, EmployeeStatus } from "@pentacode/core/src/model";
import { toDateString } from "@pentacode/core/src/util";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import { ContractForm } from "./contract-form";
import "./contract-form";
import { Avatar } from "./avatar";
import "./spinner";
import { Checkbox } from "./checkbox";
import { PermissionsForm } from "./permissions-form";
import { PositionsForm } from "./positions-form";
import { DateInput } from "./date-input";
import { ProfileForm } from "./employees-profile-form";
import { live } from "lit/directives/live.js";
import { Scroller } from "./scroller";

@customElement("ptc-employees-new")
export class EmployeesNew extends LitElement {
    @state()
    private _page: string;

    @state()
    private _invite: { email: string; message: string } | null = null;

    @state()
    private _employee: Employee;

    @state()
    private _probation = false;

    @query("ptc-profile-form")
    private _profileForm: ProfileForm;

    @query("ptc-contract-form")
    private _contractForm: ContractForm;

    @query("ptc-permissions-form")
    private _permissionsForm: PermissionsForm;

    @query("ptc-positions-form")
    private _positions: PositionsForm;

    @query("ptc-avatar")
    private _avatar: Avatar;

    @query("#setupAccountCheckbox")
    private _setupAccountCheckbox: Checkbox;

    @query("#profileScroller")
    private _profileScroller: Scroller;

    private _pages = ["general", "positions", "contract", "invite", "permissions"];

    private get _setupAccount() {
        return this._setupAccountCheckbox?.checked;
    }

    private get _profileData() {
        return this._profileForm?.data || {};
    }

    private get _accessData() {
        const { permissions, filters, ...rest } = this._permissionsForm?.data || { permissions: [], filters: [] };
        return { access: { permissions, filters }, ...rest };
    }

    private get _positionsData(): { positions: number[] } {
        return this._positions ? { positions: this._positions.positions.map((p) => p.id) } : { positions: [] };
    }

    private get _contractData() {
        const { initialHours, initialVacationDays, ...contract } = this._contractForm.data;
        return { initialHours, initialVacationDays, contract: new Contract(contract) };
    }

    get data() {
        const data = {
            ...this._profileData,
            ...this._accessData,
            ...this._positionsData,
            ...this._contractData,
            avatar: this._avatar.newImage,
            invite: this._invite || undefined,
            status: this._probation ? EmployeeStatus.Probation : EmployeeStatus.Active,
        };
        if (this._invite) {
            data.email = this._invite.email;
        }

        return data;
    }

    async init(employee: Employee) {
        this._employee = Object.assign(new Employee(), {
            ...employee,
            id: undefined,
            positions: employee.positions || [],
        });
        if (!this._profileForm) {
            await this.updateComplete;
        }
        this._profileForm.init(employee);
        this._profileForm.generatePin();
        if (employee.access) {
            this._permissionsForm.filters = employee.access.filters;
            this._permissionsForm.permissions = employee.access.permissions;
        }
        this._positions.positions = employee.positions || [];
        this._contractForm.contract = employee.latestContract || new Contract({ start: toDateString(new Date()) });
        this._probation = employee.status === EmployeeStatus.Probation;
        this._page = "general";
        this._invite = null;
        this._profileScroller.scrollTop = 0;
    }

    private _updateEmployee() {
        Object.assign(this._employee, {
            positions: this._positionsData.positions.map((p) => app.getPosition(p)!.position),
            ...this._profileData,
        });
        this.requestUpdate();
    }

    private async _submitProfile(e: FocusEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (!this._profileForm.reportValidity()) {
            return;
        }
        this._page = "positions";
    }

    private async _submitPositions(e: FocusEvent) {
        e.preventDefault();
        this._page = "contract";
    }

    private _submitInvite(e: FocusEvent) {
        e.preventDefault();
        const data = new FormData(e.target as HTMLFormElement);
        this._invite = this._setupAccount
            ? {
                  email: data.get("email") as string,
                  message: data.get("message") as string,
              }
            : null;
        if (this._setupAccount) {
            this._page = "permissions";
        } else {
            this._submit();
        }
    }

    private async _submitContract() {
        if (!this._contractForm.reportValidity()) {
            return;
        }
        this._invite = null;
        this._page = "invite";
    }

    private async _submit() {
        this.dispatchEvent(new CustomEvent("submit", { detail: { data: this.data } }));
    }

    static styles = [
        shared,
        Checkbox.styles,
        DateInput.styles,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 250px;
                padding: 0;
                position: relative;
            }

            .menu {
                margin: 10px;
            }

            .employee-card {
                margin: 1em;
                padding: 1.4em 0.5em 0.5em 0.5em;
                border: solid 1px var(--shade-2);
                display: flex;
                flex-direction: column;
                text-align: center;
                border-radius: calc(2 * var(--border-radius));
            }

            .employee-avatar {
                font-size: var(--font-size-enormous);
                align-self: center;
            }

            .employee-name {
                font-size: var(--font-size-medium);
                align-self: center;
                margin: 0.5em 0;
                padding: 0.3em;
                padding-left: 0.5em;
            }

            .employee-positions {
                margin-bottom: 0;
            }

            .sub-view {
                flex: 1;
                width: 0;
                position: relative;
                max-width: 1000px;
                border-right: solid 1px var(--shade-1);
                margin-right: -1px;
            }

            .general {
                --form-label-width: 150px;
            }

            .info-text {
                padding: 10px;
                margin: 10px;
            }
        `,
    ];

    render() {
        const emp = this._employee;

        if (!emp) {
            return;
        }

        return html`
            <div class="sub-menu">
                <div class="employee-card">
                    <ptc-avatar .employee=${emp} class="employee-avatar" editable></ptc-avatar>

                    <div class="employee-name">${emp.name || "Neuer Mitarbeiter"}</div>

                    <div class="employee-positions pills">
                        ${emp.positions.map((position) => {
                            const { department } = app.getDepartment(position.departmentId);
                            return html` <div class="pill ${department && department.color}">${position.name}</div> `;
                        })}
                    </div>

                    <ptc-checkbox-button
                        label="Auf Probe"
                        .buttonClass=${"slim transparent"}
                        .checked=${live(this._probation)}
                        @change=${(e: Event) => (this._probation = (e.target as Checkbox).checked)}
                        ?disabled=${["contract", "invite", "permissions"].includes(this._page)}
                    ></ptc-checkbox-button>
                </div>

                <div class="menu vertical tabs">
                    <button
                        ?active=${this._page === "general"}
                        @click=${() => (this._page = "general")}
                        ?disabled=${this._pages.indexOf(this._page) < this._pages.indexOf("general")}
                    >
                        Stammdaten
                    </button>

                    <button
                        ?active=${this._page === "positions"}
                        @click=${() => (this._page = "positions")}
                        ?disabled=${this._pages.indexOf(this._page) < this._pages.indexOf("positions")}
                    >
                        Positionen
                    </button>

                    <button
                        ?active=${this._page === "contract"}
                        @click=${() => (this._page = "contract")}
                        ?disabled=${this._pages.indexOf(this._page) < this._pages.indexOf("contract")}
                        class="${this._probation ? "line-through" : ""}"
                    >
                        Vertrag
                    </button>

                    <button
                        ?active=${this._page === "invite" || this._page === "permissions"}
                        @click=${() => (this._page = "invite")}
                        ?disabled=${this._pages.indexOf(this._page) < this._pages.indexOf("invite")}
                        class="${this._probation ? "line-through" : ""}"
                    >
                        Zugänge & Rechte
                    </button>
                </div>
            </div>

            <div class="sub-view">
                <div class="general" ?hidden=${this._page !== "general"}>
                    <div class="fullbleed vertical layout">
                        <ptc-scroller class="stretch collapse" id="profileScroller">
                            <ptc-profile-form
                                class="double-margined"
                                @submit=${this._submitProfile}
                                @change=${() => this._updateEmployee()}
                                displayHighestStaffNumber
                            ></ptc-profile-form>
                        </ptc-scroller>

                        <div class="horizontal end-justifying padded-medium layout">
                            <button class="ghost" @click=${this._submitProfile}>
                                Positionen
                                <i class="arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="positions" ?hidden=${this._page !== "positions"}>
                    <form id="positionsForm" @submit=${this._submitPositions} @input=${() => this.requestUpdate()}>
                        <div class="fullbleed vertical layout">
                            <ptc-scroller class="stretch">
                                <ptc-positions-form
                                    @change=${() => this._updateEmployee()}
                                    class="double-margined"
                                ></ptc-positions-form>
                            </ptc-scroller>

                            <div class="horizontal padded-medium layout">
                                <button class="ghost" type="button" @click=${() => (this._page = "general")}>
                                    <i class="arrow-left"></i>
                                    Stammdaten
                                </button>

                                <div class="stretch"></div>

                                ${this._probation
                                    ? html`
                                          <button class="primary" @click=${this._submit}>Mitarbeiter Erstellen</button>
                                      `
                                    : html`
                                          <button class="ghost" @click=${this._submitPositions}>
                                              Vertrag
                                              <i class="arrow-right"></i>
                                          </button>
                                      `}
                            </div>
                        </div>
                    </form>
                </div>

                <div class="contract" ?hidden=${this._page !== "contract"}>
                    <div class="fullbleed vertical layout">
                        <ptc-scroller class="stretch">
                            <ptc-contract-form
                                class="padded-full"
                                .employee=${emp}
                                .initial=${true}
                                @submit=${this._submitContract}
                            ></ptc-contract-form>
                        </ptc-scroller>

                        <div class="horizontal padded-medium layout">
                            <button class="ghost" type="button" @click=${() => (this._page = "positions")}>
                                <i class="arrow-left"></i>
                                Positionen
                            </button>

                            <div class="stretch"></div>

                            <button class="ghost" @click=${this._submitContract}>
                                Zugänge & Rechte
                                <i class="arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="invite" ?hidden=${this._page !== "invite"}>
                    <form @submit=${this._submitInvite}>
                        <div class="fullbleed vertical layout">
                            <div class="stretch centering vertical layout" ?hidden=${!!this._invite}>
                                <div style="max-width: 35em">
                                    <div class="double-margined text-centering">
                                        Möchten Sie einen <strong>Mitarbeiterzugang</strong> für diesen Mitarbeiter
                                        einrichten? Der Mitarbeiter erhält dann eine
                                        <strong>Einladung per Email</strong>, die ihn durch die weiteren
                                        Registrierungsschritte führt. Die Berechtigungen des Mitarbeiters können Sie im
                                        nächsten Schritt konfigurieren.
                                    </div>

                                    <ptc-checkbox-button
                                        id="setupAccountCheckbox"
                                        label="Mitarbeiterzugang Einrichten"
                                        buttonClass="ghost"
                                        class="double-margined"
                                        @change=${() => this.requestUpdate()}
                                        .checked=${true}
                                    ></ptc-checkbox-button>

                                    <div ?disabled=${!this._setupAccount}>
                                        <div class="double-margined spacing vertical layout">
                                            <div class="field">
                                                <label>Emailadresse</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Emailadresse"
                                                    ?required=${this._setupAccount}
                                                    class="stretch"
                                                    .value=${this._profileData?.email || ""}
                                                />
                                            </div>
                                            <div class="field">
                                                <label>Nachricht (optional)</label>
                                                <textarea name="message"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="horizontal padded-medium layout">
                                <button class="ghost" type="button" @click=${() => (this._page = "contract")}>
                                    <i class="arrow-left"></i>
                                    Vertrag
                                </button>

                                <div class="stretch"></div>

                                ${this._setupAccount
                                    ? html`
                                          <button class="ghost">
                                              Berechtigungen
                                              <i class="arrow-right"></i>
                                          </button>
                                      `
                                    : html` <button class="primary">Mitarbeiter Erstellen</button> `}
                            </div>
                        </div>
                    </form>
                </div>

                <div class="permissions" ?hidden=${this._page !== "permissions"}>
                    <div class="fullbleed vertical layout">
                        <ptc-permissions-form class="stretch" @change=${() => this.requestUpdate()} isNew>
                        </ptc-permissions-form>

                        <div class="horizontal padded-medium layout">
                            <button class="ghost" type="button" @click=${() => (this._page = "contract")}>
                                <i class="arrow-left"></i>
                                Vertrag
                            </button>

                            <div class="stretch"></div>

                            <button class="primary" @click=${this._submit}>Mitarbeiter Erstellen</button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
