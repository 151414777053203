import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { GetRosterTargetsParams, GetTimeEntriesParams, GetRevenuePredictionsParams } from "@pentacode/core/src/api";
import { RosterTargets, TimeEntry, TimeEntryType, RevenuePrediction, Department } from "@pentacode/core/src/model";
import {
    dateAdd,
    formatDate,
    formatWeekDay,
    getRange,
    parseDateString,
    toDateString,
    formatNumber,
} from "@pentacode/core/src/util";
import { getHolidayForDate } from "@pentacode/core/src/holidays";
import { clone } from "@pentacode/core/src/encoding";
import { app } from "../init";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { shared, colors } from "../styles";
import { alert, confirm } from "./alert-dialog";
import { Checkbox } from "./checkbox";
import "./popover";
import { Balance } from "./balance";
import "./spinner";
import { DateString, Hours } from "@pentacode/openapi/src/units";

@customElement("ptc-planning-roster")
export class PlanningRoster extends Routing(StateMixin(LitElement)) {
    routePattern = /^planning\/roster/;

    get routeTitle() {
        return this.venue ? `Stundenvorgaben: ${this.venue.name}` : undefined;
    }

    readonly preventDismiss = true;

    @state()
    private _currTargets: RosterTargets[] = [];

    @state()
    private _targets: RosterTargets[] = [];

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _revenuePredictions: RevenuePrediction[] = [];

    @state()
    private _calcAverage = false;

    @state()
    private _displayPredictions = false;

    @state()
    private _loading = false;

    @query(".calc-average-form")
    private _calcAverageForm: HTMLFormElement;

    @query("#defaultButton")
    private _defaultButton: Checkbox;

    private get _dates() {
        const { from, to } = getRange(this.date, "week");
        let d = from;
        const dates: DateString[] = [];
        while (d < to) {
            dates.push(d);
            d = dateAdd(d, { days: 1 });
        }
        return dates;
    }

    private get _calcAverageSettings() {
        const dates = this._dates;

        if (!this._calcAverageForm) {
            return {
                weeks: 4,
                adjustments: dates.map((date) => ({
                    date,
                    factor: 0,
                })),
            };
        }

        const data = new FormData(this._calcAverageForm);

        return {
            weeks: Number(data.get("weeks") as string),
            adjustments: dates.map((date) => ({
                date,
                factor: Number(data.get("adjustment-" + date) as string),
            })),
        };
    }

    async handleRoute() {
        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }

        await this._load();
    }

    private async _load() {
        if (!this.venue) {
            return;
        }

        this._loading = true;

        try {
            const currWeek = getRange(this.date, "week");
            const { from } = getRange(dateAdd(this.date, { days: -7 * 13 }), "week");

            const [currTargets, timeEntries, revenuePredictions] = await Promise.all([
                app.api.getRosterTargets(new GetRosterTargetsParams({ venue: this.venue.id, date: this.date })),
                app.api.getTimeEntries(
                    new GetTimeEntriesParams({
                        venue: this.venue.id,
                        from,
                        to: currWeek.from,
                        type: [TimeEntryType.Work],
                    })
                ),
                app.api.getRevenuePredictions(
                    new GetRevenuePredictionsParams({
                        venue: this.venue.id,
                        ...currWeek,
                    })
                ),
            ]);

            this._currTargets = currTargets;
            this._timeEntries = timeEntries;
            this._revenuePredictions = revenuePredictions;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._calcAverage = !this._currTargets.length;
        this._displayPredictions = !!this._revenuePredictions.length;
        await this.updateComplete;
        this._resetCalcAverageSettings();
        this._fillData();

        this._loading = false;
    }

    private _fillExisting() {
        if (!this.venue) {
            return [];
        }

        const targets: RosterTargets[] = [];

        for (const department of this.venue.departments) {
            for (const date of this._dates) {
                const target =
                    this._currTargets.find((t) => t.departmentId === department.id && t.date === date) ||
                    new RosterTargets();
                target.departmentId = department.id;
                target.date = date;
                targets.push(target);
            }
        }

        this._targets = targets;
    }

    private _fillAverage() {
        const settings = this._calcAverageSettings;
        const { from } = getRange(dateAdd(this.date, { days: -7 * settings.weeks }), "week");
        const targets: RosterTargets[] = [];

        for (const dep of this.venue!.departments) {
            for (const date of this._dates) {
                const weekday = parseDateString(date)!.getDay();
                const timeEntries = this._timeEntries.filter(
                    (e) =>
                        e.position &&
                        e.position.departmentId === dep.id &&
                        e.date >= from &&
                        parseDateString(e.date)!.getDay() === weekday
                );

                const weeks = new Set(timeEntries.map((e) => e.date)).size;
                const total = timeEntries.reduce((total, e) => total + e.durationFinal - (e.break || 0), 0);
                const average = weeks ? total / weeks : null;
                const existing = this._currTargets.find((t) => t.departmentId === dep.id && t.date === date);
                const target = existing ? clone(existing) : new RosterTargets();
                const adjustment = settings.adjustments.find((a) => a.date === date) || { factor: 0 };
                target.departmentId = dep.id;
                target.date = date;
                if (average !== null) {
                    target.hours = (average + (average * adjustment.factor) / 100) as Hours;
                }
                targets.push(target);
            }
        }

        this._targets = targets;
    }

    private async _fillData() {
        this._calcAverage ? this._fillAverage() : this._fillExisting();
        await this.updateComplete;
        this._fill();
    }

    private _fill() {
        if (!this.venue) {
            return;
        }
        for (const dep of this.venue.departments) {
            for (const date of this._dates) {
                const target = this._targets.find((t) => t.departmentId === dep.id && t.date === date);
                const input = this.renderRoot!.querySelector(
                    `input[name="hours"][data-department="${dep.id}"][data-date="${date}"]`
                ) as HTMLInputElement;
                input && (input.value = (target && target.hours && target.hours.toFixed(1)) || "");
            }
        }

        this._defaultButton.checked = this._targets.every((t) => !!t.id && t.default);
    }

    private _getRevenuePrediction(dep: Department, date: string) {
        let revenue = 0;
        let hasAny = false;
        for (const p of this._revenuePredictions.filter((p) => p.date === date)) {
            for (const att of p.attributions.filter((a) => a.department === dep.id)) {
                revenue += (p.amount * att.ratio) / 100;
                hasAny = true;
            }
        }
        return hasAny ? revenue : null;
    }

    private _toggleCalcAverage() {
        this._calcAverage = !this._calcAverage;
        this._fillData();
    }

    private _resetCalcAverageSettings() {
        const weekSelector = this.renderRoot!.querySelector("select[name='weeks']") as HTMLSelectElement;
        weekSelector && (weekSelector.value = "4");

        const adjustmentInputs = this.renderRoot!.querySelectorAll(
            "input[name^='adjustment-']"
        ) as NodeListOf<HTMLInputElement>;
        for (const inp of adjustmentInputs) {
            inp.value = "0";
        }
    }

    private async _togglePredictions() {
        if (!this._displayPredictions && !this._revenuePredictions.length) {
            const confirmed = await confirm(
                "Es wurden noch keine Planumsätze für diese Woche definiert!",
                "Umsätze Planen",
                "Abbrechen",
                {
                    icon: "euro-sign",
                    title: "Fehlende Planumsätze",
                }
            );
            if (confirmed) {
                this.go("planning/revenue");
            }
            return;
        }

        this._displayPredictions = !this._displayPredictions;
    }

    private async _submit() {
        if (this._loading || !this.venue) {
            return;
        }

        this._loading = true;

        const targets = this._targets;

        targets.forEach((k) => (k.default = this._defaultButton.checked));

        try {
            await app.api.updateRosterTargets({ updated: targets });
            await this._load();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        shared,
        Checkbox.styles,
        Balance.styles,
        css`
            .scroller {
                flex: 1;
                overflow: auto;
            }

            .scroller-inner {
                padding: 1em 0.5em;
            }

            .row {
                display: flex;
                min-width: 67em;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 2;
                background: var(--color-bg);
            }

            .row > * {
                flex: 1;
                min-width: 0;
            }

            .row > :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .row-header {
                font-weight: 600;
                color: var(--color-highlight);
                padding: 0.2em;
                box-sizing: border-box;
                position: sticky;
                left: 0;
                z-index: 1;
                background: var(--color-bg);
                padding: 0.5em;
                min-width: 12em;
            }

            .form-row {
                display: flex;
            }

            .form-row > .hours {
                flex: 1;
            }

            .form-row > .rev-prod {
                border-left: dashed 1px var(--shade-2);
                display: flex;
                flex-direction: column;
                width: 50px;
                flex: none;
            }

            .form-row .revenue,
            .form-row .productivity {
                flex: 1;
                font-size: 0.7em;
                padding: 0.1em 0.3em;
                box-sizing: border-box;
                min-width: 0;
                text-align: right;
                font-weight: 600;
            }

            .form-row .revenue {
                border-bottom: dashed 1px var(--shade-2);
            }

            .form-row input {
                border-radius: 0;
                border: none;
                text-align: right;
                padding: 0.5em;
                width: 100%;
                height: 100%;
            }

            .form-row input:focus {
                background: var(--blue-bg);
            }

            .day-header {
                font-weight: bold;
                text-align: center;
            }

            .day-header.holiday {
                color: var(--violet);
            }

            .day-subheader {
                font-size: 80%;
                opacity: 0.7;
                margin: 0.1em 0.5em 0.5em 0.5em;
            }

            .day-date {
                font-size: var(--font-size-small);
                padding: 0.3em;
            }

            .form-row.form-labels .revenue,
            .form-row.form-labels .productivity {
                box-sizing: border-box;
                min-width: 0;
                text-align: center;
                font-weight: bold;
            }

            .form-labels > .hours {
                font-size: 0.85em;
            }

            .formula {
                text-align: center;
            }

            .formula > :not(:last-child) {
                margin-right: 0.4em;
            }

            .formula-label {
                margin-left: -0.3em;
                font-size: var(--font-size-tiny);
                color: var(--color-primary);
            }

            .formula-value {
                font-size: var(--font-size-large);
            }

            .average-hours {
                opacity: 0.8;
                font-size: var(--font-size-tiny);
                text-align: center;
                margin: 0.3em 0 -0.3em 0;
            }

            .form-popover {
                max-width: 30em;
                box-shadow: rgba(0, 0, 0, 0.2) 0 0 3px;
            }

            .info-button {
                position: absolute;
                right: 1em;
                top: 1em;
                z-index: 1;
            }

            .row.adjustments-row,
            .row.adjustments-row > * {
                border: none;
            }

            .calc-average-form {
                margin-bottom: 2em;
            }

            .calc-average-weeks {
                margin-bottom: 0.5em;
                font-size: var(--font-size-medium);
                font-weight: 600;
            }

            .calc-average-weeks select {
                padding-top: 0.3em;
                padding-bottom: 0.3em;
                margin: 0 0.5em;
            }

            .calc-average-adjustment input {
                width: 90%;
            }

            .calc-average-adjustment label {
                font-size: var(--font-size-large);
                margin-bottom: 0.5em;
            }
        `,
    ];

    render() {
        if (!this.venue) {
            return html``;
        }

        const dates = this._dates;

        const departments = this.venue.departments;
        return html`
            <div class="fullbleed vertical layout">
                <div class="scroller stretch">
                    <div class="scroller-inner">
                        <form class="calc-average-form" @input=${this._fillData} ?hidden=${!this._calcAverage}>
                            <div class="horizontal center-aligning center-justifying layout calc-average-weeks">
                                <div>Durchschnitt der letzten</div>
                                <select name="weeks">
                                    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                                        (val) => html` <option .value=${val.toString()}>${val}</option> `
                                    )}
                                </select>
                                <div>Wochen</div>
                            </div>

                            <div class="row adjustments-row">
                                <div class="row-header"></div>
                                ${dates.map((date) => {
                                    const adjustment = this._calcAverageSettings.adjustments.find(
                                        (s) => s.date === date
                                    )!;

                                    return html`
                                        <div
                                            class="vertical center-aligning center-justifying layout calc-average-adjustment"
                                        >
                                            <label>
                                                <ptc-balance
                                                    .value=${adjustment.factor}
                                                    .decimals=${0}
                                                    icon="percent"
                                                ></ptc-balance>
                                            </label>

                                            <input
                                                type="range"
                                                min="-100"
                                                max="100"
                                                step="1"
                                                name="adjustment-${date}"
                                                value="0"
                                            />
                                        </div>
                                    `;
                                })}
                            </div>

                            <div class="medium horizontal center-aligning center-justifying layout padded-full" hidden>
                                <button class="transparent">
                                    <i class="arrow-down"></i>
                                    Berechnen
                                    <i class="arrow-down"></i>
                                </button>
                            </div>
                        </form>
                        <div class="row header-row">
                            <div class="row-header"></div>
                            ${dates.map((date) => {
                                const holiday = getHolidayForDate(date, {
                                    country: app.company!.country,
                                    holidays: app.company!.venues[0]?.enabledHolidays,
                                });
                                return html`
                                    <div
                                        class=${classMap({
                                            "day-header": true,
                                            holiday: !!holiday,
                                        })}
                                    >
                                        ${formatWeekDay(date)}
                                        <div class="day-subheader ellipsis">
                                            ${holiday ? holiday.name : formatDate(date)}
                                        </div>

                                        <div class="form-row form-labels" ?hidden=${!this._displayPredictions}>
                                            <div class="hours center-justifying vertical layout">
                                                <div>Stunden</div>
                                            </div>
                                            <div class="rev-prod">
                                                <div class="revenue">Ums. (€)</div>
                                                <div class="productivity">Pr (€/St)</div>
                                            </div>
                                        </div>
                                    </div>
                                `;
                            })}
                        </div>

                        ${departments.map(
                            (dep) => html`
                                <div class="row">
                                    <div
                                        class="row-header ellipsis"
                                        style="--color-highlight: ${colors[dep.color] || dep.color}"
                                    >
                                        ${dep.name}
                                    </div>

                                    ${dates.map((date) => {
                                        const weekDay = parseDateString(date)!.getDay();
                                        const target = this._targets.find(
                                            (t) => t.weekDay === weekDay && t.departmentId === dep.id
                                        )!;
                                        const hours = (target && target.hours) || null;
                                        const revenue = this._getRevenuePrediction(dep, date);
                                        const productivity = hours && revenue ? revenue / hours : null;
                                        return html`
                                            <div>
                                                <form class="form-row">
                                                    <div class="input hours">
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            step="0.1"
                                                            name="hours"
                                                            data-department="${dep.id}"
                                                            data-date="${date}"
                                                            @input=${(e: Event) => {
                                                                target.hours = Number(
                                                                    (e.target as HTMLInputElement).value
                                                                ) as Hours;
                                                                this.requestUpdate();
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        class="rev-prod"
                                                        ?hidden=${!this._displayPredictions || revenue === null}
                                                    >
                                                        <div class="revenue">
                                                            ${revenue === null ? "?" : formatNumber(revenue, 2)}
                                                        </div>
                                                        <div class="productivity">
                                                            ${productivity === null
                                                                ? "?"
                                                                : formatNumber(productivity, 2)}
                                                        </div>
                                                    </div>

                                                    <ptc-popover
                                                        trigger="hover"
                                                        .preferAlignment=${[
                                                            "right",
                                                            "right-bottom",
                                                            "right-top",
                                                            "bottom-left",
                                                        ]}
                                                        class="form-popover"
                                                    >
                                                        <div class="formula horizontal center-aligning layout">
                                                            <div>
                                                                <div class="formula-label">
                                                                    <i class="euro-sign"></i>Umsatz
                                                                </div>
                                                                <div class="formula-value">
                                                                    ${revenue === null
                                                                        ? "?"
                                                                        : `${formatNumber(revenue, 2)} €`}
                                                                </div>
                                                            </div>
                                                            <i class="divide"></i>
                                                            <div>
                                                                <div class="formula-label">
                                                                    <i class="stopwatch"></i>Arbeitsst.
                                                                </div>
                                                                <div class="formula-value">
                                                                    ${hours === null
                                                                        ? "?"
                                                                        : `${formatNumber(hours, 1)} St`}
                                                                </div>
                                                            </div>
                                                            <i class="equals"></i>
                                                            <div>
                                                                <div class="formula-label">
                                                                    <i class="rocket"></i>Produktivität
                                                                </div>
                                                                <div class="formula-value">
                                                                    ${productivity === null
                                                                        ? "?"
                                                                        : `${formatNumber(productivity, 2)} €/St`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ptc-popover>
                                                </form>
                                            </div>
                                        `;
                                    })}
                                </div>
                            `
                        )}
                    </div>
                </div>

                <div class="footer horizontal layout padded-medium pad-children border-top">
                    <ptc-checkbox-button
                        label="Als Standard Verwenden"
                        buttonClass="ghost"
                        id="defaultButton"
                    ></ptc-checkbox-button>
                    <ptc-popover class="tooltip" trigger="hover">
                        Wenn Sie diese Planungsziele als Standard festlegen werden sie in künftigen Wochen automatisch
                        herangezogen und müssen somit nicht jede Woche erneut festgelegt werden. Abweichende
                        Planungsziele können in den jeweiligen Wochen angepasst werden.
                    </ptc-popover>
                    <button class=${this._calcAverage ? "primary" : "transparent"} @click=${this._toggleCalcAverage}>
                        <i class="empty-set"></i>
                    </button>
                    <button
                        class=${this._displayPredictions ? "primary" : "transparent"}
                        @click=${this._togglePredictions}
                    >
                        <i class="euro-sign"></i>
                    </button>
                    <div class="stretch"></div>
                    <button class="transparent" @click=${() => this._load()}>Zurücksetzen</button>
                    <button class="primary" @click=${this._submit}>Speichern</button>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
