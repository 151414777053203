import "./scroller";
import { html, css, TemplateResult, nothing } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { mixins } from "../styles";
import { Dialog } from "./dialog";
import {
    Document,
    fileExtension,
    fileIcon,
    Form,
    FormField,
    FormStatus,
    formStatusColor,
    formStatusLabel,
    MimeType,
} from "@pentacode/core/src/model";
import { EditDocumentDialog } from "./edit-document-dialog";
import { singleton } from "../lib/singleton";
import { alert, confirm } from "./alert-dialog";
import { app } from "../init";
import { downloadFromUrl } from "../lib/util";
import { formatDate } from "@pentacode/core/src/util";
import { clone } from "@pentacode/core/src/encoding";
import { CreateOrUpdateDocumentParams } from "@pentacode/core/src/api";
import "./popover";
import { SignatureDialog } from "./signature-dialog";
import "./pdf-viewer";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { sanitize } from "dompurify";
import { createTableRow } from "@pentacode/core/src/forms";

@customElement("ptc-document-dialog")
export class DocumentDialog extends Dialog<Document, boolean> {
    readonly preventDismiss = true;

    @state()
    private _document: Document;

    @state()
    private _form: Form | null = null;

    @state()
    private _edited = false;

    @singleton("ptc-edit-document-dialog")
    private _editDocumentDialog: EditDocumentDialog;

    @query("form")
    private _formElement: HTMLFormElement;

    @singleton("ptc-signature-dialog")
    private _signatureDialog: SignatureDialog;

    @state()
    private _expandedTableSections = new Set<string>();

    private get _employee() {
        return app.getEmployee(this._document.employeeId);
    }

    show(document: Document) {
        this._document = document;
        this._form = this._document.form && clone(this._document.form);
        this._edited = false;
        return super.show();
    }

    private async _edit() {
        this.hide();
        const edited = await this._editDocumentDialog.show({ document: this._document });
        if (edited) {
            this._edited = true;
            this._document = edited;
        }
        this._show();
    }

    private async _delete() {
        this.hide();

        const confirmed = await confirm(
            "Sind Sie sicher dass Sie dieses Dokument löschen wollen?",
            "Löschen",
            "Abbrechen",
            { title: "Dokument Löschen", type: "destructive" }
        );

        if (confirmed) {
            await app.api.deleteDocument(this._document.id);
            this.done(true);
        } else {
            this._show();
        }
    }

    private async _download() {
        if (!this._document.url) {
            return;
        }
        await downloadFromUrl(this._document.url, `${this._document.name}.${fileExtension(this._document.type)}`);
    }

    private async _saveForm() {
        if (!this._form) {
            return;
        }

        this.loading = true;

        try {
            this._document = await app.api.createOrUpdateDocument(
                new CreateOrUpdateDocumentParams({
                    ...this._document,
                    form: this._form,
                })
            );
            this._edited = true;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._form = this._document.form && clone(this._document.form);

        this.loading = false;
    }

    private async _submitForm(e?: Event) {
        e?.preventDefault();
    }

    private async _finalizeOrShareForm() {
        const choice = await alert(
            "Möchten Sie dieses Formular dem Mitarbeiter zum Bearbeiten freigeben oder direkt in ein Dokument umwandeln?",
            {
                title: "Formular Absenden",
                icon: "paper-plane",
                optionsLayout: "vertical",
                options: [
                    {
                        label: html`<i class="paper-plane"></i> Zum Bearbeiten Freigeben`,
                        buttonClass: "transparent box",
                    },
                    {
                        label: html`<i class="memo-circle-check"></i> Fertigstellen`,
                        buttonClass: "transparent box",
                    },
                ],
            }
        );

        switch (choice) {
            case 0:
                this._shareForm();
                break;
            case 1:
                this._finalizeForm();
                break;
        }
    }

    private async _shareForm() {
        if (!this._form || !this._formElement.checkValidity()) {
            await this._expandSectionsWithInvalidFields();
            return;
        }

        if (!this._employee?.accountId) {
            this.hide();
            await alert("Das Teilen von Formularen ist nur für Mitarbeiter mit Zugang zur Mitarbeiter-App möglich!", {
                title: "Mitarbeiter-Zugang Erforderlich",
                type: "warning",
            });
            this._show();
            return;
        }

        this.loading = true;

        try {
            this._form.status = FormStatus.InProgress;
            this._document = await app.api.createOrUpdateDocument(
                new CreateOrUpdateDocumentParams({
                    ...this._document,
                    form: this._form,
                })
            );
            alert(
                "Das Formular wurde mit dem Mitarbeiter geteilt und kann nun über die Mitarbeiter-App ausgefüllt werden. Der Mitarbeiter wurde per Email informiert.",
                { title: "Formular Freigegeben", type: "success" }
            );
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._form = this._document.form && clone(this._document.form);
        this._edited = true;

        this.loading = false;
    }

    private async _completeForm() {
        if (!this._form || !this._formElement.checkValidity()) {
            await this._expandSectionsWithInvalidFields();
            return;
        }

        this.loading = true;

        try {
            this._form.status = FormStatus.Completed;
            this._document = await app.api.createOrUpdateDocument(
                new CreateOrUpdateDocumentParams({
                    ...this._document,
                    form: this._form,
                })
            );
            this.done(true);
            alert("Das ausgefüllte Formular wurde erfolgreich übermittelt.", {
                title: "Formular Übermittelt",
                type: "success",
            });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._form = this._document.form && clone(this._document.form);
        this.loading = false;
    }

    private async _finalizeForm() {
        const form = this._form;
        const employee = this._employee;
        if (!employee || !form || !this._formElement.checkValidity()) {
            await this._expandSectionsWithInvalidFields();
            return;
        }

        this.loading = true;

        const mappedFields = form.fields.filter((f) => !!f.mapsTo && employee[f.mapsTo] !== f.value);
        const updateEmployee =
            mappedFields.length &&
            (await confirm(
                html`
                    <div>Möchten Sie die folgenden Angaben in die Stammdaten des Mitarbeiters übernehmen?</div>
                    <table
                        class="vertically-margined"
                        style="border-collapse: separate; border-spacing: 0.5em; margin-bottom: 1em;"
                    >
                        ${mappedFields.map(
                            (field) => html`
                                <tr>
                                    <th class="bold margined">${field.label}</th>
                                    <td class="margined">${form!.getHumanReadableValue(field)}</td>
                                </tr>
                            `
                        )}
                    </table>
                `,
                "Ja",
                "Nein",
                { title: "Angaben Übernehmen", icon: "pen-field", optionsLayout: "horizontal" }
            ));

        try {
            if (updateEmployee) {
                const updates: Record<string, string | number | boolean> = {};
                for (const field of mappedFields) {
                    if (field.value) {
                        updates[field.mapsTo!] = field.value;
                    }
                }
                await app.updateEmployee(employee, updates);
            }

            form.status = FormStatus.Finalized;
            this._document = await app.api.createOrUpdateDocument(
                new CreateOrUpdateDocumentParams({
                    ...this._document,
                    form,
                })
            );
            this._edited = true;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._form = this._document.form && clone(this._document.form);

        this.loading = false;
    }

    private async _editManagerSignature() {
        if (!this._form) {
            return;
        }

        this._signatureDialog.requestContactInfo = false;

        const result = await this._signatureDialog.show();
        if (result) {
            this._form.managerSignature = {
                employeeId: app.profile!.id,
                name: app.profile!.firstName + " " + app.profile!.lastName,
                src: result.src,
                time: new Date().toISOString(),
            };
            this.requestUpdate();
        }

        this._show();
    }

    private async _editEmployeeSignature() {
        if (!this._form) {
            return;
        }

        this._signatureDialog.requestContactInfo = false;

        const result = await this._signatureDialog.show();
        if (result) {
            this._form.employeeSignature = {
                employeeId: app.profile!.id,
                name: app.profile!.firstName + " " + app.profile!.lastName,
                src: result.src,
                time: new Date().toISOString(),
            };
            this.requestUpdate();
        }

        this._show();
    }

    private async _editCaretakerSignature() {
        if (!this._form) {
            return;
        }

        this._signatureDialog.requestContactInfo = true;

        const result = await this._signatureDialog.show();
        if (result) {
            this._form.caretakerSignature = {
                name: result.info?.firstName + " " + result.info?.lastName,
                src: result.src,
                info: result.info,
                time: new Date().toISOString(),
            };
            this.requestUpdate();
        }

        this._show();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                background: none;
                box-shadow: none;
                border-radius: 0;
                ${mixins.fullbleed()};
                max-width: none;
            }

            :host([open]) .scrim {
                opacity: 1;
            }

            form,
            .form-actions {
                max-width: 40em;
                width: 100%;
                margin: 0px auto;
                padding: 0.5em;
                box-sizing: border-box;
            }

            select[data-readonly="true"] {
                pointer-events: none;
            }

            .section {
                margin: 2em 0;
            }

            .section-fields {
                display: grid;
                grid-gap: 1em;
                grid-template-columns: minmax(10em, 1fr) minmax(20em, 1fr);
                align-items: center;
            }

            input[type="checkbox"] {
                justify-self: start;
            }

            label {
                padding: 0 0.5em;
            }

            .signature {
                width: 10em;
                height: 5em;
            }

            input[type="date"] {
                text-align: left;
            }

            table {
                width: 100%;
                border-collapse: collapse;
                font-size: var(--font-size-smaller);
            }

            th,
            td {
                padding: 0.5em;
            }

            th {
                font-weight: 600;
            }

            tbody tr {
                border-top: solid 1px var(--shade-2);
            }

            @media (max-width: 30em) {
                .section-fields {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    grid-gap: 0;
                }

                label {
                    padding: 0.5em;
                    margin-top: 0.5em;
                }

                input[type="checkbox"] {
                    align-self: flex-end;
                    margin-top: -1.7em;
                    margin-right: 1em;
                }

                .signatures {
                    flex-direction: column !important;
                    clear: both;
                }

                h1.horizontal.layout {
                    flex-direction: column;
                    gap: 0.3em;
                    align-items: start;
                }
            }

            table td > input {
                width: 100%;
            }
        `,
    ];

    private _renderPreview() {
        if (!this._document?.url) {
            return null;
        }

        switch (this._document.type) {
            case MimeType.JPG:
            case MimeType.PNG:
                return html`
                    <img src="${this._document.url}" style="width: 100%; height: 100%; object-fit: scale-down;" />
                `;
            case MimeType.CSV:
            case MimeType.TXT:
                return html`
                    <iframe
                        src="https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(this._document.url)}"
                        width="100%"
                        height="100%"
                    >
                    </iframe>
                `;
            case MimeType.MSExcel:
            case MimeType.MSExcelLegacy:
            case MimeType.MSWord:
            case MimeType.MSWordLegacy:
                return html`
                    <iframe
                        src="https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            this._document.url
                        )}"
                        width="100%"
                        height="100%"
                    >
                        This is an embedded <a target="_blank" href="http://office.com">Microsoft Office</a> document,
                        powered by <a target="_blank" href="http://office.com/webapps">Office Online</a>.
                    </iframe>
                `;
            case MimeType.PDF:
                return html` <ptc-pdf-viewer class="fullbleed" .src=${this._document.url}></ptc-pdf-viewer> `;
            default:
                return html`
                    <object
                        style="width: 100%; height: 100%;"
                        .type=${this._document.type}
                        .data=${`${this._document.url}#pagemode=0&toolbar=0&statusbar=0&navpanes=0&scrollbar=0&messages=0`}
                    ></object>
                `;
        }
    }

    private _renderField(
        field: FormField,
        sectionIndex: number,
        fieldIndex: number,
        subIndex: string | undefined = undefined,
        readonly = false,
        renderLabel = true
    ): TemplateResult<1> {
        const form = this._form!;
        if (field.condition && !form.checkCondition(field.condition)) {
            return html``;
        }
        const fieldName = `field_${sectionIndex}_${fieldIndex}${subIndex ? `_${subIndex}` : ""}`;
        if (form.status === FormStatus.InProgress && field.managerOnly) {
            readonly = true;
        }
        const required =
            !!field.required &&
            ((field.managerOnly && form.status === FormStatus.Draft) || this._form!.status === FormStatus.InProgress);
        return html`
            ${renderLabel && (field.label || field.description)
                ? html`<label for="${fieldName}">
                      ${field.label}
                      ${field.description ? html`<div class="small subtle">${field.description}</div>` : ""}
                  </label>`
                : ""}
            ${field.type === "boolean"
                ? html`
                      <input
                          type="checkbox"
                          id="${fieldName}"
                          name="${fieldName}"
                          .checked=${field.value as boolean}
                          .readOnly=${readonly}
                          ?required=${required}
                          @change=${(e: Event) => {
                              field.value = (e.target as HTMLInputElement).checked;
                              this.requestUpdate();
                          }}
                          style="justify-self: start"
                      />
                  `
                : field.type === "table" && !!field.data && !!field.headers
                  ? html`${this._renderTableData(field, sectionIndex, fieldIndex, readonly)}`
                  : field.type === "date"
                    ? html` <input
                          type="date"
                          id="${fieldName}"
                          name="${fieldName}"
                          .value=${field.value?.toString() || ""}
                          ?required=${required}
                          @change=${(e: Event) => {
                              field.value = (e.target as HTMLInputElement).value;
                              this.requestUpdate();
                          }}
                          .readOnly=${readonly}
                          .pattern=${field.pattern || `.*`}
                      />`
                    : field.options
                      ? html`
                            <select
                                id="${fieldName}"
                                name="${fieldName}"
                                .value=${field.value?.toString() || ""}
                                @change=${(e: Event) => {
                                    const val = (e.target as HTMLSelectElement).value;
                                    field.value = field.type === "number" ? Number(val) : val;
                                    this.requestUpdate();
                                }}
                                ?required=${required}
                                data-readonly="${readonly}"
                            >
                                <option value="">-- Bitte Wählen --</option>
                                ${field.options.map(
                                    (option) => html`
                                        <option
                                            .value=${option.value.toString()}
                                            ?selected=${option.value === field.value}
                                        >
                                            ${option.label}
                                        </option>
                                    `
                                )}
                            </select>
                        `
                      : field.type === "number"
                        ? html` <input
                              type="number"
                              id="${fieldName}"
                              name="${fieldName}"
                              .value=${field.value?.toString() || ""}
                              ?required=${required}
                              @change=${(e: Event) => {
                                  field.value = (e.target as HTMLInputElement).value;
                                  this.requestUpdate();
                              }}
                              .readOnly=${readonly}
                              .min=${field.min}
                              .max=${field.max}
                              .step=${field.step || "1"}
                          />`
                        : html` <input
                              id="${fieldName}"
                              name="${fieldName}"
                              type="text"
                              .value=${field.value?.toString() || ""}
                              ?required=${required}
                              .readOnly=${readonly}
                              .pattern=${field.pattern || `.*`}
                              @change=${(e: Event) => {
                                  field.value = (e.target as HTMLInputElement).value;
                                  this.requestUpdate();
                              }}
                          />`}
        `;
    }

    private _renderTableData(table: FormField, sectionIndex: number, fieldIndex: number, readonly: boolean) {
        if (!table.data) {
            return nothing;
        }

        const tableIdPart = `field_${sectionIndex}_${fieldIndex}`;
        const addRow = (e: Event) => {
            e.preventDefault();
            table.data!.push(createTableRow(table.headers!));
            this._expandedTableSections.add(`${tableIdPart}_${table.data!.length - 1}`);
            this.requestUpdate();
        };

        const removeRow = (e: Event, rowIndex: number) => {
            e.preventDefault();
            if (table.data?.length === 1) {
                table.data![0] = createTableRow(table.headers!);
            } else {
                table.data!.splice(rowIndex, 1);
            }
            this.requestUpdate();
        };

        const toggleRowVisibility = (id: string) => {
            if (this._expandedTableSections.has(id)) {
                this._expandedTableSections.delete(id);
            } else {
                this._expandedTableSections.add(id);
            }
            this.requestUpdate();
        };

        return html`<div class="padded" style="grid-column: span 2;">
            <label class="bottom-margined">
                ${table.label}
                ${table.description ? html`<div class="small subtle">${table.description}</div>` : nothing}
            </label>
            <div class="box">
                ${table.data?.map((row, rowIndex) => {
                    const dataRowId = `${tableIdPart}_${rowIndex}`;
                    const collapsed = !this._expandedTableSections.has(dataRowId);
                    return html`<div class="border-bottom">
                        <button
                            type="button"
                            class="smaller fill-horizontally transparent text-left-aligning"
                            @click=${() => toggleRowVisibility(dataRowId)}
                        >
                            <div class="horizontal spacing center-aligning layout">
                                <div class="stretch">
                                    ${row.filter((v) => v.required)?.[0]?.value || "Unvollständige Daten"}
                                </div>
                                <i class="smaller ${collapsed ? "chevron-right" : "chevron-down"}"></i>
                            </div>
                        </button>
                        <ptc-drawer .id=${dataRowId} ?collapsed=${collapsed}>
                            <div class="padded vertical stretching layout" style="padding-bottom: 1em;">
                                ${rowIndex > 0
                                    ? html` <button
                                          type="button"
                                          class="smaller subtle red"
                                          style="margin-bottom: 1em;"
                                          @click=${(e: Event) => removeRow(e, rowIndex)}
                                      >
                                          <i class="trash"></i> Eintrag Entfernen
                                      </button>`
                                    : nothing}
                                <div>
                                    <div class="section-fields">
                                        ${row.map((field, fieldOfRowIndex) =>
                                            this._renderField(
                                                field,
                                                sectionIndex,
                                                fieldIndex,
                                                `${rowIndex}_${fieldOfRowIndex}`,
                                                readonly,
                                                true
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ptc-drawer>
                    </div>`;
                })}
                <div class="margined vertical layout">
                    <button
                        class="transparent smaller"
                        type="button"
                        ?disabled=${table.max && table.data!.length >= parseInt(table.max)}
                        @click=${addRow}
                    >
                        <i class="plus"></i> Eintrag Hinzufügen
                    </button>
                </div>
            </div>
        </div>`;
    }

    private _renderFormActions(allowEdit: boolean = true) {
        const form = this._form!;

        switch (form.status) {
            case FormStatus.Draft:
                return html`
                    <div
                        class="padded evenly stretching spacing horizontal layout form-actions"
                        ?disabled=${!allowEdit}
                    >
                        ${form.requiresEmployeeSignature
                            ? html`
                                  <button class="primary" type="button" @click=${this._shareForm}>
                                      <i class="paper-plane"></i> Zum Ausfüllen Freigeben
                                  </button>
                              `
                            : html`
                                  <button class="primary" type="button" @click=${this._finalizeOrShareForm}>
                                      <i class="paper-plane"></i> Absenden
                                  </button>
                              `}

                        <button class="transparent" type="button" @click=${this._saveForm}>
                            <i class="floppy-disk"></i> Zwischenspeichern
                        </button>
                    </div>
                `;
            case FormStatus.InProgress:
                return html`
                    <div
                        class="padded evenly stretching spacing horizontal layout form-actions"
                        ?disabled=${!allowEdit}
                    >
                        <button class="primary" type="button" @click=${this._completeForm}>
                            <i class="paper-plane"></i> Absenden
                        </button>

                        <button class="transparent" type="button" @click=${this._saveForm}>
                            <i class="floppy-disk"></i> Zwischenspeichern
                        </button>
                    </div>
                `;
            case FormStatus.Completed:
                return html`
                    <div
                        class="padded evenly stretching spacing horizontal layout form-actions"
                        ?disabled=${!allowEdit}
                    >
                        <button class="primary" type="button" @click=${this._finalizeForm}>
                            <i class="memo-circle-check"></i> Fertigstellen
                        </button>
                        <button class="transparent" type="button" @click=${this._saveForm}>
                            <i class="floppy-disk"></i> Zwischenspeichern
                        </button>
                    </div>
                `;
        }
    }

    private _renderForm() {
        const form = this._form;

        if (!form) {
            return;
        }

        const isEmployee = this._document.employeeId === app.profile?.id;

        const allowEdit =
            ([FormStatus.Draft, FormStatus.Completed].includes(form.status) &&
                app.hasPermission("manage.employees.documents") &&
                app.hasPermissionForEmployee(this._employee!)) ||
            (form.status === FormStatus.InProgress && isEmployee);

        return html`
            <div class="fullbleed vertical layout">
                <ptc-scroller class="stretch">
                    <form @submit=${this._submitForm}>
                        <h1 class="center-aligning spacing horizontal layout">
                            <div class="stretch">${form.name}</div>
                            <div class="small pill" style="--color-highlight: ${formStatusColor(form.status)}">
                                <i class="pen-field"></i>
                                ${formStatusLabel(form.status)}
                            </div>
                        </h1>
                        <div>${form.description ? html`${unsafeHTML(sanitize(form.description))}` : ""}</div>
                        ${form.sections.map((section, sectionIndex) =>
                            form.checkCondition(section.condition)
                                ? html`
                                      <div class="section">
                                          ${section.title ? html`<h2>${section.title}</h2>` : ""}
                                          <div class="section-fields">
                                              ${section.fields.map((field, fieldIndex) =>
                                                  this._renderField(
                                                      field,
                                                      sectionIndex,
                                                      fieldIndex,
                                                      undefined,
                                                      !allowEdit,
                                                      field.type !== "table"
                                                  )
                                              )}
                                          </div>
                                      </div>
                                  `
                                : ""
                        )}

                        <div class="horizontal layout signatures">
                            ${form.requiresManagerSignature && !isEmployee
                                ? html`
                                      <div
                                          class="relative padded rounded click"
                                          @click=${this._editManagerSignature}
                                          style=${isEmployee ? "pointer-events: none;" : ""}
                                      >
                                          ${form.managerSignature
                                              ? html`
                                                    <img class="signature" .src=${form.managerSignature.src} />
                                                    <div class="padded border-top">
                                                        ${form.managerSignature.name},
                                                        ${formatDate(new Date(form.managerSignature.time))}
                                                    </div>
                                                `
                                              : html`
                                                    <div class="signature"></div>
                                                    <div class="padded border-top">Unterschrift Arbeitgeber</div>
                                                `}
                                          <input
                                              ?required=${form.status === FormStatus.Completed}
                                              .value=${form.managerSignature?.src || ""}
                                              style="opacity: 0; position: absolute; bottom: 0; pointer-events: none;"
                                          />
                                      </div>
                                  `
                                : ""}

                            <div class="stretch"></div>

                            ${form.requiresEmployeeSignature
                                ? html`
                                      <div
                                          class="relative padded rounded click"
                                          @click=${this._editEmployeeSignature}
                                          style=${!isEmployee ? "pointer-events: none;" : ""}
                                      >
                                          ${form.employeeSignature
                                              ? html`
                                                    <img class="signature" .src=${form.employeeSignature.src} />
                                                    <div class="padded border-top">
                                                        ${form.employeeSignature.name},
                                                        ${formatDate(new Date(form.employeeSignature.time))}
                                                    </div>
                                                `
                                              : html`
                                                    <div class="signature"></div>
                                                    <div class="padded border-top">Unterschrift Arbeitnehmer</div>
                                                `}
                                          <input
                                              ?required=${form.status === FormStatus.InProgress}
                                              .value=${form.employeeSignature?.src || ""}
                                              style="opacity: 0; position: absolute; bottom: 0; pointer-events: none;"
                                          />
                                      </div>
                                  `
                                : ""}

                            <div class="stretch"></div>

                            ${form.requiresCaretakerSignature && isEmployee
                                ? html`
                                      <div
                                          class="relative padded rounded click"
                                          @click=${this._editCaretakerSignature}
                                          style=${!isEmployee ? "pointer-events: none;" : ""}
                                      >
                                          ${form.caretakerSignature
                                              ? html`
                                                    <img class="signature" .src=${form.caretakerSignature.src} />
                                                    <div class="padded border-top">
                                                        ${form.caretakerSignature.name},
                                                        ${formatDate(new Date(form.caretakerSignature.time))}
                                                    </div>
                                                `
                                              : html`
                                                    <div class="signature"></div>
                                                    <div class="padded border-top">
                                                        Unterschrift Gesetzlicher Vertreter
                                                    </div>
                                                `}
                                          <input
                                              ?required=${form.status === FormStatus.InProgress}
                                              .value=${form.caretakerSignature?.src || ""}
                                              style="opacity: 0; position: absolute; bottom: 0; pointer-events: none;"
                                          />
                                      </div>
                                  `
                                : ""}
                            <div class="stretch"></div>
                        </div>

                        <div class="giant spacer"></div>
                    </form>
                </ptc-scroller>

                ${this._renderFormActions(allowEdit)}
            </div>
        `;
    }

    renderContent() {
        if (!this._document) {
            return html``;
        }

        return html`
            <div class="fullbleed vertical layout background">
                <div class="horizontally-padded center-aligning horizontal layout border-bottom">
                    <i class="large ${fileIcon(this._document.type)}"></i>
                    <div class="padded stretch collapse">
                        <div class="bold ellipsis">${this._document.name}</div>
                        <div class="tiny pills">
                            <div class="pill"><i class="calendar"></i> ${formatDate(this._document.date, false)}</div>
                            ${this._document.tags.map(
                                (tag) =>
                                    html`<div class="pill" style="--color-highlight: ${tag.color}">
                                        <i class="tag"></i> ${tag.name}
                                    </div>`
                            )}
                            ${this._form
                                ? html`<div
                                      class="pill"
                                      style="--color-highlight: ${formStatusColor(this._form.status)}"
                                  >
                                      <i class="pen-field"></i> ${formStatusLabel(this._form.status)}
                                  </div>`
                                : ""}
                        </div>
                    </div>
                    <button class="slim transparent" @click=${this._download} ?disabled=${!this._document.url}>
                        <i class="download"></i>
                    </button>
                    <button
                        class="slim transparent"
                        @click=${this._edit}
                        ?hidden=${!app.hasPermission("manage.employees.documents")}
                    >
                        <i class="pencil-alt"></i>
                    </button>
                    <button
                        class="slim transparent"
                        @click=${this._delete}
                        ?hidden=${!app.hasPermission("manage.employees.documents")}
                    >
                        <i class="trash"></i>
                    </button>
                    <button class="slim transparent" @click=${() => this.done(this._edited)}>
                        <i class="times"></i>
                    </button>
                </div>

                <div class="stretch relative">${this._form ? this._renderForm() : this._renderPreview()}</div>
            </div>
        `;
    }

    private async _expandSectionsWithInvalidFields() {
        const allInputs = this._formElement.querySelectorAll("input, select");

        //find drawers of all invalid input fields and expand them
        const invalidCollapsedDrawers = Array.from(allInputs)
            .filter((input) => input.id.split("_").length === 5)
            .filter((input) => !(input as HTMLInputElement).checkValidity())
            .map((input) => {
                const [_, sectionId, fieldId, rowId] = input.id.split("_");
                return `field_${sectionId}_${fieldId}_${rowId}`;
            })
            .filter((id) => !this._expandedTableSections.has(id));

        invalidCollapsedDrawers.forEach((id) => this._expandedTableSections.add(id));

        this.requestUpdate();
        await this.updateComplete;

        if (invalidCollapsedDrawers.length) {
            /* the drawer takes some time to expand. If we immmediately report validity, 
            the popover position will be wrong */
            setTimeout(() => {
                this._formElement?.reportValidity();
            }, 100);
        } else {
            this._formElement?.reportValidity();
        }
    }
}
