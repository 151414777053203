import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { getRange, toDurationString, dateAdd } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./date-range-picker";
import "./avatar";
import "./employees-filter";
import "./spinner";
import "./popover";
import { CommitTimesDialog } from "./commit-times-dialog";
import { singleton } from "../lib/singleton";
import { MonthlyStatement, TimeEntryType, timeEntryTypeColor } from "@pentacode/core/src/model";
import { formatFraction } from "../lib/lit-dependent-util";
import { calcTotalHours } from "@pentacode/core/src/hours";
import { virtualize } from "@lit-labs/virtualizer/virtualize.js";
import { matchesFilters, serializeFilters } from "@pentacode/core/src/filters";
import { DateRange } from "packages/core/src/time";
import { BatchHourAdjustmentDialog } from "./batch-hour-adjustment-dialog";

@customElement("ptc-employees-time-all")
export class EmployeesTimeAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/time/;

    get routeTitle() {
        return "Arbeitszeiten: Alle Mitarbeiter";
    }

    @state()
    private _statements: MonthlyStatement[] = [];

    @state()
    private _loading = false;

    @singleton("ptc-commit-times-dialog")
    private _commitTimesDialog: CommitTimesDialog;

    @singleton("ptc-batch-hour-adjustments-dialog")
    private _batchHourAdjustmentDialog: BatchHourAdjustmentDialog;

    protected defaultRange = () => getRange(new Date(), "month");
    protected rangeChanged = () => this.load();

    async load() {
        if (this._loading || !this.dateRange) {
            return;
        }

        this._loading = true;

        try {
            const from = getRange(this.dateRange.from, "month").from;
            const to = getRange(dateAdd(this.dateRange.to, { days: -1 }), "month").to;
            this._statements = await app.getMonthlyStatements({
                from,
                to,
                exclude: [],
                minVersion: "1.24.0",
            });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _getDistinctByType(statements: MonthlyStatement[]) {
        const items = statements
            .flatMap((s) => s.items)
            .filter((item) => item.entry.date >= this.dateFrom && item.entry.date < this.dateTo);

        const work = calcTotalHours(
            items.filter(
                (item) =>
                    item.entry.type === TimeEntryType.Work &&
                    item.entry.position &&
                    matchesFilters(app.employeeFilters.filters, {
                        company: app.company!,
                        employee: app.getEmployee(item.entry.employeeId)!,
                        date: item.entry.date,
                        position: item.entry.position,
                    })
            )
        );
        const vacation = calcTotalHours(items.filter((item) => item.entry.type === TimeEntryType.Vacation));
        const sick = calcTotalHours(
            items.filter((item) =>
                [TimeEntryType.Sick, TimeEntryType.SickInKUG, TimeEntryType.ChildSick].includes(item.entry.type)
            )
        );

        return { work, vacation, sick };
    }

    private async _addBatchHourAdjustment() {
        await this._batchHourAdjustmentDialog.show({
            filters: app.employeeFilters.filters,
        });
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .scroller {
                flex: 1;
                overflow: auto;
            }

            .company {
                text-align: right;
            }

            table {
                border-collapse: separate;
                display: block;
            }

            thead {
                display: block;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            tbody {
                display: block;
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                display: grid;
                grid-template-columns: 1fr 4rem 6rem 4rem 6rem 4rem 6rem;
                align-items: center;
                width: 100%;
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th,
            td {
                padding: 0.5em;
                vertical-align: middle;
            }

            thead {
                font-weight: bold;
                background: var(--color-bg);
                text-align: center;
            }

            thead th[scope="col"] {
                text-align: left;
            }

            tbody.total {
                font-weight: bold;
                font-size: var(--font-size-larger);
                position: sticky;
                top: 4em;
                z-index: 2;
                background: var(--color-bg);
                border-bottom: solid 1px var(--shade-2);
            }
        `,
    ];

    render() {
        const filteredEmployees = app.getFilteredEmployees();
        const total = this._getDistinctByType(
            this._statements.filter((s) => filteredEmployees.some((e) => e.id === s.employeeId))
        );

        return html`
            <div class="center-aligning horizontal layout padded">
                <ptc-date-range-picker
                    @range-selected=${(e: CustomEvent<DateRange>) => this.go(null, e.detail)}
                    .range=${this.dateRange}
                    maxDays=${366}
                ></ptc-date-range-picker>

                <div class="stretch"></div>

                <button class="transparent slim">
                    <i class="plus"></i>
                </button>

                <ptc-popover class="popover-menu" hide-on-click>
                    <button @click=${() => this._addBatchHourAdjustment()} class="small">
                        <i class="book"></i>
                        Zeitbuchung
                    </button>
                </ptc-popover>

                <button
                    class="transparent slim right-margined"
                    title="Arbeitszeiten Exportieren"
                    @click=${() =>
                        this.go("exports/time_sheet", {
                            filters: serializeFilters(app.employeeFilters.filters),
                            ...this.dateRange,
                        })}
                >
                    <i class="download"></i>
                </button>

                <button
                    class="transparent slim right-margined"
                    @click=${() => this._commitTimesDialog.show()}
                    ?hidden=${!app.hasPermission("manage.settings.company")}
                    title="Arbeitzeiten & Konten Festschreiben"
                >
                    <i class="file-lock"></i>
                </button>
            </div>

            <ptc-employees-filter class="border-top border-bottom" disableFreeSearch></ptc-employees-filter>

            <div class="scroller">
                <div class="double-padded">
                    <table class="fill-horizontally">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="colgroup" colspan="2" style="grid-column: span 2;">
                                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Work)}"></i> Arbeit
                                </th>
                                <th
                                    scope="colgroup"
                                    class="number colored-text"
                                    colspan="2"
                                    style="--color-highlight: ${timeEntryTypeColor(
                                        TimeEntryType.Vacation
                                    )}; grid-column: span 2;"
                                >
                                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Vacation)}"></i> Urlaub
                                </th>
                                <th
                                    scope="colgroup"
                                    class="number colored-text"
                                    colspan="2"
                                    style="--color-highlight: ${timeEntryTypeColor(
                                        TimeEntryType.Sick
                                    )}; grid-column: span 2;"
                                >
                                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Sick)}"></i> Krank
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th scope="col" class="smaller">Tage</th>
                                <th scope="col" class="smaller">Stunden</th>
                                <th scope="col" class="smaller">Tage</th>
                                <th scope="col" class="smaller">Stunden</th>
                                <th scope="col" class="smaller">Tage</th>
                                <th scope="col" class="smaller">Stunden</th>
                            </tr>
                        </thead>

                        <tbody class="total">
                            <tr>
                                <th scope="row"><i class="people-group"></i> Gesamt</th>
                                <td>${formatFraction(total.work.days || 0)}</td>
                                <td>${toDurationString(total.work.full)}</td>
                                <td>${formatFraction(total.vacation.days || 0)}</td>
                                <td>${toDurationString(total.vacation.full)}</td>
                                <td>${formatFraction(total.sick.days || 0)}</td>
                                <td>${toDurationString(total.sick.full)}</td>
                            </tr>
                        </tbody>

                        <tbody>
                            ${virtualize({
                                items: filteredEmployees,
                                renderItem: (employee) => {
                                    const statements = this._statements.filter((s) => s.employeeId === employee.id);

                                    const { work, vacation, sick } = this._getDistinctByType(statements);

                                    return html`
                                        <tr
                                            class="click"
                                            @click=${() => this.go(`employees/${employee.id}/time`, this.dateRange!)}
                                        >
                                            <th>
                                                <div class="horizontal center-aligning spacing layout employee-header">
                                                    <ptc-avatar class="tinier" .employee=${employee}></ptc-avatar>
                                                    <div class="stretch collapse ellispis">
                                                        <span class="semibold">
                                                            ${employee.lastName}, ${employee.firstName}
                                                        </span>
                                                        ${employee.staffNumber
                                                            ? html`
                                                                  <span class="subtle">#${employee.staffNumber}</span>
                                                              `
                                                            : ""}
                                                    </div>
                                                </div>
                                            </th>
                                            <td>${formatFraction(work.days!)}</td>
                                            <td>${toDurationString(work.full!)}</td>
                                            <td>${formatFraction(vacation.days!)}</td>
                                            <td>${toDurationString(vacation.full!)}</td>
                                            <td>${formatFraction(sick.days!)}</td>
                                            <td>${toDurationString(sick.full!)}</td>
                                        </tr>
                                    `;
                                },
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
