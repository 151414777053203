import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { RosterTab, TimeEntryType, timeEntryTypeColor, TimeFilter, Department } from "@pentacode/core/src/model";
import { UpdateAccountParams } from "@pentacode/core/src/api";
import { debounce } from "@pentacode/core/src/util";
import { shared, colors } from "../styles";
import { app, router } from "../init";
import { StateMixin } from "../mixins/state";
import "./popover";
import { Popover } from "./popover";
import { Checkbox } from "./checkbox";
import { TimeInput } from "./time-input";
import { EntityMultiSelect } from "./entity-multi-select";
import "./sortable-list";
import { SortableList } from "./sortable-list";

const defaultTypes = [TimeEntryType.Vacation, TimeEntryType.Sick, TimeEntryType.CompDay, TimeEntryType.Free];

@customElement("ptc-roster-tabs")
export class RosterTabs extends StateMixin(LitElement) {
    @property({ type: Number })
    activeIndex: number;

    private get _activeTab() {
        return app.rosterTabs[this.activeIndex];
    }

    @state()
    private _filterString = "";

    @query(".edit-tab-form")
    private _form: HTMLFormElement;

    @query(".form-popover")
    private _formPopover: Popover;

    @query("ptc-sortable-list")
    private _list: SortableList<RosterTab>;

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query("#tabsListPopover")
    private _tabsListPopover: Popover;

    private _updateTab(tab: RosterTab) {
        if (!app.profile) {
            return;
        }
        const form = this._form;
        const departmentsInput = form?.querySelector(
            "ptc-entity-multi-select[name='departments']"
        ) as EntityMultiSelect<Department>;
        const typesInput = form?.querySelector(
            "ptc-entity-multi-select[name='types']"
        ) as EntityMultiSelect<TimeEntryType>;
        if (!form || !departmentsInput || !typesInput) {
            return;
        }
        const data = new FormData(form);
        const name = data.get("name") as string;
        const from = data.get("from") as string;
        const to = data.get("to") as string;
        const departments = departmentsInput.selected.map((d) => d.id);
        const types = typesInput.selected;

        if (types.includes(TimeEntryType.Sick)) {
            types.push(TimeEntryType.SickInKUG, TimeEntryType.ChildSick);
        }
        tab.departments = departments.length ? departments : undefined;
        tab.name = name;
        if (!tab.time) {
            tab.time = { from: undefined, to: undefined } as { from?: string; to?: string };
        }
        tab.time.from = from || undefined;
        tab.time.to = to || undefined;
        tab.types = types;
        this._update();
    }

    private async _newTab() {
        if (!app.profile) {
            return;
        }

        app.profile.rosterTabs.push({
            name: "Neuer Reiter",
            venue: this._activeTab.venue,
        });
        this._update();
        await this.updateComplete;
        this._selectTab(app.profile.rosterTabs.length - 1);
        const popover = this._formPopover;
        if (popover) {
            popover.show(true);
            const nameInput = popover.querySelector("input[name='name']") as HTMLInputElement;
            nameInput && nameInput.select();
        }
    }

    private _removeTab(tab: RosterTab) {
        if (!app.profile || app.rosterTabs.length < 2) {
            return;
        }
        const i = app.profile.rosterTabs.indexOf(tab);
        app.profile.rosterTabs.splice(i, 1);
        this._update();
        if (this.activeIndex === i) {
            this._selectTab(Math.max(i - 1, 0));
        }
        this._formPopover.hide();
    }

    private _update() {
        app.publish();
        this.requestUpdate();
        this._debouncedUpdateProfile();
    }

    private _tabMoved(_tab: RosterTab, i: number) {
        app.profile!.rosterTabs = this._list.items;
        this._update();
        this._selectTab(i);
    }

    private _debouncedUpdateProfile = debounce(
        () =>
            app.api.updateAccount(
                new UpdateAccountParams({
                    profile: {
                        rosterTabs: app.rosterTabs,
                    },
                })
            ),
        3000
    );

    private _selectTab(i: number) {
        if (this.activeIndex !== i) {
            router.go(undefined, { ...router.params, tab: i.toString() });
            this._tabsListPopover.hide();
        }
    }

    // private _clearTime(i: number) {
    //     app.profile!.rosterTabs[i].time = undefined;
    //     this._update();
    // }

    private _selectVenue(e: Event, tab: RosterTab) {
        const venue = Number((e.target as HTMLSelectElement).value);
        tab.venue = venue;
        tab.departments = undefined;
        this._update();
    }

    private _cycleFromRule(tab: RosterTab) {
        if (!tab.time) {
            tab.time = {} as TimeFilter;
        }
        const rules = ["closed", "open", "equal"];
        const currRule = tab.time.fromRule || "closed";
        tab.time.fromRule = rules[(rules.indexOf(currRule) + 1) % rules.length] as any;
        this._update();
    }

    private _cycleToRule(tab: RosterTab) {
        if (!tab.time) {
            tab.time = {} as TimeFilter;
        }
        const rules = ["closed", "open", "equal"];
        const currRule = tab.time.toRule || "closed";
        tab.time.toRule = rules[(rules.indexOf(currRule) + 1) % rules.length] as any;
        this._update();
    }

    private _renderTab(tab: RosterTab, i: number) {
        const fs = this._filterString.toLowerCase();
        if (
            fs &&
            (!tab.name.toLowerCase().includes(fs) || !app.getVenue(tab.venue)?.name?.toLowerCase().includes(fs))
        ) {
            return;
        }

        const venue = app.getVenue(tab.venue);

        if (!venue) {
            return;
        }

        const departments = venue.departments.filter((department) => app.hasAccess({ department }));
        const isActive = i === this.activeIndex;

        return html`
            <div
                class="stretch collapse horizontal start-aligning layout click tab ${isActive ? "active" : ""}"
                @click=${() => this._selectTab(i)}
            >
                <div class="stretch collapse">
                    <div class="tab-name ellipsis">${tab.name}</div>
                    <div class="horizontal spacing layout">
                        ${app.venues.length > 1
                            ? html` <div class="pill ellipsis"><i class="inline building"></i> ${venue.name}</div> `
                            : ""}
                        ${tab.time && (tab.time.from || tab.time.to)
                            ? html`
                                  <div class="black pill">
                                      <i class="inline clock"></i> ${tab.time.from
                                          ? tab.time.from.slice(0, 5)
                                          : "offen"}
                                      - ${tab.time.to ? tab.time.to.slice(0, 5) : "offen"}
                                  </div>
                              `
                            : ""}
                        ${!tab.departments || tab.departments.length === departments.length
                            ? html` <div class="pill">Alle Abteilungen</div> `
                            : tab.departments.length <= 5
                              ? tab.departments.map((id) => {
                                    const { department } = app.getDepartment(id);
                                    return (
                                        department &&
                                        html`
                                            <div
                                                class="pill ellipsis"
                                                style="--color-highlight: ${colors[department.color] ||
                                                department.color}"
                                            >
                                                ${department.name}
                                            </div>
                                        `
                                    );
                                })
                              : html`<div class="pill ellipsis">
                                    <i class="inline filter"></i> ${tab.departments.length} Abteilungen
                                </div>`}
                        ${defaultTypes.map((t) =>
                            tab.types && !tab.types.includes(t)
                                ? html`
                                      <div
                                          class="pill strike-through ellipsis"
                                          style="--color-highlight: ${timeEntryTypeColor(t)}"
                                      >
                                          <i class="inline ${app.localized.timeEntryTypeIcon(t)}"></i>
                                      </div>
                                  `
                                : ""
                        )}
                    </div>
                </div>
                <i class="check" ?hidden=${!isActive}></i>
            </div>
        `;
    }

    private _renderForm(tab: RosterTab = this._activeTab) {
        const venue = app.getVenue(tab.venue);

        if (!venue) {
            return;
        }

        const fromRule = (tab.time && tab.time.fromRule) || "closed";
        const toRule = (tab.time && tab.time.toRule) || "closed";
        const departments = venue.departments.filter((department) => app.hasAccess({ department }));
        return html`
            <div class="padded semibold center-aligning horizontal layout border-bottom">
                <div class="stretch half-padded"><i class="pen-to-square"></i> Dienstplan-Tab Bearbeiten</div>
                <button
                    class="small slim transparent"
                    type="button"
                    @click=${() => this._removeTab(tab)}
                    ?disabled=${app.rosterTabs.length < 2}
                >
                    <i class="trash"></i>
                </button>
            </div>

            <form
                class="double-padded edit-tab-form"
                @change=${() => this._updateTab(tab)}
                autocomplete="off"
                @keydown=${(e: Event) => e.stopPropagation()}
            >
                <div class="field">
                    <label><i class="smaller pen-field"></i> Name</label>
                    <div class="horizontal center-aligning spacing layout">
                        <input
                            name="name"
                            placeholder="Reitername"
                            .value=${tab.name}
                            autocomplete="nope"
                            class="stretch"
                        />
                    </div>
                </div>

                ${app.accessibleVenues.length > 1
                    ? html`
                          <div class="field">
                              <label><i class="smaller building"></i> Standort</label>

                              <select @change=${(e: Event) => this._selectVenue(e, tab)} class="venue-selector">
                                  ${app.accessibleVenues.map(
                                      (venue) => html`
                                          <option .value=${venue.id} ?selected=${tab.venue === venue.id}>
                                              ${venue.name}
                                          </option>
                                      `
                                  )}
                              </select>
                          </div>
                      `
                    : ""}

                <div class="field">
                    <label><i class="smaller filter"></i> Abteilungen</label>

                    <ptc-entity-multi-select
                        name="departments"
                        .existing=${departments}
                        .getId=${(dep: Department) => dep.id}
                        .getLabel=${(dep: Department) => dep.name}
                        .getColor=${(dep: Department) => colors[dep.color] || dep.color}
                        .getIcon=${() => "people-line"}
                        .selected=${tab.departments
                            ? tab.departments.map((id) => app.getDepartment(id)!.department)
                            : []}
                        emptyLabel="Alle Abteilungen"
                        emptyIcon="people-group"
                        addLabel="Filter Hinzufügen..."
                        noOptionsLabel="Keine Abteilung gefunden."
                        @change=${() => this._updateTab(tab)}
                    ></ptc-entity-multi-select>
                </div>

                <div class="field">
                    <label><i class="smaller umbrella-beach"></i> Fehltage</label>

                    <ptc-entity-multi-select
                        name="types"
                        .existing=${defaultTypes}
                        .getLabel=${(type: TimeEntryType) => app.localized.timeEntryTypeLabel(type)}
                        .getColor=${(type: TimeEntryType) => timeEntryTypeColor(type)}
                        .getIcon=${(type: TimeEntryType) => app.localized.timeEntryTypeIcon(type)}
                        .selected=${tab.types?.filter((t) => defaultTypes.includes(t)) || defaultTypes}
                        emptyLabel="Keine Fehltage Anzeigen"
                        emptyIcon="eye-slash"
                        addLabel="Hinzufügen..."
                        noOptionsLabel="Nichts gefunden."
                        @change=${() => this._updateTab(tab)}
                    ></ptc-entity-multi-select>
                </div>

                <div class="field">
                    <label><i class="smaller clock"></i> Zeitraum</label>

                    <div class="end-aligning horizontal layout" style="overflow: hidden;">
                        <button
                            type="button"
                            class="skinny transparent interval-button ${fromRule === "equal"
                                ? "equal"
                                : fromRule === "open"
                                  ? "open-left"
                                  : "open-right"}"
                            @click=${() => this._cycleFromRule(tab)}
                        ></button>
                        <div class="stretch">
                            <div class="tiny bottom-margined bold text-centering">
                                ${fromRule === "equal"
                                    ? `Beginn genau um`
                                    : fromRule === "open"
                                      ? `Ende nach`
                                      : `Beginn nach einschl.`}
                            </div>
                            <ptc-time-input .value=${tab.time && tab.time.from} name="from"></ptc-time-input>
                        </div>
                        <div class="time-dash">-</div>
                        <div class="stretch">
                            <div class="tiny bottom-margined bold text-centering">
                                ${toRule === "equal"
                                    ? `Ende genau um`
                                    : toRule === "open"
                                      ? `Beginn vor`
                                      : `Ende vor einschl.`}
                            </div>
                            <ptc-time-input .value=${tab.time && tab.time.to} name="to"></ptc-time-input>
                        </div>
                        <button
                            type="button"
                            class="skinny transparent interval-button ${toRule === "equal"
                                ? "equal"
                                : toRule === "open"
                                  ? "open-right"
                                  : "open-left"}"
                            @click=${() => this._cycleToRule(tab)}
                        ></button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = [
        shared,
        Checkbox.styles,
        TimeInput.styles,
        css`
            :host {
                display: block;
                overflow-x: auto;
            }

            .tab {
                padding: 0.8em;
                min-width: 15em;
                position: relative;
                overflow: hidden;
                border-bottom: solid 1px var(--shade-1);
            }

            .tab .pill {
                font-size: 0.65em;
                max-width: 10em;
                font-weight: 600;
            }

            .tab:not(:hover) .edit-button {
                opacity: 0;
                position: absolute;
                right: 0;
            }

            .tab.active {
                font-weight: 600;
                color: var(--color-primary);
            }

            .tab-name {
                margin: 0 0.3em 0.5em 0.3em;
            }

            .checkbox-grid {
                display: grid;
                grid-gap: 0.5em;
                grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
            }

            .divider {
                margin: 0.5em 0;
                font-size: 0.9em;
            }

            ptc-popover {
                z-index: 100;
                width: 30em;
            }

            ptc-time-input input {
                padding: 0.4em;
            }

            select {
                width: 100%;
                font-size: 0.9em;
            }

            .interval-button {
                padding: 0 !important;
            }

            .interval-button::after {
                display: block;
                font-size: 1.5em;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                text-align: center;
            }

            .interval-button.open-right::after {
                content: "﹝";
                margin: 0 0.1em 0 -0.1em;
            }

            .interval-button.open-left::after {
                content: "﹞";
                margin: 0 -0.1em 0 0.1em;
            }

            .interval-button.equal::after {
                content: "=";
            }

            .time-dash {
                font-size: var(--font-size-large);
                margin: 0.3em 0.6em;
            }

            .filter-indicator {
                position: absolute;
                right: 0.5em;
                bottom: 0.5em;
                width: 1.1em;
                height: 1.1em;
                background: var(--orange);
                border-radius: 1.1em;
                color: var(--color-bg);
            }

            .filter-wrapper i.search {
                color: var(--shade-5);
            }

            .filter-wrapper:focus-within i.search {
                color: var(--color-primary);
            }
        `,
    ];

    render() {
        const activeTab = this._activeTab;
        return html`
            <button class="slim ghost">
                <div class="horizontal half-spacing center-aligning layout text-left-aligning" style="width: 15em;">
                    <i class="rectangle-history"></i>
                    <div class="stretch collapse ellipsis">${activeTab?.name}</div>
                    <i class="angle-down"></i>
                </div>
            </button>

            <ptc-popover
                style="padding: 0; width: 20em;"
                id="tabsListPopover"
                @popover-hide=${() => (this._filterString = this._filterInput.value = "")}
            >
                <div class="padded semibold border-bottom center-aligning horizontal layout">
                    <div class="stretch half-padded"><i class="rectangle-history"></i> Dienstplan-Tabs</div>
                    <button class="small slim transparent" @click=${this._newTab}><i class="plus"></i></button>
                </div>

                <div class="padded filter-wrapper border-bottom" ?hidden=${app.rosterTabs.length < 6}>
                    <i class="search"></i>
                    <input
                        class="plain stretch"
                        id="filterInput"
                        type="text"
                        placeholder="Suchen..."
                        @input=${() => (this._filterString = this._filterInput.value)}
                        @keydown=${(e: Event) => e.stopPropagation()}
                    />
                </div>

                <ptc-scroller style="max-height: 80vh;">
                    <div class="vertical layout">
                        <ptc-sortable-list
                            .items=${app.rosterTabs}
                            .renderItem=${(tab: RosterTab, i: number) => this._renderTab(tab, i)}
                            @item-moved=${(e: CustomEvent<{ item: RosterTab; toIndex: number }>) =>
                                this._tabMoved(e.detail.item, e.detail.toIndex)}
                            ?locked=${!!this._filterString}
                        >
                        </ptc-sortable-list>

                        <div class="padded vertical layout" hidden>
                            <button class="small slim transparent" @click=${this._newTab}><i class="plus"></i></button>
                        </div>
                    </div>
                </ptc-scroller>
            </ptc-popover>

            <button class="slim transparent relative">
                <i class="filter"></i> ${activeTab?.departments?.length ||
                (activeTab?.types && defaultTypes.some((t) => !activeTab?.types!.includes(t))) ||
                activeTab?.time?.from ||
                activeTab?.time?.to
                    ? html` <div class="tinier filter-indicator">&nbsp;</div> `
                    : ""}
            </button>

            <ptc-popover class="form-popover unpadded">
                <ptc-scroller style="max-height: 80vh;"> ${this._renderForm()} </ptc-scroller>
            </ptc-popover>
        `;
    }
}
