import { RevenueEntry, RevenueType, TimeEntry, TimeEntryType } from "@pentacode/core/src/model";
import {
    toDateString,
    formatNumber,
    dateAdd,
    formatDateShort,
    formatWeekDayShort,
    getRange,
} from "@pentacode/core/src/util";
import { MonthlyStatement } from "@pentacode/core/src/statement";
import { calcAllHours, calcTotalHours } from "@pentacode/core/src/hours";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { ChartConfig } from "./chart";
import "./scroller";
import "./spinner";
import { Balance } from "./balance";
import { Permission } from "@pentacode/core/src/permissions";
import { DateString } from "@pentacode/openapi/src/units";

interface Chart {
    title: string;
    getData: (date: string) => number;
    unit: string;
    total: "sum" | "average";
    data: {
        curr: number[];
        prev: number[];
    };
    scopes: Permission[];
    config?: ChartConfig;
}

@customElement("ptc-reports-overview")
export class ReportsOverview extends Routing(StateMixin(LitElement)) {
    routePattern = /^reports\/overview/;

    get routeTitle() {
        return this.venue ? `Berichte Übersicht: ${this.venue.name}` : undefined;
    }

    @state()
    private _loading = false;

    @state()
    private _salesEntries: RevenueEntry[];

    @state()
    private _timeEntries: TimeEntry[];

    @state()
    private _statements: MonthlyStatement[];

    @state()
    private _range = 12;

    @state()
    private _resolution = 7;

    @state()
    private _showDataPoint: number | null = null;

    @state()
    private _charts: Chart[] = [
        {
            title: "Einnahmen",
            unit: "€",
            getData: (d: string) => this._getSalesData(d),
            total: "sum",
            scopes: ["manage.reports.revenue"],
            data: {
                curr: [],
                prev: [],
            },
        },
        {
            title: "Personalkosten",
            unit: "€",
            getData: (d: string) => this._getCostsData(d),
            total: "sum",
            scopes: ["manage.reports.costs"],
            data: {
                curr: [],
                prev: [],
            },
        },
        {
            title: "Arbeitszeit",
            unit: "Std.",
            getData: (d: string) => this._getHoursData(d),
            total: "sum",
            scopes: ["manage.reports.time"],
            data: {
                curr: [],
                prev: [],
            },
        },
        {
            title: "Produktivität",
            unit: "€/St",
            getData: (d: string) => {
                const sales = this._getSalesData(d);
                const hours = this._getHoursData(d);
                return hours ? sales / hours : 0;
            },
            total: "average",
            scopes: ["manage.reports.time", "manage.reports.revenue"],
            data: {
                curr: [],
                prev: [],
            },
        },
    ];

    @query(".venue-selector")
    private _venueSelector: HTMLSelectElement;

    // @query("#salesChart")
    // private _salesChart: Chart;
    //
    // @query("#costsChart")
    // private _costsChart: Chart;
    //
    // @query("#hoursChart")
    // private _hoursChart: Chart;

    private get _today() {
        return (this.router.params.today as DateString) || toDateString(new Date());
    }

    private get _currRange() {
        const today = this._today;

        const dates: string[] = [];
        const bulkSize = this._range * this._resolution;

        for (let days = -1 * bulkSize; days < 0; days++) {
            dates.push(dateAdd(today, { days }));
        }

        return dates;
    }

    private get _prevRange() {
        const today = this._today;

        const dates: string[] = [];
        const bulkSize = this._range * this._resolution;

        for (let days = -2 * bulkSize; days < -bulkSize; days++) {
            dates.push(dateAdd(today, { days }));
        }

        return dates;
    }

    private get _enabledCharts() {
        return this._charts.filter((c) => c.scopes.every((p) => app.hasPermission(p)));
    }

    async handleRoute() {
        if (!this.venue) {
            if (app.venues.length) {
                this.go(null, { venue: app.venues[0].id.toString() }, true);
            }
            return;
        }

        this.load();
    }

    async load(range = 7, resolution = 1) {
        if (!this.venue) {
            return;
        }

        this._range = range;
        this._resolution = resolution;

        const to = this._today;
        const from = dateAdd(to, { days: -2 * this._range * this._resolution });

        this._loading = true;

        try {
            const [sales, time, statements] = await Promise.all([
                app.api.getRevenueEntries({
                    from,
                    to,
                    venue: this.venueId!,
                    type: [RevenueType.Sales],
                    reporting: true,
                }),
                app.api.getTimeEntries({
                    from,
                    to,
                    venue: this.venueId!,
                }),
                app.api.getMonthlyStatements({
                    employee: app.employees.map((e) => e.id),
                    from: getRange(from, "month").from,
                    to: getRange(to, "month").to,
                }),
            ]);

            this._salesEntries = sales;
            this._timeEntries = time;
            this._statements = statements;
            this._updateCharts();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _selectVenue() {
        this.go(null, { venue: this._venueSelector.value });
    }

    private _getSalesData(date: string) {
        return this._salesEntries.filter((e) => e.date === date).reduce((total, entry) => total + entry.amount, 0);
    }

    private _getHoursData(date: string) {
        return this._timeEntries
            .filter((e) => e.type === TimeEntryType.Work && e.date === date)
            .reduce((total, entry) => total + entry.durationFinal, 0);
    }

    private _getCostsData(date: string) {
        let total = 0;

        for (const employee of app.employees) {
            const statement = this._statements.find((s) => s.from <= date && s.to > date);
            const entries = this._timeEntries.filter((e) => e.employeeId === employee.id && e.date === date);

            if (!statement || !entries.length) {
                continue;
            }

            const hours = calcTotalHours(
                calcAllHours(employee, app.company!, entries, statement.previousAverages?.hoursPerWorkDay)
            );
            total += hours.salaryCost + hours.commissionCost + hours.bonusCost;
        }

        return total;
    }

    private _updateCharts() {
        for (const chart of this._enabledCharts) {
            chart.data = this._getChartData(chart);
            chart.config = this._getChartConfig(chart);
        }

        this.requestUpdate();
    }

    private _getChartData(chart: Chart) {
        const curr: number[] = [];
        const prev: number[] = [];

        for (let i = 0; i < this._range; i++) {
            const currVal = this._currRange
                .slice(i * this._resolution, (i + 1) * this._resolution)
                .reduce((total, d) => total + chart.getData(d), 0);
            const prevVal = this._prevRange
                .slice(i * this._resolution, (i + 1) * this._resolution)
                .reduce((total, d) => total + chart.getData(d), 0);
            curr.push(chart.total === "average" ? currVal / this._resolution : currVal);
            prev.push(chart.total === "average" ? prevVal / this._resolution : prevVal);
        }

        return { curr, prev };
    }

    private _getChartConfig({ data: { curr, prev }, title }: Chart): ChartConfig {
        return {
            chart: {
                type: "line",
                parentHeightOffset: 0,
                sparkline: { enabled: true },
                height: 200,
                id: title,
                group: "overview",
            },
            colors: [colors.blue, colors.black],
            series: [
                { name: "current", data: curr },
                { name: "previous", data: prev },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                custom: ({ dataPointIndex }) => {
                    this._showDataPoint = dataPointIndex;
                    return ``;
                },
                x: {
                    show: false,
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            stroke: {
                curve: "smooth",
                dashArray: [0, 5],
                width: 2,
            },
            fill: {
                opacity: [1, 0.5],
            },
            xaxis: {
                type: "category",
                axisBorder: { show: true },
                axisTicks: { show: true },
            },
        };
    }

    // private _updateCharts() {
    //     this._salesChart.config = this._getChartConfig({
    //         data: (d: string) => this._getSalesData(d),
    //         unit: "€"
    //     });
    //     this._costsChart.config = this._getChartConfig({
    //         data: (d: string) => this._getSalesData(d),
    //         unit: "€"
    //     });
    //     this._hoursChart.config = this._getChartConfig({
    //         data: (d: string) => this._getHoursData(d),
    //         unit: "Std."
    //     });
    // }

    static styles = [
        shared,
        Balance.styles,
        css`
            .venue-selector {
                font-size: var(--font-size-medium);
                padding-top: 0.3em;
                padding-bottom: 0.3em;
            }

            .charts {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(28em, 1fr));
            }

            .charts-tile {
                padding: 1em;
                border-right: dashed 1px var(--shade-2);
                border-bottom: dashed 1px var(--shade-2);
            }

            .chart-title {
                font-size: var(--font-size-big);
                font-weight: 600;
                padding-bottom: 0.5em;
            }

            .chart-total:last-child {
                text-align: right;
            }

            .chart-total-range {
                opacity: 0.5;
            }

            .chart-total-value {
                color: var(--color-highlight);
                font-size: var(--font-size-huge);
            }

            .chart-axis {
                padding: 0.5em;
                border-top: solid 1px var(--shade-2);
                color: var(--blue);
            }

            .highlight-data {
                transition: all 0.3s;
                margin: 0 0.5em;
                margin-bottom: -1em;
                width: 8em;
                flex: none;
            }

            .highlight-data.hide {
                opacity: 0;
            }

            .highlight-range {
                color: var(--color-fg);
                opacity: 0.5;
            }

            .highlight-data.current {
                text-align: right;
            }

            .highlight-value {
                font-size: var(--font-size-base);
                font-weight: 600;
            }

            .current .highlight-value {
                color: var(--color-primary);
            }

            .previous .highlight-value {
                color: var(--black);
            }
        `,
    ];

    render() {
        if (!app.company || !this.venue || !this._salesEntries || !this._timeEntries) {
            return html`
                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        const currRange = this._currRange;
        const currFromDate = formatDateShort(currRange[0]);
        const currToDate = formatDateShort(currRange[currRange.length - 1]);

        const prevRange = this._prevRange;
        const prevFromDate = formatDateShort(prevRange[0]);
        const prevToDate = formatDateShort(prevRange[prevRange.length - 1]);

        const showInd = this._showDataPoint;
        let showRangeCurr = "";
        let showRangePrev = "";

        if (showInd !== null) {
            let from = currRange[showInd * this._resolution];
            let to = currRange[(showInd + 1) * this._resolution - 1];
            showRangeCurr =
                from === to
                    ? `${formatWeekDayShort(from)}, ${formatDateShort(from)}`
                    : `${formatDateShort(from)} - ${formatDateShort(to)}`;

            from = prevRange[showInd * this._resolution];
            to = prevRange[(showInd + 1) * this._resolution - 1];
            showRangePrev =
                from === to
                    ? `${formatWeekDayShort(from)}, ${formatDateShort(from)}`
                    : `${formatDateShort(from)} - ${formatDateShort(to)}`;
        }

        return html`
            <div class="fullbleed vertical layout">
                <div class="header horizontal center-aligning layout padded-medium border-bottom">
                    <div class="horizontal tabs">
                        <button ?active=${this._range === 7 && this._resolution === 1} @click=${() => this.load(7, 1)}>
                            7 Tage
                        </button>
                        <button
                            ?active=${this._range === 14 && this._resolution === 1}
                            @click=${() => this.load(14, 1)}
                        >
                            14 Tage
                        </button>
                        <button
                            ?active=${this._range === 12 && this._resolution === 7}
                            @click=${() => this.load(12, 7)}
                        >
                            12 Wochen
                        </button>
                    </div>

                    <div class="stretch"></div>

                    <select class="venue-selector" @change=${this._selectVenue} ?hidden=${app.venues.length < 2}>
                        ${app.venues.map(
                            (venue) => html`
                                <option
                                    .value=${venue.id.toString()}
                                    ?selected=${venue.id === this.venueId}
                                    ?disabled=${!app.hasAccess({ venue })}
                                >
                                    ${venue.name}
                                </option>
                            `
                        )}
                    </select>
                </div>

                <ptc-scroller class="stretch">
                    <div class="charts">
                        ${repeat(
                            this._enabledCharts,
                            (chart) => chart.title,
                            (chart) => {
                                if (!chart.config) {
                                    return;
                                }
                                let currTotal = chart.data.curr.reduce((total: number, val: number) => total + val, 0);
                                let prevTotal = chart.data.prev.reduce((total: number, val: number) => total + val, 0);

                                if (chart.total === "average") {
                                    currTotal /= chart.data.curr.length;
                                    prevTotal /= chart.data.prev.length;
                                }

                                const diff = prevTotal ? Math.round(((currTotal - prevTotal) / prevTotal) * 100) : 0;

                                return html`
                                    <div class="charts-tile">
                                        <div class="chart-title horizontal center-aligning layout">
                                            <div class="stretch">${chart.title}</div>
                                            <div>
                                                <ptc-balance .value=${diff} icon="percent"></ptc-balance>
                                            </div>
                                        </div>
                                        <div class="horizontal layout">
                                            <div class="chart-total blue">
                                                <div class="chart-total-range">${currFromDate} - ${currToDate}</div>
                                                <div class="chart-total-value">
                                                    ${chart.total === "average" ? "∅" : ""} ${formatNumber(currTotal)}
                                                    ${chart.unit}
                                                </div>
                                            </div>
                                            <div class="stretch"></div>
                                            <div class="chart-total black">
                                                <div class="chart-total-range">${prevFromDate} - ${prevToDate}</div>
                                                <div class="chart-total-value">
                                                    ${chart.total === "average" ? "∅" : ""} ${formatNumber(prevTotal)}
                                                    ${chart.unit}
                                                </div>
                                            </div>
                                        </div>

                                        <ptc-chart
                                            .config=${chart.config}
                                            @mouseleave=${() => (this._showDataPoint = null)}
                                        ></ptc-chart>

                                        <div class="chart-axis horizontal layout">
                                            <div>${currFromDate}</div>
                                            <div class="stretch"></div>
                                            <div class="highlight-data current ${showInd === null ? "hide" : ""}">
                                                <div class="highlight-range">${showRangeCurr}</div>
                                                <div class="highlight-value">
                                                    ${formatNumber(chart.data.curr[showInd || 0])}
                                                </div>
                                            </div>
                                            <div class="highlight-data previous ${showInd === null ? "hide" : ""}">
                                                <div class="highlight-range">${showRangePrev}</div>
                                                <div class="highlight-value">
                                                    ${formatNumber(chart.data.prev[showInd || 0])}
                                                </div>
                                            </div>
                                            <div class="stretch"></div>
                                            <div>${currToDate}</div>
                                        </div>
                                    </div>
                                `;
                            }
                        )}
                    </div>
                </ptc-scroller>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
