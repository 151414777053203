import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { RevenueGroup, RevenueType, TaxKey, taxKeyLabel } from "@pentacode/core/src/model";
import { shared } from "../styles";
import { app } from "../init";
import { Checkbox } from "./checkbox";

@customElement("ptc-revenues-group-form")
export class RevenuesGroupForm extends LitElement {
    @property({ attribute: false })
    group!: RevenueGroup;

    @property({ attribute: false })
    readonly: boolean = false;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    @query("input[name='ledger']")
    private _ledgerInput: HTMLInputElement;

    @query("input[name='costCenter']")
    private _costCenterInput: HTMLInputElement;

    @query("select[name='taxKey']")
    private _taxKeySelect: HTMLSelectElement;

    @query("input[name='postingKey']")
    private _postingKeyInput: HTMLInputElement;

    @query("ptc-checkbox-button[name='cashbook']")
    private _cashbookButton: Checkbox;

    @query("ptc-checkbox-button[name='reporting']")
    private _reportingButton: Checkbox;

    @query("ptc-checkbox-button[name='daily']")
    private _dailyButton: Checkbox;

    @query("form")
    private _form: HTMLFormElement;

    reportValidity() {
        this._form && this._form.reportValidity();
    }

    async updated(changes: Map<string, any>) {
        if (changes.has("group") && this.group) {
            this._nameInput.value = this.group.name;
            this._taxKeySelect.value = this.group.taxKey.toString();
            this._ledgerInput.value = this.group.ledger;
            this._costCenterInput.value = this.group.costCenter;
            this._cashbookButton.checked = this.group.cashbook;
            this._reportingButton.checked = this.group.reporting;
            this._dailyButton.checked = this.group.daily;
            this._postingKeyInput.value = this.group.postingKey;
        }
    }

    private _updateGroup() {
        const formData = new FormData(this._form);
        const group = this.group!;
        group.name = formData.get("name") as string;
        const taxKey = parseInt(formData.get("taxKey") as string);
        group.taxKey = taxKey in TaxKey ? taxKey : TaxKey.None;
        group.ledger = formData.get("ledger") as string;
        group.costCenter = formData.get("costCenter") as string;
        group.cashbook = formData.has("cashbook");
        group.reporting = formData.has("reporting");
        group.daily = formData.has("daily");
        group.postingKey = formData.get("postingKey") as string;

        this.dispatchEvent(new CustomEvent("change", { detail: { group: this.group } }));
    }

    private _submit(e: Event) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit", { detail: { group: this.group } }));
    }

    private _cancel() {
        this.dispatchEvent(new CustomEvent("cancel"));
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: block;
            }

            input[type="number"] {
                text-align: right;
            }

            input[disabled],
            select[disabled] {
                opacity: 1 !important;
                border: none !important;
                background: none;
            }
        `,
    ];

    render() {
        const group = this.group;
        const venue = group && app.getVenue(group.venueId);

        if (!group || !venue) {
            return html``;
        }

        return html`
            <form autocomplete="off" @submit=${this._submit} @change=${this._updateGroup}>
                <div class="field">
                    <label>Text</label>
                    <input name="name" required ?disabled=${this.readonly} />
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field stretch">
                        <label>Steuersatz</label>
                        <select name="taxKey" ?disabled=${this.readonly}>
                            <option .value=${TaxKey.None.toString()}>${taxKeyLabel(TaxKey.None)}</option>
                            <option .value=${TaxKey.Free.toString()}>${taxKeyLabel(TaxKey.Free)}</option>
                            <option
                                .value=${TaxKey.Sales7.toString()}
                                ?disabled=${this.group.type === RevenueType.Expense}
                            >
                                ${taxKeyLabel(TaxKey.Sales7)}
                            </option>
                            <option
                                .value=${TaxKey.Sales19.toString()}
                                ?disabled=${this.group.type === RevenueType.Expense}
                            >
                                ${taxKeyLabel(TaxKey.Sales19)}
                            </option>
                            <option
                                .value=${TaxKey.Input7.toString()}
                                ?disabled=${this.group.type !== RevenueType.Expense}
                            >
                                ${taxKeyLabel(TaxKey.Input7)}
                            </option>
                            <option
                                .value=${TaxKey.Input9_5.toString()}
                                ?disabled=${this.group.type !== RevenueType.Expense}
                            >
                                ${taxKeyLabel(TaxKey.Input9_5)}
                            </option>
                            <option
                                .value=${TaxKey.Input19.toString()}
                                ?disabled=${this.group.type !== RevenueType.Expense}
                            >
                                ${taxKeyLabel(TaxKey.Input19)}
                            </option>
                        </select>
                    </div>

                    <div class="field stretch">
                        <label>Konto</label>
                        <input type="text" name="ledger" ?disabled=${this.readonly} />
                    </div>
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field stretch">
                        <label>Kostenst.</label>
                        <input type="text" name="costCenter" ?disabled=${this.readonly} />
                    </div>

                    <div class="field stretch">
                        <label>Buchungsschlüssel</label>
                        <input type="text" name="postingKey" ?disabled=${this.readonly} />
                    </div>
                </div>

                <div class="divider"></div>

                <div class="field">
                    <ptc-checkbox-button
                        name="cashbook"
                        label="Im Kassenbuch Führen"
                        buttonClass="ghost"
                    ></ptc-checkbox-button>
                </div>

                <div class="field">
                    <ptc-checkbox-button
                        name="reporting"
                        label="In Berichten Berücksichtigen"
                        buttonClass="ghost"
                    ></ptc-checkbox-button>
                </div>

                <div class="field">
                    <ptc-checkbox-button
                        name="daily"
                        label="Als Vorauswahl in Tagesabrechnung"
                        buttonClass="ghost"
                    ></ptc-checkbox-button>
                </div>

                <div class="horizontal spacing evenly stretching top-margined layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${this._cancel}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
