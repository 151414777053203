import { toDateString } from "@pentacode/core/src/util";
import { html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";
import "./scroller";
import { DateInput } from "./date-input";
import { EmployeeStatus, EntityFilter, TimeEntry, TimeEntryType } from "@pentacode/core/src/model";
import { DateString } from "@pentacode/openapi/src/units";
import { EntityFiltersEl } from "./entity-filters";
import { live } from "lit/directives/live.js";
import { DurationInput } from "./duration-input";
import { Checkbox } from "./checkbox";
import "./select-wage-type";
import "./avatar";

@customElement("ptc-batch-hour-adjustments-dialog")
export class BatchHourAdjustmentDialog extends Dialog<{ filters: EntityFilter[] } | undefined, boolean> {
    private get _employees() {
        return this._filtersInput?.filteredEmployees || app.accessibleEmployees;
    }

    preventDismiss = true;

    @query("ptc-date-input")
    private _dateInput: DateInput;

    @query("ptc-entity-filters")
    private _filtersInput: EntityFiltersEl;

    @query("ptc-checkbox-button[name='paid']")
    private _paidButton: Checkbox;

    @query("form")
    private _form: HTMLFormElement;

    private async _submit(e: Event) {
        e.preventDefault();

        this.loading = true;
        const entries: TimeEntry[] = [];

        for (const employee of this._employees) {
            const row = this.renderRoot.querySelector(`tr[data-employee-id="${employee.id}"]`) as HTMLTableRowElement;

            if (!row) {
                continue;
            }
            const timeInput = row.querySelector("ptc-duration-input[name='time']") as DurationInput;
            const time = timeInput.value;

            if (!time) {
                continue;
            }

            const entry = new TimeEntry({
                employeeId: employee.id,
                type: TimeEntryType.HourAdjustment,
            });

            const formData = new FormData(this._form);

            entry.date = formData.get("date") as DateString;
            entry.hours = time;
            entry.paid = formData.has("paid");
            entry.comment = formData.get("comment") as string;
            entry.wageType = formData.get("wageType") as string;

            entries.push(entry);
        }

        try {
            await app.createOrUpdateTimeEntries(entries, { wait: true, publish: true });
            this.done(true);
        } catch (err) {
            this.hide();
            await alert(err.message, { type: "warning" });
            this.show();
        }

        this.loading = false;
    }

    private _resetInputValues() {
        for (const employee of this._employees) {
            const row = this.renderRoot.querySelector(`tr[data-employee-id="${employee.id}"]`) as HTMLTableRowElement;
            if (!row) {
                continue;
            }
            const timeInput = row.querySelector("ptc-duration-input[name='time']") as DurationInput;
            timeInput.value = null;
        }
    }

    async show({
        filters = [{ type: "employeeStatus", value: EmployeeStatus.Active }],
        date = toDateString(new Date()),
    }: { date?: DateString; filters?: EntityFilter[] } = {}) {
        const promise = super.show();
        await this.updateComplete;
        this._filtersInput.filters = filters;
        this._dateInput.value = date;
        this._resetInputValues();
        return promise;
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        DateInput.styles,
        DurationInput.styles,
        css`
            .inner {
                --dialog-max-width: 510px;
                --dialog-width: 510px;
            }

            table {
                border-collapse: separate;
                display: block;
            }

            thead {
                display: block;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            thead th {
                text-align: center;
            }

            tbody {
                display: block;
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                display: grid;
                grid-template-columns: 1fr 2em 7em;
                align-items: center;
                width: 100%;
                background: var(--color-bg);
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th {
                font-weight: 600;
            }

            th,
            td {
                padding: 0.3em;
                vertical-align: middle;
                position: relative;
            }

            td {
                text-align: right;
            }

            td input {
                width: 100%;
            }
        `,
    ];

    renderContent() {
        const employees = this._employees;
        return html`
            <form class="vertical layout fit-vertically">
                <div class="double-padded border-bottom semibold"><i class="book"></i> Neue Zeitbuchungen</div>

                <ptc-scroller class="stretch">
                    <div class="double-padded">
                        <div class="fields spacing horizontal layout">
                            <div class="field">
                                <label>Datum</label>
                                <ptc-date-input
                                    name="date"
                                    class="slim"
                                    required
                                    @change=${() => this._resetInputValues()}
                                ></ptc-date-input>
                            </div>
                            <div class="field stretch">
                                <label>Mitarbeiter</label>
                                <ptc-entity-filters
                                    class="field larger focus-container"
                                    @change=${() => this.requestUpdate()}
                                ></ptc-entity-filters>
                            </div>
                        </div>

                        <div class="field">
                            <label><i class="comment"></i>Kommentar</label>
                            <textarea name="comment" placeholder='z.B. "Auszahlung Überstunden"'></textarea>
                        </div>

                        <div class="field stretch">
                            <ptc-checkbox-button
                                .label=${html` <i class="euro-sign"></i> Im Lohnbericht Berücksichtigen `}
                                name="paid"
                                buttonClass="transparent semislim"
                                @change=${() => this.requestUpdate()}
                            ></ptc-checkbox-button>

                            <ptc-popover trigger="hover" class="tooltip" alignment="right">
                                Wählen Sie diese Option, falls die abgebuchten oder gutgeschriebenen Stunden ausbezahlt
                                werden. Die Zeitbuchung wird dann in der Lohnbuchhaltung und in der
                                Personalkostenberechnung berücksichtigt.
                            </ptc-popover>
                        </div>

                        <div class="field" ?hidden=${!this._paidButton?.checked}>
                            <label><i class="calculator-simple"></i> Lohnart</label>
                            <ptc-select-wage-type name="wageType"></ptc-select-wage-type>
                        </div>
                        ${employees.length > 0
                            ? html`<table>
                                  <thead>
                                      <th></th>
                                      <th style="grid-column: span 2;">Betrag</th>
                                  </thead>
                                  <tbody>
                                      ${employees.map((employee) => {
                                          if (!employee.id) {
                                              return;
                                          }

                                          return html`
                                              <tr data-employee-id=${employee.id}>
                                                  <th class="center-aligning spacing horizontal layout">
                                                      <input
                                                          type="hidden"
                                                          name="employeeId"
                                                          .value=${live(employee.id?.toString())}
                                                      />
                                                      <ptc-avatar .employee=${employee} class="tiny"></ptc-avatar>
                                                      <div class="stretch ellipsis">
                                                          ${employee.lastName}, ${employee.firstName}
                                                      </div>
                                                  </th>

                                                  <td></td>

                                                  <td>
                                                      <ptc-duration-input
                                                          name="time"
                                                          class="skinny"
                                                          allowNegative
                                                          defaultSign="-"
                                                      ></ptc-duration-input>
                                                  </td>
                                              </tr>
                                          `;
                                      })}
                                  </tbody>
                              </table>`
                            : html`<div class="subtle text-centering top-margined">
                                  Die gewählten Filter treffen auf keine Mitarbeiter zu.
                              </div>`}
                    </div>
                </ptc-scroller>

                <div class="horizontal padded spacing evenly stretching layout">
                    <button ?disabled=${employees.length === 0} class="primary" @click=${this._submit}>
                        Übernehmen
                    </button>
                    <button type="button" class="transparent" @click=${() => this.done(false)}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
