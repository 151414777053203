import "./drawer";
import "./time-input";
import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { Department, RosterTemplate, TimeEntry, Venue } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import "./popover";
import { EntityMultiSelect } from "./entity-multi-select";
import { colors } from "../styles";
import { alert } from "./alert-dialog";
import { UpdateVenueParams } from "@pentacode/core/src/api";
import { app } from "../init";

type Input = { timeEntries: TimeEntry[]; venue: Venue; departments: Department[] };

@customElement("ptc-create-roster-template-dialog")
export class CreateRosterTemplateDialog extends Dialog<Input, RosterTemplate> {
    readonly preventDismiss = true;

    @state()
    private _availableDepartments: Department[] = [];

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _venue: Venue;

    @query("[name='departments']")
    private _departmentsInput: EntityMultiSelect<Department>;

    @query("form")
    private _form: HTMLFormElement;

    private async _submit(e: Event) {
        e.preventDefault();

        const departmentsInput = this._departmentsInput;

        const departments = departmentsInput?.selected.map((d) => d.id) || this._availableDepartments.map((d) => d.id);
        const formData = new FormData(this._form);

        const template = new RosterTemplate();
        template.name = (formData?.get("name") as string) || "";
        template.shifts = this._timeEntries
            .filter((a) => a.position && (!departments || departments.includes(a.position.departmentId)))
            .map((a) => ({
                day: a.start.getDay(),
                employee: a.employeeId,
                position: a.position!.id,
                start: a.startPlanned!.toTimeString().slice(0, 5),
                end: a.endPlanned!.toTimeString().slice(0, 5),
                break: a.breakPlanned,
            }));

        if (!template.shifts.length) {
            alert("Es wurden keine Schichten gewählt! (Dienstplanvorlagen umfassen nur Schichten mit Planzeiten)");
            return;
        }

        this.loading = true;
        try {
            await app.updateVenue(
                new UpdateVenueParams({
                    id: this._venue!.id,
                    rosterTemplates: [...this._venue!.rosterTemplates, template],
                })
            );
            this.requestUpdate();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this.loading = false;

        this.done(template);

        alert("Dienstplanvorlage erfolgreich erstellt.", { title: "Vorlage Erstellt", type: "success" });
    }

    async show({ timeEntries, departments, venue }: Input) {
        const promise = super.show();

        this._timeEntries = timeEntries;
        this._availableDepartments = departments;
        this._venue = venue;

        await this.updateComplete;

        this._departmentsInput.selected = departments.filter((d) =>
            this._timeEntries.some((e) => e.position?.departmentId === d.id)
        );

        return promise;
    }

    static styles = [...Dialog.styles, css``];

    renderContent() {
        return html`
            <div class="padded center-aligning horizontal layout border-bottom">
                <div class="half-padded semibold stretch">
                    <i class="floppy-disk-circle-arrow-right"></i> Vorlage Erstellen
                </div>
                <button class="slim transparent" @click=${() => this.done()}>
                    <i class="times"></i>
                </button>
            </div>
            <form class="padded spacing vertical layout" @submit=${this._submit}>
                <div class="field">
                    <label><i class="pen-field"></i> Name der Vorlage</label>
                    <input type="text" name="name" placeholder="Vorlagenname" required />
                </div>

                <div class="field">
                    <label><i class="smaller filter"></i> Abteilungen</label>

                    <ptc-entity-multi-select
                        name="departments"
                        .existing=${this._availableDepartments}
                        .getId=${(dep: Department) => dep.id}
                        .getLabel=${(dep: Department) =>
                            `${dep.name} (${
                                this._timeEntries.filter((entry) => entry.position?.departmentId === dep.id).length
                            })`}
                        .getColor=${(dep: Department) => colors[dep.color] || dep.color}
                        .getIcon=${() => "people-line"}
                        emptyLabel="Alle Abteilungen"
                        emptyIcon="people-group"
                        addLabel="Filter Hinzufügen..."
                        noOptionsLabel="Keine Abteilung gefunden."
                    ></ptc-entity-multi-select>
                </div>

                <div class="spacing evenly stretching horizontal layout">
                    <button class="primary">Vorlage Erstellen</button>
                </div>
            </form>
        `;
    }
}
