import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { routeProperty, Routing } from "../mixins/routing";
import { shared } from "../styles";
import "./recruiting-posting";
import { employmentTypeLabel, JobPosting } from "@pentacode/core/src/model";
import { app } from "../init";
import { alert } from "./alert-dialog";
import { formatDate } from "@pentacode/core/src/util";

@customElement("ptc-recruiting")
export class RecruitingPostings extends Routing(StateMixin(LitElement)) {
    readonly routePattern = /^recruiting(?:\/(?<id>[^\/]+))?/;

    get routeTitle() {
        return !this._selectedPosting
            ? "Stellenausschreibungen: "
            : `Stellenauschreibungen: ${this._selectedPosting.title}`;
    }

    @routeProperty({ arg: "id" })
    private _selectedPostingId: string;

    @state()
    private _postings: JobPosting[] = [];

    @state()
    private _loading = false;

    @state()
    private _selectedPosting: JobPosting | null = null;

    async updated(changes: Map<string, unknown>) {
        if (changes.has("active") && this.active) {
            await this._load();
        }
    }

    handleRoute() {
        this._selectedPosting = !this._selectedPostingId
            ? null
            : this._selectedPostingId === "new"
              ? new JobPosting({
                    venueId: app.venues[0]?.id,
                })
              : this._postings.find((p) => p.id === Number(this._selectedPostingId)) || null;
    }

    private async _load() {
        this._loading = true;

        try {
            this._postings = await app.api.getJobPostings();
            this.handleRoute();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        shared,
        css`
            .sub-menu {
                padding: 0;
                width: 20em;
            }
        `,
    ];

    render() {
        return html`
            <div class="fullbleed horizontal layout">
                <div class="sub-menu">
                    ${this._postings.map(
                        (posting) => html`
                            <div
                                class="double-padded border-bottom click ${this._selectedPostingId ===
                                posting.id?.toString()
                                    ? "bold blue colored-text"
                                    : ""}"
                                @click=${() => this.go(`recruiting/${posting.id}`)}
                            >
                                <div class="bottom-margined">${posting.title}</div>
                                <div class="tiny pills">
                                    <div class="pill">
                                        <i class="file-contract"></i> ${employmentTypeLabel(posting.employmentType)}
                                    </div>

                                    <div class="pill">
                                        <i class="clock"></i> Ab
                                        ${posting.start ? formatDate(posting.start) : " Sofort"}
                                    </div>

                                    ${posting.positions.map(
                                        (pos) => html`
                                            <div class="pill" style="--color-highlight: ${app.getPositionColor(pos)}">
                                                <i class="arrows-down-to-people"></i>
                                                ${pos.name}
                                            </div>
                                        `
                                    )}

                                    <div class="orange pill">
                                        <i class="image-polaroid-user"></i> ${posting.applications.length} Bew.
                                    </div>
                                </div>
                            </div>
                        `
                    )}
                    <div
                        class="double-padded border-bottom click ${this._selectedPostingId === "new"
                            ? "bold blue colored-text"
                            : ""}"
                        @click=${() => this.go("recruiting/new")}
                    >
                        <i class="plus"></i> Neue Ausschreibung
                    </div>
                </div>
                ${this._selectedPosting
                    ? html`
                          <ptc-recruiting-posting
                              class="stretch"
                              .posting=${this._selectedPosting}
                              @posting-edited=${() => this._load()}
                              @posting-created=${async (e: CustomEvent<{ posting: JobPosting }>) => {
                                  await this._load();
                                  this.go(`recruiting/${e.detail.posting.id}`);
                              }}
                              @posting-create-canceled=${() => this.go("recruiting")}
                              @posting-deleted=${async () => {
                                  await this._load();
                                  this.go("recruiting");
                              }}
                          ></ptc-recruiting-posting>
                      `
                    : html`
                          <div class="stretch faded double-padded centering vertical layout">
                              <i class="giant sign-hanging"></i>
                              <div>Keine Auschreibung gewählt.</div>
                          </div>
                      `}
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
